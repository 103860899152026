import { encrypt, decrypt } from "@/helpers/encrypt.helper";

export const localStorageGet = (key) => {
    var get_item = localStorage.getItem(key);
    if (!get_item) return get_item;
    return decrypt(get_item);
};

export const localStorageSet = (key, item) => {
    var item_encrypted = encrypt(item);
    localStorage.setItem(key, item_encrypted);
};

export const retrieveFromLocalStorageAndConvert = (key) => {
    var get_item = localStorage.getItem(key);
    if (!get_item) return get_item;
    var decrypted = decrypt(get_item);
    return JSON.parse(decrypted);
};

export const convertAndStoreInLocalStorage = (key, item) => {
    var stringfied = JSON.stringify(item);
    var item_encrypted = encrypt(stringfied);
    localStorage.setItem(key, item_encrypted);
};

export const localStorageSetMultiple = (obj) => {
    var keysOfObj = Object.keys(obj);
    keysOfObj.forEach((key) => localStorageSet(key, obj[key]));
};

export const localStorageRemove = (key) => {
    localStorage.removeItem(key);
};

export const localStorageRemoveMany = (arrayKeys) => {
    arrayKeys.forEach((key) => localStorageRemove(key));
};

export const sessionStorageGet = (key) => {
    var get_item = sessionStorage.getItem(key);
    if (!get_item) return get_item;
    return decrypt(get_item);
};

export const sessionStorageSet = (key, item) => {
    var item_encrypted = encrypt(item);
    sessionStorage.setItem(key, item_encrypted);
};

export const sessionStorageRemove = (key) => {
    sessionStorage.removeItem(key);
};

export const sessionStorageRemoveMany = (arrayKeys) => {
    arrayKeys.forEach((key) => localStorageRemove(key));
};
