import api from "@/services/api";
import { objectToURLQuery } from "@/helpers/http.helper";

const clearObject = (object) => {
    let newObj = {};

    Object.keys(object).forEach((key) => {
        if (object[key] === undefined) return;
        if (object[key] === null) return;
        if (Array.isArray(object[key]) && !object[key].length) return;

        newObj[key] = object[key];
    });
    return newObj;
};

export const blockUser = function (userId) {
    return api()
        .post(`/views/users/${userId}/block`)
        .then((res) => res.data);
};

export const unblockUser = function (userId) {
    return api()
        .post(`/views/users/${userId}/unblock`)
        .then((res) => res.data);
};

export const deactivateUser = function (userId) {
    return api()
        .post(`/views/users/${userId}/deactivate`)
        .then((res) => res.data);
};

export const activateUser = function (userId) {
    return api()
        .post(`/views/users/${userId}/activate`)
        .then((res) => res.data);
};

export const addNewUserBasedOnOther = function (userId, body) {
    return api()
        .post(`/views/users/${userId}`, body)
        .then((res) => res.data);
};

export const addMultipleUsersByFileUpload = function (data) {
    const { file, user_id, notify } = data;
    const form = new FormData();
    form.append("file", file);
    form.append("notify", notify);
    return api()
        .post(`/views/users/${user_id}/file`, form)
        .then((res) => res.data);
};

export const updateUserInfo = function (userId, data) {
    return api()
        .put(`/views/users/${userId}`, data)
        .then((res) => res.data);
};

export const massUpdateUsersInfo = function (filter, data) {
    let query = objectToURLQuery(clearObject(filter));
    return api()
        .put(`/views/users?${query}`, data)
        .then((res) => res.data);
};

export const getParameters = function (empresa_id = null) {
    let url = `/views/parameters`;

    if (empresa_id) url += `/${empresa_id}`;

    return api()
        .get(url)
        .then((res) => res.data);
};

export default {
    find(data) {
        const form = new FormData();
        Object.keys(data).forEach((item) => form.append(item, data[item]));
        return api().post("/log_stream", form);
    },
    changeOrderNumber(data) {
        const form = new FormData();
        form.append("old_number", data["old_number"]);
        form.append("new_number", data["new_number"]);

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ data: { status: "ok" } });
            }, 3000);
        });
        // return api().post(`/changeOrderNumber`, form);
    },
    getClients(params) {
        return api().get(`views/clients`, { params });
    },
    getEmailLogs(page, perPage) {
        return api()
            .get(`/email-logs?page=${page}&per_page=${perPage}`)
            .then((res) => res.data);
    },

    getAllUsers(data) {
        let query = objectToURLQuery(clearObject(data));
        return api()
            .get(`/views/users?${query}`)
            .then((res) => res.data);
    },
    blockUser,
    unblockUser,
    deactivateUser,
    activateUser,
    addNewUserBasedOnOther,
    addMultipleUsersByFileUpload,
    updateUserInfo,
    massUpdateUsersInfo,
    getParameters,
};
