// import i18n from "@/plugins/i18n";
import newOrderService from "@/services/api/new_order.js";
import pedidos_service from "@/services/api/pedidos";
import router from "@/router";
import { newOrderTableColumns } from "@/configs/table.columns";

function formateKeyName(string) {
    if (string) return string;
}

const initial_state = () => ({
    parameters: {
        header: {
            cod_client: "",
            cnpj: "",
            responsable: "",
            client_name: "",
            email: "",
            end_faturamento: "",
            end_envio: "",
            creation_date: "",
            num_order: "",
            num_contract: "",
            type_contract: "",
            contract_expiration: "",
        },
        body: {
            category: {
                LIOS: [
                    "cod_product",
                    "product",
                    "qtd",
                    "und",
                    "selling_price",
                    "negotiate_price",
                    "sub_total",
                    "alcada",
                    "type",
                    "obs",
                    "actions",
                ],
                Outros: [
                    "cod_product",
                    "product",
                    "qtd",
                    "und",
                    "qty",
                    "selling_price",
                    "negotiate_price",
                    "sub_total",
                    "alcada",
                    "type",
                    "obs",
                    "actions",
                ],
            },
        },
        footer: {
            sub_total: {},
            shipping_date: "",
            shipping: ["", ""],
            obs: "",
            total: 0,
            total_items: 0,
            payment_condition: "",
            payment_condition_available: [],
            payment_condition_available_description: [],

            //-- MERGE - 15-05 --
            // Armazena a lista de métodos e o selecionado
            payment_method: "",
            payment_method_available: [],
            payment_method_available_description: [],
        },
        new_items_condition: {
            ean: false,
            dioptria: true,
            allow_price_change: false,
            max_quantity: -1,
        },
        minimum_order_quantity: {},
    },

    payment_condition_selected: null,
    payment_method_selected: null,

    payment_method_options: {},

    list_filters: {},
    hierarchy_filter: {},

    show_modal_novo_pedido: false,
    show_modal_tracking: false,

    tracking_history: [],
    tracking_information: {},

    loading_parameters: false,
    loading_items: false,
    loading_actions: false,

    modal_cart: false,

    reason_codes: {},
    grouped_reason_codes: {},

    items: [],

    cod_client: "",

    products: [],

    loading_obs: false,

    total_items: 0,

    table_columns: newOrderTableColumns,
});

const state = initial_state();

const getters = {
    show_modal_novo_pedido: (state) => state.show_modal_novo_pedido,
    show_modal_tracking: (state) => state.show_modal_tracking,
    modal_cart: (state) => state.modal_cart,
    loading: (state) => state.loading_parameters || state.loading_items || state.loading_actions,

    minimun_order_quantity: (state) => state.parameters.minimum_order_quantity,

    header: (state) => state.parameters.header,
    footer: (state) => state.parameters.footer,
    payment_condition_selected: (state) => state.payment_condition_selected,
    payment_method_selected: (state) => state.payment_method_selected,

    reason_codes: (state) => state.reason_codes,
    grouped_reason_codes: (state) => state.grouped_reason_codes,

    list_filters: (state) => state.list_filters,
    hierarchy_filter: (state) => state.hierarchy_filter,

    tracking_history: (state) => state.tracking_history,
    tracking_information: (state) => state.tracking_information,

    cod_client: (state) => state.cod_client,

    products: (state) => state.products,

    obs: (state) => state.parameters.footer.obs,

    loading_obs: (state) => state.loading_obs,

    categories: (state) =>
        Object.keys(state.parameters["body"]["category"]).map((item) => formateKeyName(item)),

    items: (state, getters) => {
        var aux = {};
        getters.categories.forEach((item) => (aux[item] = []));
        state.items.forEach((item) => {
            if (!aux[formateKeyName(item.type)]) {
                aux[formateKeyName(item.type)] = [item];
            } else {
                aux[formateKeyName(item.type)].push(item);
            }
        });
        return aux;
    },

    items_as_array: (state) => state.items,

    table_fields_each_type: (state) => {
        var obj = {};
        Object.keys(state.parameters.body.category).forEach((item) => {
            var aux = [];

            if (
                !state.parameters.body.category[item] ||
                state.parameters.body.category[item].lenth == 0
            ) {
                obj[item] = state.table_columns;
            }

            state.parameters.body.category[item].forEach((i) => {
                state.table_columns.forEach((j) => {
                    if (i == j.key || j.key == "index" || j.key == "selection") {
                        if (
                            !(
                                state.parameters.header.blocked &&
                                (j.key == "selection" || j.key == "actions")
                            )
                        )
                            aux.push(j);
                    }
                });
            });
            obj[item] = aux;
        });
        return obj;
    },

    payment_method_options: (state) => state.payment_method_options,
};

const mutations = {
    SHOW_MODAL_CART: (state, value) => (state.modal_cart = value),
    SHOW_MODAL_NOVO_PEDIDO: (state, value) => (state.show_modal_novo_pedido = value),
    SHOW_MODAL_TRACKING: (state, value) => (state.show_modal_tracking = value),
    SET_TRACKING_HISTORY: (state, value) => (state.tracking_history = value),
    SET_TRACKING_INFORMATION: (state, value) => (state.tracking_information = value),
    SET_LOADING_PARAMETERS: (state, value) => (state.loading_parameters = value),
    SET_LOADING_ITEMS: (state, value) => (state.loading_items = value),
    SET_LOADING_ACTIONS: (state, value) => (state.loading_actions = value),
    SET_PARAMETERS: (state, value) => (state.parameters = value),
    CHANGE_END_ENVIO: (state, value) => (state.parameters.header.end_envio = value),
    SET_LIST_OF_FILTERS: (state, value) => (state.list_filters = value),
    SET_HIERARCHY_FILTERS: (state, value) => (state.hierarchy_filter = value),
    SET_REASON_CODES: (state, value) => (state.reason_codes = value),
    SET_GROUPED_REASON_CODES: (state, value) => (state.grouped_reason_codes = value),

    SET_ITEMS: (state, value) => (state.items = value),
    SET_PRODUCTS: (state, value) => (state.products = value),
    SET_COD_CLIENT: (state, value) => (state.cod_client = value),
    SET_TOTAL_ITEMS: (state, value) => (state.total_items = value),
    SET_LOADING_OBS: (state, value) => (state.loading_obs = value),
    SET_FOOTER: (state, footer) => (state.parameters.footer = footer),
    SET_PAYMENT_CONDITION: (state, value) => (state.payment_condition_selected = value),
    SET_PAYMENT_METHOD: (state, value) => (state.payment_method_selected = value),
    SET_RESET_STORE: (state) => Object.assign(state, initial_state()),
    SET_PAYMENT_METHOD_OPTIONS: (state, value) => (state.payment_method_options = value),
};

const actions = {
    createDraftOrder({ commit, dispatch }, payload) {
        commit("saveStatus", "loading", { root: true });

        const cod_client = payload.client_code;
        const payment_method = payload.payment_code ? payload.payment_code : null;

        return newOrderService
            .newOrder(cod_client, payment_method)
            .then((result) => {
                commit("SHOW_MODAL_NOVO_PEDIDO", false);

                dispatch("getParameters", result.data["id"])
                    .then(() => {
                        router.push("/new_order/" + result.data["id"]);
                    })
                    .finally(() => commit("saveStatus", "success", { root: true }));
            })
            .catch((err) => {
                commit("saveStatus", "error", { root: true });
                const message_error = err.response.data.error;
                commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", message_error ? message_error : "Erro", {
                    root: true,
                });
            });
    },
    getParameters({ commit }, id_order) {
        commit("SET_LOADING_PARAMETERS", true);

        return new Promise(function (resolve, reject) {
            newOrderService
                .getParameters(id_order)
                .then(function (result) {
                    commit("SET_PARAMETERS", result.data);
                    const products = result.data["products"];
                    commit("SET_PRODUCTS", products);
                    commit("SET_LOADING_PARAMETERS", false);
                    commit("SET_LIST_OF_FILTERS", result.data["sku_columns"]);
                    commit("SET_HIERARCHY_FILTERS", result.data["sku_hierarchy"]);
                    commit("SET_REASON_CODES", result.data["reason_codes"]);
                    commit("SET_GROUPED_REASON_CODES", result.data["grouped_reason_codes"]);
                    resolve(result);
                })
                .catch(function (err) {
                    const message_error = err.response.data.error;
                    commit(
                        "PUSH_ERROR_TO_ARRAY_PURE_TEXT",
                        message_error ? message_error : "Erro",
                        {
                            root: true,
                        }
                    );
                    commit("SET_LOADING_PARAMETERS", false);
                    reject(err);
                });
        });
    },
    async getParametersAndItems({ dispatch }, id_order) {
        await dispatch("getParameters", id_order);
        dispatch("getItems", id_order);
    },
    async getItems({ commit }, id_order) {
        commit("SET_LOADING_ITEMS", true);

        try {
            const result = await newOrderService.getItems(id_order);
            commit("SET_ITEMS", result.data["items"]);
            commit("SET_TOTAL_ITEMS", result.data["total_items"]);
            if (result["data"]["footer"]) {
                commit("SET_FOOTER", result["data"]["footer"]);
            }
        } catch (error) {
            const message_error = error.response.data.error;
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", message_error ? message_error : "Erro", {
                root: true,
            });
        }
        commit("SET_LOADING_ITEMS", false);
    },
    async saveObs({ commit }, { id, obs }) {
        commit("saveStatus", "loading", { root: true });
        try {
            commit("SET_LOADING_OBS", true);
            const result = await newOrderService.saveOBS(id, obs);
            if (result["data"]["footer"]) {
                commit("SET_FOOTER", result["data"]["footer"]);
            }
            commit("SET_LOADING_OBS", false);
            commit("saveStatus", "success", { root: true });
            return true;
        } catch (error) {
            commit("SET_LOADING_OBS", false);
            commit("saveStatus", "error", { root: true });
            const message_error = error.response.data.error;
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", message_error ? message_error : "Erro", {
                root: true,
            });
            return false;
        }
    },
    async deleteItem({ commit, dispatch, getters }, { id, cod_product }) {
        if (getters.header.blocked || getters.loading) return;
        const paymentCondition = getters.payment_condition_selected;
        const result = await dispatch(
            "modal/ModalGeneric",
            {
                title: "Deseja apagar o item " + cod_product + "?",
                text: `Essa ação não poderá ser desfeita, deseja continuar?`,
                icon: "error",
            },
            { root: true }
        );

        if (!result) return;

        commit("saveStatus", "loading", { root: true });
        try {
            const result = await newOrderService.deleteItem(id, [cod_product], paymentCondition);
            if (result["data"]["footer"]) {
                commit("SET_FOOTER", result["data"]["footer"]);
            }
            dispatch("getItems", id);
            commit("PUSH_SUCCESS_TO_ARRAY", "Item deletado com sucesso!", { root: true });
        } catch (error) {
            const message_error = error.response.data.error;
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", message_error ? message_error : "Erro", {
                root: true,
            });
        }
        commit("saveStatus", "success", { root: true });
    },
    async deleteMultipleItems({ commit, dispatch, getters }, { id, products }) {
        if (getters.header.blocked) return;
        if (products.length == 0) return;
        const paymentCondition = getters.payment_condition_selected;

        const result = await dispatch(
            "modal/ModalGeneric",
            {
                title: `Deseja apagar o${products.length > 1 ? "s" : ""} item${
                    products.length > 1 ? "s" : ""
                } selecionado${products.length > 1 ? "s" : ""}?`,
                text: `Essa ação não poderá ser desfeita, ${
                    products.length > 1
                        ? "todos os items selecionados serão"
                        : "o item selecionado será"
                } apagado${products.length > 1 ? "s" : ""}, deseja continuar?`,
                icon: "error",
            },
            { root: true }
        );

        if (!result) return;

        commit("SET_LOADING_ACTIONS", true);

        newOrderService
            .deleteItem(
                id,
                products.map((item) => item.cod_product),
                paymentCondition
            )
            .then((result) => {
                commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", "Exclusão bem sucedida!", {
                    root: true,
                });
                if (result["data"]["footer"]) {
                    commit("SET_FOOTER", result["data"]["footer"]);
                }
                dispatch("getItems", id);
            })
            .catch((err) => {
                const message_error = err.response.data.error;
                commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", message_error ? message_error : "Erro", {
                    root: true,
                });
            })
            .finally(() => commit("SET_LOADING_ACTIONS", false));
    },
    async addItems({ commit, dispatch, getters }, { id, items }) {
        if (getters.header.blocked) return;

        const paymentCondition = null; //getters.payment_condition_selected;

        commit("SET_LOADING_ACTIONS", true);
        var array_promises = items.map((item) =>
            newOrderService.insertNewItem(id, item, paymentCondition)
        );

        return new Promise((resolve, reject) => {
            Promise.allSettled(array_promises).then((results) => {
                const arrayFailures = results.filter((item) => item.status == "rejected");
                const arraySuccess = results.filter((item) => item.status == "fulfilled");

                const itemsThatFailedToBeAddedToTheOrder = items
                    .filter((item, index) => results[index].status == "rejected")
                    .map((item, index) => ({
                        ...item,
                        error_message: arrayFailures[index].reason.response.data.error,
                    }));

                if (arraySuccess.length > 0) {
                    const lastFufilledResponse = arraySuccess[arraySuccess.length - 1];
                    const footer = lastFufilledResponse.value.data.footer;
                    if (footer) commit("SET_FOOTER", footer);
                    dispatch("getItems", id);
                }

                if (arrayFailures.length > 0) {
                    arrayFailures.forEach((failure) => {
                        commit(
                            "PUSH_ERROR_TO_ARRAY_PURE_TEXT",
                            failure.reason.response.data.error,
                            {
                                root: true,
                            }
                        );
                    });

                    if (arraySuccess.length > 0) {
                        const message = `${arraySuccess.length} item(s) adicionado(s)!`;
                        commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", message, {
                            root: true,
                        });
                    }

                    reject(itemsThatFailedToBeAddedToTheOrder);
                } else {
                    commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", "Sucesso ao adicionar os itens!!", {
                        root: true,
                    });
                    resolve("success");
                }
                commit("SET_LOADING_ACTIONS", false);
            });
        });
    },
    async createOrder({ commit, dispatch, getters }, order_id) {
        if (getters.loading || getters.loading_obs) return;
        if (getters.header.blocked) return;

        // Modal de notificação da condição de pagamento
        if (
            getters.products.length > 0 &&
            getters.footer.payment_condition_available &&
            getters.footer.payment_condition_available.length > 0
        ) {
            const indexOfPaymentCondition = getters.footer.payment_condition_available.indexOf(
                getters.payment_condition_selected
            );
            const descriptionPaymentConditionSelected =
                getters.footer.payment_condition_available_description[indexOfPaymentCondition];

            let descriptionPaymentMethodSelected = "";
            if (getters.footer.payment_method_available) {
                const indexOfPaymentMethod = getters.footer.payment_method_available.indexOf(
                    getters.payment_method_selected
                );
                descriptionPaymentMethodSelected =
                    getters.footer.payment_method_available_description[indexOfPaymentMethod];
            }

            const resultPaymentCondition = await dispatch(
                "modal/ModalGeneric",
                {
                    title: "Confirme os dados de pagamento",
                    html: `
                        <ol style="text-align: left;">
                            <li>Método de pagamento selecionado: <b>${descriptionPaymentMethodSelected}</b></li>
                            <li>O valor total é: <b>${Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(getters.footer.total)}</b></li>
                            <li>A condição de pagamento é: <b>${descriptionPaymentConditionSelected} (${
                        getters.payment_condition_selected
                    })</b></li>
                        </ol>
                        <p>
                        Não será possível alterá-los após a geração do pedido. Deseja continuar?
                        </p>
                    `,
                    icon: "warning",
                },
                { root: true }
            );

            if (!resultPaymentCondition) return;
        }

        const result = await dispatch(
            "modal/ModalGeneric",
            {
                title: "Deseja gerar o pedido?",
                text: `Quando o pedido for gerado, ele não poderá ser alterado dentro do ped+, deseja continuar?`,
                icon: "success",
            },
            { root: true }
        );

        if (!result) return;

        commit("SET_LOADING_ACTIONS", true);
        return newOrderService
            .createOrder(order_id)
            .then(() => {
                commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", "Sucesso ao gerar o pedido!", {
                    root: true,
                });
                router.push("/curatorship");
            })
            .catch((err) => {
                const message_error = err.response.data.error;
                commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", message_error ? message_error : "Erro", {
                    root: true,
                });
            })
            .finally(() => commit("SET_LOADING_ACTIONS", false));
    },
    inputChanged({ commit, dispatch, getters }, { order_id, item, value }) {
        if (getters.header.blocked) return;
        const paymentCondition = null; //getters.payment_condition_selected;

        commit("SET_LOADING_ACTIONS", true);

        newOrderService
            .updateQtd(order_id, item.cod_product, value, paymentCondition)
            .then((result) => {
                if (result["data"]["footer"]) {
                    commit("SET_FOOTER", result["data"]["footer"]);
                }
                dispatch("getItems", order_id);
            })
            .catch((err) => {
                const message_error = err.response.data.error;
                commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", message_error ? message_error : "Erro", {
                    root: true,
                });
            })
            .finally(() => commit("SET_LOADING_ACTIONS", false));
    },
    changeEndEnvio({ commit }, { order_id, new_cod }) {
        return new Promise(function (resolve, reject) {
            newOrderService
                .changeEndEnvio(order_id, new_cod)
                .then((result) => {
                    commit("CHANGE_END_ENVIO", result["data"]["end_envio"]);
                    commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", "Sucesso ao alterar o envio!", {
                        root: true,
                    });
                    resolve(result);
                })
                .catch((err) => {
                    const message_error = err.response.data.error;
                    commit(
                        "PUSH_ERROR_TO_ARRAY_PURE_TEXT",
                        message_error ? message_error : "Erro",
                        {
                            root: true,
                        }
                    );
                    reject(err);
                });
        });
    },
    track_order({ commit }, id_order) {
        commit("saveStatus", "loading", { root: true });
        return pedidos_service
            .track_order(id_order)
            .finally(() => commit("saveStatus", "success", { root: true }));
    },
    async changePaymentCondition({ commit, dispatch }, { id_order, cond_pagto }) {
        commit("SET_PAYMENT_CONDITION", cond_pagto);
        await newOrderService.updatePaymentCondition(id_order, cond_pagto).catch(() => {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao atualizar a condição de pagamento!", {
                root: true,
            });
        });
        dispatch("getItems", id_order);
    },
    //-- MERGE - 15-05 --
    async changePaymentMethod({ commit, dispatch }, { id_order, tipo_pagto }) {
        // Atualiza o método de pagamento na blueprint
        commit("saveStatus", "loading", { root: true });
        commit("SET_PAYMENT_METHOD", tipo_pagto);
        await newOrderService.updatePaymentMethod(id_order, tipo_pagto).catch((err) => {
            const message_error = err.response.data.error;
            commit(
                "PUSH_ERROR_TO_ARRAY_PURE_TEXT",
                message_error ? message_error : "Erro ao atualizar a metodo de pagamento!",
                {
                    root: true,
                }
            );
        });
        await dispatch("getParameters", id_order);
        await dispatch("getItems", id_order);
        commit("saveStatus", "success", { root: true });
    },
    //-- MERGE - 15-05 --
    async getPaymentMethods({ commit }) {
        // Retorna obj com todos métodos de pagamento
        commit("SET_LOADING_ITEMS", true);
        try {
            const result = await newOrderService.getPaymentMethods();
            if (result.data) {
                commit("SET_PAYMENT_METHOD_OPTIONS", result.data["payment_methods"]);
            } else {
                throw "result.data is invalid";
            }
        } catch (error) {
            commit("SET_PAYMENT_METHOD_OPTIONS", {});
        }
        commit("SET_LOADING_ITEMS", false);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
