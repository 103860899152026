import CryptoJS from "crypto-js";

const SECRET = process.env.VUE_APP_ENCRYPTION_KEY;

export const encrypt = (obj) => {
    if (!obj) return null;
    try {
        var stringfy = JSON.stringify(obj);
        var encryption_result = CryptoJS.AES.encrypt(stringfy, SECRET).toString();
        return encryption_result;
    } catch (error) {
        return obj;
    }
};

export const decrypt = (encryptedString) => {
    if (!encryptedString) return null;
    var obj;
    try {
        obj = CryptoJS.AES.decrypt(encryptedString, SECRET).toString(CryptoJS.enc.Utf8);
        if (!obj) return null;
        var decryption_result = JSON.parse(obj);
        return decryption_result;
    } catch (error) {
        return encryptedString;
    }
};
