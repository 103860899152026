import i18n from "@/plugins/i18n";
import router from "@/router";
import help_service from "@/services/api/help";
import { helpTableColumns } from "@/configs/table.columns";
const initial_state = () => ({
    loading_most_asked: false,
    loading_faqs: false,
    loading_tutorials: false,
    loading_single_tutorial: false,

    loading_saving_record: false,

    // Titles help header
    title_of_page: "",
    subtitle_of_page: "",

    title_tutorial: "",
    subtitle_tutorial: "",

    text_back: false,
    center_of_page: "",

    loading_list_table: false,
    loading_editor: false,

    loading_help_center: true,

    most_asked: [],
    all_faqs: [],
    all_tutorials: [],

    // filter
    search_filter: "",

    all_items: [],

    list_table: [],
    list_table_fields: helpTableColumns,
    list_table_pagination: {
        page: 1,
        per_page: 10,
        total_items: 0,
    },
});

const state = initial_state();

const getters = {
    loading_list_table: (state) => state.loading_list_table,

    loading_most_asked: (state) => state.loading_most_asked,
    loading_faqs: (state) => state.loading_faqs,
    loading_tutorials: (state) => state.loading_tutorials,
    loading_single_tutorial: (state) => state.loading_single_tutorial,
    loading_saving_record: (state) => state.loading_saving_record,

    loading_editor: (state) => state.loading_editor,
    loading_help_center: (state) =>
        state.loading_most_asked ||
        state.loading_faqs ||
        state.loading_tutorials ||
        state.loading_single_tutorial,

    most_asked: (state) => state.most_asked,

    all_faqs: (state) => {
        let aux = {};
        state.all_faqs.forEach((item) => {
            if (!aux[item.category]) {
                aux[item.category] = [item];
            } else {
                aux[item.category].push(item);
            }
        });
        return aux;
    },

    all_tutorials: (state) => {
        let aux = {};
        state.all_tutorials.forEach((item) => {
            if (!aux[item.category]) {
                aux[item.category] = [item];
            } else {
                aux[item.category].push(item);
            }
        });
        return aux;
    },

    search_filter: (state) => state.search_filter,
    list_table_fields: (state) => state.list_table_fields,
    list_table: (state) => state.list_table,
    list_table_page: (state) => state.list_table_pagination.page,
    list_table_total_items: (state) => state.list_table_pagination.total_items,
    list_table_per_page: (state) => state.list_table_pagination.per_page,

    filter_to_api: (state) => ({
        per_page: state.list_table_pagination.per_page,
        offset: state.list_table_pagination.page - 1,
        search: state.search_filter,
    }),
};

const mutations = {
    SET_LOADING_LIST_TABLE: (state, value) => (state.loading_list_table = value),
    SET_LOADING_HELP_CENTER: (state, value) => (state.loading_help_center = value),
    SET_SEARCH_FILTER: (state, value) => (state.search_filter = value),

    SET_LOADING_MOST_ASKED: (state, value) => (state.loading_most_asked = value),
    SET_LOADING_FAQS: (state, value) => (state.loading_faqs = value),
    SET_LOADING_TUTORIALS: (state, value) => (state.loading_tutorials = value),
    SET_LOADING_SINGLE_TUTORIAL: (state, value) => (state.loading_single_tutorial = value),
    SET_LOADING_SAVING_RECORD: (state, value) => (state.loading_saving_record = value),

    SET_HELP_TITLES_WITH_ROUTE_CHANGES: (state, name_of_router) => {
        switch (name_of_router) {
            case "help_center":
                state.title_of_page = "pages.help_center.home.title";
                state.subtitle_of_page = "pages.help_center.home.subtitle";
                state.center_of_page = true;
                state.text_back = false;
                break;

            case "help_center_faqs":
                state.title_of_page = "pages.help_center.home.faq_title";
                state.subtitle_of_page = "pages.help_center.home.faq_subtitle";
                state.text_back = i18n.t("pages.help_center.home.text_back");
                state.center_of_page = false;
                break;

            case "help_center_guides":
                state.title_of_page = "pages.help_center.home.tutorials_title";
                state.subtitle_of_page = "pages.help_center.home.tutorials_subtitle";
                state.text_back = i18n.t("pages.help_center.home.text_back");
                state.center_of_page = false;
                break;

            case "help_center_guide":
                state.title_of_page = "";
                state.subtitle_of_page = "";
                state.text_back = i18n.t("pages.help_center.home.text_back_tutorials");
                state.center_of_page = false;
                break;
        }
    },
    SET_HELP_TUTORIAL_TITLE_AND_SUBTITLE: (state, { title, subtile }) => {
        state.title_of_page = title;
        state.subtitle_of_page = subtile;
    },

    SET_MOST_ASKED: (state, value) => (state.most_asked = value),
    SET_FAQS: (state, value) => (state.all_faqs = value),
    SET_TUTORIALS: (state, value) => (state.all_tutorials = value),

    SET_ALL_ITEMS: (state, items) => (state.all_items = items),
    SET_LIST_TABLE: (state, list) => (state.list_table = list),
    SET_LIST_PAGE: (state, page) => (state.list_table_pagination.page = page),
    SET_LIST_PER_PAGE: (state, per_page) => (state.list_table_pagination.per_page = per_page),
    SET_LIST_TOTAL_ITEMS: (state, total_items) =>
        (state.list_table_pagination.total_items = total_items),

    RESET_DATA: (state) => {
        state.search_filter = "";
        state.page = 1;
    },
};

const actions = {
    search({ dispatch }) {
        dispatch("changePage", 1);
    },
    clear({ commit, dispatch }) {
        commit("RESET_DATA");
        dispatch("getRegisters");
    },

    changePage({ commit, dispatch }, page) {
        commit("SET_LIST_PAGE", page);
        dispatch("getRegisters");
    },

    changePerPage({ commit, dispatch }, per_page) {
        commit("SET_LIST_PER_PAGE", per_page);
        commit("SET_LIST_PAGE", 1);
        dispatch("getRegisters");
    },

    async getFiveMostAsked({ commit }) {
        commit("SET_LOADING_MOST_ASKED", true);
        try {
            const result = await help_service.most_five();
            commit("SET_MOST_ASKED", result.data);
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao buscar perguntas", { root: true });
        }
        commit("SET_LOADING_MOST_ASKED", false);
    },

    async getFAQS({ commit }) {
        commit("SET_LOADING_FAQS", true);
        try {
            const result = await help_service.faqs();
            commit("SET_FAQS", result.data);
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "action failed", { root: true });
        }

        commit("SET_LOADING_FAQS", false);
    },

    async getTutorials({ commit }) {
        commit("SET_LOADING_TUTORIALS", true);
        try {
            const result = await help_service.tutorial_list();
            commit("SET_TUTORIALS", result.data);
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "action failed", { root: true });
        }
        commit("SET_LOADING_TUTORIALS", false);
    },

    async getTutorialById({ commit }, id) {
        commit("SET_LOADING_SINGLE_TUTORIAL", true);
        try {
            const result = await help_service.tutorial_by_id(id);
            commit("SET_LOADING_SINGLE_TUTORIAL", false);
            return result.data;
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "action failed", { root: true });
        }
        commit("SET_LOADING_SINGLE_TUTORIAL", false);
    },

    async getRegisters({ commit, getters }) {
        commit("SET_LOADING_LIST_TABLE", true);
        try {
            const filter = getters.filter_to_api;
            const res = await help_service.registers(filter);
            const response = res.data;

            commit("SET_LIST_TABLE", response.items);
            commit("SET_LIST_TOTAL_ITEMS", response.total_items);
        } catch (error) {
            commit("SET_LIST_TABLE", []);
            commit("SET_LIST_TOTAL_ITEMS", 0);
        }
        commit("SET_LOADING_LIST_TABLE", false);
    },

    getRegisterById({}, id) {
        return help_service.register_by_id(id).then((res) => res.data);
    },

    countRegisterClicked({}, id) {
        return help_service.count_register(id).then((res) => res.data);
    },

    async saveRecord({ commit }, data) {
        commit("SET_LOADING_SAVING_RECORD", true);
        commit("saveStatus", "loading", { root: true });
        try {
            if (data.id == "new") {
                let aux = data;
                delete aux.id;
                await help_service.new_register(data);
                commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", "Salvo com sucesso!", { root: true });
                router.push("/help_manager");
            } else {
                let aux = data;
                await help_service.update_register(aux.id, aux);
                commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", "Editado com sucesso!", { root: true });
            }
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", error.response.data.msg, { root: true });
        }
        commit("SET_LOADING_SAVING_RECORD", false);
        commit("saveStatus", "success", { root: true });
    },

    async DeleteHelp({ dispatch, commit }, id) {
        const confirm = await dispatch(
            "modal/ModalGeneric",
            {
                title: "Apagar Registro?",
                text: "Ao confirmar esse registro será apagado, essa ação não pode ser desfeita, deseja continuar?",
            },
            { root: true }
        );

        if (!confirm) return;

        try {
            await help_service.delete_register(id);

            commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", "Registro apagado com sucesso!", {
                root: true,
            });
            dispatch("getRegisters");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao apagar registro!", { root: true });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
