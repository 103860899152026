<template>
    <div class="loading-screen">
        <b-spinner
            class="spinner"
            style="width: 8rem; height: 8rem"
            label="Large Spinner"
        ></b-spinner>
    </div>
</template>

<script>
export default {
    name: "LoadingScreen",
};
</script>

<style lang="scss" scoped>
.texto-loading-screen {
    color: white;
    font-size: 22px;
}
</style>
