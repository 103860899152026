import api from "@/services/api";

export default {
    listTemplates(data, page, perPage) {
        let filters = "";

        Object.keys(data).forEach((field) => {
            if (data[field]) filters += `&${field}=${data[field]}`;
        });

        const query = `?page=${page - 1}&per_page=${perPage}&${filters}`;

        return api().get("/templates/filter" + query);
    },

    listDistinctTemplates() {
        return api().get("/templates/distinct");
    },
    updateTemplates(data) {
        return api()
            .patch("/templates", data)
            .then((res) => res.data);
    },
    createTemplate(data) {
        const form = new FormData();
        Object.keys(data).forEach((field) => {
            if (data[field] && field !== "file") form.append(field, data[field]);
            else if (field == "file") {
                data[field].forEach((file, index) => form.append(`file${index}`, file));
            }
        });
        return api()
            .post("/templates", form)
            .then((res) => res.data);
    },
    addThumbnailsInTemplate(template, files) {
        const form = new FormData();
        files.forEach((item, index) => form.append(`file${index}`, item));
        for (const chave in template) {
            if (template.hasOwnProperty(chave)) {
                const valor = template[chave];

                if (Array.isArray(valor)) {
                    // Se o valor for um array, itere pelos itens e adicione-os ao FormData com nomes únicos
                    valor.forEach((item, index) => {
                        form.append(`${chave}_${index}`, item);
                    });
                } else {
                    // Se não for um array, adicione o valor normalmente
                    form.append(chave, valor);
                }
            }
        }

        return api().patch("/templates/thumbnails", form);
    },
    deleteTemplate(template) {
        return api()
            .delete("/templates", { data: template })
            .then((res) => res.data);
    },
    downloadTemplate(data) {
        const { empresa_id, template } = data;
        return api()
            .get(`/download_template?company_id=${empresa_id}&filename=${template}`)
            .then((res) => res.data);
    }
};
