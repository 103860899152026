import api from '@/services/api'

export default {
    list (data) {
        const form = new FormData()

        form.append('limpar', '')
        form.append('offset', data.offset)
        form.append('per_page', data.per_page ? data.per_page : 10)

        return api().post('/curadoria', form)
    },
    find (data) {        
        const form = new FormData()

        form.append('buscar', '')
        form.append('item_search', data.item_search)
        form.append('ean', data.ean)
        form.append('cnpj_search', data.cnpj_search)
        form.append('nome_cliente', data.nome_cliente)
        form.append('status_filter', '')
        form.append('status_curadoria_filter', data.status_curadoria_filter)
        form.append('per_page', data.per_page ? data.per_page : 10)
        form.append('offset', data.offset)

        return api().post('/curadoria', form)
    },
    update (data) {      
        const form = new FormData()

        form.append('update', data.update)
        form.append('cnpj', data.cnpj)
        form.append('ds_sku_input', data.ds_sku_input)
        form.append('sugestoes_nome', data.sugestoes_nome)
        form.append('nova_sugestao', data.nova_sugestao)
        form.append('qty', data.qty)
        form.append('vigencia_inicial', data.vigencia_inicial)
        form.append('vigencia_final', data.vigencia_final)
        form.append('per_page', data.per_page ? data.per_page : 10)
        form.append('offset', 0)
        
        return api().post('/curadoria', form)
    },
    process (data) {      
        const form = new FormData()

        form.append('processar_checked', '')
        form.append('cnpj', data.cnpj)
        form.append('ds_sku_input', data.ds_sku_input)
        form.append('sugestoes_nome', data.sugestoes_nome)
        form.append('nova_sugestao', data.nova_sugestao)
        form.append('qty', data.qty)
        form.append('vigencia_inicial', data.vigencia_inicial)
        form.append('vigencia_final', data.vigencia_final)
        form.append('per_page', data.per_page ? data.per_page : 10)
        form.append('offset', 0)
        
        return api().post('/curadoria', form)
    }, 
    export(data) {
        const form = new FormData(); 

        form.append('exportar', '')
        form.append('item_search', data.item_search)
        form.append('ean', data.ean)
        form.append('cnpj_search', data.cnpj_search)
        form.append('nome_cliente', data.nome_cliente)
        form.append('status_filter', '')
        form.append('status_curadoria_filter', data.status_curadoria_filter)
        form.append('per_page', data.per_page ? data.per_page : 10)
        form.append('offset', data.offset)

        api().post('/curadoria', form); 
    }
}