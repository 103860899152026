import i18n from "@/plugins/i18n";
import onboarding_service from "@/services/api/onboarding.js";

import { onboardingTableColumns } from "@/configs/table.columns";
import {
    selectOptionsStatusOptions,
    selectOptionsExtensionsAllowed,
    selectOptionsOrderProductType,
    selectOptionsCountries,
} from "@/configs/select.options";

const initial_state = () => ({
    /*
        BLUEPRINT da tela de Onboarding
    */

    loading: false,
    loading_export: false,
    searched: false,

    // Filters
    id_search: "", // foreign_user_id / account_id / order_id / vendor_id / dt_timestamp_id
    filename_filter: "",
    status_filter: "",
    status_integration_filter: "",
    company_name_filter: "",
    country_filter: "",
    dt_ref_filter: "",
    user_filter: "",
    owner_filter: "",

    // Options
    company_name_options: [],
    user_options: [],
    dt_ref_options: [],
    owner_options: [],
    countries_options: [],

    status_options: selectOptionsStatusOptions,
    extensionsAllowedOptions: selectOptionsExtensionsAllowed,
    orderProductTypeOptions: selectOptionsOrderProductType,
    countriesOptions: selectOptionsCountries,

    onboarding_notes: "",
    new_status: "",

    date: new Date(),
    pagination: {
        page: 1,
        perPage: 10,
        total_items: 0,
    },

    // Table fields
    table_fields: onboardingTableColumns,

    onboarding: [],
    //MODEL DE CRIACAO
    account_id_model: "",
    vendor_id_model: "",
    country_model: "",
    userId_model: "",
    fileName_model: "",
    account_name_model: "",
    vendor_name_model: "",
    vendor_location_model: "",
    orderProductType_model: "",
    language_model: "",
    email_model: "",
    empresa_id_model: "",
});

const state = initial_state();

const getters = {
    loading(state) {
        return state.loading;
    },
    onboarding: (state) => state.onboarding,
    current_page: (state) => state.pagination.page,
    per_page: (state) => state.pagination.perPage,
    total_items: (state) => state.pagination.total_items,
    onboarding_notes: (state) => state.onboarding_notes,

    // getters dos Filtros
    id_search: (state) => state.id_search,
    user_filter: (state) => state.user_filter,
    company_name_filter: (state) => state.company_name_filter,
    country_filter: (state) => state.country_filter,
    dt_ref_filter: (state) => state.dt_ref_filter,
    filename_filter: (state) => state.filename_filter,
    last_update_filter: (state) => state.last_update_filter,
    status_filter: (state) => state.status_filter,
    status_integration_filter: (state) => state.status_integration_filter,
    new_status: (state) => state.new_status,
    owner_filter: (state) => state.owner_filter,

    // getters dos Filtros Options
    user_options: (state) =>
        [{ value: "", text: i18n.tc("pages.onboarding.filters_labels.user") }].concat(
            state.user_options
        ),
    company_name_options: (state) =>
        [{ value: "", text: i18n.tc("pages.onboarding.filters_labels.company_name") }].concat(
            state.company_name_options
        ),
    country_options: (state) =>
        [{ value: "", text: i18n.tc("pages.onboarding.filters_labels.country") }].concat(
            state.country_options
        ),
    dt_ref_options: (state) => state.dt_ref_options,

    status_options: (state) =>
        [{ value: "", text: i18n.tc("pages.onboarding.filters_labels.status") }].concat(
            state.status_options
        ),

    countriesOptions: (state) =>
        [{ value: "", text: i18n.tc("pages.onboarding.filters_labels.country") }].concat(
            state.countriesOptions
        ),

    owner_options: (state) =>
        [{ value: "", text: i18n.tc("pages.onboarding.filters_labels.owner") }].concat(
            state.owner_options
        ),

    extensionsAllowedOptions: (state) => {
        return [
            { value: "", text: i18n.tc("pages.onboarding.filters_labels.extensionType") },
        ].concat(state.extensionsAllowedOptions);
    },

    orderProductTypeOptions: (state) => {
        return [
            { value: "", text: i18n.tc("pages.onboarding.filters_labels.orderProductType") },
        ].concat(state.orderProductTypeOptions);
    },
    // Table Data
    table_fields: (state, getter, rootState, rootGetters) => {
        let aux = state.table_fields.filter((item) => {
            if (!item.roles) return true;
            if (item.roles.includes(rootGetters.role)) return true;
            return false;
        });
        return aux.map((item) => ({ ...item, label: i18n.tc(item.label) }));
    },

    filter_options: (state) => ({
        company_name_options: state.company_name_options,
        country_options: state.country_options,
        dt_ref_options: state.dt_ref_options,
    }),

    filter_to_api: (state) => {
        var obj = {
            empresa_id: state.company_name_filter,
            country: state.country_filter,
            id: state.id_search,
            user: state.user_filter,
            uploaded: state.dt_ref_filter,
            filename: state.filename_filter,
            status: state.status_filter,
            owner: state.owner_filter,
            status_integration: state.status_integration_filter,
        };
        return obj;
    },
};

const mutations = {
    SET_LOADING: (state, status) => (state.loading = status),

    SET_ID_SEARCH: (state, status) => (state.id_search = status),
    SET_COMPANY_NAME: (state, status) => (state.company_name_filter = status),
    SET_COUNTRY: (state, status) => (state.country_filter = status),
    SET_USER: (state, status) => (state.user_filter = status),
    SET_DT_REF: (state, status) => (state.dt_ref_filter = status),
    SET_FILENAME: (state, status) => (state.filename_filter = status),
    SET_LAST_UPDATE: (state, status) => (state.last_update_filter = status),
    SET_STATUS: (state, status) => (state.status_filter = status),
    SET_STATUS_INTERGRATION: (state, status) => (state.status_integration_filter = status),

    SET_FILTER_OPTIONS: (state, { field, value }) => (state[field] = value),

    SET_ONBOARDING: (state, onboarding) => (state.onboarding = onboarding),
    SET_PAGE: (state, page) => (state.pagination.page = page),
    SET_PER_PAGE: (state, per_page) => (state.pagination.perPage = per_page),
    SET_TOTAL_ITEMS: (state, total_items) => (state.pagination.total_items = total_items),
    SET_ONBOARDING_NOTES: (state, note) => (state.onboarding_notes = note),
    SET_NEW_STATUS: (state, status) => (state.new_status = status),
    SET_OWNER: (state, owner) => (state.owner_filter = owner),

    SET_ACCOUNT_ID_MODEL: (state, account_id_model) => (state.account_id = account_id_model),
    SET_VENDOR_ID_MODEL: (state, vendor_id_model) => (state.vendor_id = vendor_id_model),
    SET_COUNTRY_MODEL: (state, country_model) => (state.country = country_model),
    SET_USERID_MODEL: (state, userId_model) => (state.userId = userId_model),
    SET_FILENAME_MODEL: (state, fileName_model) => (state.fileName = fileName_model),
    SET_ACCOUNT_NAME_MODEL: (state, account_name_model) =>
        (state.account_name = account_name_model),
    SET_VENDOR_NAME_MODEL: (state, vendor_name_model) => (state.vendor_name = vendor_name_model),
    SET_VENDOR_LOCATION_MODEL: (state, vendor_location_model) =>
        (state.vendor_location = vendor_location_model),
    SET_ORDERPRODUCTTYPE_MODEL: (state, orderProductType_model) =>
        (state.orderProductType = orderProductType_model),
    SET_LANGUAGE_MODEL: (state, language_model) => (state.language = language_model),
    SET_EMAIL_MODEL: (state, email_model) => (state.email = email_model),
    SET_EMPRESA_ID_MODEL: (state, empresa_id_model) => (state.empresa_id = empresa_id_model),
    RESET(state) {
        state.id_search = "";
        state.filename_filter = "";
        state.last_update_filter = "";
        state.status_filter = "";
        state.user_filter = "";
        state.status_integration_filter = "";
        state.company_name_filter = "";
        state.country_filter = "";
        state.dt_ref_filter = "";
        state.company_name_options = [];
        state.country_options = [];
        state.dt_ref_options = [];
        state.owner_options = [];
        state.pagination.page = 1;
        state.new_status = "";
        state.onboarding_notes = "";
        state.owner_filter = "";
    },

    RESET_DATA: (state) => {
        Object.assign(state, initial_state());
    },
};

const actions = {
    // BUSCA OS DADOS NA API
    async getOnboarding({ commit, getters }) {
        commit("SET_LOADING", true);
        try {
            var page = state.pagination.page;
            var perPage = state.pagination.perPage;

            // Variavel com um OBJ contendo os filtros
            const filter = getters.filter_to_api;

            // Recebe o DATA da API
            var result = await onboarding_service.onboading_list(page, perPage, filter);

            if (result.data) {
                result = result.data;

                // Variavel com os items recebidos da API
                var items = [...result.items];

                // Itera cada item e cria uma nova KEY "info" com multiplos valores dentro
                Object.keys(items).forEach((item) => {
                    items[item]["info"] = {
                        account_id: items[item]["account_id"],
                        vendor_id: items[item]["vendor_id"],
                        account_name: items[item]["account_name"],
                        vendor_name: items[item]["vendor_name"],
                        location: items[item]["vendor_location"],
                    };
                });

                commit("SET_ONBOARDING", items);

                // Variaveis que recebem um ARRAY com os options
                var company_name_options = result.distinct["company"];
                var country_options = result.distinct["country"];
                var user_options = result.distinct["user"];
                var owner_options = result.distinct["owner"];

                var total_items = result.total_items;

                commit("SET_FILTER_OPTIONS", {
                    field: "company_name_options",
                    value: company_name_options,
                });
                commit("SET_FILTER_OPTIONS", { field: "country_options", value: country_options });
                commit("SET_FILTER_OPTIONS", { field: "user_options", value: user_options });
                commit("SET_FILTER_OPTIONS", { field: "owner_options", value: owner_options });

                commit("SET_TOTAL_ITEMS", total_items);
            }
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao buscar a lista de Onboarding", {
                root: true,
            });
        }
        commit("SET_LOADING", false);
    },
    // Salva/Fecha o Onboarding
    async setOnboardingStatus({ commit, getters, dispatch }, dt_timestamp_id) {
        commit("SET_LOADING", true);

        try {
            const new_status = getters.new_status;
            const notes = getters.onboarding_notes;

            if (["SUCCESS", "FAILURE"].includes(new_status)) {
                let code = null;

                if (new_status == "SUCCESS") code = 200;
                else code = 473;

                await onboarding_service.close_onboarding({
                    dt_timestamp_id: dt_timestamp_id,
                    status_code: code,
                    note: notes,
                });
            } else {
                await onboarding_service.set_status(dt_timestamp_id, {
                    status: new_status,
                    note: notes,
                });
            }

            commit("saveStatus", "loading", { root: true });
            await dispatch("getOnboarding");
            dispatch("closeFilterSidebar");
        } catch (error) {
            commit(
                "PUSH_ERROR_TO_ARRAY_PURE_TEXT",
                "Erro ao tentar salvar as mudanças no Onboarding",
                { root: true }
            );
        }

        commit("saveStatus", "success", { root: true });
        commit("SET_LOADING", false);
    },
    async search({ commit, dispatch }) {
        commit("saveStatus", "loading", { root: true });
        await dispatch("getOnboarding");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    async clearFilter({ commit, dispatch }) {
        commit("RESET");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getOnboarding");
        commit("saveStatus", "success", { root: true });
    },

    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getOnboarding", true);
        commit("saveStatus", "success", { root: true });
    },

    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PER_PAGE", per_page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getOnboarding", true);
        commit("saveStatus", "success", { root: true });
    },

    async cleanCacheData({ commit }) {
        commit("RESET_DATA");
    },

    closeFilterSidebar({ commit }) {
        commit("SET_RIGHT_SIDEBAR", false, { root: true });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
