var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WeduuTemplatePage",
    {
      attrs: {
        title_page: _vm.$t("pages.dashboard.title"),
        subtitle_page: _vm.$t("pages.dashboard.subtitle"),
        loading: _vm.loading,
      },
    },
    [
      _c("p", { staticClass: "text-pedmais small-text" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.$t("pages.dashboard.text1", { date: _vm.date_30_days })
            ) +
            "\n    "
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _vm.showCard("order") && _vm.checkDinamycPermission("order")
          ? _c("div", { staticClass: "col-12 col-sm-6 col-md-4 p-1" }, [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _vm.order != null && !_vm.loading
                    ? _c("content-snippet", {
                        attrs: {
                          route: { name: "cockpit" },
                          color: "#4c7897",
                          icon: "cart3",
                          title: _vm.$t("pages.dashboard.cards.order"),
                          value: _vm.order,
                        },
                      })
                    : _c("SkeletonCard", {
                        staticStyle: { "max-width": "100%" },
                      }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.showCard("detalhamento") &&
        _vm.checkDinamycPermission("detalhamento")
          ? _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4 p-1" },
              [
                _vm.supply != null && !_vm.loading
                  ? _c("content-snippet", {
                      attrs: {
                        route: { name: "detalhamento" },
                        color: "#1a961a",
                        icon: "list-check",
                        title: _vm.$t("pages.dashboard.cards.supply"),
                        value: _vm.supply,
                      },
                    })
                  : _c("SkeletonCard"),
              ],
              1
            )
          : _vm._e(),
        _vm.showCard("upload") && _vm.checkDinamycPermission("upload")
          ? _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4 p-1" },
              [
                _vm.curator != null && !_vm.loading
                  ? _c("content-snippet", {
                      attrs: {
                        route: {
                          name: "upload",
                          query: { status_filter: "Pendente" },
                        },
                        color: "#e99e12",
                        icon: "ui-checks-grid",
                        title: _vm.$t("pages.dashboard.cards.curator"),
                        value: _vm.curator,
                      },
                    })
                  : _c("SkeletonCard"),
              ],
              1
            )
          : _vm._e(),
        _vm.showCard("sku") && _vm.checkDinamycPermission("sku")
          ? _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4 p-1" },
              [
                _vm.sku != null && !_vm.loading
                  ? _c("content-snippet", {
                      attrs: {
                        route: { name: "sku" },
                        color: "#6e58b0",
                        icon: "upc-scan",
                        title: _vm.$t("pages.dashboard.cards.sku"),
                        value: _vm.sku,
                      },
                    })
                  : _c("SkeletonCard"),
              ],
              1
            )
          : _vm._e(),
        _vm.showCard("dictionary") && _vm.checkDinamycPermission("dictionary")
          ? _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4 p-1" },
              [
                _vm.dictionary != null && !_vm.loading
                  ? _c("content-snippet", {
                      attrs: {
                        route: { name: "dictionary" },
                        color: "#1a9692",
                        icon: "journal-text",
                        title: _vm.$t("pages.dashboard.cards.dictionary"),
                        value: _vm.dictionary,
                      },
                    })
                  : _c("SkeletonCard"),
              ],
              1
            )
          : _vm._e(),
        _vm.showCard("logs") && _vm.checkDinamycPermission("logs")
          ? _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4 p-1" },
              [
                _vm.logs != null && !_vm.loading
                  ? _c("content-snippet", {
                      attrs: {
                        route: { name: "logs" },
                        color: "#eb6157",
                        icon: "clock",
                        title: _vm.$t("pages.dashboard.cards.logs"),
                        value: _vm.logs,
                      },
                    })
                  : _c("SkeletonCard"),
              ],
              1
            )
          : _vm._e(),
        _vm.showCard("pagamento_module") &&
        _vm.checkDinamycPermission("pagamento_menu")
          ? _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4 p-1" },
              [
                _vm.pagamento_total != null && !_vm.loading
                  ? _c("content-snippet", {
                      attrs: {
                        route: {
                          name: "pagamento_cockpit",
                          query: { status: "todos" },
                        },
                        color: "#4c7897",
                        icon: "files",
                        title: _vm.$t("pages.dashboard.cards.pagamento_total"),
                        value: _vm.pagamento_total,
                      },
                    })
                  : _c("SkeletonCard"),
              ],
              1
            )
          : _vm._e(),
        _vm.showCard("pagamento_module") &&
        _vm.checkDinamycPermission("pagamento_menu")
          ? _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4 p-1" },
              [
                _vm.pagamento_processado != null && !_vm.loading
                  ? _c("content-snippet", {
                      attrs: {
                        route: {
                          name: "pagamento_cockpit",
                          query: { status: "processado" },
                        },
                        color: "#1a961a",
                        icon: "list-check",
                        title: _vm.$t(
                          "pages.dashboard.cards.pagamento_processado"
                        ),
                        value: _vm.pagamento_processado,
                      },
                    })
                  : _c("SkeletonCard"),
              ],
              1
            )
          : _vm._e(),
        _vm.showCard("pagamento_module") &&
        _vm.checkDinamycPermission("pagamento_menu")
          ? _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4 p-1" },
              [
                _vm.pagamento_pendente != null && !_vm.loading
                  ? _c("content-snippet", {
                      attrs: {
                        route: {
                          name: "pagamento_cockpit",
                          query: { status: "pendente" },
                        },
                        color: "#e99e12",
                        icon: "ui-checks-grid",
                        title: _vm.$t(
                          "pages.dashboard.cards.pagamento_pendente"
                        ),
                        value: _vm.pagamento_pendente,
                      },
                    })
                  : _c("SkeletonCard"),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }