var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row content-header text-left " }, [
    _c("div", { staticClass: "col-12 col-sm-6 mb-2" }, [
      _c(
        "div",
        { staticClass: "d-flex content-header-title-page" },
        [
          _c("h4", [_vm._v(_vm._s(_vm.title))]),
          _vm.loading
            ? _c("b-spinner", {
                staticClass: "ml-2",
                attrs: { label: "Loading..." },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("small", { staticClass: "content-header-subtitle-page" }, [
        _vm._v("\n            " + _vm._s(_vm.subtitle) + "\n        "),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "col-12 col-sm-6 align-self-end text-right mb-2 " },
      [_c("small", [_c("breadcrumb")], 1)]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }