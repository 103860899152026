import i18n from "@/plugins/i18n";
import templates_service from "@/services/api/templates.js";
import { templatesTableColumns } from "@/configs/table.columns";
import {
    selectOptionsCurrencyOptions,
    selectOptionsExtensionsAllowed,
    selectOptionsOrderProductType,
} from "@/configs/select.options";
import { createIntegerArray } from "@/helpers/array.helper";

const initial_state = () => ({
    loading: false,
    loading_export: false,
    searched: false,

    // Filtros.
    lastUpdateFilter: "",
    companyNameFilter: "",
    accountIdFilter: "",
    accountNameFilter: "",
    templateNameFilter: "",
    paramFileFilter: "",
    parserParamFilter: "",
    paramDataframeFilter: "",

    // Opções dos filtros.
    companyNameOptions: [],

    thumbIndexList: [],

    // Lista de templates.
    listedTemplates: [],
    editedTemplates: {},
    editedThumbs: {},

    table_fields: templatesTableColumns,
    currencyTypeOptions: selectOptionsCurrencyOptions,
    extensionsAllowedOptions: selectOptionsExtensionsAllowed,
    orderProductTypeOptions: selectOptionsOrderProductType,

    versionTypeOptions: createIntegerArray(7),

    // Parâmetros de paginação.
    pagination: {
        page: 1,
        perPage: 10,
        totalItems: 0,
    },

    date: null,
});

const state = initial_state();

const doubleDigitsAlways = function (val) {
    return `${("0" + val).slice(-2)}`;
};

const getters = {
    loading: (state) => state.loading,
    date: (state) => {
        var aux = state.date;
        if (!aux) return "";
        return `${doubleDigitsAlways(aux.getDate())}/${doubleDigitsAlways(
            aux.getMonth() + 1
        )}/${doubleDigitsAlways(aux.getFullYear())} ${doubleDigitsAlways(
            aux.getHours()
        )}:${doubleDigitsAlways(aux.getMinutes())}:${doubleDigitsAlways(aux.getSeconds())}`;
    },
    listedTemplates: (state) => state.listedTemplates,
    editedTemplates: (state) => state.editedTemplates,
    editedThumbs: (state) => state.editedThumbs,
    thumbIndexList: (state) => state.thumbIndexList,
    distinctTemplates: (state) => state.distinctTemplates,
    table_fields: (state, getter, rootState, rootGetters) => {
        let aux = state.table_fields.filter((item) => {
            if (!item.roles) return true;
            if (item.roles.includes(rootGetters.role)) return true;
            return false;
        });
        return aux.map((item) => ({ ...item, label: i18n.tc(item.label) }));
    },

    // Paginação.
    page: (state) => state.pagination.page,
    perPage: (state) => state.pagination.perPage,
    totalItems: (state) => state.pagination.totalItems,

    // Filtros.
    lastUpdateFilter: (state) => state.lastUpdateFilter,
    companyNameFilter: (state) => state.companyNameFilter,
    accountIdFilter: (state) => state.accountIdFilter,
    accountNameFilter: (state) => state.accountNameFilter,
    templateNameFilter: (state) => state.templateNameFilter,
    paramFileFilter: (state) => state.paramFileFilter,
    parserParamFilter: (state) => state.parserParamFilter,
    paramDataframeFilter: (state) => state.paramDataframeFilter,

    // Opções dos filtros.
    companyNameOptions: (state) => {
        return [{ value: "", text: i18n.tc("pages.templates.filters_labels.companyName") }].concat(
            state.companyNameOptions
        );
    },
    extensionsAllowedOptions: (state) => {
        return [
            { value: "", text: i18n.tc("pages.templates.filters_labels.extensionType") },
        ].concat(state.extensionsAllowedOptions);
    },
    currencyTypeOptions: (state) => {
        return [{ value: "", text: i18n.tc("pages.templates.filters_labels.currencyType") }].concat(
            state.currencyTypeOptions
        );
    },
    orderProductTypeOptions: (state) => {
        return [
            { value: "", text: i18n.tc("pages.templates.filters_labels.orderProductType") },
        ].concat(state.orderProductTypeOptions);
    },
    versionTypeOptions: (state) => {
        return [{ value: "", text: i18n.tc("pages.templates.filters_labels.versionType") }].concat(
            state.versionTypeOptions
        );
    },

    //Mapping dos filtros p/ o endpoint de filtragem.
    filterToApi: (state) => {
        const obj = {
            empresa_id: state.companyNameFilter,
            cnpj: state.accountIdFilter,
            sigla: state.accountNameFilter,
            template: state.templateNameFilter,
            last_update: state.lastUpdateFilter,
            param_file: state.paramFileFilter,
            parser_param: state.parserParamFilter,
            param_dataframe: state.paramDataframeFilter,
        };
        return obj;
    },
};

const mutations = {
    SET_LOADING: (state, status) => (state.loading = status),
    UPDATE_DATE(state) {
        state.date = new Date();
    },
    SET_LISTED_TEMPLATES: (state, value) => (state.listedTemplates = value),
    SET_EDITED_TEMPLATES: (state, value) => (state.editedTemplates = value),
    SET_EDITED_THUMBS: (state, value) => (state.editedThumbs = value),
    SET_THUMB_INDEX_LIST: (state, value) => (state.thumbIndexList = value),
    SET_DISTINCT_TEMPLATES: (state, value) => (state.distinctTemplates = value),
    SET_LAST_UPDATE: (state, value) => (state.lastUpdateFilter = value),
    SET_COMPANY_NAME: (state, value) => (state.companyNameFilter = value),
    SET_ACCOUNT_ID: (state, value) => (state.accountIdFilter = value),
    SET_ACCOUNT_NAME: (state, value) => (state.accountNameFilter = value),
    SET_TEMPLATE_NAME: (state, value) => (state.templateNameFilter = value),
    SET_PARAM_FILE: (state, value) => (state.paramFileFilter = value),
    SET_PARAM_PARSER: (state, value) => (state.parserParamFilter = value),
    SET_PARAM_DATAFRAME: (state, value) => (state.paramDataframeFilter = value),
    SET_PAGE: (state, value) => (state.pagination.page = value),
    SET_PER_PAGE: (state, value) => (state.pagination.perPage = value),
    SET_TOTAL_ITEMS: (state, value) => (state.pagination.totalItems = value),
    RESET: (state) => {
        (state.lastUpdateFilter = ""),
            (state.companyNameFilter = ""),
            (state.accountIdFilter = ""),
            (state.accountNameFilter = ""),
            (state.templateNameFilter = ""),
            (state.paramFileFilter = ""),
            (state.parserParamFilter = ""),
            (state.paramDataframeFilter = ""),
            (state.pagination.page = 1);
    },
};

const actions = {
    async modifyThumb({ commit }, to_delete) {
        commit("SET_LOADING", true);
        let listedTemplates = JSON.parse(JSON.stringify(state.listedTemplates));
        let listedTemplate = JSON.parse(
            JSON.stringify(state.listedTemplates.filter((item) => item.id == to_delete.id)[0])
        );
        if (to_delete.indexes.length) {
            for (let index of to_delete.indexes.sort().reverse()) {
                listedTemplate.thumbnail.splice(index, 1);
            }
        }
        listedTemplates = listedTemplates.map((item) => {
            if (item.id == to_delete.id) return listedTemplate;
            return item;
        });
        commit("SET_LISTED_TEMPLATES", listedTemplates);
        commit("UPDATE_DATE");
        commit("SET_LOADING", false);
    },
    async setTemplate({ commit }, modifiedTemplate) {
        let listedTemplates = JSON.parse(JSON.stringify(state.listedTemplates));
        let editedTemplates = JSON.parse(JSON.stringify(state.editedTemplates));
        let editedThumbs = JSON.parse(JSON.stringify(state.editedThumbs));
        const { thumbnail, file, ...rest } = modifiedTemplate;

        let originalTemplate;
        if (!(modifiedTemplate.id in editedTemplates)) {
            originalTemplate = state.listedTemplates.filter(
                (item) => item.id == modifiedTemplate.id
            )[0];

            editedTemplates[modifiedTemplate.id] = {
                original: originalTemplate,
                modified: rest,
            };

            editedThumbs[modifiedTemplate.id] = {
                thumbnail: thumbnail,
                file: file,
                empresa_id: modifiedTemplate.empresa_id,
                template: modifiedTemplate.template,
                cnpj: modifiedTemplate.cnpj
            };
        } else {
            originalTemplate = editedTemplates[modifiedTemplate.id].original;
            editedThumbs[modifiedTemplate.id] = {
                thumbnail: thumbnail,
                file: file,
                empresa_id: modifiedTemplate.empresa_id,
                template: modifiedTemplate.template,
                cnpj: modifiedTemplate.cnpj
            }
            if (!hasChanged(originalTemplate, rest))
                delete editedTemplates[modifiedTemplate.id];
            else editedTemplates[modifiedTemplate.id].modified = rest;
        }

        listedTemplates = listedTemplates.map((item) => {
            if (item.id == modifiedTemplate.id) return modifiedTemplate;
            return item;
        });

        commit("SET_EDITED_TEMPLATES", editedTemplates);
        commit("SET_LISTED_TEMPLATES", listedTemplates);
        commit("SET_EDITED_THUMBS", editedThumbs);
    },
    async setTemplateArrayAtIndex({ dispatch }, payload) {
        const templateArray = [...payload.array];
        const templateString = templateArray.map((item) => item.replace(/[,|]/, ";")).join(";");

        let modifiedTemplate = JSON.parse(JSON.stringify(payload.template));
        modifiedTemplate.template = templateString;

        await dispatch("setTemplate", modifiedTemplate);
    },
    async getTemplates({ commit, getters, dispatch }) {
        commit("SET_LOADING", true);
        try {
            const page = state.pagination.page;
            const perPage = state.pagination.perPage;

            const filter = getters.filterToApi;

            let results = await templates_service.listTemplates(filter, page, perPage);

            if (results.data) {
                let result = results.data;

                let items = [...result.items].map((item) => {
                    if (item.id in state.editedTemplates)
                        item.template = state.editedTemplates[item.id].modified;
                    return item;
                });

                commit("SET_LISTED_TEMPLATES", items);

                const totalItems = result.total_items;

                commit("SET_TOTAL_ITEMS", totalItems);
                await dispatch("getDistinctTemplates");
                commit("UPDATE_DATE");
            }
        } catch (err) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao listar os templates", { root: true });
        }
        commit("SET_LOADING", false);
    },
    async getDistinctTemplates({ commit }) {
        commit("SET_LOADING", true);
        try {
            let templates = await templates_service.listDistinctTemplates();

            if (templates.data) {
                let result = templates.data;

                commit("SET_DISTINCT_TEMPLATES", result.items);
            }
        } catch (err) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao listar os templates distintos", {
                root: true,
            });
        }
        commit("SET_LOADING", false);
    },
    async createTemplate({ commit, dispatch }, payload) {
        commit("SET_LOADING", true);
        try {
            const newTemplate = payload;

            if (newTemplate._showDetails) {
                delete newTemplate._showDetails;
            }

            if (newTemplate) {
                const result = await templates_service.createTemplate(newTemplate);

                commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", result.message, { root: true });

                await dispatch("getTemplates");
            }
        } catch (err) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", err.response.data.error, { root: true });
        }
        commit("SET_LOADING", false);
    },
    async updateTemplates({ commit, getters, dispatch }) {
        commit("SET_LOADING", true);
        try {
            const editedTemplates = getters.editedTemplates;
            const editedThumbs = getters.editedThumbs;
            var data = new Array();
            var thumbsData = new Array();

            Object.keys(editedTemplates).forEach((key) => {
                data.push(editedTemplates[key]["modified"]);
            });

            Object.keys(editedThumbs).forEach((key) => {
                thumbsData.push({
                    id: key,
                    thumbnail: editedThumbs[key]["thumbnail"],
                    file: editedThumbs[key]["file"],
                    empresa_id: editedThumbs[key]["empresa_id"],
                    template: editedThumbs[key]["template"],
                    cnpj: editedThumbs[key]["cnpj"]
                });
            });

            data.forEach((item) => {
                if ("_showDetails" in item) {
                    delete item._showDetails;
                }
            });

            if (data) {
                const result = await templates_service.updateTemplates(data);
                const arrayOfPromises = thumbsData.map(({ file, ...rest }) =>
                    templates_service.addThumbnailsInTemplate(rest, file)
                );
                // thumbsData.forEach(async (item) => {
                //     const { file, ...rest } = item;
                //     let resultThumb = await templates_service.addThumbnailsInTemplate(rest, file);
                //     resultThumb.forEach((item) => {
                //         commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", item, { root: true });
                //     });
                // });

                result.forEach((item) => {
                    commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", item, { root: true });
                });

                commit("SET_EDITED_TEMPLATES", {});
                commit("SET_EDITED_THUMBS", {});

                Promise.all(arrayOfPromises)
                    .then((res) => {
                        res.forEach((item) =>
                            commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", item, { root: true })
                        );
                        dispatch("getTemplates");
                    })
                    .catch(() => {
                        commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao atualizar os templates", {
                            root: true,
                        });
                    });
            }
        } catch (err) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao atualizar os templates", {
                root: true,
            });
        }
        commit("SET_LOADING", false);
    },
    async deleteTemplate({ commit, dispatch }, payload) {
        commit("SET_LOADING", true);
        try {
            const template = payload;

            if ("_showDetails" in template) {
                delete template._showDetails;
            }

            if (template) {
                const result = await templates_service.deleteTemplate(template);
                commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", result.message, { root: true });

                await dispatch("getTemplates");
            }
        } catch (err) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao deletar o template", { root: true });
        }
        commit("SET_LOADING", false);
    },
    async downloadTemplate({ commit }, payload) {
        commit("SET_LOADING", true);
        try {
            const template = payload;

            if (template) {
                const result = await templates_service.downloadTemplate(template);
                if (result) {
                    const url = result.url;
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", template.template);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else
                commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", result.message, { root: true });
            }
        } catch (err) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao baixar o template", { root: true });
        }
        commit("SET_LOADING", false);
    },
    async search({ commit, dispatch }) {
        commit("saveStatus", "loading", { root: true });
        await dispatch("getTemplates");
        commit("saveStatus", "success", { root: true });
        await dispatch("closeFilterSidebar");
    },
    async clearFilter({ commit, dispatch }) {
        commit("RESET");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getTemplates");
        commit("saveStatus", "success", { root: true });
    },
    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getTemplates", true);
        commit("saveStatus", "success", { root: true });
    },
    async changePerPage({ commit, dispatch }, perPage) {
        commit("SET_PER_PAGE", perPage);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getTemplates", true);
        commit("saveStatus", "success", { root: true });
    },
    async closeFilterSidebar({ commit }) {
        commit("SET_RIGHT_SIDEBAR", false, { root: true });
    },
};

/*
 * Função responsável por checar se a versão modificada de um
 * template é equivalente à versão original do mesmo.
 *
 * @param {object} originalTemplate - A versão original do template.
 * @param {object} modifiedTemplate - A versão modificada do template.
 */
function hasChanged(originalTemplate, modifiedTemplate) {
    const comparisonFields = [
        "id",
        "empresa_id",
        "cnpj",
        "sigla",
        "template",
        "param_file",
        "parser_param",
        "param_dataframe",
    ];

    for (const comparisonField of comparisonFields)
        if (originalTemplate[comparisonField] !== modifiedTemplate[comparisonField]) return true;
    return false;
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
