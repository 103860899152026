import i18n from "@/plugins/i18n";
import curator_service from "@/services/api/curator";
import autocomplete_service from "@/services/api/autocomplete";
import { curatorTableColumns } from "@/configs/table.columns";
const initial_state = () => ({
    loading: false,

    // Variaveis do filtro
    cnpj: "",
    ean: "",
    client_name: "",
    item: "",
    curator_filter: "",

    autocomplete: [],
    curator: [],
    selected_itens: [],

    pagination: {
        page: 1,
        perPage: 10,
        total_itens: 0,
    },

    table_fields: curatorTableColumns,
});

const state = initial_state();

const getters = {
    loading: (state) => state.loading,
    table_fields(state) {
        return state.table_fields.map((item) => ({ ...item, label: i18n.tc(item.label) }));
    },
    cnpj: (state) => state.cnpj,
    ean: (state) => state.ean,
    client_name: (state) => state.client_name,
    item: (state) => state.item,
    curator_filter: (state) => state.curator_filter,
    total_items: (state) => state.pagination.total_itens,
    current_page: (state) => state.pagination.page,
    per_page: (state) => state.pagination.perPage,
    curator: (state) => state.curator,
    autocomplete: (state) => state.autocomplete,
    selected_itens: (state) => state.selected_itens,
    pagination: (state) => state.pagination,
    filter_to_api(state) {
        return {
            offset: state.pagination.page - 1,
            per_page: state.pagination.perPage,
            item_search: state.item,
            ean: state.ean,
            cnpj_search: state.cnpj,
            nome_cliente: state.client_name,
            status_curadoria_filter: state.curator_filter,
        };
    },
};

const mutations = {
    SET_NEW_SUGGESTION(state, { index, new_suggestion }) {
        state.curator[index].new_suggestion = new_suggestion;
    },
    SET_SUGESTION_NAME(state, { index, sugestoes_nome }) {
        state.curator[index].sugestoes_nome = sugestoes_nome;
    },
    SET_SUGESTOES_NOME_IN_ITEM: (state, { index, dado }) => {
        var aux = Object(state.curator);
        aux[index].sugestoes_nome = dado;
        state.curator = aux;
    },
    SET_NEW_SUGGESTION_IN_ITEM: (state, { index, dado }) => {
        var aux = Object(state.curator);
        aux[index].new_suggestion = dado;
        state.curator = aux;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_CURATOR(state, curator) {
        state.curator = curator;
    },
    SET_FILTER_FIELD(state, { field, value }) {
        state[field] = value;
    },
    SET_PAGE(state, page) {
        state.pagination.page = page;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },
    SET_SELECTED_ITEMS(state, selected_array) {
        state.selected_itens = selected_array;
    },
    SET_TOTAL_ITENS(state, total_itens) {
        state.pagination.total_itens = total_itens;
    },
    SET_AUTOCOMPLETE(state, payload) {
        state.autocomplete = payload;
    },
    RESET_DATA: (state) => {
        Object.assign(state, initial_state());
    },
    RESET_FILTER(state) {
        state.cnpj = "";
        state.ean = "";
        state.client_name = "";
        state.item = "";
        state.curator_filter = "";
        state.pagination.page = 1;
    },
};

const actions = {
    async SearchClicked({ commit, dispatch }) {
        commit("saveStatus", "loading", { root: true });
        commit("SET_PAGE", 1);
        await dispatch("getCurator");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },
    async SearchByPropertyClicked({ commit, dispatch }, { field, value }) {
        commit("SET_FILTER_FIELD", { field, value });
        commit("saveStatus", "loading", { root: true });
        await dispatch("getCurator");
        commit("saveStatus", "success", { root: true });
    },
    async getAutocomplete({ commit }) {
        var autocomplete_itens = [];
        try {
            const autocomplete = await autocomplete_service.list();
            if (autocomplete.data != "") autocomplete_itens = autocomplete.data;
            else autocomplete_itens = [];
        } catch (error) {
            throw error;
        }
        commit("SET_AUTOCOMPLETE", autocomplete_itens);
    },
    async getCurator({ commit, getters }) {
        commit("SET_LOADING", true);
        let curator_itens = [];
        let total_items = 0;

        try {
            var filtro = getters.filter_to_api;
            var res = await curator_service.find(filtro);
            let response = res.data;
            curator_itens = response.slice(0, response.length - 1);
            curator_itens = curator_itens.map((item) => ({
                ...item,
                new_suggestion: "",
                sugestoes_nome: "",
            }));
            total_items = _.find(response, "total_items")["total_items"];
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", i18n.t("action failed"), {
                root: true,
            });
        }
        commit("SET_CURATOR", curator_itens);
        commit("SET_TOTAL_ITENS", total_items);
        commit("SET_LOADING", false);
    },
    async clearField({ commit, dispatch }) {
        commit("RESET_FILTER");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getCurator");
        commit("saveStatus", "sucess", { root: true });
        dispatch("closeFilterSidebar");
    },
    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getCurator");
        commit("saveStatus", "success", { root: true });
    },
    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PAGE", 1);
        commit("SET_PER_PAGE", per_page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getCurator");
        commit("saveStatus", "success", { root: true });
    },
    closeFilterSidebar({ commit }) {
        commit("SET_RIGHT_SIDEBAR", false, { root: true });
    },
    // UPDATE AND PROCESS
    async UpdateSelect({ commit, dispatch, getters }) {
        try {
            var selected = getters.selected_itens;

            if (selected.length == 0) {
                commit("PUSH_ERROR_TO_ARRAY", "no item selected", { root: true });
            }

            let cnpj = []; //_.map(curator_itens, 'cnpj')
            let nome_cliente = []; //_.map(curator_itens, 'nome_cliente')
            let ds_sku_input = []; //_.map(curator_itens, 'ds_sku_input')
            let sugestoes_nome = [];
            let nova_sugestao = [];
            let qty = []; //_.map(curator_itens, 'qty')
            let vigencia_inicial = []; //_.map(curator_itens, 'vigencia_inicial')
            let vigencia_final = []; //_.map(curator_itens, 'vigencia_final')
            let update = [];

            selected.forEach((item, index) => {
                if (item.sugestoes_nome && item.sugestoes_nome != "") {
                    sugestoes_nome.push(item.sugestoes_nome);
                } else {
                    sugestoes_nome.push(item.best[0]);
                }

                if (item.new_suggestion) {
                    nova_sugestao.push(item.new_suggestion);
                } else {
                    nova_sugestao.push(null);
                }

                update.push(index);
                cnpj.push(item.cnpj + " - " + item.nome_cliente);
                nome_cliente.push(item.nome_cliente);
                ds_sku_input.push(item.ds_sku_input);
                qty.push(item.qty);
                vigencia_inicial.push(item.vigencia_inicial);
                vigencia_final.push(item.vigencia_final);
            });

            var obj = {
                update: update.join("; "),
                cnpj: cnpj.join("; "),
                ds_sku_input: ds_sku_input.join("; "),
                sugestoes_nome: sugestoes_nome.join("; "),
                nova_sugestao: nova_sugestao.join("; "),
                qty: qty.join("; "),
                vigencia_inicial: vigencia_inicial.join("; "),
                vigencia_final: vigencia_final.join("; "),
            };
            commit("saveStatus", "loading", { root: true });
            await curator_service.update(obj);
            var success_modal = {
                type: "success",
                title: i18n.tc("modals.common.operation_successfull"),
                text: i18n.tc("modals.curator.success_text"),
            };
            await dispatch("getCurator");
            // dispatch('commitCuratorData', update_response);
            dispatch("modal/SimpleModal", success_modal, { root: true });
        } catch (error) {
            var modal_error = {
                type: "error",
                title: i18n.tc("modals.common.ops"),
                text: i18n.tc("modals.curator.error_text_load_data"),
            };
            dispatch("modal/SimpleModal", modal_error, { root: true });
        }
        commit("saveStatus", "success", { root: true });
    },
    async Process({ commit, dispatch, getters }) {
        commit("saveStatus", "loading", { root: true });
        try {
            var curator_itens = getters.curator;
            let cnpj = _.map(curator_itens, "cnpj");
            let ds_sku_input = _.map(curator_itens, "ds_sku_input");
            let sugestoes_nome = [];
            let nova_sugestao = [];
            let qty = _.map(curator_itens, "qty");
            let vigencia_inicial = _.map(curator_itens, "vigencia_inicial");
            let vigencia_final = _.map(curator_itens, "vigencia_final");

            curator_itens.forEach((item) => {
                if (item.sugestoes_nome) {
                    sugestoes_nome.push(item.sugestoes_nome);
                } else sugestoes_nome.push(item.sugestoes_nome);

                if (item.new_suggestion) {
                    nova_sugestao.push(item.new_suggestion);
                } else nova_sugestao.push(null);

                cnpj.push(item.cnpj + item.nome_cliente);
            });

            var obj = {
                cnpj: cnpj,
                ds_sku_input: ds_sku_input,
                sugestoes_nome: sugestoes_nome,
                nova_sugestao: nova_sugestao,
                qty: qty,
                vigencia_inicial: vigencia_inicial,
                vigencia_final: vigencia_final,
            };

            await curator_service.process(obj).catch(() => {
                throw "process";
            });
            const success_modal = {
                type: "success",
                title: i18n.tc("modals.common.order_process"),
                text: i18n.tc("modals.curator.success_curated_itens"),
            };

            dispatch("modal/SimpleModal", success_modal, { root: true });
            await dispatch("getCurator");
        } catch (error) {
            var error_modal = {};
            if (error == "process") {
                error_modal = {
                    type: "error",
                    title: i18n.tc("modals.common.ops"),
                    text: i18n.tc("modals.curator.success_processed_itens"),
                };
            } else {
                error_modal = {
                    type: "error",
                    title: i18n.tc("modals.common.ops"),
                    text: i18n.tc("modals.curator.error_text_load_data"),
                };
            }
            dispatch("modal/SimpleModal", error_modal, { root: true });
        }
        commit("saveStatus", "success", { root: true });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
