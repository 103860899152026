import { render, staticRenderFns } from "./SuggestionBadge.vue?vue&type=template&id=92d03a1a&scoped=true&"
import script from "./SuggestionBadge.vue?vue&type=script&lang=js&"
export * from "./SuggestionBadge.vue?vue&type=script&lang=js&"
import style0 from "./SuggestionBadge.vue?vue&type=style&index=0&id=92d03a1a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92d03a1a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('92d03a1a')) {
      api.createRecord('92d03a1a', component.options)
    } else {
      api.reload('92d03a1a', component.options)
    }
    module.hot.accept("./SuggestionBadge.vue?vue&type=template&id=92d03a1a&scoped=true&", function () {
      api.rerender('92d03a1a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Sugestoes/components/SuggestionBadge.vue"
export default component.exports