var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.notifications, function (notification, index) {
      return _c(
        "b-toast",
        {
          key: "notification_" + index,
          attrs: {
            visible: true,
            title: notification.title,
            variant: notification.type,
            solid: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.SnackClosed(index)
            },
          },
        },
        [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(_vm.returnHTMLOfMessage(notification.message)),
            },
          }),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }