var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forgot" }, [
    _vm._m(0),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          !_vm.senha_alterada
            ? [
                _c("div", { staticClass: "text-left mb-4" }, [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("auth.reset_password.title"))),
                  ]),
                ]),
                _c("b-form", { attrs: { autocomplete: "off" } }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("div", { staticClass: "input-group-prepend" }, [
                          _c(
                            "span",
                            { staticClass: "input-group-text" },
                            [_c("b-icon", { attrs: { icon: "lock" } })],
                            1
                          ),
                        ]),
                        _c("b-form-input", {
                          ref: "ref_nova_senha",
                          attrs: {
                            name: "nova_senha_input_form",
                            type: _vm.show_new_password ? "text" : "password",
                            autocomplete: "off",
                            placeholder: _vm.$t(
                              "auth.reset_password.new_password"
                            ),
                            "aria-label": _vm.$t(
                              "auth.reset_password.new_password"
                            ),
                            "aria-describedby": "nova senha",
                            required: "",
                          },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.$refs["ref_confirmar_senha"].focus()
                            },
                          },
                          model: {
                            value: _vm.nova_senha,
                            callback: function ($$v) {
                              _vm.nova_senha = $$v
                            },
                            expression: "nova_senha",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "input-group-append",
                            on: {
                              click: function ($event) {
                                _vm.show_new_password = !_vm.show_new_password
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "show-password input-group-text" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: !_vm.show_new_password
                                      ? "eye-slash"
                                      : "eye",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "password-rules" }, [
                      !_vm.isPasswordValid
                        ? _c("ul", [
                            _c(
                              "li",
                              {
                                class: {
                                  "rule-checked": _vm.rule_min_characteres,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "auth.reset_password.password_length"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                class: {
                                  "rule-checked": _vm.contains_Lowercase,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "auth.reset_password.contains_lowercase"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                class: {
                                  "rule-checked": _vm.contains_Uppercase,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "auth.reset_password.contains_uppercase"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                class: { "rule-checked": _vm.contains_number },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "auth.reset_password.contains_number"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                class: {
                                  "rule-checked":
                                    _vm.contains_special_character,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "auth.reset_password.contains_special_character"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                          ])
                        : _c(
                            "div",
                            { staticClass: "password-okay-class" },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "check2-circle",
                                  "font-scale": "1.5",
                                },
                              }),
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "auth.reset_password.password_is_valid"
                                  )
                                ) + "\n                            "
                              ),
                            ],
                            1
                          ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("div", { staticClass: "input-group-prepend" }, [
                          _c(
                            "span",
                            { staticClass: "input-group-text" },
                            [_c("b-icon", { attrs: { icon: "lock" } })],
                            1
                          ),
                        ]),
                        _c("b-form-input", {
                          ref: "ref_confirmar_senha",
                          attrs: {
                            type: _vm.show_confirm_password
                              ? "text"
                              : "password",
                            autocomplete: "off",
                            placeholder: _vm.$t(
                              "auth.reset_password.confirm_password"
                            ),
                            "aria-label": _vm.$t(
                              "auth.reset_password.confirm_password"
                            ),
                            "aria-describedby": "confirmar senha",
                            required: "",
                          },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.SalvarNovaSenha()
                            },
                          },
                          model: {
                            value: _vm.confirmar_senha,
                            callback: function ($$v) {
                              _vm.confirmar_senha = $$v
                            },
                            expression: "confirmar_senha",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "input-group-append",
                            on: {
                              click: function ($event) {
                                _vm.show_confirm_password =
                                  !_vm.show_confirm_password
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "show-password input-group-text" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: !_vm.show_confirm_password
                                      ? "eye-slash"
                                      : "eye",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  !_vm.doesPasswordMatch
                    ? _c(
                        "div",
                        { staticClass: "senhas-nao-iguais" },
                        [
                          _c("b-icon", { attrs: { icon: "x-circle" } }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t(
                                  "auth.reset_password.passwords_dont_match"
                                )
                              ) +
                              "\n                    "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.doesPasswordMatch &&
                  _vm.confirmar_senha &&
                  _vm.confirmar_senha != ""
                    ? _c(
                        "div",
                        { staticClass: "senhas-iguais" },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "check2-circle",
                              "font-scale": "1.5",
                            },
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t("auth.reset_password.passwords_match")
                              ) +
                              "\n                    "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "form-group mb-4" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: !_vm.isFormValid(),
                            block: "",
                            variant: "secondary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.SalvarNovaSenha()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t("auth.reset_password.save_password")
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            : [
                _c("div", { staticClass: "text-left mb-4" }, [
                  _c("h4", [
                    _vm._v(
                      _vm._s(_vm.$t("auth.reset_password.password_changed"))
                    ),
                  ]),
                ]),
                _c("small", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("auth.reset_password.success_message")) +
                      "\n                "
                  ),
                ]),
              ],
          _c("hr"),
          _c(
            "div",
            {
              staticClass:
                "row align-items-center justify-content-between no-gutters",
            },
            [
              _c(
                "router-link",
                { staticClass: "card-body-link", attrs: { to: "/login" } },
                [_c("small", [_vm._v(" Login ")])]
              ),
              _c("img", {
                attrs: {
                  src: require("../../assets/img/logo_weduu.png"),
                  width: "100px",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "forgot-header mb-4" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/img/logo_pedmais_login_s.png"),
          width: "100",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }