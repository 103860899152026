import api from "@/services/api";

export default {
    cards(month, company_id = "") {
        var query = `date=${month}`;
        if (company_id) query += `&empresa_id=${company_id}`;

        return api()
            .get(`/billing/cards?${query}`)
            .then((res) => res.data);
    },
    chart(month, company_id = "") {
        var query = `date=${month}`;
        if (company_id) query += `&empresa_id=${company_id}`;

        return api()
            .get(`/billing/chart?${query}`)
            .then((res) => res.data);
    },
    table(month, page, company_id = "") {
        var query = `date=${month}&page=${page}`;
        if (company_id) query += `&empresa_id=${company_id}`;
        return api()
            .get(`/billing/table?${query}`)
            .then((res) => res.data);
    },
    completeData(month, company_id = "") {
        var query = `date=${month}`;
        if (company_id) query += `&empresa_id=${company_id}`;

        return api()
            .post(`/billing?${query}`, null, {
                responseType: "blob",
                headers: {
                    "Content-Type": "*",
                },
            })
            .then((res) => {
                return res.data;
            });
    },
    detailedCards(month, company_id = "") {
        var query = `date=${month}`;
        if (company_id) query += `&empresa_id=${company_id}`;

        return api()
            .get(`/billing/detailed/cards?${query}`)
            .then((res) => res.data);
    },
    getDetailedPayment(month, company_id = "") {
        var query = `date=${month}`;
        if (company_id) query += `&empresa_id=${company_id}`;

        return api()
            .get(`/billing/detailed/table?${query}`)
            .then((res) => res.data);
    },
    allCompanies(month) {
        var query = `date=${month}`;

        return api()
            .get(`/billing/detailed/all?${query}`)
            .then((res) => res.data);
    },
    getPriceTable(company_id) {
        return api()
            .get(`/billing/price_table/${company_id}`)
            .then((res) => res.data);
    },
    addTablePayment(body) {
        return api()
            .post(`/billing/price_table`, body)
            .then((res) => res.data);
    },
    editTablePayment(empresa_id, body) {
        return api()
            .put(`/billing/price_table/${empresa_id}`, body)
            .then((res) => res.data);
    },
    dispatchBillingEmail(body) {
        return api()
            .post(`/billing/mail`, body)
            .then((res) => res.data);
    },
};
