import i18n from "@/plugins/i18n";

const initial_state = () => ({
    modal_ref: null,

    modal_checkout_edit_item: false,
    modal_checkout_edit_item_params: {
        order_id: "",
        action: "none",
        title: "Novo Item",
        cnpj: "",
        num_pedido: "",
        sku: "",
        item: "",
        vl: "",
        qtd: "",
        data: "",
        obs: "",
    },

    modal_upload_pagamento_cma: false,
});
const state = initial_state();

const getters = {
    modal_ref: (state) => state.modal_ref,

    modal_checkout_edit_item: (state) => state.modal_checkout_edit_item,
    modal_checkout_edit_item_params: (state) => state.modal_checkout_edit_item_params,

    modal_upload_pagamento_cma: (state) => state.modal_upload_pagamento_cma,
};

const mutations = {
    SET_MODAL_REF(state, ref) {
        state.modal_ref = ref;
    },

    SET_CHECKOUT_MODAL_PARAMS: (state, params) => (state.modal_checkout_edit_item_params = params),

    SET_EDIT_ITEM_CHECKOUT: (state, value) => {
        state.modal_checkout_edit_item = value;
    },

    SET_MODAL_UPLOAD_PAGAMENTO_CMA: (state, value) => {
        state.modal_upload_pagamento_cma = value;
    },
};

const actions = {
    async ModalGeneric({ getters }, params) {
        var confirm = await getters.modal_ref({
            title: params.title,
            text: params.text ? params.text : "",
            icon: params.icon,
            html: params.html ? params.html : "",
            showCancelButton: true,
            confirmButtonColor: params.confirmButtonColor
                ? params.confirmButtonColor
                : "rgb(221, 107, 85)",
            confirmButtonText: params.okText ? params.okText : i18n.t("buttons.yes"),
            cancelButtonText: params.cancelText ? params.cancelText : i18n.t("buttons.no"),
        });

        if (confirm.isConfirmed) return true;
        else return false;
    },
    async SimpleModal({ getters }, params) {
        await getters.modal_ref({
            type: params.type,
            title: params.title,
            text: params.text,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-lg btn-secondary",
            },
        });
    },
    async ModalThreeButtonDateChanged({ dispatch }) {
        return dispatch("ModalThreeButtonGeneric", {
            title1: i18n.t("modals.update_date.title"),
            text1: i18n.t("modals.update_date.text"),
            title2: i18n.t("modals.update_date.title2"),
            text2: i18n.t("modals.update_date.text2"),
        });
    },
    async ModalThreeButtonPriceChanged({ dispatch }, { sku, sku_description }) {
        return dispatch("ModalThreeButtonGeneric", {
            title1: i18n.t("modals.update_price.title"),
            text1: i18n.t("modals.update_price.text", { sku, sku_description }),
            title2: i18n.t("modals.update_price.title2"),
            text2: i18n.t("modals.update_price.text2"),
        });
    },
    async ModalThreeButtonGeneric({ getters, dispatch }, params) {
        var confirm = await getters.modal_ref({
            title: params.title1,
            text: params.text1,
            icon: "warning",
            showDenyButton: true,
            showCancelButton: true,
            keydownListenerCapture: true,
            allowEscapeKey: true,
            confirmButtonText: params.confirmButtonText || i18n.t("buttons.yes_all_stores"),
            denyButtonText: params.denyButtonText || i18n.t("buttons.yes_this_store"),
            cancelButtonText: params.cancelButtonText || i18n.t("buttons.only_this_item"),
            cancelButtonColor: "#696969",
            confirmButtonColor: "#eb6157",
            denyButtonColor: "#15ae15",
        });
        if (confirm.dismiss == "backdrop") {
            return "backdrop";
        }
        if (confirm.dismiss == "esc") {
            return "esc";
        }
        if (confirm.isConfirmed) {
            var second_confirm = await dispatch("ModalGeneric", {
                title: params.title2,
                text: params.text2,
                icon: "warning",
            });
            if (second_confirm) {
                return "total";
            } else return "esc";
        } else if (confirm.isDenied) {
            return "card";
        } else if (confirm.isDismissed) {
            return "esc";
        }
    },
    async ModalTwoButtonGeneric({ getters }, params) {
        var confirm = await getters.modal_ref({
            title: params.title1,
            text: params.text1,
            icon: "warning",
            showConfirmButton: false,
            showDenyButton: true,
            showCancelButton: true,
            keydownListenerCapture: true,
            allowEscapeKey: true,
            denyButtonText: params.denyButtonText || i18n.t("buttons.yes"),
            cancelButtonText: params.cancelButtonText || i18n.t("buttons.only_this_item"),
            denyButtonColor: "#15ae15",
            cancelButtonColor: "#696969",
        });
        if (confirm.dismiss == "esc") {
            return "esc";
        }
        if (confirm.isDenied) {
            return "card";
        } else if (confirm.isDismissed) {
            return "esc";
        }
    },
    async newItemModalCheckout({ commit }, params) {
        commit("SET_CHECKOUT_MODAL_PARAMS", {
            action: "new",
            title: i18n.t("pages.checkout.modal_item.add_new_item"),
            cnpj: params.cnpj,
            num_pedido: params.num_pedido,
            item: params,
            sku: "",
            vl: "",
            qtd: "",
            data: "",
            order_id: params.order_id,
            obs: "",
        });
        commit("SET_EDIT_ITEM_CHECKOUT", true);
    },
    async editItemModalCheckout({ commit }, params) {
        commit("SET_CHECKOUT_MODAL_PARAMS", {
            item: params,
            action: "edit",
            title: i18n.t("pages.checkout.modal_item.edit_item"),
            cnpj: params.cnpj,
            num_pedido: params.num_pedido,
            sku: `${params.cd_sku_output} -- ${params.ds_sku_output}`,
            vl: params.vl_p_unitario,
            qtd: params.qty,
            data: params.dt_entrega,
            order_id: params.order_id,
            obs: params.item_log,
        });
        commit("SET_EDIT_ITEM_CHECKOUT", true);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
