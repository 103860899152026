import i18n from "@/plugins/i18n";
import detailing_service from "@/services/api/detalhamento";
import { exportFileCSV } from "@/helpers/files.helper";

import { detalhamentoTableColumns } from "@/configs/table.columns";

const initial_state = () => ({
    loading: false,
    loading_export: false,
    searched: false,
    // filtro
    dt_ref_filter: "",
    nome_empresa_filter: "",
    nome_cliente_filter: "",
    usuario_filter: "",
    canal_filter: "",
    country_filter: "",
    loja: "",
    num_pedido: "",
    file_filter: "",
    nr_exec_filter: "",
    janela_filter: "30",
    log: "",
    order_id: "",
    uuid_list: "",
    uploaded: "",

    today_date: new Date(),

    nome_empresa_FilterOptions: [],
    nome_cliente_FilterOptions: [],
    canal_FilterOptions: [],
    usuario_FilterOptions: [],
    dt_ref_FilterOptions: [],
    filter_log_options: [],
    country_options: [],
    detailing: [],
    pagination: {
        page: 1,
        perPage: 10,
        total_itens: 0,
    },

    table_fields: detalhamentoTableColumns,
});

const state = initial_state();

const doubleDigitsAlways = function (val) {
    return `${("0" + val).slice(-2)}`;
};

const getters = {
    loading(state) {
        return state.loading;
    },
    today_date: (state) => {
        var aux = state.today_date;
        return `${doubleDigitsAlways(aux.getDate())}/${doubleDigitsAlways(
            aux.getMonth() + 1
        )}/${doubleDigitsAlways(aux.getFullYear())} ${doubleDigitsAlways(
            aux.getHours()
        )}:${doubleDigitsAlways(aux.getMinutes())}:${doubleDigitsAlways(aux.getSeconds())}`;
    },
    loading_export: (state) => state.loading_export,
    // getters dos filtros
    nome_empresa_filter: (state) => state.nome_empresa_filter,
    nome_cliente_filter: (state) => state.nome_cliente_filter,
    canal_filter: (state) => state.canal_filter,
    usuario_filter: (state) => state.usuario_filter,
    dt_ref_filter: (state) => state.dt_ref_filter,
    loja: (state) => state.loja,
    log: (state) => state.log,
    janela_filter: (state) => state.janela_filter,

    fields(state, getters, rootState, rootGetters) {
        var role = rootGetters.role;
        var aux = state.table_fields.map((item) => ({ ...item, label: i18n.t(item.label) }));
        if (role >= 6) {
            const coluna_empresa = {
                key: "nome_empresa",
                label: i18n.t("pages.detailing.table_labels.company"),
                sortable: false,
            };
            aux.splice(1, 0, coluna_empresa);
        }

        if (state.country_options.length) {
            const countryColumn = {
                key: "country",
                label: "Pais",
            };
            aux.splice(2, 0, countryColumn);
        }

        return aux;
    },
    detailingData(state) {
        return state.detailing;
    },
    detailing_total_items(state) {
        return state.pagination.total_itens;
    },
    detailing_current_page: (state) => state.pagination.page,
    detailing_per_page(state) {
        return state.pagination.perPage;
    },
    filter_to_api(state) {
        return {
            buscar: "",
            "s.update": state.dt_ref_filter,
            "e.name": state.nome_empresa_filter,
            "s.nome_cliente": state.nome_cliente_filter,
            "u.username": state.usuario_filter,
            "s.canal": state.canal_filter,
            "s.cnpj": state.loja,
            "s.num_pedido": state.num_pedido,
            "s.filepath": state.file_filter,
            "s.nr_exec": state.nr_exec_filter,
            janela: state.janela_filter,
            "s.order_id": state.order_id,
            "s.uuid": state.uuid_list,
            "s.country": state.country_filter,
            log: state.log,
            uploaded: state.uploaded,
            offset: state.pagination.page - 1,
            per_page: state.pagination.perPage,
        };
    },
    filter_options(state) {
        return {
            nome_empresa_FilterOptions: state.nome_empresa_FilterOptions,
            nome_cliente_FilterOptions: state.nome_cliente_FilterOptions,
            canal_FilterOptions: state.canal_FilterOptions,
            usuario_FilterOptions: state.usuario_FilterOptions,
            dt_ref_FilterOptions: state.dt_ref_FilterOptions,
        };
    },
    filter_log_options: (state) =>
        [{ value: "", text: i18n.t("pages.detailing.filters_labels.log") }].concat(
            state.filter_log_options.map((item) => ({ value: item, text: item }))
        ),
    nomeEmpresaOptions(state) {
        var aux = [{ value: "", text: i18n.t("pages.detailing.filters_labels.company") }];
        return aux.concat(state.nome_empresa_FilterOptions);
    },
    nomeClienteOptions(state) {
        // var aux = [{ value: "", text: i18n.t("pages.detailing.filters_labels.cliente") }];
        // return aux.concat(state.nome_cliente_FilterOptions);
        return state.nome_cliente_FilterOptions;
    },
    canalOptions(state) {
        var aux = [{ value: "", text: i18n.t("pages.detailing.filters_labels.channel") }];
        return aux.concat(state.canal_FilterOptions);
    },
    usuarioOptions(state) {
        var aux = [{ value: "", text: i18n.t("pages.detailing.filters_labels.user") }];
        return aux.concat(state.usuario_FilterOptions);
    },
    dtrefOptions(state) {
        return state.dt_ref_FilterOptions;
    },
    showCountryFilter: (state) => !!state.country_options.length,
};

const mutations = {
    SET_LOADING(state, status) {
        state.loading = status;
    },
    REFRESH_TODAY_DATE: (state) => (state.today_date = new Date()),
    SET_LOADING_EXPORT(state, status) {
        state.loading_export = status;
    },
    SET_DETAILING(state, detailing) {
        state.detailing = detailing;
    },
    SET_UPDATE: (state, value) => (state.dt_ref_filter = value),
    SET_UPLOADED: (state, value) => (state.uploaded = value),
    SET_FILTER_FIELD(state, { field, value }) {
        state[field] = value;
    },
    SET_PAGE(state, page) {
        var aux = state.pagination;
        aux.page = page;
        state.pagination = aux;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },
    SET_TOTAL_ITENS(state, total_itens) {
        state.pagination.total_itens = total_itens;
    },
    RESET(state) {
        state.dt_ref_filter = "";
        state.nome_empresa_filter = "";
        state.nome_cliente_filter = "";
        state.usuario_filter = "";
        state.canal_filter = "";
        state.loja = "";
        state.log = "";
        state.uuid_list = "";
        state.uploaded = "";
        state.order_id = "";
        state.country_filter = "";
        state.file_filter = "";
        state.janela_filter = "30";
    },
    RESET_DATA: (state) => {
        Object.assign(state, initial_state());
    },
    SET_SEARCHED(state) {
        state.searched = true;
    },
};

const actions = {
    async updateDate({ dispatch }) {
        dispatch("getDetailing");
    },
    async getDetailing({ commit, getters }, changing_page = false) {
        commit("REFRESH_TODAY_DATE");
        commit("SET_LOADING", true);
        try {
            var filtro = {};
            filtro = Object(getters.filter_to_api);
            if (changing_page) {
                filtro["view"] = "";
            } else {
                commit("SET_PAGE", 1);
            }

            var response = await detailing_service.find(filtro);
            response = response.data;

            commit("SET_DETAILING", response.items);

            if (!changing_page) {
                commit("SET_TOTAL_ITENS", response.total_items || 0);

                commit("SET_FILTER_FIELD", {
                    field: "dt_ref_FilterOptions",
                    value: response["list_dt_ref"] || [],
                });

                commit("SET_FILTER_FIELD", {
                    field: "nome_empresa_FilterOptions",
                    value: response["list_nome_empresa"] || [],
                });

                if (filtro["s.nome_cliente"] == "")
                    commit("SET_FILTER_FIELD", {
                        field: "nome_cliente_FilterOptions",
                        value: response["list_nome_cliente"] || [],
                    });

                commit("SET_FILTER_FIELD", {
                    field: "canal_FilterOptions",
                    value: response["list_canal"] || [],
                });

                commit("SET_FILTER_FIELD", {
                    field: "usuario_FilterOptions",
                    value: response["list_nome_usuario"] || [],
                });

                commit("SET_FILTER_FIELD", {
                    field: "country_options",
                    value: response["country"] || [],
                });

                if (getters.filter_to_api.log == "")
                    commit("SET_FILTER_FIELD", {
                        field: "filter_log_options",
                        value: response["filter_log"] || [],
                    });
            }
        } catch (err) {
            console.error(err);
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao buscar os dados do detalhamento", {
                root: true,
            });
        }
        commit("SET_LOADING", false);
    },
    async search({ commit, dispatch }) {
        commit("SET_PAGE", 1);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getDetailing");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },
    async Export({ commit, getters }) {
        commit("SET_LOADING_EXPORT", true);
        let filter = {...getters.filter_to_api};
        filter["exportar"] = "";
        try {
            const response = await detailing_service.export(filter);
            exportFileCSV(response.data, i18n.t("pages.detailing.export_file_name"));
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao exportar", { root: true });
        }
        commit("SET_LOADING_EXPORT", false);
    },
    async ApplyFilter({ commit }, { field, value }) {
        commit("SET_FILTER_FIELD", { field, value });
    },
    async ResetFilter({ commit, dispatch }) {
        commit("RESET");
        dispatch("search");
    },
    async ClearFilter({ commit }) {
        commit("RESET");
    },
    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getDetailing", true);
        commit("saveStatus", "success", { root: true });
    },
    async changePerPage({ commit, dispatch }, itens_perPage) {
        commit("SET_PER_PAGE", itens_perPage);
        commit("SET_PAGE", 1);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getDetailing");
        commit("saveStatus", "success", { root: true });
    },
    closeFilterSidebar({ commit }) {
        commit("SET_RIGHT_SIDEBAR", false, { root: true });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
