import api from '@/services/api';

export default {
    list(data) {
        const form = new FormData(); 
        for(let key in data) form.append(key, data[key]); 
        // form.append('offset', data.offset); 
        // form.append('per_page', data.limit ? data.limit : 10)

        // form.append('cnpj', data.cnpj.replace(/[^0-9]/g, ""))
        // form.append('status', data.status_filter && data.status_filter != '' ? data.status_filter : 'Todos')
        // form.append('uploaded', data.data) 
        return api().post('/bonificacao', form); 
    }, 
    accept_request(data) {
        const form = new FormData(); 

        form.append('order_id', data.order_id); 
        form.append('filepath', data.filepath); 
        form.append('uploaded', data.uploaded); 
        form.append('cnpj', data.cnpj)
        form.append('per_page', data.limit ? data.limit : 10)

        return api().post("/bonificacao/aceitar_pedido", form); 
    }, 
    reject_request(data) {
        const form = new FormData(); 

        form.append('order_id', data.order_id); 
        form.append('filepath', data.filepath); 
        form.append('uploaded', data.uploaded); 
        form.append('cnpj', data.cnpj)
        form.append('per_page', data.limit ? data.limit : 10)


        return api().post("/bonificacao/rejeitar_pedido ", form); 
    }, 

    export (data) {
        const form = new FormData(); 
        form.append('exportar','')
        form.append('offset', data.offset); 
        form.append('per_page', data.limit ? data.limit : 10)

        form.append('cnpj', data.cnpj )
        form.append('status', data.status_filter && data.status_filter != '' ? data.status_filter : 'Todos')
        form.append('uploaded', data.data)

        return api().post('/bonificacao', form); 
    }
}
