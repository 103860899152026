import admin_service from "@/services/api/admin.js";
import { exportFileTXT } from "@/helpers/files.helper";
import { getListOfCompanyToSelectInput } from "@/helpers/companies.helper";
import { localStorageGet, localStorageSet } from "@/helpers/storage.helper";

const initial_state = () => ({
    loading: false,

    qty_lines: 100,
    start_date: "",

    logs: "",

    loadingUsers: false,

    selected_user: null,

    filterForm: {},

    userList: [],
    total_items: 0,

    page: 1,
    per_page: 100,

    selected_enterprise: null,
    parameters: [],
    temp_parameters: [],
});
const state = initial_state();

const getters = {
    loading: (state) => state.loading,
    qty_lines: (state) => state.qty_lines,
    start_date: (state) => state.start_date,
    logs: (state) => state.logs,
    filter_to_api: (state) => ({
        qty_lines: state.qty_lines,
        start_date: state.start_date,
    }),
    showUserId() {
        return true;
    },
    showUserCompany() {
        return true;
    },
    showRole() {
        return true;
    },
    showAboutMe() {
        return true;
    },
    showBURoleId() {
        return true;
    },
    showInactive() {
        return true;
    },
    loadingUsers: (state) => state.loadingUsers,
    filterForm: (state) => state.filterForm,
    selected_user: (state) => state.selected_user,
    listOfUsers: (state) => state.userList,
    total_items: (state) => state.total_items,
    page: (state) => state.page,

    lista_empresas: (state, getters, rootState, rootGetters) => {
        if (rootGetters["role"] < 6) return [];
        const list_store = rootGetters["lista_empresas"];
        const list_empresas = getListOfCompanyToSelectInput(list_store);
        list_empresas.sort((a, b) => {
            if (a.text >= b.text) return 1;
            else return -1;
        });
        return list_empresas;
    },

    filterToApi: (state) => ({
        page: state.page - 1,
        per_page: state.per_page,

        ...state.filterForm,
    }),

    selected_enterprise: (state) => state.selected_enterprise,
    parameters: (state) => state.parameters,
    temp_parameters: (state) => state.temp_parameters,
};

const mutations = {
    SET_LOADING: (state, loading) => (state.loading = loading),
    SET_QTY_LINES: (state, payload) => (state.qty_lines = payload),
    SET_START_DATE: (state, payload) => (state.start_date = payload),
    SET_LOGS: (state, logs) => (state.logs = logs),

    SET_SELECTED_USER: (state, user) => (state.selected_user = user),
    SET_LOADING_USERS: (state, loading) => (state.loadingUsers = loading),

    SET_FILTER_FORM: (state, form) => (state.filterForm = { ...form }),
    SET_PAGE: (state, page) => (state.page = page),
    ADD_USERS_TO_LIST: (state, users) => (state.userList = [...state.userList.concat(users)]),
    CLEAR_USERS_LIST: (state) => (state.userList = []),
    SET_TOTAL_ITEMS: (state, total) => (state.total_items = total),

    UPDATE_USER_IN_LIST: (state, user) => {
        let listOfUsers = state.userList;
        const indexOfUser = listOfUsers.findIndex((item) => item.id == user.id);

        if (indexOfUser == -1) return;

        listOfUsers.splice(indexOfUser, 1, user);

        if (state.selected_user.id == user.id) state.selected_user = user;

        state.userList = [];
        state.userList = [...listOfUsers];
    },

    RESET_FILTER: (state) => {
        state.qty_lines = 100;
        state.start_date = "";
    },
    RESET_DATA: (state) => {
        Object.assign(state, initial_state());
    },
    SET_ENTERPRISE: (state, enterprise) => {
        state.selected_enterprise = enterprise;
    },
    SET_PARAMETERS: (state, { parameters }) => {
        state.parameters = parameters;
    },
    SET_TEMP_PARAMETERS: (state, { parameters }) => {
        state.temp_parameters = parameters;
    },
};

const actions = {
    search({ dispatch }) {
        dispatch("getContainerLogs");
    },
    clear({ commit, dispatch }) {
        commit("RESET_FILTER");
        dispatch("getContainerLogs");
    },

    async getContainerLogs({ commit, getters }) {
        commit("SET_LOADING", true);
        try {
            const filter = getters.filter_to_api;

            const response = await admin_service.find(filter);
            commit("SET_LOGS", Object.freeze(response.data));
        } catch {
            commit("SET_LOGS", "");
        }
        commit("SET_LOADING", false);
    },
    DownloadLogFile({ getters }) {
        exportFileTXT(getters.logs, `logs-${getters.start_date}-qty-${getters.qty_lines}`);
    },
    resetAdminStore({ commit }) {
        commit("RESET_DATA");
    },
    selectUser({ commit }, user) {
        commit("SET_ENTERPRISE", user.empresa_id);
        commit("SET_SELECTED_USER", user);
    },
    clearUser({ commit }) {
        commit("SET_SELECTED_USER", null);
    },
    setUsersPage({ commit }, page) {
        commit("SET_PAGE", page);
    },
    nextPageOfUsers({ commit, dispatch, state }) {
        if (state.loadingUsers) return;
        if (state.userList.length == state.total_items) return;
        commit("SET_LOADING_USERS", true);
        commit("SET_PAGE", state.page + 1);
        dispatch("getUsers");
    },
    setUsersFilterForm({ commit }, form) {
        commit("SET_FILTER_FORM", form);
    },
    resetPageAndUsersList({ commit, dispatch }) {
        commit("SET_PAGE", 1);
        commit("CLEAR_USERS_LIST");
        dispatch("getUsers");
    },
    getUsers({ commit, getters }) {
        commit("SET_LOADING_USERS", true);
        admin_service
            .getAllUsers(getters.filterToApi)
            .then((result) => {
                commit("ADD_USERS_TO_LIST", result.items);
                commit("SET_TOTAL_ITEMS", result.total_items);
            })
            .catch(() => {})
            .finally(() => commit("SET_LOADING_USERS", false));
    },
    updateUserInfoInList({ commit }, user) {
        commit("UPDATE_USER_IN_LIST", user);
    },
    sortAndSetOnlyTempParameters({ commit }, params) {
        const sortedParams = params.sort((a, b) => {
            if (a.empresa_id <= b.empresa_id) return -1;
            else return 1;
        });

        commit("SET_TEMP_PARAMETERS", { parameters: sortedParams });

        Promise.resolve();
    },
    sortAndSetParameters({ commit }, params, saveOnLocalStorage) {
        const sortedParams = params.sort((a, b) => {
            if (a.empresa_id <= b.empresa_id) return -1;
            else return 1;
        });

        if (saveOnLocalStorage) {
            localStorageSet("parameters", JSON.stringify(sortedParams));
        }

        commit("SET_PARAMETERS", { parameters: sortedParams });
        commit("SET_TEMP_PARAMETERS", { parameters: sortedParams });

        Promise.resolve();
    },
    enterpriseOnFilterToApiReturn({ dispatch, getters }, params, saveOnLocalStorage) {
        const enterpriseOnFilterToApi = getters.filterToApi.empresa_id;

        if (enterpriseOnFilterToApi) {
            const result = params.filter((item) =>
                enterpriseOnFilterToApi.includes(item.empresa_id)
            );

            if (result.length) {
                dispatch("sortAndSetParameters", result, saveOnLocalStorage);
            }

            return;
        }

        dispatch("sortAndSetParameters", params, saveOnLocalStorage);
    },
    getUsersParameters({ dispatch }, enterpriseId) {
        const cachedParameters = localStorageGet("parameters");
        const parameters = JSON.parse(cachedParameters);
        const key = enterpriseId || null;

        if (cachedParameters && key) {
            const result = parameters.filter((item) => item.empresa_id === key);

            if (result.length) {
                return dispatch("enterpriseOnFilterToApiReturn", result, false);
            }

            return admin_service.getParameters(key).then((result) => {
                return dispatch("enterpriseOnFilterToApiReturn", result.items, true);
            });
        }

        if (cachedParameters && !key) {
            if (!parameters.length) {
                return admin_service.getParameters().then((result) => {
                    return dispatch("enterpriseOnFilterToApiReturn", result.items, true);
                });
            }

            const firstEnterpriseId = parameters[0].empresa_id;
            const allSameEmpresaId = parameters.every(
                (item) => item.empresa_id === firstEnterpriseId
            );

            if (allSameEmpresaId) {
                return admin_service.getParameters().then((result) => {
                    return dispatch("enterpriseOnFilterToApiReturn", result.items, true);
                });
            }

            return dispatch("enterpriseOnFilterToApiReturn", parameters);
        }

        if (!cachedParameters && key) {
            return admin_service.getParameters(key).then((result) => {
                return dispatch("enterpriseOnFilterToApiReturn", result.items, true);
            });
        }

        if (!cachedParameters && !key) {
            return admin_service.getParameters().then((result) => {
                return dispatch("enterpriseOnFilterToApiReturn", result.items, true);
            });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
