<template>
    <div :class="variant">
        <b-icon v-if="icon" :icon="icon"></b-icon>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: null,
        },
        variant: {
            type: String,
            default: "primary",
        },
    },
};
</script>

<style lang="scss" scoped>
.primary,
.secondary,
.success,
.danger,
.warning {
    padding: 2px 4px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 14px;
    margin: 0px 4px 0px 0px;
}

.primary {
    background-color: #fff7ed;
    border: 1px solid #fdba74;
    color: #9a3412;
}

.secondary {
    background-color: #eff6ff;
    border: 1px solid #93c5fd;
    color: #1e40af;
}

.success {
    background-color: #f0fdf4;
    border: 1px solid #86efac;
    color: #166534;
}

.danger {
    background-color: #fef2f2;
    border: 1px solid #fca5a5;
    color: #991b1b;
}

.warning {
    background-color: #fefce8;
    border: 1px solid #fde047;
    color: #854d0e;
}

div {
    white-space: nowrap;
    align-self: start;
}
</style>
