<template>
    <div class="breadcrumb-pedmais">
        <router-link to="/">
            <b-icon class="breadcrumb-pedmais-icon" icon="house-door"></b-icon>
        </router-link>
        <b-breadcrumb class="breadcrumb-pedmais-text" :items="crumbArray"></b-breadcrumb>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "Breadcrumb",
    computed: {
        ...mapGetters(["crumbArray"])
    },
    created() {
        this.getCrumbs();
    },
    methods: {
        ...mapMutations(["resetCrumbArray", "pushCrumbArray"]),

        // This is the method that computes the actual crumbs
        getCrumbs() {
            // Every time we call this method, we reset the crumbs
            this.resetCrumbArray();
            // If there is a meta route defined, we use it
            let metaRoute = this.$route.meta.crumbs || [];

            metaRoute.forEach(element => {
                this.pushCrumbArray({
                    text: this.$t(`breadcumb.${element.text}`),
                    to: element.to || null,
                    active: true
                });
            });
        }
    }
};
</script>
