<template>
    <div class="forgot">
        <div class="forgot-header mb-4">
            <img src="../../assets/img/logo_pedmais_login_s.png" width="100" />
        </div>
        <div class="card">
            <div class="card-body">
                <template v-if="!senha_alterada">
                    <div class="text-left mb-4">
                        <h4>{{ $t("auth.reset_password.title") }}</h4>
                    </div>
                    <b-form autocomplete="off">
                        <!-- Nova senha -->
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <b-icon icon="lock"></b-icon>
                                    </span>
                                </div>
                                <b-form-input
                                    name="nova_senha_input_form"
                                    ref="ref_nova_senha"
                                    v-model="nova_senha"
                                    :type="show_new_password ? 'text' : 'password'"
                                    autocomplete="off"
                                    @keyup.enter="$refs['ref_confirmar_senha'].focus()"
                                    :placeholder="$t('auth.reset_password.new_password')"
                                    :aria-label="$t('auth.reset_password.new_password')"
                                    aria-describedby="nova senha"
                                    required
                                >
                                </b-form-input>
                                <div
                                    @click="show_new_password = !show_new_password"
                                    class="input-group-append"
                                >
                                    <div class="show-password input-group-text">
                                        <b-icon
                                            :icon="!show_new_password ? 'eye-slash' : 'eye'"
                                        ></b-icon>
                                    </div>
                                </div>
                            </div>
                            <!-- <b-form-text id="input-live-help" class="text-left">
                                {{ $t('auth.reset_password.password_hint') }}
                            </b-form-text> -->
                            <div class="password-rules">
                                <ul v-if="!isPasswordValid">
                                    <li :class="{ 'rule-checked': rule_min_characteres }">
                                        {{ $t("auth.reset_password.password_length") }}
                                    </li>
                                    <li :class="{ 'rule-checked': contains_Lowercase }">
                                        {{ $t("auth.reset_password.contains_lowercase") }}
                                    </li>
                                    <li :class="{ 'rule-checked': contains_Uppercase }">
                                        {{ $t("auth.reset_password.contains_uppercase") }}
                                    </li>
                                    <li :class="{ 'rule-checked': contains_number }">
                                        {{ $t("auth.reset_password.contains_number") }}
                                    </li>
                                    <li :class="{ 'rule-checked': contains_special_character }">
                                        {{ $t("auth.reset_password.contains_special_character") }}
                                    </li>
                                </ul>
                                <div class="password-okay-class" v-else>
                                    <b-icon icon="check2-circle" font-scale="1.5"></b-icon
                                    >{{ $t("auth.reset_password.password_is_valid") }}
                                </div>
                            </div>
                        </div>

                        <!-- Confirmar a senha -->
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <b-icon icon="lock"></b-icon>
                                    </span>
                                </div>
                                <b-form-input
                                    ref="ref_confirmar_senha"
                                    v-model="confirmar_senha"
                                    :type="show_confirm_password ? 'text' : 'password'"
                                    autocomplete="off"
                                    @keyup.enter="SalvarNovaSenha()"
                                    :placeholder="$t('auth.reset_password.confirm_password')"
                                    :aria-label="$t('auth.reset_password.confirm_password')"
                                    aria-describedby="confirmar senha"
                                    required
                                >
                                </b-form-input>
                                <div
                                    @click="show_confirm_password = !show_confirm_password"
                                    class="input-group-append"
                                >
                                    <div class="show-password input-group-text">
                                        <b-icon
                                            :icon="!show_confirm_password ? 'eye-slash' : 'eye'"
                                        ></b-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!doesPasswordMatch" class="senhas-nao-iguais">
                            <b-icon icon="x-circle"></b-icon>
                            {{ $t("auth.reset_password.passwords_dont_match") }}
                        </div>

                        <div
                            class="senhas-iguais"
                            v-if="doesPasswordMatch && confirmar_senha && confirmar_senha != ''"
                        >
                            <b-icon icon="check2-circle" font-scale="1.5"></b-icon>
                            {{ $t("auth.reset_password.passwords_match") }}
                        </div>

                        <div class="form-group mb-4">
                            <b-button
                                :disabled="!isFormValid()"
                                @click="SalvarNovaSenha()"
                                block
                                variant="secondary"
                            >
                                <!-- <b-icon icon="unlock"></b-icon> -->
                                {{ $t("auth.reset_password.save_password") }}
                            </b-button>
                        </div>
                    </b-form>
                </template>
                <template v-else>
                    <div class="text-left mb-4">
                        <h4>{{ $t("auth.reset_password.password_changed") }}</h4>
                    </div>
                    <small>
                        {{ $t("auth.reset_password.success_message") }}
                    </small>
                </template>

                <hr />
                <div class="row align-items-center justify-content-between no-gutters">
                    <router-link class="card-body-link" to="/login">
                        <small> Login </small>
                    </router-link>
                    <img src="../../assets/img/logo_weduu.png" width="100px" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import login from "@/services/api/login";

export default {
    name: "NewPassword",
    props: {
        email: {
            type: String,
        },
    },
    data() {
        return {
            nova_senha: "",
            confirmar_senha: "",
            token_auth: "",
            senha_alterada: false,
            format: /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,

            // Variaveis dos inputs
            show_new_password: false,
            show_confirm_password: false,
        };
    },
    mounted() {
        this.token_auth = this.$route.params.token;

        this.checkAccess();
    },
    computed: {
        ...mapGetters(["appName"]),
        rule_min_characteres() {
            return this.nova_senha.length >= 8 ? true : false;
        },
        contains_number() {
            return /\d/.test(this.nova_senha);
        },
        contains_Uppercase() {
            return /[A-Z]/.test(this.nova_senha);
        },
        contains_Lowercase() {
            return /[a-z]/.test(this.nova_senha);
        },
        contains_special_character() {
            return this.format.test(this.nova_senha);
        },
        isPasswordValid() {
            return (
                this.rule_min_characteres &&
                this.contains_number &&
                this.contains_Uppercase &&
                this.contains_Lowercase &&
                this.contains_special_character
            );
        },
        doesPasswordMatch() {
            if (this.confirmar_senha != "") {
                if (this.nova_senha != this.confirmar_senha) return false;
                else return true;
            } else return true;
        },
    },
    methods: {
        ...mapActions(["checkAccess"]),
        ...mapMutations("configs", ["CHANGE_LANGUAGE"]),
        ...mapMutations(["PUSH_SUCCESS_TO_ARRAY", "PUSH_ERROR_TO_ARRAY"]),
        async SalvarNovaSenha() {
            if (this.isFormValid()) {
                try {
                    await login.set_new_password(this.nova_senha, this.token_auth);

                    this.PUSH_SUCCESS_TO_ARRAY("senha_alterada_com_sucesso");
                    this.senha_alterada = true;
                } catch (error) {
                    if (error.response.data.message) {
                        this.PUSH_ERROR_TO_ARRAY("erro_token_alterar_senha");
                    } else if (error.response.data.error) {
                        this.PUSH_ERROR_TO_ARRAY("erro_senha_fraca_demais");
                    }
                }
            }
        },
        isFormValid() {
            const c1 = this.nova_senha && this.nova_senha != "";
            const c3 = this.confirmar_senha && this.confirmar_senha != "";
            const c5 = this.nova_senha === this.confirmar_senha;

            return c1 && c3 && c5 && this.isPasswordValid;
        },
    },
};
</script>

<style lang="scss" scoped>
.password-rules {
    display: flex;
    justify-content: flex-start;
    margin: 20px 0px 0px 30px;

    text-align: left;
    font-size: 12px;
}

.rule-checked {
    text-decoration: line-through;
    color: rgba(14, 206, 14, 0.863);
}

.password-okay-class {
    color: rgb(14, 136, 14);
}

.card-body {
    min-width: 400px;
}

.senhas-nao-iguais {
    padding-left: 30px;
    padding-bottom: 10px;
    font-size: 12px;
    text-align: left;
    color: red;
    font-weight: bolder;
    margin: 5px 0px;
}

.senhas-iguais {
    padding-left: 30px;
    padding-bottom: 10px;
    font-size: 12px;
    text-align: left;
    color: rgb(14, 136, 14);
    font-weight: bolder;
    margin: 5px 0px;
}

.forgot {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    &-header {
        align-items: center;
        display: flex;
        flex-direction: row;
    }
}

.card {
    &-body {
        &-link {
            color: $black-color;
            text-decoration: none;
            transition: $transition;
            &:hover {
                color: $primary-color;
                cursor: pointer;
            }
        }
    }
}
</style>
