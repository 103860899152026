import api from "@/services/api";

export default {
    onboading_list(offset, page, data){
        
        var cols = [
            "uploaded",
            "last_update",
            "account_id",
            "user",
            "company",
            "country",
            "status",
            "status_integration",
            "account_name",
            "vendor_id",
            "order_id",
            "status_description",
            "filename",
            "foreign_user_id",
            "dt_timestamp_id",
            "vendor_location",
            "vendor_name",
            "note",
            "pages",
            "lines",
            "file_extension",
            "owner",
            "multpoc",
            "account_list",
        ]

        var filters = ""
        
        Object.keys(data).forEach( col => {
            if(data[col]){
                filters += "&" + col + "=" + data[col]
            }
        })

        var query = `?offset=${offset-1}&per_page=${page}&columns=${cols}`;
         
        return api().get("/curatorship/onboarding" + query+filters);
                
    },
    // ROTA DE MUDANÇA DE STATUS
    set_status(timestamp_id,data){
        return api().patch("/curatorship/onboarding/" + timestamp_id, data);
    },
    // ROTA DE FINALIZAÇÂO DO ONBOARDING
    close_onboarding(data){
        return api().post("/curatorship/onboarding/abinbev/update", data);
    },
    //Rota de criação de onboarding
    create_onboaring(payload){
        return api().post("/bees/send_ticket", payload);
    },
}