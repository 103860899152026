// Inspirado em uma função do object.helper.js
import { isDateBeforeToday } from "@/helpers/date.helper";

export const returnListOfAllOrderIdInNestedObject = function (nestedObject) {
    if (nestedObject instanceof Object && nestedObject instanceof Array) {
        return nestedObject.map((item) => item.order_id);
    }

    const listOfKeys = Object.keys(nestedObject);
    return listOfKeys.reduce(
        (pv, cur) => pv.concat(returnListOfAllOrderIdInNestedObject(nestedObject[cur])),
        []
    );
};

export const isAllSuggestionsApproved = function (nestedObject) {
    if (nestedObject instanceof Object && nestedObject instanceof Array) {
        return !nestedObject.some((item) => !item.suggestion_status);
    }

    const listOfKeys = Object.keys(nestedObject);
    return !listOfKeys.some((key) => !isAllSuggestionsApproved(nestedObject[key]));
};

export const isAllSuggestionsSent = function (nestedObject) {
    if (nestedObject instanceof Object && nestedObject instanceof Array) {
        return nestedObject.every((item) => item.suggestion_sent);
    }

    const listOfKeys = Object.keys(nestedObject);
    return listOfKeys.every((key) => isAllSuggestionsSent(nestedObject[key]));
};

export const isAnySuggestionApproved = function (nestedObject) {
    if (nestedObject instanceof Object && nestedObject instanceof Array) {
        return nestedObject.some((item) => item.suggestion_status);
    }

    const listOfKeys = Object.keys(nestedObject);
    return listOfKeys.some((key) => isAnySuggestionApproved(nestedObject[key]));
};

export const isAnySuggestionBeforeToday = function (nestedObject) {
    if (nestedObject instanceof Object && nestedObject instanceof Array) {
        return nestedObject.some((item) => isDateBeforeToday(item.created));
    }

    const listOfKeys = Object.keys(nestedObject);
    return listOfKeys.some((key) => isAnySuggestionBeforeToday(nestedObject[key]));
};

export const searchAndUpdateInSuggestions = (originalSuggestions, suggestionToUpdate) => {
    if (!Array.isArray(originalSuggestions)) {
        for (const [, value] of Object.entries(originalSuggestions)) {
            const isSuccessfull = searchAndUpdateInSuggestions(value, suggestionToUpdate);
            if (isSuccessfull) return;
        }
    } else {
        // Caso seja um array
        const index = originalSuggestions.findIndex(
            (item) => item.order_id == suggestionToUpdate.order_id
        );
        if (index === -1) return false; // Não encontrou nessa lista de sugestões
        originalSuggestions.splice(index, 1, suggestionToUpdate); // Substitui a sugestão no mesmo indice
        return true;
    }
};

export const returnListOfAllSuggestionUnGrouped = (nestedSuggestion) => {
    if (Array.isArray(nestedSuggestion)) return nestedSuggestion;

    return Object.keys(nestedSuggestion).reduce((pv, cur) => {
        return pv.concat(returnListOfAllSuggestionUnGrouped(nestedSuggestion[cur]));
    }, []);
};

export const filterSuggestionsByCustomFunction = (nestedObject, filterFunction) => {
    if (Array.isArray(nestedObject)) return nestedObject.filter(filterFunction);

    return nestedObject;
};
