export const objectToURLQuery = (object) => {
    const arrayParam = Object.keys(object).map((i) => `${i}=${object[i]}`);
    return arrayParam.join("&");
};

export const prepareFormDataToRequest = (object, callback = null) => {
    const form = new FormData();
    Object.keys(object).forEach((field) => {
        var value = object[field];
        if (callback) value = callback(field, object[field]);

        form.append(field, value);
    });
    return form;
};

export const prepareAndClearFormDataToRequest = (object, mapObject = null) => {
    const form = new FormData();
    Object.keys(object).forEach((field) => {
        var value = object[field];

        if (mapObject && mapObject[field]) form.append(mapObject[field], value);
        else {
            let aux = value;
            if (aux === 0) aux = "0";

            form.append(field, aux);
        }
    });
    return form;
};
