import SuggestionGuard from "../guards/suggestion-guard";

export const SuggestionsRoutes = [
    {
        path: "/sugestoes",
        name: "Sugestoes",
        beforeEnter: SuggestionGuard,
        component: () => import("@/layouts/Sugestoes"),
        children: [
            {
                path: "estatisticas",
                name: "Statistics",
                component: () => import("@/views/Sugestoes/Statistics.vue"),
            },
            {
                path: "",
                name: "SugestoesPrincipal",
                component: () => import("@/views/Sugestoes/Suggestions.vue"),
            },
        ],
    },
];
