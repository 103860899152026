import { exportFilePDF, exportFileXLSX } from "@/helpers/files.helper";
import { GetTodayDateTimeString } from "@/helpers/date.helper";
import pagamento_service from "@/services/api/pagamento.js";
import i18n from "@/plugins/i18n";
import { paymentTableColumns, paymentMonitoringTableColumns } from "@/configs/table.columns";
const initial_state = () => ({
    upload_files: [],

    loading_upload: false,
    total_progress_upload: 0,

    loading: false,
    loading_export: false,

    timestamp_generated: null,

    // ANALITICO
    list_of_filters: [],

    date_detalhamento: new Date(),

    data_ref: "",
    data_chamado: "",
    invoice: "",
    bl: "",
    ref: "",
    vessel: "",
    discharge_port: "",
    consignee: "",
    charge_description: "",

    table_fields: paymentTableColumns,
    detalhamento: [],
    pagination: {
        page: 1,
        perPage: 10,
        total_itens: 0,
    },

    // MONITORAMENTO

    all_selected: false,

    file_params: {
        file_timestamp: "",
        file_uuid: "",
        file_name: "",
    },

    modal_export_pagamento: false,

    date_monitoramento: new Date(),

    pesquisar: "",
    data_processamento: "",
    data_fatura: "",
    status_monitoramento: "",

    table_fields_monitoramento: paymentMonitoringTableColumns,

    monitoramento: [],

    pagination_monitoramento: {
        page: 1,
        perPage: 10,
        total_itens: 0,
    },
});
const state = initial_state();

const getters = {
    loading_upload: (state) => state.loading_upload,
    files: (state) => state.upload_files,
    loading: (state) => state.loading,
    loading_export: (state) => state.loading_export,
    total_progress_upload: (state) => state.total_progress_upload,
    timestamp_generated: (state) => state.timestamp_generated,

    data_processamento: (state) => state.data_processamento,
    data_fatura: (state) => state.data_fatura,
    pesquisar: (state) => state.pesquisar,
    status_monitoramento: (state) => state.status_monitoramento,
    date_monitoramento: (state) => state.date_monitoramento.toLocaleString("pt-BR"),

    // Filtro detalhamento
    data_ref: (state) => state.data_ref,
    data_chamado: (state) => state.data_chamado,
    invoice: (state) => state.invoice,
    bl: (state) => state.bl,
    ref: (state) => state.ref,
    vessel: (state) => state.vessel,
    discharge_port: (state) => state.discharge_port,
    consignee: (state) => state.consignee,
    charge_description: (state) => state.charge_description,
    file_params: (state) => state.file_params,

    all_selected: (state) => state.all_selected,
    table_fields: (state) =>
        state.table_fields.map((item) => ({ ...item, label: i18n.tc(item.label) })),
    detalhamento: (state) => state.detalhamento,
    current_page: (state) => state.pagination.page,
    total_items: (state) => state.pagination.total_itens,
    per_page: (state) => state.pagination.perPage,
    date_detalhamento: (state) => state.date_detalhamento.toLocaleString("pt-BR"),
    nome_arquivo: (state) => state.nome_arquivo,
    monitoramento_table_fields: (state) =>
        state.table_fields_monitoramento.map((item) => ({ ...item, label: i18n.tc(item.label) })),
    monitoramento: (state) => state.monitoramento,
    monitoramento_current_page: (state) => state.pagination_monitoramento.page,
    monitoramento_per_page: (state) => state.pagination_monitoramento.perPage,
    monitoramento_total_items: (state) => state.pagination_monitoramento.total_itens,

    modal_export_pagamento: (state) => state.modal_export_pagamento,

    monitoramento_filter_to_api: (state) => ({
        dt_processing: state.data_processamento,
        dt_invoice: state.data_fatura,
        search: state.pesquisar,
        file_status: state.status_monitoramento,
        offset: state.pagination_monitoramento.page - 1,
        per_page: state.pagination_monitoramento.perPage,
    }),

    filter_to_api: (state) => ({
        offset: state.pagination.page - 1,
        per_page: state.pagination.perPage,
        date: state.data_ref,
        call_date: state.data_chamado,
        invoice: state.invoice,
        bill_of_lading: state.bl,
        booking_ref: state.bl,
        your_ref: state.ref,
        vessel: state.vessel,
        discharge_port: state.discharge_port,
        consignee: state.consignee,
        charge_description: state.charge_description,
        file_uuid: state.file_params.file_uuid,
        file_timestamp: state.file_params.file_timestamp,
    }),
};

const mutations = {
    SET_LOADING: (state, status) => (state.loading = status),
    SET_LOADING_EXPORT: (state, status) => (state.loading_export = status),

    SET_FILE_PARAMS: (state, params) => {
        state.file_params = params;
    },

    // upload
    SET_FILES: (state, files) => (state.upload_files = files),
    GENERATE_TIMESTAMP: (state) => (state.timestamp_generated = new Date().getTime()),
    CLEAR_FILES: (state) => {
        state.upload_files = [];
        state.total_progress_upload = 0;
    },
    SET_PERCENTAGE_UPLOAD: (state, percentage) => (state.total_progress_upload = percentage),
    REMOVE_FILE_FROM_ARRAY: (state, index) => {
        state.upload_files.splice(index, 1);
    },

    SET_SELECT_MULTIPLE: (state, status) => (state.all_selected = status),
    SELECT_LINE: (state, index) => {
        state.monitoramento[index].selected = true;
    },
    UNSELECT_LINE: (state, index) => {
        state.monitoramento[index].selected = false;
    },
    SELECT_ALL_LINES: (state) => {
        state.monitoramento.forEach((item) => {
            item.selected = true;
        });
    },
    UNSELECT_ALL_LINES: (state) => {
        state.monitoramento.forEach((item) => {
            item.selected = false;
        });
    },

    // monitoramento
    SET_DATA_PROCESSAMENTO: (state, value) => (state.data_processamento = value),
    SET_DATA_FATURA: (state, value) => (state.data_fatura = value),
    SET_PESQUISAR: (state, value) => (state.pesquisar = value),
    SET_STATUS_MONITORAMENTO: (state, value) => (state.status_monitoramento = value),
    CLEAR_FILTER_MONITORAMENTO: (state) => {
        state.pesquisar = "";
        state.data_processamento = "";
        state.data_fatura = "";
        state.status_monitoramento = "";
    },
    SET_MONITORAMENTO_LINES: (state, items) => (state.monitoramento = items),
    SET_TOTAL_MONITORAMENTO_LINES: (state, total) =>
        (state.pagination_monitoramento.total_itens = total),
    SET_PAGE_MONITORAMENTO: (state, page) => (state.pagination_monitoramento.page = page),
    SET_PER_PAGE_MONITORAMENTO: (state, per_page) =>
        (state.pagination_monitoramento.perPage = per_page),
    UPDATE_DATE_MONITORAMENTO: (state) => (state.date_monitoramento = new Date()),

    // detalhamento
    SET_DATA_REF: (state, payload) => (state.data_ref = payload),
    SET_DATA_CHAMADO: (state, payload) => (state.data_chamado = payload),
    SET_INVOICE: (state, payload) => (state.invoice = payload),
    SET_BL: (state, payload) => (state.bl = payload),
    SET_REF: (state, payload) => (state.ref = payload),
    SET_VESSEL: (state, payload) => (state.vessel = payload),
    SET_DISCHARGE_PORT: (state, payload) => (state.discharge_port = payload),
    SET_CONSIGNEE: (state, payload) => (state.consignee = payload),
    SET_CHARGE_DESCRIPTION: (state, payload) => (state.charge_description = payload),
    UPDATE_DATE_DETALHAMENTO: (state) => (state.date_detalhamento = new Date()),

    OPEN_MODAL_EXPORT_PAGAMENTO: (state) => (state.modal_export_pagamento = true),
    CLOSE_MODAL_EXPORT_PAGAMENTO: (state) => (state.modal_export_pagamento = false),

    CLEAR_INPUT_FILTERS_DETALHAMENTO: (state) => {
        state.data_ref = "";
        state.data_chamado = "";
        state.invoice = "";
        state.bl = "";
        state.ref = "";
        state.vessel = "";
        state.discharge_port = "";
        state.consignee = "";
        state.charge_description = "";
    },
    CLEAR_FILTER_DETALHAMENTO: (state) => {
        state.file_params = {
            file_timestamp: "",
            file_uuid: "",
        };
        state.data_ref = "";
        state.data_chamado = "";
        state.invoice = "";
        state.bl = "";
        state.ref = "";
        state.vessel = "";
        state.discharge_port = "";
        state.consignee = "";
        state.charge_description = "";
    },
    SET_FILTERS_DETALHAMENTO: (state, list) => (state.list_of_filters = list),
    SET_DETALHAMENTO_LINES: (state, lines) => (state.detalhamento = lines),
    SET_TOTAL_DETALHAMENTO: (state, total) => (state.pagination.total_itens = total),
    SET_PAGE_DETALHAMENTO: (state, page) => (state.pagination.page = page),
    SET_PER_PAGE_DETALHAMENTO: (state, per_page) => (state.pagination.perPage = per_page),
};

const actions = {
    async openModalExportPagamento({ commit, dispatch }) {
        await dispatch("getDetalhamento");
        commit("OPEN_MODAL_EXPORT_PAGAMENTO");
    },
    updateDateCockpit({ commit, dispatch }) {
        commit("UPDATE_DATE_MONITORAMENTO");
        dispatch("getMonitoramento");
    },
    updateDateDetailing({ commit, dispatch }) {
        commit("UPDATE_DATE_DETALHAMENTO");
        dispatch("getDetalhamento");
    },
    // Funções de pesquisa de dados
    searchMonitoramento({ commit, dispatch }) {
        commit("SET_PAGE_MONITORAMENTO", 1);
        dispatch("getMonitoramento");
    },
    clearMonitoramento({ commit, dispatch }) {
        commit("CLEAR_FILTER_MONITORAMENTO");
        commit("SET_PAGE_MONITORAMENTO", 1);
        dispatch("getMonitoramento");
    },
    searchDetalhamento({ commit, dispatch }) {
        commit("SET_PAGE_DETALHAMENTO", 1);
        dispatch("getDetalhamento");
    },
    clearDetalhamento({ commit, dispatch }) {
        commit("CLEAR_FILTER_DETALHAMENTO");
        commit("SET_PAGE_DETALHAMENTO", 1);
        dispatch("getDetalhamento");
    },
    clearInputsDetalhamento({ commit, dispatch }) {
        commit("CLEAR_INPUT_FILTERS_DETALHAMENTO");
        commit("SET_PAGE_DETALHAMENTO", 1);
        dispatch("getDetalhamento");
    },
    async downloadPdF({ commit }, { timestamp, filepath }) {
        try {
            commit("saveStatus", "loading", { root: true });
            const response = await pagamento_service.get_doc({ timestamp, filepath });
            exportFilePDF(response.data, filepath);
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Falha ao baixar documento", { root: true });
        }
        commit("saveStatus", "success", { root: true });
    },
    async getMonitoramento({ commit, getters }) {
        try {
            commit("SET_LOADING", true);
            var filtro = getters.monitoramento_filter_to_api;

            var response = await pagamento_service.monitoramento(filtro);
            if (!response.data.items || !response.data.total_items) throw "erro";

            var inserted_selection = response.data.items.map((item) => ({
                selected: false,
                ...item,
            }));
            commit("SET_MONITORAMENTO_LINES", inserted_selection);
            commit("SET_TOTAL_MONITORAMENTO_LINES", response.data.total_items);
            commit("SET_SELECT_MULTIPLE", false);
        } catch {
            commit("SET_MONITORAMENTO_LINES", []);
            commit("SET_TOTAL_MONITORAMENTO_LINES", 0);
        }
        commit("SET_LOADING", false);
    },
    async getDetalhamento({ commit, getters }) {
        try {
            commit("SET_LOADING", true);
            var filter = getters.filter_to_api;

            var response = await pagamento_service.analytics(filter);
            if (!response.data.items || !response.data.total_items) throw "erro";

            commit("SET_DETALHAMENTO_LINES", response.data.items);
            commit("SET_TOTAL_DETALHAMENTO", response.data.total_items);
        } catch {
            commit("SET_DETALHAMENTO_LINES", []);
            commit("SET_TOTAL_DETALHAMENTO", 0);
        }
        commit("SET_LOADING", false);
    },
    async getListOfFilters({ commit }) {
        var lista_de_filtros = await pagamento_service.list_of_filters();
        commit("SET_FILTERS_DETALHAMENTO", lista_de_filtros.data);
    },
    async getListOfColumns() {
        await pagamento_service.list_of_columns();
    },
    async dowloadLines({ getters, commit }) {
        try {
            const response = await pagamento_service.export_table(getters.filter_to_api);
            exportFileXLSX(
                response.data,
                `${GetTodayDateTimeString({ seconds: true })}${i18n.tc(
                    "pages.payments.detailing.filename_export"
                )}`
            );
            commit("CLOSE_MODAL_EXPORT_PAGAMENTO");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Falha ao baixar documento", { root: true });
        }
    },
    async deleteFile({ commit, dispatch }, item) {
        try {
            const result = await dispatch(
                "modal/ModalGeneric",
                {
                    title: i18n.tc("pages.payments.export.modal_delete_file.title"),
                    text: i18n.tc("pages.payments.export.modal_delete_file.text"),
                    icon: "error",
                },
                { root: true }
            );
            if (!result) return;
            commit("saveStatus", "loading", { root: true });
            await dispatch("deleteSingleFile", item);
            dispatch("getMonitoramento");
            commit("PUSH_SUCCESS_TO_ARRAY", "Item deleted sucessfully", { root: true });
        } catch {
            commit("PUSH_ERROR_TO_ARRAY", "Exclusion failed", { root: true });
        }
        commit("saveStatus", "success", { root: true });
    },
    deleteSingleFile(_, item) {
        return pagamento_service.delete_file(item);
    },
    async deleteMultipleFiles({ commit, dispatch, getters }) {
        var selected_files = getters.monitoramento.filter((item) => item.selected);
        if (selected_files.length == 0) return;

        const result = await dispatch(
            "modal/ModalGeneric",
            {
                title: i18n.tc("pages.payments.export.modal_delete_file.multiple.title"),
                text: i18n.tc("pages.payments.export.modal_delete_file.multiple.text"),
                icon: "error",
            },
            { root: true }
        );
        if (!result) return;
        commit("saveStatus", "loading", { root: true });
        var array_promises = selected_files.map((item) => dispatch("deleteSingleFile", item));

        try {
            await Promise.all(array_promises);
            commit("PUSH_SUCCESS_TO_ARRAY", "Items deleted sucessfully", { root: true });
        } catch {
            commit("PUSH_ERROR_TO_ARRAY", "Exclusion failed", { root: true });
        }
        dispatch("getMonitoramento");
        commit("saveStatus", "success", { root: true });
    },
    // Funções de upload de arquivos
    goToUploadPage(_, router) {
        router.push("/pagamento_upload");
    },
    async SendFiles({ commit, dispatch, getters }) {
        var files_to_upload = getters.files;
        commit("SET_LOADING", true);

        await pagamento_service.upload({ files: files_to_upload }, (event) => {
            commit("SET_PERCENTAGE_UPLOAD", Math.round((100 * event.loaded) / event.total));
        });
        commit("SET_LOADING", false);
        dispatch("FinishUploadOfFiles");
    },
    CancelFileSelection({ commit }) {
        commit("CLEAR_FILES");
    },
    // Tratamentos de paginas
    changePageDetalhamento({ commit, dispatch }, page) {
        commit("SET_PAGE_DETALHAMENTO", page);
        dispatch("getDetalhamento");
    },
    changePerPageDetalhamento({ commit, dispatch }, per_page) {
        commit("SET_PAGE_DETALHAMENTO", 1);
        commit("SET_PER_PAGE_DETALHAMENTO", per_page);
        dispatch("getDetalhamento");
    },
    changePage_monitoramento({ commit, dispatch }, page) {
        commit("SET_PAGE_MONITORAMENTO", page);
        dispatch("getMonitoramento");
    },
    changePerPage_monitoramento({ commit, dispatch }, per_page) {
        commit("SET_PAGE_MONITORAMENTO", 1);
        commit("SET_PER_PAGE_MONITORAMENTO", per_page);
        dispatch("getMonitoramento");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
