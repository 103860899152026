import i18n from "@/plugins/i18n";
import { replaceAll } from "../helpers/string.helper";
import { localStorageSet, convertAndStoreInLocalStorage } from "@/helpers/storage.helper";

const mutations = {
    save_denied_url(state, url) {
        state.denied_url = url;
    },
    SET_WINDOW_WIDTH(state, width) {
        state.window_width = width;
    },
    UP_SPLASH_SCREEN: (state) => (state.splash_screen = true),
    DOWN_SPLASH_SCREEN: (state) => (state.splash_screen = false),
    // Two factor
    SET_ID_TWO_FACTOR: (state, id) => (state.two_factor_params.id = id),
    SET_EMAIL_TWO_FACTOR: (state, email) => (state.two_factor_params.email = email),
    SET_CODE_TWO_FACTOR: (state, code) => (state.two_factor_params.code = code),
    SET_URI_TWO_FACTOR: (state, uri) => (state.two_factor_params.uri = uri),
    // Auth

    SET_WHATSAPP_BANNER: (state, value) => (state.show_optin_whatsapp = value),

    SET_TYPE_LOGGIN: (state, value) => (state.type_loggin = value),

    SET_STATUS_PROCESSING: (state, value) => (state.status_processing = value),

    SET_CUSTOM_FILTERS: (state, payload) => (state.custom_filters = payload),

    SET_LOADING_STATUS: (state, status) => (state.loading_login = status),
    SET_USERNAME: (state, payload) => (state.username = payload),
    SET_COMPANY: (state, payload) => (state.company = payload),
    SET_NAME: (state, payload) => (state.name = payload),
    SET_ROLE: (state, payload) => (state.role = payload),
    SET_LOCATION: (state, payload) => (state.location = payload),
    SET_CLIENT_EMAIL: (state, payload) => (state.client_email = payload),
    SET_FOTO_URL: (state, payload) => (state.foto_url = payload),
    SET_LISTA_EMPRESAS: (state, payload) => (state.lista_empresas = payload),
    SET_MODULES: (state, payload) => {
        state.modules = payload;
        convertAndStoreInLocalStorage("modules_pedmais", payload);
    },
    SET_PAGES_LIMITED: (state, pages) => (state.pages_limited = pages),
    SET_NEW_FEATURES: (state, payload) => (state.new_features = payload),
    SET_ADVANCED_ORDER: (state, payload) => (state.advanced_order = payload),
    SET_EDIT_ORDER_NUMBER: (state, payload) => (state.edit_order_number = payload),
    SET_OWNER_ID: (state, payload) => (state.owner_id = payload),
    SET_COMPANY_ID: (state, payload) => (state.company_id = payload),

    SET_GLOBAL_PARAMETERS_PEDMAIS: (state, payload) => (state.global_fields_parameters = payload),

    saveStatus(state, status) {
        state.status = status;
    },
    saveToken(state, token) {
        state.token = token;
    },
    saveLoggedAsAdmin(state, data) {
        state.logged_from_admin_account = data;
    },
    saveLoginStatus(state) {
        state.login_saved = "salvo";
    },

    // Crumbs
    popCrumbArray(state) {
        state.crumbArray.pop();
    },
    pushCrumbArray(state, crumb) {
        state.crumbArray.push(crumb);
    },
    resetCrumbArray(state) {
        state.crumbArray = [
            {
                text: "",
                to: "",
            },
        ];
    },

    // Sidebar
    setSidebarCollapse(state) {
        state.collapsed = !state.collapsed;
        localStorageSet("collpased_sidebar", state.collapsed);
    },
    setSideBarHidden(state) {
        state.hidden = !state.hidden;
        if (state.hidden) {
            state.collapsed = false;
        }
    },
    setCollapsableToFalse(state) {
        state.collapsed = false;
    },
    setHiddenToTrue(state) {
        state.hidden = true;
    },
    setHiddenToFalse(state) {
        state.hidden = false;
    },
    setSidebarMenuActive(state, key) {
        if (state.active === key) {
            state.active = undefined;
        } else {
            state.active = key;
        }
    },
    SET_RIGHT_SIDEBAR(state, value) {
        state.right_open = value;
    },
    SET_COMPANY_ID_BILLING: (state, value) => (state.company_id = value),
    SET_VALUE_MONTH: (state, value) => (state.month_report = value),
    SET_MONTH_OPTIONS: (state, options) => (state.month_report_options = options),
    SET_FAKE_DATA_REPORT: (state, value) => (state.fake_data_report = value),
    PUSH_SUCCESS_TO_ARRAY_WITH_PARAMETERS(state, { message, value }) {
        var text = i18n.t(`${message}`, { data: value });
        state.notifications.push({ title: i18n.t("Sucesso!"), type: "success", message: text });
    },
    PUSH_ERROR_TO_ARRAY_WITH_PARAMETERS(state, { message, value }) {
        var text = i18n.t(`${message}`, { data: value });
        state.notifications.push({ title: i18n.t("Erro!"), type: "danger", message: text });
    },
    PUSH_SUCCESS_TO_ARRAY(state, message) {
        var text_translated;
        if (typeof message == "string")
            text_translated = i18n.tc(`${replaceAll(message, ".", "")}`);
        else {
            text_translated = i18n.tc(message.text, message.count, {
                count: message.count,
            });
        }
        state.notifications.push({
            title: i18n.t("Sucesso!"),
            type: "success",
            message: text_translated ? text_translated : message,
        });
    },
    PUSH_WARNING_TO_ARRAY(state, message) {
        var text_translated = i18n.tc(`${replaceAll(message, ".", "")}`);
        state.notifications.push({
            title: i18n.t("Aviso!"),
            type: "warning",
            message: text_translated ? text_translated : message,
        });
    },
    PUSH_ERROR_TO_ARRAY(state, message) {
        var text_translated = i18n.tc(`${replaceAll(message, ".", "")}`);
        state.notifications.push({
            title: i18n.t("Erro!"),
            type: "danger",
            message: text_translated ? text_translated : message,
        });
    },
    PUSH_SUCCESS_TO_ARRAY_PURE_TEXT(state, message) {
        state.notifications.push({ title: i18n.t("Sucesso!"), type: "success", message });
    },
    PUSH_ERROR_TO_ARRAY_PURE_TEXT(state, message) {
        state.notifications.push({ title: i18n.t("Erro!"), type: "danger", message });
    },
    PUSH_WARNING_TO_ARRAY_PURE_TEXT(state, message) {
        state.notifications.push({ title: i18n.t("Aviso!"), type: "warning", message });
    },
    PUSH_INFO_TO_ARRAY_PURE_TEXT(state, message) {
        state.notifications.push({ title: i18n.t("Aviso!"), type: "info", message });
    },
    REMOVE_NOTIFICATION_FROM_ARRAY(state, index) {
        state.notifications.splice(index, 1);
    },
};

export default mutations;
