import TheForgotPassword from "@/components/auth/TheForgotPassword";
import TheLogin from "@/components/auth/TheLogin";
import TwoFactor from "@/components/auth/TwoFactor";
import NewPassword from "@/components/auth/NewPassword";
import NewLoginComponent from "@/components/auth/NewLoginComponent";

export const AuthenticationRoutes = [
    {
        path: "/reset",
        alias: "/auth/reset",
        name: "esqueceu_senha",
        component: TheForgotPassword,
    },
    {
        path: "/login",
        name: "Login",
        component: TheLogin,
    },
    {
        path: "/auth/login-2fa",
        name: "TwoFactor",
        component: TwoFactor,
    },
    {
        path: "/auth/reset/:token",
        name: "nova_senha",
        component: NewPassword,
    },
    {
        path: "/new_login/:token",
        name: "new_login",
        component: NewLoginComponent,
    },
];
