<template>
    <div class="login">
        <div class="d-flex flex-column login-header mb-4">
            <img src="../../assets/img/logo_pedmais_cut.png" class="img-logo-pedmais" />
            <div class="title-login-pedmais text-pedmais">
                {{ $t("A PLATAFORMA INTELIGENTE DE PEDIDOS") }}
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="mt-2 mb-3">
                    <h4 class="titulo-login text-black-pedmais">{{ $t("auth.title") }}</h4>
                </div>
                <b-form @submit.prevent="onLoginSubmit">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="email">
                                    <b-icon class="text-pedmais" icon="envelope"></b-icon>
                                </span>
                            </div>
                            <b-form-input
                                v-model="email"
                                class="input-pedmais input-login"
                                type="text"
                                :placeholder="$t('auth.email')"
                                :aria-label="$t('auth.email')"
                                aria-describedby="email"
                                required
                            >
                            </b-form-input>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="password">
                                    <b-icon class="text-pedmais" icon="lock"></b-icon>
                                </span>
                            </div>
                            <b-form-input
                                class="input-pedmais input-login"
                                v-model="password"
                                :type="!show_password ? 'password' : 'text'"
                                :placeholder="$t('auth.password')"
                                :aria-label="$t('auth.password')"
                                aria-describedby="password"
                                required
                            >
                            </b-form-input>
                            <div @click="show_password = !show_password" class="input-group-append">
                                <div class="show-password input-group-text">
                                    <b-icon
                                        class="text-pedmais"
                                        :icon="!show_password ? 'eye-slash' : 'eye'"
                                    ></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="error_show" class="error-message-the-login">
                        {{ $t(error_message) }}
                    </div>
                    <div class="row justify-content-between no-gutters form-group mb-3">
                        <b-form-checkbox v-model="lembrar_me" switch>
                            <small>{{ $t("auth.remember_me") }}</small>
                        </b-form-checkbox>
                        <router-link
                            class="card-body-link"
                            :to="{ name: 'esqueceu_senha', params: { email: 'email' } }"
                        >
                            <small class="text-pedmais">{{ $t("auth.forgot_password") }} </small>
                        </router-link>
                    </div>

                    <div v-if="!loading_login" class="form-group mb-3">
                        <b-button block :disabled="loading_login" variant="secondary" type="submit">
                            <b-icon v-if="!loading_login" icon="box-arrow-in-right"></b-icon>
                            <b-icon v-else icon="three-dots" animation="cylon"></b-icon>
                            {{ $t("buttons.enter") }}
                        </b-button>
                    </div>
                    <div
                        v-else
                        class="d-flex justify-content-center mb-4"
                        style="min-height: 37.19px"
                    >
                        <b-spinner></b-spinner>
                    </div>
                </b-form>
                <hr />
                <div class="row align-items-center justify-content-center no-gutters">
                    <img src="../../assets/img/logo_weduu.png" width="100px" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "TheLogin",
    data() {
        return {
            email: "",
            password: "",
            lembrar_me: false,
            show_password: false,

            error_show: false,
            error_message: "",
        };
    },
    computed: {
        ...mapGetters(["appName", "status", "loginLembrarMe", "denied_url", "loading_login"]),
    },
    mounted() {
        this.UpdateLembrarMeData();
        this.checkAccess();
        this.DOWN_SPLASH_SCREEN();
    },
    methods: {
        ...mapActions([
            "login",
            "info",
            "removeLoginLembrarMe",
            "saveLoginLembrarMe",
            "checkAccess",
        ]),
        ...mapMutations(["DOWN_SPLASH_SCREEN"]),
        onLoginSubmit() {
            this.error_show = false;
            let data = {
                email: this.email,
                password: this.password,
                redirect_url: this.$route.query.redirect || "/",
            };

            this.LembrarMe();
            this.login(data).catch((err_message) => {
                this.error_message = err_message;
                this.error_show = true;
            });
        },
        LembrarMe() {
            if (this.lembrar_me) {
                var obj = {
                    email: this.email,
                    password: this.password,
                };
                this.saveLoginLembrarMe(obj);
            } else {
                this.removeLoginLembrarMe();
            }
        },
        UpdateLembrarMeData() {
            var aux = this.loginLembrarMe;
            if (!aux) {
                this.lembrar_me = false;
            } else {
                this.lembrar_me = true;
                this.email = aux.email;
                this.password = aux.password;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.title-login-pedmais {
    font-size: 12px;
    font-weight: bolder;
}

.show-password {
    cursor: pointer;
}

.img-logo-pedmais {
    $size-image: 15vw;
    max-width: $size-image;
    max-height: $size-image;
    min-height: 150px !important;
    min-width: 150px !important;
}

.error-message-the-login {
    background-color: rgb(255, 218, 218);
    border: 0.5px solid rgba(255, 0, 0, 0.274);
    border-radius: 5px;
    text-align: left;
    color: red;
    font-size: 14px;
    padding: 2px 10px;
    margin-bottom: 5px;
}

.login {
    background-color: white;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    justify-content: center;
    text-align: center;
    &-header {
        align-items: center;
        display: flex;
        flex-direction: row;
    }
}

.card {
    box-shadow: 1px 1px 3px 1px rgb(204, 201, 201);
    &-body {
        padding: 10px 15px;
        &-link {
            color: $black-color;
            text-decoration: none;
            transition: $transition;
            &:hover {
                color: $primary-color;
                cursor: pointer;
            }
        }
    }
}

.custom-switch {
    padding-right: 2.25rem;
}

.titulo-login {
    font-size: 14px;
}
</style>
