import api from '@/services/api'

export default {
    find(data) {
        var filteredParams = Object.keys(data).filter(item => data[item] != '');
        var query = filteredParams.map(item => `${item}=${data[item]}`);
        return api().get("/views/files_received?"+ query.join("&"));
    },
    changeOrderNumber(data) {
        const form = new FormData();
        form.append("old_number", data["old_number"]);
        form.append("new_number", data["new_number"]);

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ data: { status: "ok" } });
            }, 3000);
        });
        // return api().post(`/changeOrderNumber`, form);
    },
};