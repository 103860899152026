<template>
    <div class="wrapper-input-code">
        <b-input
            ref="input_1"
            class="input-validation-code"
            @keydown="methodKeyDown($event, 1)"
            autofocus
            v-model="c1"
        ></b-input>
        <b-input
            ref="input_2"
            class="input-validation-code"
            @keydown="methodKeyDown($event, 2)"
            @click.prevent
            v-model="c2"
        ></b-input>
        <b-input
            ref="input_3"
            class="input-validation-code"
            @keydown="methodKeyDown($event, 3)"
            @click.prevent
            v-model="c3"
        ></b-input>
        <b-input
            ref="input_4"
            class="input-validation-code"
            @keydown="methodKeyDown($event, 4)"
            @click.prevent
            v-model="c4"
        ></b-input>
        <b-input
            ref="input_5"
            class="input-validation-code"
            @keydown="methodKeyDown($event, 5)"
            @click.prevent
            v-model="c5"
        ></b-input>
        <b-input
            ref="input_6"
            class="input-validation-code"
            @keydown="methodKeyDown($event, 6)"
            @click.prevent
            v-model="c6"
        ></b-input>
    </div>
</template>

<script>
import Vue from "vue";
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

export default {
    name: "AppInputCodeForm",
    data() {
        return {
            char1: "",
            char2: "",
            char3: "",
            char4: "",
            char5: "",
            char6: "",
            numbers: "01234567890",
        };
    },
    computed: {
        c1: {
            get() {
                return this.char1;
            },
            set(value) {
                this.char1 = value;
                this.$emit("final_code", this.finalCode);
                if (value != "") this.$refs["input_2"].focus();
            },
        },

        c2: {
            get() {
                return this.char2;
            },
            set(value) {
                this.char2 = value;
                this.$emit("final_code", this.finalCode);

                if (value != "") this.$refs["input_3"].focus();
                if (value == "") this.$refs["input_1"].focus();
            },
        },
        c3: {
            get() {
                return this.char3;
            },
            set(value) {
                this.char3 = value;
                this.$emit("final_code", this.finalCode);

                if (value != "") this.$refs["input_4"].focus();
                if (value == "") this.$refs["input_2"].focus();
            },
        },
        c4: {
            get() {
                return this.char4;
            },
            set(value) {
                this.char4 = value;
                this.$emit("final_code", this.finalCode);

                if (value != "") this.$refs["input_5"].focus();
                if (value == "") this.$refs["input_3"].focus();
            },
        },
        c5: {
            get() {
                return this.char5;
            },
            set(value) {
                this.char5 = value;
                this.$emit("final_code", this.finalCode);

                if (value != "") this.$refs["input_6"].focus();
                if (value == "") this.$refs["input_4"].focus();
            },
        },
        c6: {
            get() {
                return this.char6;
            },
            set(value) {
                this.char6 = value;
                this.$emit("final_code", this.finalCode);

                if (value == "") this.$refs["input_5"].focus();
            },
        },
        finalCode() {
            let aux = this.char1 + this.char2 + this.char3 + this.char4 + this.char5 + this.char6;
            return aux;
        },
    },
    methods: {
        methodKeyDown(e, input) {
            if (e.key == "Enter") {
                if (input == 6) this.$emit("enter_pressed");
                return;
            }
            if (e.key == "Tab") {
                return;
            }
            if (e.key == "Backspace") {
                // if (input > 1) this.$refs[`input_${input - 1}`].focus();
                return;
            }
            if (/[^0-9.]/.test(e.key)) {
                e.preventDefault();
            }
            if (this[`char${input}`].length > 0) {
                e.preventDefault();
            }
        },
        change(num, input) {
            let aux = num.replaceAll(/[^0-9.]/g, "");
            this.char1 = aux;
        },
        inputChanged(event, input) {
            if (this.numbers.includes(event)) {
                if (event != "") {
                    if (input == 6) {
                        this.$emit("final_code", this.finalCode);
                    } else this.$refs[`input_${input + 1}`].focus();
                } else {
                    if (input != 1) this.$refs[`input_${input - 1}`].focus();
                }
            }
        },
        enterPressed() {
            if (this.finalCode.length == 6) {
                this.$emit("enter_pressed");
                this.char1 = "";
                this.char2 = "";
                this.char3 = "";
                this.char4 = "";
                this.char5 = "";
                this.char6 = "";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper-input-code {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 5px;
    margin: 15px 0px;
    min-height: 50px;
}
.input-validation-code {
    max-width: 40px;
    text-align: center;
    margin: 0px 3px;
    min-height: 38px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
