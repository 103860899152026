/* eslint-disable no-unused-vars */
import {
    getCurator,
    getCuratorByProduct,
    getPendingCurator,
    insertCurator,
    exportCurator,
} from "@/services/api/gestaoCuradoria.js";
import { exportFileCSV } from "@/helpers/files.helper";

import i18n from "@/plugins/i18n";

const initial_state = () => ({
    curator: [],
    pagination: {
        page: 1,
        perPage: 10,
        total_itens: 0,
    },
    status_curator: "all",
    curator_filter: "customer",
    customer_id: "",
    search_input: "",
    search_output: "",
    loading: false,
    loading_save_suggestion: false,
    loading_export: false,
    new_suggestion: [],
});

const state = initial_state();

const getters = {
    curator: (state) => state.curator,
    per_page: (state) => state.pagination.perPage,
    current_page: (state) => state.pagination.page,
    total_items: (state) => state.pagination.total_itens,
    status_curator: (state) => state.status_curator,
    curator_filter: (state) => state.curator_filter,
    new_suggestion: (state) => state.new_suggestion,
    filter_curator: (state) => ({
        search: state.customer_id,
        search_input: state.search_input,
        search_output: state.search_output,
        offset: state.pagination.page - 1,
        per_page: state.pagination.perPage,
    }),
    customer_id: (state) => state.customer_id,
    search_input: (state) => state.search_input,
    search_output: (state) => state.search_output,
    loading(state) {
        return state.loading;
    },
    loading_export: (state) => state.loading_export,
    loading_save_suggestion: (state) => state.loading_save_suggestion,
};

const mutations = {
    SET_CURATOR(state, value) {
        state.curator = value;
    },
    SET_PAGE(state, page) {
        state.pagination.page = page;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },
    SET_TOTAL_ITENS(state, total_itens) {
        state.pagination.total_itens = total_itens;
    },
    SET_CUSTOMER_ID: (state, customer_id) => (state.customer_id = customer_id),
    SET_SEARCH_INPUT: (state, search_input) => (state.search_input = search_input),
    SET_SEARCH_OUTPUT: (state, search_output) => (state.search_output = search_output),
    SET_STATUS_CURATOR: (state, status_curator) => (state.status_curator = status_curator),
    SET_CURATOR_FILTER: (state, curator_filter) => (state.curator_filter = curator_filter),
    RESET_FILTER: (state) => {
        state.customer_id = "";
        state.status_curator = "all";
        state.search_input = "";
        state.search_output = "";
        state.pagination.page = 1;
    },
    SET_NEW_SUGGESTION: (state, new_suggestion) => {
        const existingIndex = state.new_suggestion.findIndex(
            (item) => item.ds_sku_input === new_suggestion.ds_sku_input
        );
        if (existingIndex === -1) {
            state.new_suggestion.push(new_suggestion);
        } else {
            state.new_suggestion.splice(existingIndex, 1, new_suggestion);
        }
    },
    RESET_NEW_SUGGESTION: (state) => (state.new_suggestion = []),
    SET_SUGGESTION_CHANGED: (state, { index, value }) => {
        state.curator[index].suggestionChanged = value;
    },
    SET_LOADING_SAVE_SUGGESTION: (state, status) => (state.loading_save_suggestion = status),
    SET_LOADING_EXPORT: (state, status) => (state.loading_export = status),
    ADD_BEST_TO_CURATOR_ITEM(state, { index, cd_sku, ds_sku }) {
        state.curator[index].best.unshift({ cd_sku, ds_sku });
        state.curator[index].selectedItem = [`${cd_sku} -- ${ds_sku}`];
    },
    REMOVE_SUGGESTION(state, index) {
        state.new_suggestion.splice(index, 1);
    },
};

const actions = {
    async clear({ commit, dispatch }) {
        commit("RESET_FILTER");
    },
    async clearCurator({ commit }) {
        commit("SET_CURATOR", []);
    },
    async search({ commit, dispatch }) {
        commit("SET_PAGE", 1);
        await dispatch("getCurator");
    },

    async getCurator({ commit, getters, dispatch }) {
        commit("saveStatus", "loading", { root: true });
        try {
            const filter = getters.filter_curator;
            const status = getters.status_curator;
            const curatorType = getters.curator_filter;
            let response = null;

            if (curatorType === "customer") {
                response = await getCurator(status, filter);
            } else {
                response = await getCuratorByProduct(status, filter);
            }

            dispatch("updateCuratorState", response);
        } catch (error) {
            dispatch("updateCuratorState", { items: [], total_items: 0 });
            console.error(error);
        }
        commit("saveStatus", "success", { root: true });
    },
    updateCuratorState({ commit }, response) {
        const { items } = response;
        const filteredItems = items.map((item) => {
            return {
                ...item,
                new_suggestion: "",
                suggestionChanged: false,
                selectedItem:
                    item.best && item.best.length > 0
                        ? [`${item.best[0].cd_sku} -- ${item.best[0].ds_sku}`]
                        : [],
            };
        });
        commit("SET_CURATOR", filteredItems);
        commit("SET_TOTAL_ITENS", response.total_items);
    },
    changingSuggestions({ commit }, suggestion) {
        commit("SET_NEW_SUGGESTION", suggestion);
    },
    newChangeSuggestions(
        { commit, getters },
        { ds_sku_input, customer_ids, cd_sku_output, item, index }
    ) {
        const curatorItem = getters.curator[index];

        const existingIndex = curatorItem.best.findIndex(
            (suggestion) => suggestion.ds_sku === item.ds_sku
        );

        if (existingIndex === -1) {
            commit("ADD_BEST_TO_CURATOR_ITEM", { index, cd_sku: item.cd_sku, ds_sku: item.ds_sku });
        }

        const suggestion = {
            cd_sku_output,
            ds_sku_input,
            bu: item.bu,
            customer_ids,
        };
        commit("SET_NEW_SUGGESTION", suggestion);
    },
    async saveSuggestions({ commit, dispatch, getters }) {
        try {
            const suggestions = getters.new_suggestion;
            let modal = {
                title: i18n.t("pages.managementCurator.modals.suggestion_altered_title"),
                icon: "question",
            };

            const confirm = await dispatch("modal/ModalGeneric", modal, { root: true });
            if (!confirm) return;

            commit("SET_LOADING_SAVE_SUGGESTION", true);
            await insertCurator({ items: suggestions });
            commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", i18n.t("common.curator_saved"), {
                root: true,
            });
            commit("RESET_NEW_SUGGESTION");
            await dispatch("getCurator");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", error.response.data.error || "", {
                root: true,
            });
        }
        commit("SET_LOADING_SAVE_SUGGESTION", false);
    },
    removeSuggestion({ commit, getters }, index) {
        commit("REMOVE_SUGGESTION", index);
    },
    setSuggestionChanged({ commit }, { index, value }) {
        commit("SET_SUGGESTION_CHANGED", { index, value });
    },
    async changePage({ commit, dispatch, getters }, page) {
        const hasNewSuggestions = getters.new_suggestion.length > 0;
        if (hasNewSuggestions) {
            await dispatch("saveSuggestions");
            commit("RESET_NEW_SUGGESTION");
        }
        commit("SET_PAGE", page);
        await dispatch("getCurator");
    },
    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PAGE", 1);
        commit("SET_PER_PAGE", per_page);
        await dispatch("getCurator");
    },
    async exportCurator({ commit, getters }) {
        commit("SET_LOADING_EXPORT", true);

        let status_curator = getters.status_curator;

        switch (status_curator) {
            case "pending":
                status_curator = "Pendente";
                break;
            case "checked":
                status_curator = "Checked";
                break;
            case "all":
                status_curator = "Todos";
                break;
            default:
                status_curator = "Pendente";
        }
        try {
            const filter = {
                ...getters.filter_curator,
                status_curator,
            };
            const response = await exportCurator(filter);
            exportFileCSV(response, "curator");
        } catch (error) {
            console.error(error);
        }
        commit("SET_LOADING_EXPORT", false);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
