<template>
    <div class="forgot">
        <div class="forgot-header mb-4">
            <img src="../../assets/img/logo_pedmais_login_s.png" width="100" />
        </div>
        <div class="card">
            <div class="card-body card-email-enviado">
                <template v-if="!email_enviado">
                    <div class="text-left mb-4 text-black-pedmais">
                        <h4>{{ $t("auth.password_recovery.title") }}</h4>
                        <small>
                            {{ $t("auth.password_recovery.text1") }}
                            <br />
                            {{ $t("auth.password_recovery.text2") }}
                        </small>
                    </div>
                    <b-form @submit="formSubmit">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <b-icon icon="envelope"></b-icon>
                                    </span>
                                </div>
                                <b-form-input
                                    ref="email"
                                    v-model="email_digitado"
                                    type="email"
                                    @keyup.enter="ResetarSenha()"
                                    :placeholder="$t('auth.email')"
                                    aria-label="E-mail"
                                    aria-describedby="email"
                                    required
                                >
                                </b-form-input>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <b-button
                                :disabled="!isFormValid()"
                                @click="ResetarSenha()"
                                variant="secondary"
                            >
                                <b-icon icon="unlock"></b-icon>
                                {{ $t("auth.password_recovery.button") }}
                            </b-button>
                        </div>
                    </b-form>
                </template>
                <template v-else>
                    <div class="text-left mb-4">
                        <h4>{{ $t("auth.password_recovery.email_sent") }}</h4>
                        <p class="texto-email-enviado">
                            {{ $t("auth.password_recovery.text3") }}
                        </p>
                        <p class="texto-email-enviado">
                            {{ $t("auth.password_recovery.text4") }}
                        </p>

                        <b-button @click="email_enviado = false">
                            {{ $t("auth.password_recovery.resend_email") }}
                        </b-button>
                    </div>
                </template>

                <hr />
                <div class="row align-items-center justify-content-between no-gutters">
                    <router-link class="card-body-link" to="/login">
                        <small>{{ $t("auth.password_recovery.back_to_login") }}</small>
                    </router-link>
                    <img src="../../assets/img/logo_weduu.png" width="100px" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import login from "@/services/api/login";

export default {
    name: "TheForgotPassword",
    props: {
        email: {
            type: String,
        },
    },
    data() {
        return {
            email_digitado: this.email ? this.email : "",
            email_enviado: false,
        };
    },
    computed: {
        ...mapGetters(["appName"]),
    },
    methods: {
        ...mapActions(["checkAcess"]),
        ...mapMutations(["DOWN_SPLASH_SCREEN"]),
        formSubmit(e) {
            e.preventDefault();
            this.ResetarSenha();
        },
        async ResetarSenha() {
            if (this.isFormValid()) {
                try {
                    await login.forgot_password(this.email_digitado);
                    this.email_enviado = true;
                } catch {
                    // toast.ErrorToast(aux.message);
                }
            }
        },
        isFormValid() {
            const c1 = this.$refs.email ? this.$refs.email.checkValidity() : false;
            const c2 = this.email_digitado && this.email_digitado != "";

            return c1 && c2;
        },
    },
    mounted() {
        this.checkAccess();
        this.DOWN_SPLASH_SCREEN();
    },
};
</script>

<style lang="scss" scoped>
.card-email-enviado {
    max-width: 400px;
}

.forgot {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    &-header {
        align-items: center;
        display: flex;
        flex-direction: row;
    }
}

.card {
    &-body {
        &-link {
            color: $black-color;
            text-decoration: none;
            transition: $transition;
            &:hover {
                color: $primary-color;
                cursor: pointer;
            }
        }
    }
}
</style>
