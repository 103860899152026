import i18n from "@/plugins/i18n";
import router from "@/router";
import pedidos_service from "@/services/api/pedidos";

import { exportFileCSV } from "@/helpers/files.helper";
import { orderTableColumns } from "@/configs/table.columns";
import { doubleDigitsAlways } from "@/helpers/string.helper";

const initial_state = () => ({
    loading: false,
    loading_export: false,
    atualizando_loading: false,
    status_atualizando_selecionado: null,

    draft_mode: false,

    suggestion_altered: false,

    select_multiple_checkbox: false,

    autocomplete: [],

    date: new Date(),

    // Identificação pedido
    id: "",
    filename: "",
    uuid: "",

    // Filtros do pedido
    filter_item: "",
    filter_cd_sku: "",
    filter_log: "",
    filter_cnpj: "",

    filter_log_options: [],
    filter_cnpj_options: [],

    nome_cliente: "",
    clientes: "",
    moeda: "",
    vl_total: "",
    qty_total: 0,
    status_curator: [],

    // Table
    table_fields: orderTableColumns,

    orders: [],
    selected_items: [],

    pagination: {
        page: 1,
        perPage: 10,
        total_items: 0,
    },
});
const state = initial_state();

const getters = {
    loading: (state) => state.loading,
    loading_export: (state) => state.loading_export,
    atualizando_loading: (state) => state.atualizando_loading,
    status_atualizando_selecionado: (state) => state.status_atualizando_selecionado,

    draft_mode: (state) => state.draft_mode,

    suggestion_altered: (state) => state.suggestion_altered,

    date: (state) => {
        var aux = state.date;
        return `${doubleDigitsAlways(aux.getDate())}/${doubleDigitsAlways(
            aux.getMonth() + 1
        )}/${doubleDigitsAlways(aux.getFullYear())} ${doubleDigitsAlways(
            aux.getHours()
        )}:${doubleDigitsAlways(aux.getMinutes())}:${doubleDigitsAlways(aux.getSeconds())}`;
    },

    select_multiple_checkbox: (state) => state.select_multiple_checkbox,

    file_id: (state) => state.id,
    filename: (state) => state.filename,
    uuid_list: (state) => state.uuid,

    filter_cnpj: (state) => state.filter_cnpj,
    filter_item: (state) => state.filter_item,
    filter_log: (state) => state.filter_log,
    filter_log_options: (state) =>
        [{ value: "", text: i18n.t("pages.order.filters_labels.log") }].concat(
            state.filter_log_options.map((item) => ({ value: item, text: item }))
        ),
    filter_cnpj_options: (state) => state.filter_cnpj_options,

    table_fields: (state) =>
        state.table_fields.map((item) => ({ ...item, label: i18n.t(item.label) })),

    nome_cliente: (state) => state.nome_cliente,
    clientes: (state) => state.clientes,
    moeda: (state) => state.moeda,
    vl_total: (state) => state.vl_total,
    qty_total: (state) => state.qty_total,
    status_curator: (state) => state.status_curator,

    autocomplete: (state) => state.autocomplete,
    orders: (state) => state.orders,
    selected_items: (state) => state.selected_items,

    total_items: (state) => state.pagination.total_items,
    per_page: (state) => state.pagination.perPage,
    current_page: (state) => state.pagination.page,

    filter_to_api: (state) => ({
        offset: state.pagination.page - 1,
        per_page: state.pagination.perPage,
        id: state.id,
        filename: state.filename,
        item: state.filter_item,
        cd_sku: state.filter_cd_sku,
        log: state.filter_log,
        cnpj: state.filter_cnpj,
    }),
};

const mutations = {
    SET_LOADING: (state, loading) => (state.loading = loading),
    SET_LOADING_EXPORT: (state, loading) => (state.loading_export = loading),
    SET_ATUALIZANDO_LOADING: (state, loading) => (state.atualizando_loading = loading),

    UPDATE_DATE: (state) => (state.date = new Date()),

    SET_DRAFT_MODE: (state, value) => (state.draft_mode = value),

    SET_STATUS_ATUALIZANDO_SELECIONADO_OK: (state) =>
        (state.status_atualizando_selecionado = "success"),
    SET_STATUS_ATUALIZANDO_SELECIONADO_ERROR: (state) =>
        (state.status_atualizando_selecionado = "error"),
    SET_STATUS_ATUALIZANDO_SELECIONADO_NULL: (state) =>
        (state.status_atualizando_selecionado = null),

    SET_IDENTIFICATION_ORDER: (state, obj) => {
        state.id = obj.id;
        state.filename = obj.filename;
    },

    SET_SELECT_MULTIPLE_CHECKBOX: (state, value) => (state.select_multiple_checkbox = value),

    SET_FILTER_ITEM: (state, item) => (state.filter_item = item),
    SET_FILTER_CD_SKU: (state, cd_sku) => (state.filter_cd_sku = cd_sku),
    SET_FILTER_LOG: (state, log) => (state.filter_log = log),
    SET_FILTER_CNPJ: (state, cnpj) => (state.filter_cnpj = cnpj),

    SET_FILTER_LOG_OPTIONS: (state, log_options) => (state.filter_log_options = log_options),
    SET_CNPJ_OPTIONS: (state, cnpj_options) => (state.filter_cnpj_options = cnpj_options),

    SET_NOME_CLIENTE: (state, nome) => (state.nome_cliente = nome),
    SET_CLIENTES: (state, clientes) => (state.clientes = clientes),
    SET_MOEDA: (state, moeda) => (state.moeda = moeda),
    SET_VL_TOTAL: (state, vl) => (state.vl_total = vl),
    SET_QTY_TOTAL: (state, total) => (state.qty_total = total),
    SET_STATUS_CURATOR: (state, array_status) => (state.status_curator = array_status),

    SET_SUGESTOES_NOME_IN_ITEM: (state, { index, dado }) => {
        var aux = Object(state.orders);
        aux[index].sugestoes_nome = dado;
        state.orders = aux;
        state.suggestion_altered = true;
    },
    SET_NEW_SUGGESTION_IN_ITEM: (state, { index, dado }) => {
        var aux = Object(state.orders);
        aux[index].new_suggestion = dado;
        state.orders = aux;
        state.suggestion_altered = true;
    },

    SET_AUTOCOMPLETE: (state, payload) => (state.autocomplete = payload),
    SET_SELECTED_ITEMS: (state, items) => (state.selected_items = items),
    SET_ORDERS: (state, orders) => (state.orders = orders),
    SET_PAGE: (state, page) => (state.pagination.page = page),
    SET_PER_PAGE: (state, per_page) => (state.pagination.perPage = per_page),
    SET_TOTAL_ITEMS: (state, total_items) => (state.pagination.total_items = total_items),
    RESET_FILTER: (state) => {
        state.filter_item = "";
        state.filter_cd_sku = "";
        state.filter_log = "";
        state.filter_cnpj = "";
        state.pagination.page = 1;
    },
    RESET_STORE_DATA: (state) => {
        Object.assign(state, initial_state());
    },

    CLEAR_SUGGESTION_ALTERED: (state) => (state.suggestion_altered = false),

    // Sorting mutations
    SORT_ORDERS: (state, { sort_by, asc }) => {
        let direction = 1;
        if (!asc) direction = -1;
        let aux = state.orders;
        aux.sort((a, b) => {
            if (a[sort_by] > b[sort_by]) return 1 * direction;
            else if (a[sort_by] < b[sort_by]) return -1 * direction;
            else return 0;
        });
        state.orders = aux;
    },
};

const actions = {
    async search({ commit, dispatch }) {
        commit("saveStatus", "loading", { root: true });
        commit("SET_PAGE", 1);
        await dispatch("getOrders");
        commit("saveStatus", "sucess", { root: true });
    },
    async clearFilter({ commit, dispatch }) {
        commit("RESET_FILTER");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getOrders");
        commit("saveStatus", "sucess", { root: true });
    },
    async updateDate({ dispatch }) {
        dispatch("getOrders");
    },
    async confirmUpdateSelected({ dispatch, commit, getters }) {
        const selected = getters.selected_items;

        if (selected.length == 0) {
            commit("PUSH_WARNING_TO_ARRAY", "no item selected", { root: true });
            return;
        }

        if (
            await dispatch(
                "modal/ModalGeneric",
                {
                    title: i18n.t("modals.order.update_selected"),
                    text: "",
                    icon: "warning",
                },
                { root: true }
            )
        )
            dispatch("updateSelected");
    },
    async saveUnsavedCuratorships({ commit, dispatch, getters, rootGetters }) {
        if (getters.suggestion_altered || getters.selected_items.length > 0) {
            commit("CLEAR_SUGGESTION_ALTERED");
            if (rootGetters["configs/automatic_save_curator_on_change_page"]) return true;
            const result = await dispatch(
                "modal/ModalGeneric",
                {
                    title: i18n.t("pages.order.modals.suggestion_altered_title"),
                    text: i18n.t("pages.order.modals.suggestion_altered_text"),
                    icon: "error",
                },
                { root: true }
            );
            return result;
        }
        return false;
    },
    async updateSelected({ getters, commit, dispatch }) {
        commit("SET_STATUS_ATUALIZANDO_SELECIONADO_NULL");

        const { id, filename } = getters.filter_to_api;
        const selected = getters.selected_items;

        if (selected.length == 0) {
            commit("PUSH_WARNING_TO_ARRAY", "no item selected", { root: true });
            return;
        }
        try {
            const obj = {
                id,
                filename,
                to_update: selected.map(
                    ({
                        nome_cliente,
                        cnpj,
                        ds_sku_input,
                        cd_sku_input,
                        bu = "",
                        vendor_id,
                        new_suggestion: nova_sugestao,
                        sugestoes_nome,
                    }) => ({
                        nome_cliente,
                        cnpj,
                        ds_sku_input,
                        cd_sku_input,
                        bu,
                        vendor_id,
                        nova_sugestao,
                        sugestoes_nome,
                    })
                ),
            };

            commit("SET_ATUALIZANDO_LOADING", true);

            await pedidos_service.curadoria(obj);
            commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", i18n.t("common.curator_saved"), {
                root: true,
            });
            await dispatch("search");
            commit("SET_SELECT_MULTIPLE_CHECKBOX", false);
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", error.response.data.error || "", {
                root: true,
            });
        }
        commit("SET_ATUALIZANDO_LOADING", false);
        commit("CLEAR_SUGGESTION_ALTERED");
    },
    async backToUpload({ dispatch }) {
        const result = await dispatch("saveUnsavedCuratorships");
        if (result) await dispatch("updateSelected");
        router.push({ name: "upload" });
    },
    async pushCheckout({ getters, dispatch }, router) {
        const status_pedido = getters.status_curator;
        if (status_pedido.includes("Pendente")) {
            const result = await dispatch(
                "modal/ModalGeneric",
                {
                    title: "Oops!",
                    text: i18n.t("modals.order.there_are_pending"),
                    icon: "warning",
                    okText: i18n.t("modals.order.yes_goto_checkout"),
                    cancelText: i18n.t("modals.order.no_stay_curator"),
                },
                { root: true }
            );
            if (!result) return;
        }

        const result = await dispatch("saveUnsavedCuratorships");
        if (result) await dispatch("updateSelected");

        router.push({
            name: "checkout",
            query: {
                file_name: getters.filename,
                file_id: getters.file_id,
                // uuid_list: getters.uuid_list
            },
        });
    },
    async getOrders({ getters, commit, dispatch }) {
        commit("SET_LOADING", true);
        try {
            commit("UPDATE_DATE");
            var filter = getters.filter_to_api;
            var response = await pedidos_service.find(filter);
            await dispatch("setResponse", response.data);
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", error.response.data.error, { root: true });
        }
        commit("SET_LOADING", false);
    },

    async exportOrders({ getters, commit }) {
        commit("SET_LOADING_EXPORT", true);
        let filter = {...getters.filter_to_api};
        filter["exportar"] = "";
        try {
            const response = await pedidos_service.export_find(filter);
            exportFileCSV(response.data, "pedidos");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY", "Export failed", { root: true });
        }
        commit("SET_LOADING_EXPORT", false);
    },
    async deleteGroupedItems({ getters, dispatch, commit }, params) {
        const { id, filename } = getters.filter_to_api;

        var modal = {
            title: i18n.t("modals.order.delete_grouped_items"),
            text: i18n.t("modals.order.delete_grouped_items_text"),
            icon: "error",
        };

        const confirm = await dispatch("modal/ModalGeneric", modal, { root: true });
        if (!confirm) return;

        var obj = {
            filename: filename,
            id: id,
            uuid_list: params.uuid_list,
            ds_sku_input: params.ds_sku_input,
            vl_p_unitario: params.vl_p_unitario,
            cd_ean_input: params.cd_ean_input,
        };

        try {
            commit("SET_LOADING", true);

            var result = await pedidos_service.delete_grouped_items(obj);
            if (result.data.status == "ok") {
                commit("PUSH_SUCCESS_TO_ARRAY", "Item deleted sucessfully", { root: true });
                dispatch("search");
            } else {
                throw "error";
            }
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY", "Exclusion failed", { root: true });
            commit("SET_LOADING", false);
        }
    },
    async deleteSelectedItems({ getters, dispatch, commit }) {
        const selected = getters.selected_items;

        if (selected.length == 0) {
            commit("PUSH_WARNING_TO_ARRAY", "no item selected", { root: true });
            return;
        }

        const result = await dispatch(
            "modal/ModalGeneric",
            {
                title: i18n.t("Deletar Selecionados"),
                text: i18n.t("Essa ação irá deletar os itens selecionados, deseja continuar?"),
                icon: "error",
            },
            { root: true }
        );

        if (result) {
            commit("SET_LOADING", true);

            const vetor_exclusion = selected.map((item) =>
                dispatch("deleteSingleGroupedItems", item)
            );

            Promise.all(vetor_exclusion)
                .then(() => {
                    commit("PUSH_SUCCESS_TO_ARRAY", "Items deleted sucessfully", { root: true });
                })
                .catch(() => {
                    commit("PUSH_ERROR_TO_ARRAY", "Exclusion failed", { root: true });
                })
                .finally(async () => {
                    await dispatch("search");
                    commit("saveStatus", "success", { root: true });
                    commit("SET_SELECT_MULTIPLE_CHECKBOX", false);
                });
        }
    },
    async deleteSingleGroupedItems({ getters }, params) {
        const { id, filename } = getters.filter_to_api;
        var obj = {
            filename: filename,
            id: id,
            uuid_list: params.uuid_list,
            ds_sku_input: params.ds_sku_input,
            vl_p_unitario: params.vl_p_unitario,
            cd_ean_input: params.cd_ean_input,
        };
        return pedidos_service.delete_grouped_items(obj);
    },
    async setResponse({ commit }, response) {
        try {
            commit(
                "SET_ORDERS",
                response.items.map((item) => ({
                    ...item,
                    new_suggestion: {},
                    sugestoes_nome: item.best[0],
                }))
            );
            commit("SET_DRAFT_MODE", response.draft ? true : false);
            commit("SET_QTY_TOTAL", response.qty_total);
            commit("SET_TOTAL_ITEMS", response.total_items);
            commit("SET_NOME_CLIENTE", response.cliente);
            commit("SET_CLIENTES", response.cliente);
            commit("SET_MOEDA", response.moeda);
            commit("SET_VL_TOTAL", response.vl_total);
            commit("SET_STATUS_CURATOR", response.status_curator);
            commit("SET_FILTER_LOG_OPTIONS", response.filter_log);
            commit("SET_CNPJ_OPTIONS", response.filter_cnpj);
        } catch {
            commit(
                "PUSH_ERROR_TO_ARRAY_PURE_TEXT",
                "Erro no tratamento da resposta dos parametros da página!",
                { root: true }
            );
        }
    },
    async changePage({ commit, dispatch }, page) {
        const result = await dispatch("saveUnsavedCuratorships");
        commit("saveStatus", "loading", { root: true });
        if (result) await dispatch("updateSelected");
        commit("SET_PAGE", page);
        await dispatch("getOrders");
        commit("saveStatus", "sucess", { root: true });
    },
    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PER_PAGE", per_page);
        commit("SET_PAGE", 1);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getOrders");
        commit("saveStatus", "sucess", { root: true });
    },
    async clearDataFromStore({ commit }) {
        commit("RESET_STORE_DATA");
    },
    async restoreGroupedItems({ getters, dispatch, commit }, params) {
        const { id, filename } = getters.filter_to_api;

        var modal = {
            title: i18n.t("modals.order.restore_one_item"),
            text: i18n.t("modals.order.restore_grouped_items_text"),
            icon: "error",
        };

        const confirm = await dispatch("modal/ModalGeneric", modal, { root: true });
        if (!confirm) return;

        var obj = {
            filename: filename,
            id: id,
            uuid_list: params.uuid_list,
            ds_sku_input: params.ds_sku_input,
            vl_p_unitario: params.vl_p_unitario,
            cd_ean_input: params.cd_ean_input,
        };

        try {
            commit("SET_LOADING", true);

            var result = await pedidos_service.retrieveGroupedItem(obj);
            if (result.data.status == "ok") {
                commit("PUSH_SUCCESS_TO_ARRAY", "Item restored sucessfully", { root: true });
                dispatch("search");
            } else {
                throw "error";
            }
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY", "Restoration failed", { root: true });
            commit("SET_LOADING", false);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
