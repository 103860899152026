import api from "@/services/api";

export default {
    list() {
        const form = new FormData();

        return api().get("/_autocomplete", form);
    },
    find(data) {
        const form = new FormData();
        form.append("cnpj", data.cnpj || "");
        form.append("order_id", data.order_id || "");
        form.append("vendor", data.vendor || "");
        return api().post(
            `/_autocomplete?offset=${data.offset}&search=${data.search}&vendor=${data.vendor}`,
            form
        );
    },
};
