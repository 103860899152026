import i18n from "@/plugins/i18n";
import axios from "axios";
import router from "@/router";

import { localStorageGet, localStorageSet, sessionStorageSet } from "@/helpers/storage.helper";

const changeTitlePage = function (language) {
    if (language == "pt") {
        document.title = "ped+ | O seu ecossistema inteligente";
    } else {
        document.title = "ped+ | Your smart ecosystem";
    }
};

const initial_state = () => ({
    layout:
        localStorage.getItem("pedmais_config_layout") != undefined
            ? localStorage.getItem("pedmais_config_layout")
            : 1,
    idioma: localStorage.getItem("pedmais_language")
        ? localStorage.getItem("pedmais_language")
        : "pt",
    stacked_tables: "sm", // Sm ou false
    theme: localStorageGet("pedmais_theme"),
    show_filter_on_side: true,
    save_last_filter: true,

    automatic_save_curator_on_change_page: false,
});

const state = initial_state();

const getters = {
    layout(state) {
        return state.layout;
    },
    language: (state) => state.idioma,
    theme: (state) => state.theme,
    user_preferences: (state) => ({ stacked_tables: state.stacked_tables }),
    page_permission: (state, getter, rootState, rootGetters) => rootGetters.page_permission,
    role: (state, getter, rootState, rootGetters) => rootGetters.role,

    automatic_save_curator_on_change_page: (state) => state.automatic_save_curator_on_change_page,
};

const mutations = {
    changeLayout(state, layout) {
        localStorage.setItem("pedmais_config_layout", layout);
        state.layout = layout;
    },
    SET_STACKED_TABLES(state, value) {
        state.stacked_tables = value ? "sm" : false;
    },
    SET_THEME: (state, theme) => {
        state.theme = theme;
        localStorageSet("pedmais_theme", theme);
        let htmlElement = document.documentElement;
        htmlElement.setAttribute("theme", theme);
    },
    CHANGE_LANGUAGE(state, language) {
        state.idioma = language;
        localStorage.setItem("pedmais_language", language);

        // Atualizando o i18n
        i18n.locale = language;

        changeTitlePage(language);
    },
    CHANGE_LANGUAGE_BY_USER(state, { language, username }) {
        state.idioma = language;
        localStorage.setItem("pedmais_language", language);
        localStorage.setItem("language_changed_by_user", language); // Alterado pelo usuário
        localStorageSet("username_language", username);
        document.location.reload();
        // Atualizando o i18n
        i18n.locale = language;
        changeTitlePage(language);
    },
    SET_AUTOMATIC_SAVE_CURATOR_ON_PAGE_CHANGE: (state, value) =>
        (state.automatic_save_curator_on_change_page = value),
};

const actions = {
    changeLayout({ commit }, value) {
        commit("changeLayout", value);
    },
    async initiateConfigs({ getters, commit, dispatch, rootGetters }, current_route) {
        i18n.locale = getters.language;
        const theme = "light";
        const type_of_loggin = localStorageGet("type_loggin");

        // Corrigir isso
        if (type_of_loggin == "as_admin") {
            // await dispatch("logout", true, { root: true });
            // router.replace("/login");
            // return;
        }

        commit("SET_TYPE_LOGGIN", type_of_loggin ? type_of_loggin : null, { root: true });

        let htmlElement = document.documentElement;
        htmlElement.setAttribute("theme", theme ? theme : "light");

        var route_actual = router.app.$route.name;
        var query_route = router.app.$route.query;

        if (route_actual == "new_login") {
            sessionStorage.clear();
            var token = query_route.new_token;

            // Corrigir isso
            var login_as_admin_info = {
                origin_username: query_route.origin_username,
                destiny_email: query_route.login_email,
            };

            commit("saveToken", token, { root: true });
            commit("saveLoggedAsAdmin", login_as_admin_info, { root: true });
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            sessionStorageSet("access_token", token);
            sessionStorageSet("logged_from_admin_account", login_as_admin_info);
            await dispatch("info", null, { root: true });
            current_route.push("/");
        } else if (["esqueceu_senha", "Login", "TwoFactor", "nova_senha"].includes(route_actual)) {
            return;
        } else {
            var is_authenticated = rootGetters.isAuthenticated;
            if (is_authenticated) {
                // Info
                try {
                    await dispatch("info", null, { root: true });
                    await dispatch("getGlobalPedmaisParams", null, { root: true });
                } catch {
                    // dispatch("callLogoutFunction");
                    dispatch("clearUserInfo", null, { root: true });
                }
            } else {
                dispatch("callLogoutFunction");
            }
        }
        commit("DOWN_SPLASH_SCREEN", null, { root: true });
    },
    callLogoutFunction({ dispatch }) {
        dispatch("logout", null, { root: true });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
