var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("input", {
      ref: "input-money",
      staticClass: "hidden-input",
      attrs: { type: "number", min: "0", max: "1000000000", pattern: "\\d*" },
      domProps: { value: _vm.formatMoney(_vm.money) },
      on: {
        input: function ($event) {
          return _vm.updateMoney($event)
        },
        keydown: function ($event) {
          return _vm.handleKeydown($event)
        },
      },
    }),
    _c(
      "div",
      {
        staticClass: "inputed-value",
        on: {
          click: function ($event) {
            return _vm.$refs["input-money"].focus()
          },
        },
      },
      [
        _c("span", [
          _c("span", { staticClass: "format-money-css" }, [
            _vm._v(_vm._s(_vm.moneyFormated)),
          ]),
        ]),
        _c(
          "span",
          {
            staticClass: "mr-1 p-1",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.clearInputInfo()
              },
            },
          },
          [_c("b-icon", { attrs: { icon: "x-lg" } })],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }