import i18n from "@/plugins/i18n";
import cockpit_service from "@/services/api/cockpit";
import { exportFileCSV } from "@/helpers/files.helper";

import { cockpitTableColumns } from "@/configs/table.columns";

const initial_state = () => ({
    loading: false,
    loading_export: false,

    table_fields: cockpitTableColumns,

    nome_empresa_filter: "",
    nome_cliente_filter: "",
    canal_filter: "",
    usuario_filter: "",
    dt_ref_filter: "",
    janela_filter: "",
    country_filter: "",

    empresas_options: [],
    clientes_options: [],
    canal_options: [],
    usuario_options: [],
    dt_ref_options: [],
    country_options: [],

    cockpit: [],

    pagination: {
        page: 1,
        perPage: 10,
        total_items: 0,
    },
});

const state = initial_state();

const getters = {
    loading: (state) => state.loading,
    loading_export: (state) => state.loading_export,
    // Filtro
    nome_empresa_filter: (state) => state.nome_empresa_filter,
    nome_cliente_filter: (state) => state.nome_cliente_filter,
    canal_filter: (state) => state.canal_filter,
    usuario_filter: (state) => state.usuario_filter,
    dt_ref_filter: (state) => state.dt_ref_filter,
    janela_filter: (state) => (state.janela_filter == "" ? "30" : state.janela_filter),
    // Filtro Options
    empresas_options: (state) =>
        [{ value: "", text: i18n.tc("pages.cockpit.filters_labels.company") }].concat(
            state.empresas_options
        ),
    clientes_options: (state) =>
        [{ value: "", text: i18n.tc("pages.cockpit.filters_labels.cliente") }].concat(
            state.clientes_options
        ),
    canal_options: (state) =>
        [{ value: "", text: i18n.tc("pages.cockpit.filters_labels.channel") }].concat(
            state.canal_options
        ),
    usuario_options: (state) =>
        [{ value: "", text: i18n.tc("pages.cockpit.filters_labels.user") }].concat(
            state.usuario_options
        ),
    dt_ref_options: (state) => state.dt_ref_options,
    // Table data
    table_fields: (state, getter, rootState, rootGetters) => {
        let aux = state.table_fields.filter((item) => {
            if (!item.roles) return true;
            if (item.roles.includes(rootGetters.role)) return true;
            return false;
        });

        if (state.country_options.length) {
            const countryColumn = {
                key: "country",
                label: "Pais",
            };
            aux.splice(2, 0, countryColumn);
        }

        return aux.map((item) => ({ ...item, label: i18n.tc(item.label) }));
    },
    cockpit: (state) => state.cockpit,
    total_items: (state) => state.pagination.total_items,
    current_page: (state) => state.pagination.page,
    per_page: (state) => state.pagination.perPage,
    // Filter to api
    filter_to_api: (state) => {
        var obj = {
            offset: state.pagination.page - 1,
            per_page: state.pagination.perPage,
            "s.update": state.dt_ref_filter,
            "e.name": state.nome_empresa_filter,
            "s.nome_cliente": state.nome_cliente_filter,
            "u.username": state.usuario_filter,
            "s.canal": state.canal_filter,
            "s.country": state.country_filter,
            janela: state.janela_filter,
        };
        return obj;
    },
    filter_options: (state) => ({
        empresas_options: state.empresas_options,
        clientes_options: state.clientes_options,
        canal_options: state.canal_options,
        usuario_options: state.usuario_options,
        dt_ref_options: state.dt_ref_options,
    }),
    showCountryFilter: (state) => !!state.country_options.length,
};

const mutations = {
    SET_LOADING: (state, status) => (state.loading = status),
    SET_LOADING_EXPORT: (state, status) => (state.loading_export = status),

    SET_NOME_EMPRESA: (state, id) => (state.nome_empresa_filter = id),
    SET_NOME_CLIENTE: (state, id) => (state.nome_cliente_filter = id),
    SET_CANAL: (state, id) => (state.canal_filter = id),
    SET_USUARIO: (state, id) => (state.usuario_filter = id),
    SET_DT_REF: (state, id) => (state.dt_ref_filter = id),
    SET_JANELA: (state, id) => (state.janela_filter = id),
    SET_COUNTRY_FILTER: (state, payload) => (state.country_filter = payload),

    SET_FILTER_OPTIONS: (state, { field, value }) => (state[field] = value),

    SET_COCKPIT: (state, cockpit) => (state.cockpit = cockpit),
    SET_PAGE(state, page) {
        state.pagination.page = page;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },
    SET_TOTAL_ITENS(state, total_items) {
        state.pagination.total_items = total_items;
    },
    RESET(state) {
        state.nome_empresa_filter = "";
        state.nome_cliente_filter = "";
        state.canal_filter = "";
        state.usuario_filter = "";
        state.dt_ref_filter = "";
        state.janela_filter = "";
        state.country_filter = "";
        state.pagination.page = 1;
    },
    RESET_DATA: (state) => {
        Object.assign(state, initial_state());
    },
};

const actions = {
    async getCockpit({ commit, getters, dispatch }, changing_page = false) {
        commit("SET_LOADING", true);
        try {
            var filter = {};
            if (changing_page) {
                filter = getters.filter_to_api;
                filter["view"] = "";
            } else {
                commit("SET_PAGE", 1);
                filter = getters.filter_to_api;
            }
            var res = await cockpit_service.find(filter);
            var response = res.data;

            commit("SET_COCKPIT", response["items"] || []);

            if (changing_page) return;

            commit("SET_TOTAL_ITENS", response["total_items"] || 0);

            commit("SET_FILTER_OPTIONS", {
                field: "dt_ref_options",
                value: response["list_dt_ref"] || [],
            });
            commit("SET_FILTER_OPTIONS", {
                field: "empresas_options",
                value: response["list_nome_empresa"] || [],
            });
            commit("SET_FILTER_OPTIONS", {
                field: "clientes_options",
                value: response["list_nome_cliente"] || [],
            });
            commit("SET_FILTER_OPTIONS", {
                field: "canal_options",
                value: response["list_canal"] || [],
            });
            commit("SET_FILTER_OPTIONS", {
                field: "usuario_options",
                value: response["list_nome_usuario"] || [],
            });
            commit("SET_FILTER_OPTIONS", {
                field: "country_options",
                value: response["country"] || [],
            });
        } catch (error) {
            throw error;
        }
        commit("SET_LOADING", false);
    },

    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getCockpit", true);
        commit("saveStatus", "success", { root: true });
    },

    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PER_PAGE", per_page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getCockpit", true);
        commit("saveStatus", "success", { root: true });
    },

    async cleanCacheData({ commit }) {
        commit("RESET_DATA");
    },

    async search({ commit, dispatch }) {
        commit("saveStatus", "loading", { root: true });
        await dispatch("getCockpit");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    async clearFilter({ commit, dispatch }) {
        commit("RESET");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getCockpit");
        commit("saveStatus", "success", { root: true });
    },

    async Export({ commit, getters }) {
        commit("SET_LOADING_EXPORT", true);
        try {
            var filter = getters.filter_to_api;
            var file = await cockpit_service.export(filter);
            exportFileCSV(file.data, "cockpit");
            // var export_file = await
        } catch (error) {
            throw error;
        }
        commit("SET_LOADING_EXPORT", false);
    },

    async GoToDetalhamento({ getters }, params) {
        const { row, router } = params;
        const item = row.item;
        const options = { year: "numeric", month: "2-digit", day: "numeric" };
        const date_formated = new Date(item.dt_ref).toLocaleDateString("pt-BR", options);
        var query = {
            nome_empresa_filter: item.nome_empresa,
            nome_cliente_filter: item.nome_cliente,
            usuario_filter: item.nome_usuario,
            canal_filter: item.canal,
            file_filter: item.file,
            nr_exec_filter: item.nr_exec,
            uuid_list: item.uuid_list,
            dt_ref_filter: date_formated,
            janela_filter: getters.janela_filter,
        };
        router.push({ name: "detalhamento", query: query });
    },

    closeFilterSidebar({ commit }) {
        commit("SET_RIGHT_SIDEBAR", false, { root: true });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
