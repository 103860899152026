import api from "@/services/api";

export default {
    registers(data) {
        var query = [];
        Object.keys(data).forEach((item) => {
            query.push(`${item}=${data[item]}`);
        });
        return api().get(`/help/manager?${query.join("&")}`);
    },
    register_by_id(id) {
        return api().get(`/help/manager/${id}`);
    },
    new_register(data) {
        return api().post(`/help/manager`, data, {});
    },
    update_register(id, data) {
        return api().put(`/help/manager/${id}`, data);
    },
    delete_register(id) {
        return api().delete(`/help/manager/${id}`);
    },
    most_five() {
        return api().get(`/help`);
    },
    tutorial_list() {
        return api().get(`/help/tutorial`);
    },
    tutorial_by_id(id) {
        return api().get(`/help/tutorial/${id}`);
    },
    faqs() {
        return api().get(`/help/faq`);
    },
    count_register(id) {
        return api().post(`/help/${id}/count`);
    },
};
