import i18n from "@/plugins/i18n";
import pedidos_service from "@/services/api/pedidos";
import router from "@/router";
import { sortArraySelectOptionByText } from "@/helpers/array.helper";

import { doubleDigitsAlways } from "@/helpers/string.helper";
import { curatorshipTableColumns } from "@/configs/table.columns";
import { filterTableColumnsByBackendResponse } from "@/helpers/array.helper";
import { delayedPromise } from "@/helpers/promises.helper";
import { exportFileCSV } from "@/helpers/files.helper";

const new_id = function () {
    let today = new Date().toISOString().slice(0, 10);
    let uuidv4 = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
    let id = "@DRAFT" + today + "-" + uuidv4;
    return id.toUpperCase();
};

const initial_state = () => ({
    loading: false,
    loading_export: false,
    upload_loading: false,
    process_loading: false,

    today_date: new Date(),

    params: {},

    file_filters_upload: [
        "customer_id",
        "num_pedido",
        "dt_entrega",
        "nome_cliente",
        "cupom",
        "obs",
    ],
    date: "",
    pesquisar: "",
    status: "",
    country: "",
    cod_pagto: "",
    channel: "",

    country_list: [],
    payments_list: [],

    // Campos adicionais do upload de arquivo
    upload_cnpj: "",
    upload_customer_id: "",
    upload_num_pedido: "",
    upload_dt_entrega: "",
    upload_nome_cliente: "",
    upload_cupom: "",
    divisao: "",
    operacao: "",
    payment_type: "",
    sigla_empresa_embarque: "",
    local_estoque: "",
    dt_embarque: "",
    min_dias: "",
    max_dias: "",
    cd_reserva: "",
    num_pedido_base: "",
    obs: "",

    // Percentage of processings
    uploaded_order_processing_progress: {
        id: null,
        total: 0,
        nok: 0,
    },
    uploaded_order_time: 0,

    file: null,
    process_file: {
        filename: null,
        id: null,
        variant: "",
        show_alert: false,
        error_alert: "",
        uuid: "",
    },

    list_orders: [],
    selected_items: [],

    pagination: {
        page: 1,
        perPage: 10,
        total_items: 0,
    },

    table_fields: curatorshipTableColumns,
});
const state = initial_state();

const getters = {
    loading: (state) => state.loading,

    today_date: (state) => {
        var aux = state.today_date;
        return `${doubleDigitsAlways(aux.getDate())}/${doubleDigitsAlways(
            aux.getMonth() + 1
        )}/${doubleDigitsAlways(aux.getFullYear())} ${doubleDigitsAlways(
            aux.getHours()
        )}:${doubleDigitsAlways(aux.getMinutes())}:${doubleDigitsAlways(aux.getSeconds())}`;
    },

    params: (state) => state.params,

    upload_loading: (state) => state.upload_loading,
    process_loading: (state) => state.process_loading,
    upload_finished_status: (state) => state.process_file,

    uploaded_order_processing_progress: (state) => state.uploaded_order_processing_progress,
    uploaded_order_time: (state) => state.uploaded_order_time,

    file_filters_upload: (state) => state.file_filters_upload,

    upload_cnpj: (state) => state.upload_cnpj,
    upload_customer_id: (state) => state.upload_customer_id,
    upload_num_pedido: (state) => state.upload_num_pedido,
    upload_dt_entrega: (state) => state.upload_dt_entrega,
    upload_nome_cliente: (state) => state.upload_nome_cliente,
    upload_cupom: (state) => state.upload_cupom,
    divisao: (state) => state.divisao,
    operacao: (state) => state.operacao,
    payment_type: (state) => state.payment_type,

    sigla_empresa_embarque: (state) => state.sigla_empresa_embarque,
    local_estoque: (state) => state.local_estoque,
    dt_embarque: (state) => state.dt_embarque,
    min_dias: (state) => state.min_dias,
    max_dias: (state) => state.max_dias,
    cd_reserva: (state) => state.cd_reserva,
    obs: (state) => state.obs,

    // Filtro
    date: (state) => state.date,
    pesquisar: (state) => state.pesquisar,
    status: (state) => state.status,
    channel: (state) => state.channel,
    country: (state) => state.country,

    country_list: (state) => state.country_list,

    file: (state) => state.file,

    table_fields: (state, rootState, getters, rootGetters) => {
        var aux = state.table_fields.map((item) => ({ ...item, label: i18n.t(item.label) }));
        if (rootGetters["company"] == "jjsv") {
            return aux.filter(
                (item) =>
                    !(
                        item.key == "total_bonificacao" ||
                        item.key == "porcentagem" ||
                        item.key == "total_compra"
                    )
            );
        }
        if (state.country_list.length) {
            const countryColumn = {
                key: "country",
                label: "Pais",
            };
            aux.splice(5, 0, countryColumn);
        }
        return aux;
    },
    list_orders: (state) => state.list_orders,
    selected_items: (state) => state.selected_items,

    total_items: (state) => state.pagination.total_items,
    current_page: (state) => state.pagination.page,
    per_page: (state) => state.pagination.perPage,

    filter_to_api: (state) => ({
        offset: state.pagination.page - 1,
        per_page: state.pagination.perPage,
        dt_ref: state.date,
        pesquisar: state.pesquisar,
        status: state.status,
        channel: state.channel,
        country: state.country,
    }),

    showCountryFilter: (state) => !!state.country_list.length,
    loading_export: (state) => state.loading_export,
};

const mutations = {
    SET_FIELD(state, { field, value }) {
        state[field] = value;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_LOADING_EXPORT(state, status) {
        state.loading_export = status;
    },
    REFRESH_TODAY_DATE(state) {
        state.today_date = new Date();
    },
    SET_UPLOAD_LOADING(state, status) {
        state.upload_loading = status;
    },
    SET_PROCESS_LOADING(state, status) {
        state.process_loading = status;
    },
    SET_FILE_UPLOAD_FILTERS(state, filters) {
        state.file_filters_upload = filters;
    },
    SET_DATE(state, payload) {
        state.date = payload;
    },
    SET_PESQUISAR(state, payload) {
        state.pesquisar = payload;
    },
    SET_STATUS(state, payload) {
        state.status = payload;
    },
    SET_CHANNEL(state, payload) {
        state.channel = payload;
    },
    SET_COUNTRY(state, payload) {
        state.country = payload;
    },
    SET_COD_PAGTO(state, payload) {
        state.cod_pagto = payload;
    },
    SET_PARAMS: (state, payload) => (state.params = payload),
    SET_TABLE_FIELDS: (state, payload) => (state.table_fields = payload),
    SET_COUNTRY_LIST: (state, payload) => (state.country_list = payload),
    SET_PAYMENT_LIST: (state, payload) =>
        (state.payments_list = sortArraySelectOptionByText(payload)),
    // CAMPOS ADICIONAIS DO UPLOAD DE ARQUIVO
    SET_FILE_UPLOAD_CNPJ(state, value) {
        state.upload_cnpj = value;
    },
    SET_FILE_UPLOAD_CUSTOMER_ID(state, value) {
        state.upload_customer_id = value;
    },
    SET_FILE_UPLOAD_NUM_PEDIDO(state, value) {
        state.upload_num_pedido = value;
    },
    SET_FILE_UPLOAD_DT_ENTREGA(state, value) {
        state.upload_dt_entrega = value;
    },
    SET_FILE_UPLOAD_NOME_CLIENTE(state, value) {
        state.upload_nome_cliente = value;
    },
    SET_FILE_UPLOAD_CUPOM(state, value) {
        state.upload_cupom = value;
    },
    SET_FILE_DIVISAO(state, value) {
        state.divisao = value;
    },
    SET_FILE_OPERACAO(state, value) {
        state.operacao = value;
    },
    SET_FILE_PAYMENT_TYPE(state, value) {
        state.payment_type = value;
    },
    SET_FILE_SIGLA_EMPRESA_EMBARQUE(state, value) {
        state.sigla_empresa_embarque = value;
    },
    SET_FILE_LOCAL_ESTOQUE(state, value) {
        state.local_estoque = value;
    },
    SET_FILE_DATA_EMBARQUE(state, value) {
        state.dt_embarque = value;
    },
    SET_FILE_MIN_DIAS(state, value) {
        state.min_dias = value;
    },
    SET_FILE_MAX_DIAS(state, value) {
        state.max_dias = value;
    },
    SET_OBS: (state, payload) => (state.obs = payload),

    RESET_FILE_UPLOAD_ADITIONAL_FIELDS(state) {
        state.upload_cnpj = "";
        state.upload_customer_id = "";
        state.upload_num_pedido = "";
        state.upload_dt_entrega = "";
        state.upload_nome_cliente = "";
        state.divisao = "";
        state.operacao = "";
        state.upload_cupom = "";
        state.sigla_empresa_embarque = "";
        state.local_estoque = "";
        state.dt_embarque = "";
        state.min_dias = "";
        state.max_dias = "";
        state.cd_reserva = "";
        state.obs = "";
    },
    SET_FILE(state, file) {
        state.file = file;
    },
    SET_LIST_ORDERS(state, list) {
        state.list_orders = list;
    },
    SET_SELECTED_ITEMS(state, itens) {
        state.selected_items = itens;
    },
    SET_PAGE(state, page) {
        state.pagination.page = page;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },
    SET_TOTAL_ITENS(state, itens) {
        state.pagination.total_items = itens;
    },
    RESET_FILTER(state) {
        state.date = "";
        state.pesquisar = "";
        state.status = "";
        state.channel = "";
        state.country = "";
        state.pagination.page = 1;
    },
    SORT_LIST_ORDERS(state, { sort_by, asc }) {
        let direction = 1;
        if (!asc) direction = -1;
        let aux = state.list_orders;
        aux.sort((a, b) => {
            if (sort_by == "dt_ref") {
                var date_a = new Date(a["dt_ref"]);
                var date_b = new Date(b["dt_ref"]);

                if (date_a > date_b) return 1 * direction;
                else if (date_a < date_b) return -1 * direction;
                else return 0;
            } else {
                if (a[sort_by] > b[sort_by]) return 1 * direction;
                else if (a[sort_by] < b[sort_by]) return -1 * direction;
                else return 0;
            }
        });
        state.list_orders = aux;
    },
    SET_UPLOADED_ORDER_PROCESSING_PROCESS: (state, payload) =>
        (state.uploaded_order_processing_progress = { ...payload }),
    SET_UPLOADED_ORDER_TIME: (state, time) => (state.uploaded_order_time = time),
    INCREMENT_UPLOADED_ORDER_TIME: (state) => (state.uploaded_order_time += 1),
    // Mutation of process file
    SET_FILENAME: (state, filename) => (state.process_file.filename = filename),
    SET_FILE_ID: (state, id) => (state.process_file.id = id),
    SET_VARIANT_FILE: (state, variant) => (state.process_file.variant = variant),
    SET_UUID_FILE: (state, uuid) => (state.process_file.uuid = uuid),
    SET_ERROR_MESSAGE_IN_ALLERT: (state, status) => (state.process_file.error_alert = status),
    SHOW_FILE_STATUS_ALERT: (state, show_hide) => {
        state.process_file.show_alert = show_hide;
        if (!show_hide) {
            state.process_file.error_alert = "";
        }
    },
    RESET_DATA: (state) => {
        Object.assign(state, initial_state());
    },
    CLEAR_LOADINGS_WARNINGS_DATA: (state) => {
        state.upload_loading = false;
        state.process_loading = false;
        state.process_file = {
            filename: null,
            id: null,
            variant: "",
            show_alert: false,
            error_alert: "",
        };
    },
};

const actions = {
    async updateDate({ dispatch }) {
        dispatch("getListOrders");
    },
    async search({ commit, dispatch }) {
        commit("SET_PAGE", 1);
        // commit('UPDATE_ID');
        dispatch("getListOrders");
    },
    async clearFilter({ commit, dispatch }) {
        commit("RESET_FILTER");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getListOrders");
        commit("saveStatus", "success", { root: true });
    },
    async clearDataFromStore({ commit }) {
        commit("RESET_DATA");
    },
    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getListOrders");
        commit("saveStatus", "success", { root: true });
    },
    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PER_PAGE", per_page);
        commit("SET_PAGE", 1);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getListOrders");
        commit("saveStatus", "success", { root: true });
    },
    async getListOrders({ getters, commit }) {
        commit("REFRESH_TODAY_DATE");
        commit("SET_LOADING", true);
        try {
            const filter = getters.filter_to_api;
            const response = await pedidos_service.list_orders(filter);
            const data_response = response.data;

            const curator_itens = data_response.items;
            const total_itens = data_response.total_items ? data_response.total_items : 0;
            const filters_to_show = data_response.filters_upload
                ? data_response.filters_upload
                : [];

            const tableColumnsFilter = data_response.available_columns || [];
            const filteredColumns = filterTableColumnsByBackendResponse(
                curatorshipTableColumns,
                tableColumnsFilter
            );

            commit("SET_LIST_ORDERS", curator_itens ? curator_itens : []);
            commit("SET_TOTAL_ITENS", total_itens);
            commit("SET_FILE_UPLOAD_FILTERS", filters_to_show);
            commit("SET_TABLE_FIELDS", filteredColumns);
        } catch {
            commit("SET_LIST_ORDERS", []);
            commit("SET_TOTAL_ITENS", 0);
            commit("SET_FILE_UPLOAD_FILTERS", []);
        }
        commit("SET_LOADING", false);
    },
    async exportOrders({ getters, commit }) {
        commit("SET_LOADING_EXPORT", true);
        let filter = {...getters.filter_to_api};
        filter["exportar"] = "";
        try {
            const response = await pedidos_service.export_orders(filter);
            exportFileCSV(response.data, "pedidos");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY", "Export failed", { root: true });
        }
        commit("SET_LOADING_EXPORT", false);
    },
    async getListOrdersParams({ commit }) {
        try {
            const response = await pedidos_service.list_orders_params();
            const res = response.data;

            if (!res) return;

            const paymentList = res["payment"]
                ? res["payment"].map(({ code: value, text }) => ({
                      value,
                      text,
                  }))
                : [];

            commit("SET_PARAMS", res["client_list"] || []);
            commit("SET_COUNTRY_LIST", res["country_list"] || []);
            commit("SET_PAYMENT_LIST", paymentList);
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao trazer a lista de parametros", {
                root: true,
            });
        }
    },
    // TODO remover essa função pois ela não será mais utilizada em breve
    async VisualizeDetails(_, item) {
        var options = {
            year: "numeric",
            month: "2-digit",
            day: "numeric",
        };
        let dt = new Date(item.dt_ref).toLocaleDateString("pt-BR", options);
        const query = {
            nome_cliente_filter: item.nome_cliente,
            file_filter: item.filepath,
            dt_ref_filter: dt,
            order_id: item.order_id,
            uuid_list: item.uuid_list,
            janela_Filter: "30",
            uploaded: item.uploaded,
        };
        router.push({ name: "detalhamento", query });
    },
    async Edit({ commit }, item) {
        const query = {
            file_name: item.filepath,
            file_id: item.order_id,
            uuid_list: item.uuid_list,
        };
        try {
            router.push({ name: "order", query });
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao acessar a order para editar", {
                root: true,
            });
        }
    },
    async Delete({ commit, dispatch }, item) {
        var confirm = await dispatch(
            "modal/ModalGeneric",
            {
                title: i18n.t("modals.upload.delete_item"),
                text: i18n.t("modals.upload.confirm_delete_item"),
                icon: "warning",
            },
            { root: true }
        );
        if (!confirm) return;
        try {
            var response = await dispatch("DeleteOrderPromise", item);

            if (response.data.status == "error") {
                throw "error";
            }
            commit("PUSH_SUCCESS_TO_ARRAY", "Item deleted sucessfully", { root: true });
            commit("saveStatus", "loading", { root: true });
            await dispatch("getListOrders");
            commit("saveStatus", "sucess", { root: true });
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY", "Exclusion failed", { root: true });
        }
    },
    async DeleteMultiple({ getters, commit, dispatch }) {
        const selected_items = getters.selected_items;
        if (selected_items.length == 0) {
            commit("PUSH_WARNING_TO_ARRAY", "no item selected", { root: true });
            return;
        }

        var confirm = await dispatch(
            "modal/ModalGeneric",
            {
                title: i18n.t("modals.upload.delete_selected_items"),
                text: i18n.t("modals.upload.delete_selected_items_text", {
                    count: selected_items.length,
                }),
                icon: "error",
            },
            { root: true }
        );
        if (!confirm) return;

        commit("saveStatus", "loading", { root: true });
        const array_promises = selected_items.map((item) => {
            return dispatch("DeleteOrderPromise", item);
        });

        try {
            await Promise.all(array_promises);

            commit("PUSH_SUCCESS_TO_ARRAY", "Item deleted sucessfully", { root: true });
            commit("saveStatus", "sucess", { root: true });
            dispatch("search");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY", "Exclusion failed", { root: true });
            commit("saveStatus", "error", { root: true });
        }
    },
    DeleteOrderPromise(_, item) {
        var obj = {
            filename: item.filepath,
            id: item.order_id,
            uuid_list: item.uuid_list,
        };
        return pedidos_service.delete_order(obj);
    },
    async uploadFile({ getters, commit, dispatch }, form) {
        commit("SET_UPLOAD_LOADING", true);
        commit("SET_UPLOADED_ORDER_TIME", 0);
        try {
            var id = new_id();

            var obj = {
                file: getters.file,
                id: id,
                ...form,
            };

            const res = await pedidos_service.upload(obj);
            const response = res.data;

            if (response[0].error || response.error) {
                const error_message = response[0].flag || response.flag;
                throw error_message;
            }

            var filename_response = "";
            try {
                filename_response = response[0].file;
            } catch {
                filename_response = response.file;
            }

            commit("RESET_FILE_UPLOAD_ADITIONAL_FIELDS");
            commit("SET_FILE_ID", id);
            commit("SET_FILENAME", filename_response);

            dispatch("processFile");
            commit("SET_UPLOAD_LOADING", false);
        } catch (error) {
            commit("SET_VARIANT_FILE", "danger");
            commit("SHOW_FILE_STATUS_ALERT", true);
            commit("SET_ERROR_MESSAGE_IN_ALLERT", error);
            commit("SET_UPLOAD_LOADING", false);
        }
    },
    async processFile({ getters, commit, dispatch }) {
        commit("SET_PROCESS_LOADING", true);
        let intervalRef;
        try {
            let counter = 0;
            let maxLimitCounter = 722;
            intervalRef = setInterval(() => {
                commit("INCREMENT_UPLOADED_ORDER_TIME");
            }, 1000);
            while (counter < maxLimitCounter) {
                let seconds = counter == 0 ? 0 : 5;

                const promiseResult = await delayedPromise(seconds, () =>
                    pedidos_service.status(getters.upload_finished_status.id)
                ).then((res) => res.data);

                commit("SET_UPLOADED_ORDER_PROCESSING_PROCESS", {
                    id: promiseResult.id,
                    total: promiseResult.total,
                    nok: promiseResult.nok,
                });

                counter++;
                if (promiseResult.nok == 0) break;

                if (counter == maxLimitCounter) {
                    throw "max_limit";
                }
            }

            commit("SET_UUID_FILE", "");
            commit("SET_VARIANT_FILE", "success");
            commit("SET_PROCESS_LOADING", false);
            dispatch("search");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", i18n.t(error), { root: true });
            commit("SET_PROCESS_LOADING", false);
            commit("SET_VARIANT_FILE", "danger");
        }
        clearInterval(intervalRef);
        commit("SHOW_FILE_STATUS_ALERT", true);
    },
    async DeleteTwoInOne({ commit, dispatch }, item) {
        var confirm = await dispatch(
            "modal/ModalGeneric",
            {
                title: i18n.t("modals.upload.delete_item"),
                text: i18n.t("modals.upload.confirm_delete_item"),
                icon: "warning",
            },
            { root: true }
        );
        if (!confirm) return;
        try {
            var response = await dispatch("DeleteOrderPromise2In1", item);

            if (response.data.status == "error") {
                throw "error";
            }
            commit("PUSH_SUCCESS_TO_ARRAY", "Item deleted sucessfully", { root: true });
            commit("saveStatus", "loading", { root: true });
            await dispatch("getListOrders");
            commit("saveStatus", "sucess", { root: true });
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY", "Exclusion failed", { root: true });
        }
    },
    DeleteOrderPromise2In1(_, item) {
        var obj = {
            filename: item.filepath,
            id: item.order_id,
            uuid_list: item.uuid_list,
        };
        return pedidos_service.delete_from_tables(obj);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
