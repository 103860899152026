<template>
    <div :class="{ loader: true, fadeout: !isLoading }">
        <LogoPedmais></LogoPedmais>
    </div>
</template>

<script>
import LogoPedmais from "@/components/animation/logo/LogoPedmais.vue";

export default {
    name: "LoadingScreen",
    props: ["isLoading"],
    components: {
        LogoPedmais,
    },
};
</script>

<style>
.loader {
    background-color: #6d6e71;
    bottom: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    font-size: 32px;
    left: 0;
    overflow: hidden;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 5000;
}

.fadeout {
    animation: fadeout 500ms forwards;
}

@keyframes fadeout {
    to {
        opacity: 0;
        visibility: hidden;
    }
}
</style>
