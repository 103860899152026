import api from '@/services/api'

export default {
    list (data) {
        const form = new FormData()

        form.append('limpar', '')
        form.append('offset', data.offset)
        form.append('per_page', data.limit ? data.limit : 10)

        return api().post('/sku', form)
    },

    find (data) {        
        const form = new FormData()

        form.append('buscar', '')
        for(let key in data) form.append(key, data[key])
        return api().post('/sku', form)
    },

    find_sku (data) {
        const form = new FormData(); 
        form.append('empresa_id', data.empresa_id)
        return api().post(`/sku/${data.cd_sku}`, form); 
    },

    new (body) {
        const form = new FormData();

        for(let key in body) {
            form.append(key, body[key]); 
        }

        return api().post('/add/sku', form)
    }, 

    edit(old_, new_) {
        const form = new FormData(); 

        form.append('editar', ''); 

        form.append('cd_sku', old_.cd_sku)
        form.append('empresa_id', old_.empresa_id)
        form.append('status', old_.status)
        

        for(let key in new_) {
            if(new_[key] != old_[key]) {
                form.append(`new_${key}`, new_[key])
            }
        }

        // return api().post('/sku', form); 
        return Promise.resolve({data: {status: 200, message: 'oi'}}); 
    }
}