import i18n from "@/plugins/i18n";
import files_service from "@/services/api/files";
// import router from "@/router";
import { filesTableColumns } from "@/configs/table.columns";
const initial_state = () => ({
    loading: false,

    empresa_id: "",
    active_processes: "",
    channel: "",
    dt_timestamp_id: "",
    filename: "",
    filename_id: "",
    id: "",
    input_params: "",
    last_update: "",
    lines: "",
    order_id: "",
    pages: "",
    request_id: "",
    size: "",
    status: "",
    tag: "",
    user_id: "",

    files: [],
    // Options
    company_name_options: [],
    user_options: [],
    country_options: [],
    dt_ref_options: [],
    owner_options: [],

    date: new Date(),
    pagination: {
        page: 1,
        perPage: 10,
        total_items: 0,
    },

    table_fields: filesTableColumns,
});
const state = initial_state();

const getters = {
    loading: (state) => state.loading,
    // Filtro
    empresa_id: (state) => state.empresa_id,
    active_processes: (state) => state.active_processes,
    channel: (state) => state.channel,
    dt_timestamp_id: (state) => state.dt_timestamp_id,
    filename: (state) => state.filename,
    filename_id: (state) => state.filename_id,
    id: (state) => state.id,
    input_params: (state) => state.input_params,
    last_update: (state) => state.last_update,
    order_id: (state) => state.order_id,
    files: (state) => state.files,
    table_fields: (state) =>
        state.table_fields.map((item) => ({ ...item, label: i18n.tc(item.label) })),
    pages: (state) => state.pages,
    request_id: (state) => state.request_id,
    size: (state) => state.size,
    status: (state) => state.status,
    tag: (state) => state.tag,
    user_id: (state) => state.user_id,
    // Paginação
    total_items: (state) => state.pagination.total_items,
    current_page: (state) => state.pagination.page,
    per_page: (state) => state.pagination.perPage,

    dt_ref_options: (state) => state.dt_ref_options,

    filter_options: (state) => ({
        company_name_options: state.company_name_options,
        country_options: state.country_options,
        dt_ref_options: state.dt_ref_options,
    }),

    // Table
    filter_to_api: (state) => ({
        page: state.pagination.page - 1,
        per_page: state.pagination.perPage,
        empresa_id: state.empresa_id,
        active_processes: state.active_processes,
        channel: state.channel,
        dt_timestamp_id: state.dt_timestamp_id,
        filename: state.filename,
        filename_id: state.filename_id,
        id: state.id,
        input_params: state.input_params,
        last_update: state.last_update,
        order_id: state.order_id,
        pages: state.pages,
        request_id: state.request_id,
        size: state.size,
        status: state.status,
        tag: state.tag,
        user_id: state.user_id,
    }),
};

const mutations = {
    SET_LOADING_TRUE(state) {
        state.loading = true;
    },
    SET_LOADING_FALSE(state) {
        state.loading = false;
    },
    SET_LOADING_SEARCH_FILES: (state, payload) => (state.loading_search_files = payload),
    SET_STATUS_FILE_EXISTS: (state, payload) => (state.files_already_exists = payload),
    SET_STATUS_FILE_SEARCHED: (state, payload) => (state.files_pesquisado = payload),
    SET_OPERATION_TYPE_NEW: (state) => (state.operation_type = "new"),
    SET_OPERATION_TYPE_EDIT: (state) => (state.operation_type = "edit"),
    // FILTROS
    SET_EMPRESA_ID: (state, id) => (state.empresa_id = id),
    SET_ACTIVE_PROCESSES: (state, processes) => (state.active_processe = processes),
    SET_CHANNEL: (state, channel) => (state.channel = channel),
    SET_DT_TIMESTAMP_ID: (state, timestamp) => (state.dt_timestamp_id = timestamp),
    SET_FILENAME_ID: (state, filename_id) => (state.filename_id = filename_id),
    SET_FILENAME: (state, filename) => (state.filename = filename),
    SET_ID: (state, id) => (state.id = id),
    SET_INPUT_PARAMS: (state, input_params) => (state.input_params = input_params),
    SET_LAST_UPDATE: (state, last_update) => (state.last_update = last_update),
    SET_ORDER_ID: (state, order_id) => (state.order_id = order_id),
    SET_PAGES: (state, pages) => (state.pages = pages),
    SET_REQUEST_ID: (state, request_id) => (state.request_id = request_id),
    SET_SIZE: (state, size) => (state.size = size),
    SET_STATUS: (state, status) => (state.status = status),
    SET_TAG: (state, tag) => (state.tag = tag),
    SET_USER_ID: (state, user_id) => (state.user_id = user_id),

    SET_FILES(state, files) {
        state.files = files;
    },
    SET_TOTAL_ITEMS(state, total_items) {
        state.pagination.total_items = total_items;
    },
    SET_PAGE(state, page) {
        state.pagination.page = page;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },
    RESET_FILTER(state) {
        state.empresa_id = "";
        state.active_processes = "";
        state.channel = "";
        state.dt_timestamp_id = "";
        state.filename = "";
        state.filename_id = "";
        state.id = "";
        state.input_params = "";
        state.last_update = "";
        state.lines = "";
        state.order_id = "";
        state.pages = "";
        state.dt_ref_options = [];
        state.request_id = "";
        state.size = "";
        state.status = "";
        state.tag = "";
        state.user_id = "";
        state.pagination.page = 1;
    },
    RESET_DATA: (state) => {
        Object.assign(state, initial_state());
    },
    CLEAR_FILE_EDIT_PAGE_DATA: (state) => {
        state.loading_search_files = false;
        state.files_already_exists = false;
        state.files_pesquisado = false;
    },
};

const actions = {
    async getFiles({ commit, getters }) {
        commit("SET_LOADING_TRUE");
        let files = [];
        let total_items = 0;
        try {
            const filter = getters.filter_to_api;
            const res = await files_service.find(filter);
            const response = res.data;
            files = response.items;
            total_items = response.total_items;
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", i18n.t("action failed"), {
                root: true,
            });
        }
        commit("SET_FILES", files);
        commit("SET_TOTAL_ITEMS", total_items);
        commit("SET_LOADING_FALSE");
    },

    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getFiles");
        commit("saveStatus", "success", { root: true });
    },

    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PAGE", 1);
        commit("SET_PER_PAGE", per_page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getFiles");
        commit("saveStatus", "success", { root: true });
    },

    async search({ commit, dispatch }) {
        commit("SET_PAGE", 1);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getFiles");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    async clearFilter({ commit, dispatch }) {
        commit("RESET_FILTER");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getFiles");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    closeFilterSidebar({ commit }) {
        commit("SET_RIGHT_SIDEBAR", false, { root: true });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
