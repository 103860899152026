export const doubleDigitsAlways = function (val) {
    return `${("0" + val).slice(-2)}`;
};

export const capitalizeFirstLetter = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const clearString = function (text) {
    return text.replace(/-|_/, " ").trim();
};

export const clearStringAndUppercaseFirstLetter = function (string) {
    const text = clearString(string);
    const array = text.split(" ").map((str) => capitalizeFirstLetter(str));
    return array.join(" ");
};

export const clearStringAndUpperCase = function (string) {
    const text = clearString(string);
    return uppercaseString(text);
};

export const capitalizeFirstLetterAndLowerCaseTheRest = (string) => {
    var aux = string.trim();
    return aux.charAt(0).toUpperCase() + aux.slice(1).toLowerCase();
};

export const uppercaseString = (string) => string.toUpperCase();

export const lowerCaseString = (string) => string.toLowerCase();

export const replaceAll = function (string, finder, replacer) {
    let string_divided = string.split(finder);
    return string_divided.join(replacer).trim();
};

export const formatTemplateString = (v) => {
    const value = JSON.parse(JSON.stringify(v));
    return value ? value.replace(/[,|]/, ";").split(";") : [];
};

export const getAllPossibleUpperlettersCombination = function (arr, prefix, suffix) {
    if (suffix == "") {
        arr.push(prefix);
        return;
    }
    getAllPossibleUpperlettersCombination(
        arr,
        prefix + suffix.slice(0, 1).toLowerCase(),
        suffix.slice(1)
    );
    getAllPossibleUpperlettersCombination(
        arr,
        prefix + suffix.slice(0, 1).toUpperCase(),
        suffix.slice(1)
    );
};

export const splitStringByChar = (str, char) => {
    try {
        let aux = str.split(char);
        aux = aux.filter((item) => item);
        return aux;
    } catch (error) {
        return [];
    }
};
