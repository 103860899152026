import i18n from "../plugins/i18n";

export default {
    components: {
        config: {
            vue2Dropzone: {
                defaultOptions: {
                    dictRemoveFile: "Remover arquivo",
                },
            },
            vue_editor: {
                toolbar: [
                    "removeFormat",
                    "undo",
                    "|",
                    "elements",
                    "fontName",
                    "fontSize",
                    "foreColor",
                    "backColor",
                    "divider",
                    "bold",
                    "italic",
                    "underline",
                    "strikeThrough",
                    "links",
                    "divider",
                    "subscript",
                    "superscript",
                    "divider",
                    "justifyLeft",
                    "justifyCenter",
                    "justifyRight",
                    "justifyFull",
                    "|",
                    "indent",
                    "outdent",
                    "insertOrderedList",
                    "insertUnorderedList",
                    "|",
                ],
                fontName: [
                    { val: "arial black" },
                    { val: "times new roman" },
                    { val: "Courier New" },
                ],
                fontSize: [
                    "12px",
                    "14px",
                    "16px",
                    "18px",
                    "0.8rem",
                    "1.0rem",
                    "1.2rem",
                    "1.5rem",
                    "2.0rem",
                ],
                uploadUrl: "",
                id: "editor_renderer",
                classList: ["editor"],
            },
            vue_sweetalert2: {
                heightAuto: false,
            },
        },
    },
    filters: {
        formatNumber: function (value) {
            if (!value) return 0;
            else {
                if (i18n.locale == "pt") {
                    return new Intl.NumberFormat("pt-BR").format(value);
                } else if (i18n.locale == "en") {
                    return new Intl.NumberFormat("en").format(value);
                }
            }
            return new Intl.NumberFormat("pt-BR").format(value);
        },
        formatMoney: function (value, currency) {
            if (!value) {
                return 0;
            } else {
                if (currency) {
                    if (currency == "BRL") {
                        return (
                            "R$ " +
                            new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
                                value
                            )
                        );
                    } else {
                        return new Intl.NumberFormat("en", { minimumFractionDigits: 2 }).format(
                            value
                        );
                    }
                } else if (i18n.locale == "pt") {
                    return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
                        value
                    );
                } else if (i18n.locale == "en") {
                    return new Intl.NumberFormat("en", { minimumFractionDigits: 2 }).format(value);
                }
            }
        },
        date: function (data) {
            return `${data.getDate()}/${data.getMonth() + 1}/${data.getFullYear()}`;
        },
        date_only: function (data) {
            try {
                if (!data) return ``;
                const date = new Date(data);
                return `${(`0` + date.getDate()).slice(-2)}/${(`0` + (date.getMonth() + 1)).slice(
                    -2
                )}/${date.getFullYear()}`;
            } catch {
                return data;
            }
        },
        formatDate: function (data) {
            var string = `${("0" + data.getDate()).slice(-2)}/${("0" + (data.getMonth() + 1)).slice(
                -2
            )}/${data.getFullYear()} ${("0" + data.getHours()).slice(-2)}:${(
                "0" + data.getMinutes()
            ).slice(-2)}:${("0" + data.getSeconds()).slice(-2)}`;
            return string;
        },
        transformAndFormatDate: function (data) {
            try {
                if (!data) return "";
                const date = new Date(data);
                return date.toLocaleString("pt-BR");
            } catch (error) {
                return data;
            }
        },
        transformAndFormatDateGMT: function (data) {
            try {
                if (!data) return "";
                const date = new Date(data);
                return date.toLocaleString("pt-BR", {
                    timeZone: "GMT",
                });
            } catch (error) {
                return data;
            }
        },
        formatDateTimeSpecialCharacter: function (data) {
            try {
                if (!data) return "";

                const [datePart, timePart] = data.split(" @ ");
                const [day, month, year] = datePart.split("-");
                const [hours, minutes, seconds] = timePart.split(":");

                const formattedDate = new Date(year, month - 1, day, hours, minutes, seconds);
                return formattedDate.toLocaleString();
            } catch (error) {
                return data;
            }
        },
        transformAndFormatOnlyDateGMT: function (data) {
            try {
                if (!data) return "";
                const date = new Date(data);
                return date.toLocaleString("pt-BR", {
                    timeZone: "GMT",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
            } catch (error) {
                return data;
            }
        },
        formatDigitalNumbers: function (data) {
            try {
                return data.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
            } catch (error) {
                return data;
            }
        },
        capitalizeText: function (text) {
            if (!text) return text;
            if (typeof text != "string") return text;
            const aux = text.toLocaleLowerCase();
            return aux.charAt(0).toUpperCase() + aux.slice(1);
        },
        formatOnlySeconds: function (seconds) {
            if (!seconds) return "0 segundos";
            return `${seconds.toFixed(2)} segundos`;
        },
    },
    directives: {
        "click-outside": {
            bind: function (el, binding, vnode) {
                el.clickOutsideEvent = function (event) {
                    // here I check that click was outside the el and his children
                    if (!(el == event.target || el.contains(event.target))) {
                        // and if it did, call method provided in attribute value
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
            },
            unbind: function (el) {
                document.body.removeEventListener("click", el.clickOutsideEvent);
            },
        },
    },
};
