var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-skeleton-wrapper",
    [
      _c(
        "b-card",
        [
          _c("b-skeleton", { attrs: { width: "85%" } }),
          _c("b-skeleton", { attrs: { width: "55%" } }),
          _c("b-skeleton", { attrs: { width: "70%" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }