import api from "@/services/api";
import { objectToURLQuery } from "@/helpers/http.helper";
// consultas

export const getCurator = (type, params) => {
    return api()
        .get(`/curatorship-management/${type}?${objectToURLQuery(params)}`)
        .then((res) => res.data);
};

export const getCuratorByProduct = (type, params) => {
    return api()
        .get(`/views/curatorship-data/${type}?${objectToURLQuery(params)}`)
        .then((res) => res.data);
};

export const insertCurator = (data) => {
    return api()
        .post(`/views/curatorship-data/insert`, data)
        .then((res) => res.data);
};

export const exportCurator = (params) => {
    return api()
        .post(`/curatorship-management/export?${objectToURLQuery(params)}`)
        .then((res) => res.data);
};
