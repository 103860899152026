<template>
    <div>
        <b-toast :visible="true" v-for="(notification, index) in notifications" :key="`notification_${index}`"
            :title="notification.title" :variant="notification.type" @hidden="SnackClosed(index)" solid>
            <!-- {{ notification.message | breakLineIfThereIsSemicolon }} -->
            <div v-html="returnHTMLOfMessage(notification.message)"></div>
        </b-toast>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
    name: "AppAlertsWrapper",
    computed: {
        ...mapGetters(["notifications"])
    },
    filters: {
        breakLineIfThereIsSemicolon(value) {
            return value.replaceAll(';', '\n');
        }
    },
    methods: {
        ...mapMutations(["REMOVE_NOTIFICATION_FROM_ARRAY"]),
        SnackClosed(index) {
            setTimeout(() => {
                this.REMOVE_NOTIFICATION_FROM_ARRAY(index);
            }, 300);
        },
        returnHTMLOfMessage(text) {
            const aux = text.split(';');
            if (aux.length <= 1) return text;
            const htmlString = `<div style:"font-size: 10px;">${aux.reduce((prev, cur) => (prev + `<div>${cur.trim()}</div>`), "")}</div>`;
            return htmlString;
        }
    }
};
</script>
