import { getListOfCompanyToSelectInput } from "@/helpers/companies.helper";
import { localStorageGet } from "@/helpers/storage.helper";
import router from "@/router/index";

const breakpoints = {
    xxl: 1400,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 0,
};

export const permission = {
    External: 1,
    User: 2,
    BackOffice: 3,
    AccountApprover: 4,
    Advisor: 5,
    Customer: 6,
    Administrator: 7,
};

export const permissions_on_page = {
    operacao: [7, 6],
    templates: [7, 6],
    onboarding: [7, 6],
    dashboard: [7, 6, 5, 4, 3, 2],
    report: [7, 6, 5, 4],
    admin: [7],
    container_logs: [7],
    email_logs: [7],
    indicators: [],
    curator: [7, 6, 5, 4, 3, 2],
    dictionary: [7, 6, 5],
    sku: [7, 6, 5, 4, 3],
    supply: [],
    alerts: [],
    logs: [7, 6, 5, 4, 3],
    users: [7, 6, 5],
    add_users: [7, 6],
    cockpit: [7, 6, 5, 4, 3, 2],
    detalhamento: [7, 6, 5, 4, 3, 2],
    bonificacao: [7, 6, 5, 4, 3, 2],
    profile: [7, 6, 5, 4, 3, 2, 1],
    upload: [5, 4, 3, 2],
    approval: [5, 4, 3, 2],
    order: [5, 4, 3, 2],
    checkout: [5, 4, 3, 2],
    tutorials: [7, 6, 5, 4, 3, 2, 1],
    list_pdf: [7, 6],
    check_pdf: [7, 6],
    list_tutorials: [7, 6],
    edicao_tutorial: [7, 6],
    new_edit_sku: [7, 6],
    pagamento_module: [7, 6],
    pagamento_cockpit: [7, 6],
    pagamento_detalhamento: [7, 6],
    pagamento_upload: [7, 6],
    help_list: [7],
    help_center: [7, 6, 5, 4, 3, 2, 1],
    help_center_faqs: [7, 6, 5, 4, 3, 2, 1],
    help_center_guides: [7, 6, 5, 4, 3, 2, 1],
    admin_tasks: [7, 6],
    products: [7],
    price_list: [7],
    managementCurator: [7, 6, 5, 4, 3, 2],
    companies: [7],
    integration_logs: [5],
    yml: [7,6],
    gerenciador_templates: [7, 6],
};

const getters = {
    appName(state) {
        return state.appName;
    },
    appVersion(state) {
        return state.appVersion;
    },
    denied_url(state) {
        return state.denied_url;
    },
    window_width: (state) => state.window_width,
    window_breakpoint(state) {
        for (let key in breakpoints) {
            if (state.window_width > breakpoints[key]) {
                return key;
            }
        }
    },

    // Auth
    loading_login: (state) => state.loading_login,
    splash_screen: (state) => {
        const notLoadingSplashScreenRoutes = [
            "esqueceu_senha",
            "Login",
            "TwoFactor",
            "nova_senha",
            "new_login",
        ];
        const routeName = router.app.$route.name;
        if (notLoadingSplashScreenRoutes.includes(routeName)) return false;
        return state.splash_screen;
    },
    status(state) {
        return state.status;
    },
    token(state) {
        return state.token;
    },
    username(state) {
        return state.username;
    },
    name(state) {
        return state.name;
    },
    company(state) {
        return state.company;
    },
    role(state) {
        return permission[state.role];
    },
    modules: (state) => state.modules,

    advanced_order(state) {
        return state.advanced_order;
    },
    edit_order_number(state) {
        return state.edit_order_number;
    },
    client_email(state) {
        return state.client_email;
    },
    location(state) {
        return state.location;
    },
    isAuthenticated(state) {
        return !!state.token;
    },
    foto_url(state) {
        return state.foto_url;
    },
    pages_limited: (state) => state.pages_limited,
    logged_from_admin_account(state) {
        return state.logged_from_admin_account;
    },
    type_loggin: (state) => state.type_loggin,
    loginLembrarMe() {
        var aux = localStorageGet("login_pedmais");
        if (!aux) return null;
        return aux;
    },
    new_features: (state) => state.new_features,
    page_permission() {
        return permissions_on_page;
    },

    custom_filters: (state) => state.custom_filters,

    showCountryFilter: (state) => {
        return state.custom_filters.includes("country");
    },

    all_roles() {
        return permission;
    },
    lista_empresas(state) {
        if (state.lista_empresas) return state.lista_empresas;
    },
    country_list(state) {
        if (state.country_list) return state.country_list;
    },
    lista_empresas_as_select(state) {
        if (state.lista_empresas) {
            var listOfCompanies = getListOfCompanyToSelectInput(state.lista_empresas);
            return listOfCompanies.sort((a, b) => (a.text > b.text ? 1 : -1));
        }
        return [];
    },
    all_roles_as_select(state, getters) {
        var filter = Object.keys(permission).filter((item) => permission[item] <= getters.role);
        return filter.map((item) => ({ value: permission[item], text: item }));
    },
    owner_id(state) {
        return state.owner_id;
    },
    company_id(state) {
        return state.company_id;
    },
    delete_order(state) {
        if (
            !state.global_fields_parameters ||
            !state.global_fields_parameters.settings_by_page ||
            !state.global_fields_parameters.settings_by_page.upload
        ) {
            return false;
        }

        var params = state.global_fields_parameters.settings_by_page.upload;

        if (params.delete_order === true) {
            return true;
        }

        return false;
    },

    // Crumbs
    crumbArray(state) {
        return state.crumbArray;
    },

    // Sidebar

    isSidebarCollapsed: (state) => state.collapsed,
    isSidebarHidden: (state) => state.hidden,
    whichSidebarMenuActive: (state) => state.active,
    right_open: (state) => state.right_open,

    // Notifications
    notifications: (state) => state.notifications,

    //  global parameters
    checkoutParameters: (state) =>
        state.global_fields_parameters && state.global_fields_parameters.checkout
            ? state.global_fields_parameters.checkout
            : null,
    uploadParameters: (state) =>
        state.global_fields_parameters && state.global_fields_parameters.upload
            ? state.global_fields_parameters.upload
            : null,
    clientListParameters: (state) =>
        state.global_fields_parameters && state.global_fields_parameters.client_list
            ? state.global_fields_parameters.client_list
            : null,
    countryListParameters: () => null,
    paymentListParameters: () => null,
};

export default getters;
