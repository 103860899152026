import Vue from "vue";
import Router from "vue-router";
import { AuthenticationRoutes } from "./routes/auth.routes";
import { PedmaisRoutes } from "./routes/pedmais.routes";
import { ErrorsRoutes } from "./routes/errors.routes";
import { SuggestionsRoutes } from "./routes/suggestion.routes";
import { HelpRoutes } from "./routes/help.routes";

Vue.use(Router);
export default new Router({
    routes: [
        ...AuthenticationRoutes,
        ...PedmaisRoutes,
        ...ErrorsRoutes,
        ...SuggestionsRoutes,
        ...HelpRoutes,
    ],
    mode: "history",
});
