export const companyListAsObject = (listOfCompanies) => {
    var aux = {};
    listOfCompanies.forEach((item) => (aux[Object.keys(item)[0]] = Object.values(item)[0]));
    return aux;
};

export const getCompanyById = (listOfCompanies, idOfCompany) => {
    if (!listOfCompanies) return "";
    var result = Object.keys(listOfCompanies).find((key) => listOfCompanies[key] == idOfCompany);
    if (!result) return "";
    return result;
};

export const getIdOfCompanyByName = (listOfCompanies, companyName) => listOfCompanies[companyName];

export const getListOfCompanyToSelectInput = (listOfCompanies) => {
    var aux = new Object(listOfCompanies);
    return Object.keys(aux).map((item) => ({ value: listOfCompanies[item], text: item }));
};
