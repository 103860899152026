import { doubleDigitsAlways as dbD } from "./string.helper";

export const transformDateInString = (date, time = false, seconds = false) => {
    if (!date) return date;
    const year = date.getFullYear();
    const month = dbD(date.getMonth() + 1);
    const d = dbD(date.getDate());
    if (!time) return `${year}-${month}-${d}`;
    const hours = dbD(date.getHours());
    const minutes = dbD(date.getMinutes());
    if (!seconds) return `${year}-${month}-${d}-${hours}-${minutes}`;
    const s = dbD(date.getSeconds());
    return `${year}-${month}-${d}-${hours}-${minutes}-${s}`;
};

export const GetTomorrowString = () => {
    var today = new Date();
    var tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return transformDateInString(tomorrow);
};

export const GetYesterday = () => {
    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    return transformDateInString(yesterday);
};

export const GetTodayDate = () => {
    var today = new Date();
    return transformDateInString(today);
};

export const GetTodayDateTimeString = ({ seconds = false }) => {
    var today = new Date();
    return transformDateInString(today, true, seconds);
};

export const GetRangeLastWeek = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const sevenDaysPrior = new Date(tomorrow);
    sevenDaysPrior.setDate(sevenDaysPrior.getDate() - 8);
    return [transformDateInString(sevenDaysPrior), transformDateInString(tomorrow)];
};

export const GetRangeLastMonth = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const lastMonth = new Date(tomorrow);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    lastMonth.setDate(lastMonth.getDate() - 1);
    return [transformDateInString(lastMonth), transformDateInString(tomorrow)];
};

export const GetYearAndMonthToday = () => {
    const today = new Date();
    return `${today.getFullYear()}${dbD(today.getMonth() + 1)}`;
};

export const GetNameOfMonth = (date) => {
    return date.toLocaleString("PT-BR", { month: "long" });
};

export const GetMonthAndYearOfDate = (date) => {
    return `${dbD(date.getMonth() + 1)}-${date.getFullYear()}`;
};

export const GetListOptionsLastXMonths = (numberOfMonths) => {
    const valuesToSubtractFromToday = [...Array(numberOfMonths).keys()];
    return valuesToSubtractFromToday.map((i) => {
        var x = new Date();
        x.setDate(1);
        x.setMonth(x.getMonth() - i);
        return {
            value: GetMonthAndYearOfDate(x),
            text: `${GetNameOfMonth(x)}/${x.getFullYear()}`,
        };
    });
};

export const GetListOfStringsOfLastThreeMonths = () =>
    GetListOptionsLastXMonths(3)
        .map((item) => item.text)
        .reverse();

export const GetListOptionsLastTreeMonths = () => GetListOptionsLastXMonths(3);

export const reverseStringDateWithSlash = (value) => {
    if (!value.includes("-")) return value;
    const array_date = value.split("-");
    array_date.reverse();
    return array_date.join("/");
};

const formatReceivedDateAndToday = (date) => {
    if (!date) return false;
    try {
        const receivedDate = new Date(date).setHours(0, 0, 0, 0);
        const today = new Date().setHours(0, 0, 0, 0);
        return [receivedDate, today];
    } catch {
        return false;
    }
};

export const isDateToday = (date) => {
    const dates = formatReceivedDateAndToday(date);
    if (!dates) return false;
    const [received, today] = dates;
    return received == today;
};

export const isDateEqualOrGreaterThanToday = (date) => {
    const dates = formatReceivedDateAndToday(date);
    if (!dates) return false;
    const [received, today] = dates;
    return received >= today;
};

export const isDateBeforeToday = (date) => {
    const dates = formatReceivedDateAndToday(date);
    if (!dates) return false;
    const [received, today] = dates;
    return received < today;
};
