<template>
    <div class="loading-screen">
        <div class="texto-loading-screen">
            <b-progress
                class="progress-bar-component"
                :value="percentage"
                variant="secondary"
                height="2rem"
                :max="100"
                animated
            ></b-progress>
            <div class="w-full text-center my-3 mx-4">
                <span v-if="!order_processing_progress_status.message">
                    {{ $t("please_wait_processing_checkout") }}
                </span>
                <span v-else>
                    {{ order_processing_progress_status.message }}
                </span>
            </div>
            <div v-if="order_processing_progress_status.total !== 0" class="percentage">
                <span class="w-100 d-flex justify-content-center">
                    {{ $t("modals.common.percentage_completed", { percentage: percentage }) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "LoadingScreen",
    computed: {
        ...mapGetters("checkout", ["order_processing_progress_status"]),
        percentage() {
            const total = this.order_processing_progress_status.total;
            const done =
                this.order_processing_progress_status.total -
                this.order_processing_progress_status.nok;

            const totalPercentage = parseInt((done / total) * 100);
            if (isNaN(totalPercentage)) return 0;

            return totalPercentage;
        },
    },
};
</script>

<style lang="scss" scoped>
.loading-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.progress-bar-component {
    min-width: 440px;
}

.percentage {
    display: flex;
    flex-direction: column;
}

.texto-loading-screen {
    border-radius: 15px;
    margin: 20px 0px;
    background-color: rgba($color: #000000, $alpha: 0.6);
    padding: 20px;
    color: white;
    font-size: 22px;
    text-align: center;

    p {
        margin-top: 40px;
        max-width: 400px;
    }
}
</style>
