var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.showIfItsntLoginScreen
        ? _c("SplashScreen", { attrs: { isLoading: _vm.splash_screen } })
        : _vm._e(),
      _vm.status == "loading" ? _c("loading-screen") : _vm._e(),
      _vm.status_processing ? _c("loading-screen-processing") : _vm._e(),
      _c("app-alerts-wrapper"),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }