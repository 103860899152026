var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading-screen" }, [
    _c(
      "div",
      { staticClass: "texto-loading-screen" },
      [
        _c("b-progress", {
          staticClass: "progress-bar-component",
          attrs: {
            value: _vm.percentage,
            variant: "secondary",
            height: "2rem",
            max: 100,
            animated: "",
          },
        }),
        _c("div", { staticClass: "w-full text-center my-3 mx-4" }, [
          !_vm.order_processing_progress_status.message
            ? _c("span", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("please_wait_processing_checkout")) +
                    "\n            "
                ),
              ])
            : _c("span", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.order_processing_progress_status.message) +
                    "\n            "
                ),
              ]),
        ]),
        _vm.order_processing_progress_status.total !== 0
          ? _c("div", { staticClass: "percentage" }, [
              _c(
                "span",
                { staticClass: "w-100 d-flex justify-content-center" },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t("modals.common.percentage_completed", {
                          percentage: _vm.percentage,
                        })
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }