var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wrapper" }, [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          staticClass: "logo-part",
          attrs: { src: require("./logo_animated/1.svg"), alt: "" },
        }),
        _c("img", {
          staticClass: "logo-part",
          attrs: { src: require("./logo_animated/2.svg"), alt: "" },
        }),
        _c("img", {
          staticClass: "logo-part",
          attrs: { src: require("./logo_animated/3.svg"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "d-flex justify-content-center" }, [
        _c("div", { staticClass: "waviy" }, [
          _c("span", { staticStyle: { "--i": "1" } }, [_vm._v("p")]),
          _c("span", { staticStyle: { "--i": "2" } }, [_vm._v("e")]),
          _c("span", { staticStyle: { "--i": "3" } }, [_vm._v("d")]),
          _c("span", { staticStyle: { "--i": "4" } }, [_vm._v("+")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }