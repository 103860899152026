import i18n from "@/plugins/i18n";
import sku_service from "@/services/api/sku";
import router from "@/router";
import { skuTableColumns } from "@/configs/table.columns";

const initial_state = () => ({
    loading: false,

    empresa_id: "",
    categoria: "",
    marca: "",
    cd_sku: "",
    ds_sku: "",
    cd_ean: "",
    embalagem: "",
    status: "",

    sku: [],

    pagination: {
        page: 1,
        perPage: 10,
        total_items: 0,
    },

    table_fields: skuTableColumns,

    // Variaveis da página de edição de sku
    operation_type: "new",
    loading_search_sku: false,
    cod_sku_already_exists: false,
    cod_pesquisado: false,
});
const state = initial_state();

const getters = {
    loading: (state) => state.loading,

    loading_search_sku: (state) => state.loading_search_sku,
    cod_sku_already_exists: (state) => state.cod_sku_already_exists,
    cod_pesquisado: (state) => state.cod_pesquisado,
    operation_type: (state) => state.operation_type,

    sku: (state) => state.sku,
    // Filtro
    empresa_id: (state) => state.empresa_id,
    categoria: (state) => state.categoria,
    marca: (state) => state.marca,
    cd_sku: (state) => state.cd_sku,
    ds_sku: (state) => state.ds_sku,
    cd_ean: (state) => state.cd_ean,
    embalagem: (state) => state.embalagem,
    status: (state) => state.status,
    // Paginação
    total_items: (state) => state.pagination.total_items,
    current_page: (state) => state.pagination.page,
    per_page: (state) => state.pagination.perPage,
    // Table
    table_fields: (state, getters, rootState, rootGetters) => {
        var aux = [];
        var edit_sku = [];
        if (rootGetters.role >= 6) {
            aux = [
                {
                    key: "nome_empresa",
                    label: "pages.sku.table_labels.company_name",
                    sortable: false,
                },
            ];
            // edit_sku = [{ key: 'actions', label: 'pages.sku.table_labels.edit', sortable: false }]
        }
        aux = aux.concat(state.table_fields).concat(edit_sku);
        return aux.map((item) => ({ ...item, label: i18n.tc(item.label) }));
    },
    filter_to_api: (state) => ({
        offset: state.pagination.page - 1,
        per_page: state.pagination.perPage,
        categoria: state.categoria,
        marca: state.marca,
        cd_sku: state.cd_sku,
        ds_sku: state.ds_sku,
        cd_ean: state.cd_ean,
        embalagem: state.embalagem,
        status: state.status,
        empresa_id: state.empresa_id,
    }),
};

const mutations = {
    SET_LOADING_TRUE(state) {
        state.loading = true;
    },
    SET_LOADING_FALSE(state) {
        state.loading = false;
    },
    SET_LOADING_SEARCH_SKU: (state, payload) => (state.loading_search_sku = payload),
    SET_STATUS_SKU_CODE_EXISTS: (state, payload) => (state.cod_sku_already_exists = payload),
    SET_STATUS_CODE_SEARCHED: (state, payload) => (state.cod_pesquisado = payload),
    SET_OPERATION_TYPE_NEW: (state) => (state.operation_type = "new"),
    SET_OPERATION_TYPE_EDIT: (state) => (state.operation_type = "edit"),

    // FILTROS
    SET_EMPRESA_ID: (state, id) => (state.empresa_id = id),
    SET_CATEGORIA: (state, categoria) => (state.categoria = categoria),
    SET_MARCA: (state, marca) => (state.marca = marca),
    SET_CD_SKU: (state, cod) => (state.cd_sku = cod),
    SET_DS_SKU: (state, ds) => (state.ds_sku = ds),
    SET_EAN: (state, ean) => (state.cd_ean = ean),
    SET_EMBALAGEM: (state, payload) => (state.embalagem = payload),
    SET_STATUS: (state, status) => (state.status = status),

    SET_SKUS(state, skus) {
        state.sku = skus;
    },
    SET_TOTAL_ITEMS(state, total_items) {
        state.pagination.total_items = total_items;
    },
    SET_PAGE(state, page) {
        state.pagination.page = page;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },
    RESET_FILTER(state) {
        state.empresa_id = "";
        state.categoria = "";
        state.marca = "";
        state.cd_sku = "";
        state.ds_sku = "";
        state.cd_ean = "";
        state.embalagem = "";
        state.status = "";
        state.pagination.page = 1;
    },
    RESET_DATA: (state) => {
        Object.assign(state, initial_state());
    },
    CLEAR_SKU_EDIT_PAGE_DATA: (state) => {
        state.loading_search_sku = false;
        state.cod_sku_already_exists = false;
        state.cod_pesquisado = false;
    },
};

const actions = {
    async getSKUS({ commit, getters }) {
        commit("SET_LOADING_TRUE");
        let logs_items = [];
        let total_items = 0;
        try {
            const filter = getters.filter_to_api;
            const res = await sku_service.find(filter);
            const response = res.data;
            logs_items = response.slice(0, response.length - 1);
            total_items = _.find(response, "total_items")["total_items"];
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", i18n.t("action failed"), {
                root: true,
            });
        }
        commit("SET_SKUS", logs_items);
        commit("SET_TOTAL_ITEMS", total_items);
        commit("SET_LOADING_FALSE");
    },

    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getSKUS");
        commit("saveStatus", "success", { root: true });
    },

    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PAGE", 1);
        commit("SET_PER_PAGE", per_page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getSKUS");
        commit("saveStatus", "success", { root: true });
    },

    async search({ commit, dispatch }) {
        commit("SET_PAGE", 1);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getSKUS");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    async clearFilter({ commit, dispatch }) {
        commit("RESET_FILTER");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getSKUS");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    closeFilterSidebar({ commit }) {
        commit("SET_RIGHT_SIDEBAR", false, { root: true });
    },

    // Manipulação dos skus
    addNewSKU() {
        router.push("/sku_manager/new_sku");
    },

    async salvarNovoSKU({ commit, dispatch, getters }, { sku_obj, form_ref }) {
        const confirmation = await dispatch(
            "modal/ModalGeneric",
            {
                title:
                    getters.operation_type == "new"
                        ? i18n.t("pages.sku.sku_form_component.modal.title_new")
                        : i18n.t("pages.sku.sku_form_component.modal.title_edit"),
                text: i18n.t("pages.sku.sku_form_component.modal.text"),
                icon: "warning",
            },
            { root: true }
        );

        if (confirmation) {
            try {
                await sku_service.new(sku_obj);
                commit("PUSH_SUCCESS_TO_ARRAY", "new_sku_added", { root: true });
                commit("RESET_DATA");
                form_ref.clearForm();
            } catch (error) {
                commit(
                    "PUSH_ERROR_TO_ARRAY_PURE_TEXT",
                    error.response.data.error || "Erro ao adicionar SKU",
                    { root: true }
                );
            }
        }
    },

    async checkIfSKUExists({ commit }, { sku_code, company_code }) {
        commit("SET_LOADING_SEARCH_SKU", true);
        commit("SET_STATUS_SKU_CODE_EXISTS", false);
        try {
            const response = await sku_service.find_sku({
                cd_sku: sku_code,
                empresa_id: company_code,
            });
            const skus_found = response.data;
            if (skus_found.length > 1) commit("SET_STATUS_SKU_CODE_EXISTS", true);
            commit("SET_STATUS_CODE_SEARCHED", true);
        } catch {
            commit("SET_STATUS_SKU_CODE_EXISTS", true);
            commit("PUSH_ERROR_TO_ARRAY", "action failed", { root: true });
        }
        commit("SET_LOADING_SEARCH_SKU", false);
    },

    async getSKUObject({ commit }, { cd_sku, empresa_id }) {
        commit("saveStatus", "loading", { root: true });
        try {
            const response = await sku_service.find_sku({ cd_sku, empresa_id });
            const skus = response.data;
            commit("saveStatus", "success", { root: true });
            if (skus.length <= 1) throw "none sku found";
            else return skus[0];
        } catch {
            commit("PUSH_ERROR_TO_ARRAY", "action failed", { root: true });
            commit("saveStatus", "success", { root: true });
            return "error";
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
