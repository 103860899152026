export const HelpRoutes = [
    {
        path: "/help",
        component: () => import("@/views/Help/HelpWrapper"),
        children: [
            {
                path: "",
                name: "help_center",
                component: () => import("@/views/Help/HelpCenter"),
            },
            {
                path: "faqs",
                name: "help_center_faqs",
                component: () => import("@/views/Help/HelpFAQS"),
            },
            {
                path: "guides",
                name: "help_center_guides",
                component: () => import("@/views/Help/HelpGuides"),
            },
            {
                path: "guides/:id",
                name: "help_center_guide",
                component: () => import("@/views/Help/HelpGuide"),
            },
        ]
    },
];
