export const ErrorsRoutes = [
    {
        path: "/errors",
        component: () => import("@/views/Errors/Layout"),
        children: [
            {
                path: ":error",
                name: "ErrorsPage",
                component: () => import("@/components/errors/Error"),
            },
        ],
    },
    {
        path: "*",
        redirect: "/errors/404",
    },
];
