<template>
    <WeduuTemplatePage
        :title_page="$t('pages.dashboard.title')"
        :subtitle_page="$t('pages.dashboard.subtitle')"
        :loading="loading"
    >
        <p class="text-pedmais small-text">
            {{ $t("pages.dashboard.text1", { date: date_30_days }) }}
        </p>
        <div class="row">
            <div
                v-if="showCard('order') && checkDinamycPermission('order')"
                class="col-12 col-sm-6 col-md-4 p-1"
            >
                <div class="w-100">
                    <content-snippet
                        v-if="order != null && !loading"
                        :route="{ name: 'cockpit' }"
                        color="#4c7897"
                        icon="cart3"
                        :title="$t('pages.dashboard.cards.order')"
                        :value="order"
                    />
                    <SkeletonCard style="max-width: 100%" v-else></SkeletonCard>
                </div>
            </div>
            <div
                v-if="showCard('detalhamento') && checkDinamycPermission('detalhamento')"
                class="col-12 col-sm-6 col-md-4 p-1"
            >
                <content-snippet
                    v-if="supply != null && !loading"
                    :route="{ name: 'detalhamento' }"
                    color="#1a961a"
                    icon="list-check"
                    :title="$t('pages.dashboard.cards.supply')"
                    :value="supply"
                />
                <SkeletonCard v-else></SkeletonCard>
            </div>
            <div
                v-if="showCard('upload') && checkDinamycPermission('upload')"
                class="col-12 col-sm-6 col-md-4 p-1"
            >
                <content-snippet
                    v-if="curator != null && !loading"
                    :route="{ name: 'upload', query: { status_filter: 'Pendente' } }"
                    color="#e99e12"
                    icon="ui-checks-grid"
                    :title="$t('pages.dashboard.cards.curator')"
                    :value="curator"
                />
                <SkeletonCard v-else></SkeletonCard>
            </div>
            <div
                v-if="showCard('sku') && checkDinamycPermission('sku')"
                class="col-12 col-sm-6 col-md-4 p-1"
            >
                <content-snippet
                    v-if="sku != null && !loading"
                    :route="{ name: 'sku' }"
                    color="#6e58b0"
                    icon="upc-scan"
                    :title="$t('pages.dashboard.cards.sku')"
                    :value="sku"
                />
                <SkeletonCard v-else></SkeletonCard>
            </div>
            <div
                v-if="showCard('dictionary') && checkDinamycPermission('dictionary')"
                class="col-12 col-sm-6 col-md-4 p-1"
            >
                <content-snippet
                    v-if="dictionary != null && !loading"
                    :route="{ name: 'dictionary' }"
                    color="#1a9692"
                    icon="journal-text"
                    :title="$t('pages.dashboard.cards.dictionary')"
                    :value="dictionary"
                />
                <SkeletonCard v-else></SkeletonCard>
            </div>
            <div
                v-if="showCard('logs') && checkDinamycPermission('logs')"
                class="col-12 col-sm-6 col-md-4 p-1"
            >
                <content-snippet
                    v-if="logs != null && !loading"
                    :route="{ name: 'logs' }"
                    color="#eb6157"
                    icon="clock"
                    :title="$t('pages.dashboard.cards.logs')"
                    :value="logs"
                />
                <SkeletonCard v-else></SkeletonCard>
            </div>
            <div
                v-if="showCard('pagamento_module') && checkDinamycPermission('pagamento_menu')"
                class="col-12 col-sm-6 col-md-4 p-1"
            >
                <content-snippet
                    v-if="pagamento_total != null && !loading"
                    :route="{ name: 'pagamento_cockpit', query: { status: 'todos' } }"
                    color="#4c7897"
                    icon="files"
                    :title="$t('pages.dashboard.cards.pagamento_total')"
                    :value="pagamento_total"
                />
                <SkeletonCard v-else></SkeletonCard>
            </div>
            <div
                v-if="showCard('pagamento_module') && checkDinamycPermission('pagamento_menu')"
                class="col-12 col-sm-6 col-md-4 p-1"
            >
                <content-snippet
                    v-if="pagamento_processado != null && !loading"
                    :route="{ name: 'pagamento_cockpit', query: { status: 'processado' } }"
                    color="#1a961a"
                    icon="list-check"
                    :title="$t('pages.dashboard.cards.pagamento_processado')"
                    :value="pagamento_processado"
                />
                <SkeletonCard v-else></SkeletonCard>
            </div>
            <div
                v-if="showCard('pagamento_module') && checkDinamycPermission('pagamento_menu')"
                class="col-12 col-sm-6 col-md-4 p-1"
            >
                <content-snippet
                    v-if="pagamento_pendente != null && !loading"
                    :route="{ name: 'pagamento_cockpit', query: { status: 'pendente' } }"
                    color="#e99e12"
                    icon="ui-checks-grid"
                    :title="$t('pages.dashboard.cards.pagamento_pendente')"
                    :value="pagamento_pendente"
                />
                <SkeletonCard v-else></SkeletonCard>
            </div>
        </div>
    </WeduuTemplatePage>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import WeduuTemplatePage from "@/components/utils/WeduuTemplatePage";
import ContentSnippet from "@/components/board/content/ContentSnippet";
import SkeletonCard from "@/components/utils/SkeletonCard";
import InputMoney from "../Curatorship/NewOrder/InsertProduct/SelectionOfProducts/InputMoney.vue";

export default {
    name: "Dashboard",
    components: {
        WeduuTemplatePage,
        ContentSnippet,
        SkeletonCard,
        InputMoney,
    },
    mounted() {
        if (!this.loading) this.getDashboardData();
        this.initiateConfigs(this.$router)
    },
    computed: {
        ...mapGetters(["role", "pages_limited"]),
        ...mapGetters("dashboard", [
            "loading",
            "order",
            "supply",
            "curator",
            "dictionary",
            "sku",
            "logs",
            "cards_to_be_shown",
            "pagamento_total",
            "pagamento_pendente",
            "pagamento_processado",
        ]),
        date_30_days() {
            let d = new Date();
            d.setDate(d.getDate() - 30);
            return d.toLocaleString("pt-br");
        },
    },
    beforeDestroy() {
        this.resetDashboardStore();
    },
    methods: {
        ...mapActions("dashboard", ["getDashboardData", "readCacheData", "resetDashboardStore"]),
        ...mapActions("configs", ["initiateConfigs"]),
        showCard(card) {
            return this.cards_to_be_shown.includes(card);
        },
        checkDinamycPermission(item) {
            if (item == "pagamento_menu") {
                if (this.pages_limited.length == 0) return false;
                else {
                    let indexof = this.pages_limited.indexOf(item);
                    if (indexof === -1) return false;
                }
            }

            if (this.pages_limited.length == 0) return true;

            let indexof = this.pages_limited.indexOf(item);
            if (indexof === -1) return false;
            return true;
        },
    },
    data() {
        return {
            theme: "light",
        };
    },
};
</script>
