import i18n from "@/plugins/i18n";
import billing_service from "@/services/api/billing.js";
import { exportFileXLSX } from "@/helpers/files.helper";
import { getCompanyById, getListOfCompanyToSelectInput } from "@/helpers/companies.helper";
import { GetListOptionsLastTreeMonths } from "@/helpers/date.helper";

const initial_state = () => ({
    // billing
    month: "",
    month_billing_options: [],
    company_id: "",

    loading_cards: false,
    loading_chart: false,
    loading_table: false,
    loading_download: false,

    loading_detailed: false,
    loading_detailed_table: false,
    loading_all_companies: false,

    cards: {
        stores: 0,
        orders: 0,
        processed_lines: 0,
        skus: 0,
        total_value: 0,
        monthly_payment: 0,
    },

    cards_detailed: {
        lines_payment: 0,
        monthly_payment: 0,
        total_value: 0,
    },

    table_detailed: [],

    chart: {
        labels: [],
        orders: [],
        processed_lines: [],
    },

    table_price: {
        email: [],
        price_data: [],
        monthly_payment: 0,
        faturamento: "linha",
        vigencia_inicio: "",
        vigencia_final: "",
    },

    all_companies: [],

    table_page: 0,
    table_items: [],
    total_items: 0,
});

const state = initial_state();

const getters = {
    loading_cards: (state) => state.loading_cards,
    loading_chart: (state) => state.loading_chart,
    loading_table: (state) => state.loading_table,
    loading_download: (state) => state.loading_download,
    loading_detailed: (state) => state.loading_detailed,
    loading_detailed_table: (state) => state.loading_detailed_table,
    loading_all_companies: (state) => state.loading_all_companies,

    any_loading: (state) =>
        state.loading_cards ||
        state.loading_chart ||
        state.loading_table ||
        state.loading_detailed ||
        state.loading_detailed_table,

    month: (state) => state.month,
    month_options: (state) => state.month_billing_options,

    company_id: (state) => state.company_id,
    name_company: (state, getters, rootState, rootGetters) =>
        getCompanyById(rootGetters["lista_empresas"], getters.company_id),
    lista_empresas: (state, getters, rootState, rootGetters) => {
        if (rootGetters["role"] < 6) return [];
        const list_store = rootGetters["lista_empresas"];
        const list_empresas = getListOfCompanyToSelectInput(list_store);
        return list_empresas;
    },

    blockAcessIfCustomer: (state, getters, rootState, rootGetters) =>
        rootGetters["role"] >= 6 && getters.company_id == "",

    all_companies: (state) => state.all_companies,

    cards: (state) => state.cards,
    cards_detailed: (state) => state.cards_detailed,
    table_detailed: (state) => state.table_detailed,
    table_price: (state) => state.table_price,
    chart: (state) => state.chart,
    items: (state) => state.table_items,
    total_items: (state) => state.total_items,
    table_page: (state) => state.table_page,

    filter_to_api: (state, getters, rootState, rootGetters) => ({
        month: state.month,
        empresa_id: rootGetters["role"] >= 6 ? state.company_id : null,
    }),
};

const mutations = {
    SET_LOADING_CARDS: (state, value) => (state.loading_cards = value),
    SET_LOADING_CHART: (state, value) => (state.loading_chart = value),
    SET_LOADING_TABLE: (state, value) => (state.loading_table = value),
    SET_LOADING_DOWNLOAD: (state, value) => (state.loading_download = value),
    SET_LOADING_DETAILED: (state, value) => (state.loading_detailed = value),
    SET_LOADING_DETAILED_TABLE: (state, value) => (state.loading_detailed_table = value),
    SET_LOADING_ALL_COMPANIES: (state, value) => (state.loading_all_companies = value),

    SET_MONTH: (state, month) => (state.month = month),
    SET_MONTH_BILLING_OPTIONS: (state, options) => (state.month_billing_options = options),
    SET_COMPANY_ID: (state, id) => (state.company_id = id),

    // Data
    SET_CARDS_INFO: (state, data) => {
        state.cards = data;
        if (!data["total_value"]) state.cards["total_value"] = 0;
    },
    SET_CARDS_DETAILED: (state, data) => {
        state.cards_detailed = data;
    },
    SET_TABLE_DETAILED: (state, data) => (state.table_detailed = data),
    SET_CHART_INFO: (state, data) => {
        state.chart = data;
    },
    SET_ALL_COMPANIES_INFO: (state, data) => (state.all_companies = data),

    SET_TABLE_RULES: (state, data) => (state.table_price = { ...data }),

    APPEND_DATA_TO_TABLE: (state, items) => {
        let aux = state.table_items;
        aux = aux.concat(items);
        state.table_items = aux;
    },
    SET_TOTAL_ITEMS: (state, total) => (state.total_items = total),
    SET_TABLE_PAGE: (state, page) => (state.table_page = page),

    RESET_ALL_DATA: (state) => {
        state.cards = {
            stores: 0,
            orders: 0,
            processed_lines: 0,
            skus: 0,
            total_value: 0,
        };
        state.cards_detailed = {
            lines_payment: 0,
            monthly_payment: 0,
            total_value: 0,
        };
        state.table_detailed = [];

        state.chart = {
            labels: [],
            orders: [],
            processed_lines: [],
        };

        state.table_price = {
            email: [],
            price_data: [],
            monthly_payment: 0,
            faturamento: "linha",
            vigencia_inicio: "",
            vigencia_final: "",
        };

        state.table_items = [];
        state.total_items = 0;
        state.table_page = 0;
    },
};

const actions = {
    async resetData({ commit }) {
        commit("RESET_ALL_DATA");
    },
    async setInitialConfig({ commit }) {
        const month_options = await GetListOptionsLastTreeMonths();
        commit("SET_MONTH_BILLING_OPTIONS", month_options);
        commit("SET_MONTH", month_options[0].value);
        return Promise.resolve();
    },
    async getBillingInfo({ dispatch, getters }) {
        if (getters.blockAcessIfCustomer) return;
        if (!getters.loading_cards) dispatch("getCardsInfo");
        if (!getters.loading_chart) dispatch("getChartInfo");
        if (!getters.loading_table) dispatch("getTableInfo");
    },
    async getCardsInfo({ commit, getters }) {
        commit("SET_LOADING_CARDS", true);
        try {
            const { month, empresa_id } = getters.filter_to_api;
            const result = await billing_service.cards(month, empresa_id);
            commit("SET_CARDS_INFO", result);
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao buscar info do card", { root: true });
        }
        commit("SET_LOADING_CARDS", false);
    },
    async getChartInfo({ commit, getters }) {
        commit("SET_LOADING_CHART", true);
        try {
            const { month, empresa_id } = getters.filter_to_api;
            const result = await billing_service.chart(month, empresa_id);
            commit("SET_CHART_INFO", result);
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao buscar info do gráfico", {
                root: true,
            });
        }
        commit("SET_LOADING_CHART", false);
    },
    async getTableInfo({ commit, getters }) {
        try {
            const total_items = getters.total_items;
            const items = getters.items;
            if (total_items && total_items != 0) if (items.length >= total_items) return;

            commit("SET_LOADING_TABLE", true);
            const { month, empresa_id } = getters.filter_to_api;
            const page = getters.table_page + 1;
            await commit("SET_TABLE_PAGE", page);

            const result = await billing_service.table(month, page, empresa_id);
            commit("APPEND_DATA_TO_TABLE", result.items);
            commit("SET_TOTAL_ITEMS", result.total_items);
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao buscar info da tabela", {
                root: true,
            });
        }
        commit("SET_LOADING_TABLE", false);
    },
    async downloadXlsxFile({ commit, getters }) {
        commit("SET_LOADING_DOWNLOAD", true);
        try {
            const { month, empresa_id } = getters.filter_to_api;

            const response = await billing_service.completeData(month, empresa_id);
            let mes_formatado = month.split("-").reverse().join("");
            var filename = `${mes_formatado}_ped+ Linhas Processadas`;
            exportFileXLSX(response, filename);
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", i18n.t("action failed"), { root: true });
        }
        commit("SET_LOADING_DOWNLOAD", false);
    },
    async getDetailedBillingInfo({ getters, commit, dispatch }) {
        const { month, empresa_id } = getters.filter_to_api;

        if (!month || !empresa_id) return;
        commit("SET_TABLE_DETAILED", []);
        commit("SET_CARDS_DETAILED", {
            lines_payment: 0,
            monthly_payment: 0,
            total_value: 0,
        });

        commit("SET_LOADING_DETAILED", true);
        billing_service
            .getDetailedPayment(month, empresa_id)
            .then((result) => {
                const table_detailed_aux = result["data"]["initial"].map((el, index) => {
                    return {
                        initial: el,
                        final: result["data"]["final"][index],
                        line_value: result["data"]["line_value"][index],
                        lines: result["data"]["lines"][index],
                        price: result["data"]["price"][index],
                    };
                });

                commit("SET_TABLE_DETAILED", table_detailed_aux);

                const cards_aux = {
                    monthly_payment: result["data"]["monthly_payment"],
                    lines_payment: result["data"]["lines_payment"],
                    total_value: result["data"]["total_value"],
                };
                commit("SET_CARDS_DETAILED", cards_aux);
            })
            .catch((err) => {
                commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", err.response.data.error, { root: true });
                dispatch("clearDetailedBillingInfo");
            })
            .finally(() => commit("SET_LOADING_DETAILED", false));
    },
    async getPriceTableRulesInfo({ getters, commit }) {
        const { empresa_id } = getters.filter_to_api;

        if (!empresa_id) return;
        commit("SET_LOADING_DETAILED_TABLE", true);

        billing_service
            .getPriceTable(empresa_id)
            .then((result) => {
                const data = result["data"];

                const aux = data["faixas_inicio"].map((el, index) => {
                    return {
                        initial: el,
                        final: data["faixas_final"][index],
                        price: data["valor_linha"][index],
                    };
                });

                commit("SET_TABLE_RULES", {
                    price_data: aux,
                    monthly_payment: data["monthly_payment"],
                    faturamento: data["faturamento"],
                    vigencia_inicio: data["vigencia_inicio"],
                    vigencia_final: data["vigencia_final"],
                    email: data["email"],
                });
            })
            .catch(() => {
                // commit("SET_TABLE_RULES", {
                //     email: [],
                //     price_data: [],
                //     monthly_payment: 0,
                //     faturamento: "linha",
                //     vigencia_inicio: "",
                //     vigencia_final: "",
                // });
            })
            .finally(() => commit("SET_LOADING_DETAILED_TABLE", false));
    },
    async getDetailedDataAllCompanies({ getters, commit }) {
        const { month, empresa_id } = getters.filter_to_api;

        if (!month || empresa_id) return;

        commit("SET_LOADING_ALL_COMPANIES", true);
        billing_service
            .allCompanies(month)
            .then((result) => commit("SET_ALL_COMPANIES_INFO", result["data"]))
            .catch((err) => {
                console.error(err);
                commit("SET_ALL_COMPANIES_INFO", []);
            })
            .finally(() => commit("SET_LOADING_ALL_COMPANIES", false));
    },
    async dispatchBillingEmail({ getters, commit }, payload) {
        commit("SET_LOADING_DOWNLOAD", true);

        const dispatchEmailPayload = {
            date: getters.month,
            empresa_id: payload.id,
        };
        billing_service
            .dispatchBillingEmail(dispatchEmailPayload)
            .catch((err) => {
                console.error(err);
                return err;
            })
            .finally(() => {
                commit("SET_LOADING_DOWNLOAD", false);
            });
    },
    clearDetailedBillingInfo({ commit }) {
        commit("SET_TABLE_RULES", {
            email: [],
            price_data: [],
            monthly_payment: 0,
            faturamento: "linha",
            vigencia_inicio: "",
            vigencia_final: "",
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
