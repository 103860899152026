var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loading-screen" },
    [
      _c("b-spinner", {
        staticClass: "spinner",
        staticStyle: { width: "8rem", height: "8rem" },
        attrs: { label: "Large Spinner" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }