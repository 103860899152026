<template>
    <div class="wrapper">
        <div class="logo">
            <img class="logo-part" src="./logo_animated/1.svg" alt="" />
            <img class="logo-part" src="./logo_animated/2.svg" alt="" />
            <img class="logo-part" src="./logo_animated/3.svg" alt="" />
        </div>
        <div class="d-flex justify-content-center">
            <div class="waviy">
                <span style="--i: 1">p</span>
                <span style="--i: 2">e</span>
                <span style="--i: 3">d</span>
                <span style="--i: 4">+</span>
                <!-- <span style="--i: 5">.</span>
                <span style="--i: 6">.</span>
                <span style="--i: 7">.</span> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LogoPedmais",
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: "Cyclo";
    src: url("../../../assets/fonts/Cyclo W00 Bold Alternate.ttf") format("truetype");
}

$logo-size: 150px;

.wrapper {
    width: $logo-size;
    height: $logo-size;
}

.logo {
    position: relative;
    width: 100%;
    height: 100%;
}

.logo-part {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 500ms ease-in-out;
    opacity: 0.9;
}

.logo-part:first-child {
    -webkit-animation: logo-wiggle 5s infinite;
    animation: logo-wiggle 5s infinite;
    animation-delay: 1s;
}

.logo-part:nth-child(2) {
    -webkit-animation: logo-wiggle 5s infinite;
    animation: logo-wiggle 5s infinite;
    animation-delay: 0.5s;
}

.logo-part:nth-child(3) {
    -webkit-animation: logo-wiggle 5s infinite;
    animation: logo-wiggle 5s infinite;
}

@keyframes logo-wiggle {
    0% {
        top: 0;
        left: 0;
        transform: rotate(0deg);
        transform-origin: 10% 70%;
    }

    25% {
        top: 0;
        left: 0;
        transform: rotate(-7deg);
        transform-origin: 10% 70%;
    }

    50% {
        top: 0;
        left: 0;
        transform: rotate(-3deg);
        transform-origin: 10% 70%;
    }

    75% {
        top: 0;
        left: 0;
        transform: rotate(-6deg);
        transform-origin: 10% 70%;
    }

    100% {
        top: 0;
        left: 0;
        transform: rotate(0deg);
        transform-origin: 10% 70%;
    }
}

.waviy {
    margin-top: -25px;
    position: relative;
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
    font-size: 39px;
    font-family: Cyclo;
}

.waviy span {
    position: relative;
    display: inline-block;
    color: white;
    /* animation: waviy 1s infinite;
    animation-delay: calc(0.1s * var(--i)); */
}

@keyframes waviy {

    0%,
    40%,
    100% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(-20px);
    }
}

.text {
    font-family: Cyclo;
    font-size: 50px;
    width: 100%;
    color: #6d6e71;
    display: grid;
    place-items: center;
}</style>
