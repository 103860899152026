var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _vm._m(0),
    _c("div", { staticClass: "title-page-mfa" }, [
      _vm._v("\n        " + _vm._s(_vm.$t("auth.mfa.check_acces")) + "\n    "),
    ]),
    _c("div", { staticClass: "card mfa-card" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("p", { staticClass: "text-black-pedmais inside-card-text" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("auth.mfa.verification_code")) +
                "\n            "
            ),
          ]),
          _vm.method == "qrcode"
            ? [
                _vm.uri && _vm.uri != ""
                  ? _c(
                      "div",
                      { staticClass: "qrcode-uri" },
                      [
                        _c("QrcodeVue", {
                          staticClass: "qrcode-component",
                          attrs: { value: _vm.uri, level: "H" },
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("auth.mfa.check_the_code")) +
                          "\n                "
                      ),
                    ]),
              ]
            : [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("auth.mfa.check_email")) +
                    "\n            "
                ),
              ],
          _vm.error != ""
            ? _c(
                "div",
                { staticClass: "message" },
                [
                  _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
                    _vm._v(" " + _vm._s(_vm.$t(_vm.error)) + " "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.message != ""
            ? _c(
                "div",
                { staticClass: "message" },
                [
                  _c("b-alert", { attrs: { show: "", variant: "success" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("auth.mfa." + _vm.message)) + " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              !_vm.loading
                ? _c("OTPInput", {
                    on: {
                      enter_pressed: _vm.SendButtonClicked,
                      final_code: _vm.updateCode,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "my-1 send-code d-flex flex-column justify-content-center",
            },
            [
              _c(
                "b-button",
                {
                  attrs: { disabled: !_vm.checkCodeValidation || _vm.loading },
                  on: { click: _vm.SendButtonClicked },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("auth.mfa.button_send_code")) +
                      "\n                    "
                  ),
                  _vm.loading
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("p", { staticClass: "text-black-pedmais" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.$t("auth.mfa.message_days_validity", {
                    days: _vm.tf_login_validity,
                  })
                ) +
                "\n            "
            ),
          ]),
          _c(
            "div",
            { staticClass: "back-to-login" },
            [
              _c(
                "router-link",
                { staticClass: "card-body-link", attrs: { to: "/login" } },
                [_c("span", [_vm._v(_vm._s(_vm.$t("auth.mfa.back_to_login")))])]
              ),
            ],
            1
          ),
          _vm._m(1),
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "text-bellow-card" }, [
      _c("p", { staticClass: "mt-1" }, [
        _vm.seconds_count > 0
          ? _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("auth.mfa.countdown", { seconds: _vm.seconds_count })
                )
              ),
            ])
          : _c("span", [
              _vm._v(" " + _vm._s(_vm.$t("auth.mfa.link_bellow")) + " "),
            ]),
      ]),
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _vm.seconds_count == 0
            ? _c(
                "b-link",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resendCode()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("auth.mfa.generate_new_code")))]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex flex-column login-header mb-2" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/img/logo_pedmais_cut.png"),
          height: "150",
          width: "150",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "mt-1 row align-items-center justify-content-center no-gutters",
      },
      [
        _c("img", {
          attrs: {
            src: require("../../assets/img/logo_weduu.png"),
            width: "100px",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }