import axios from "axios";
import store from "../store";

export default () => {
    const token = store.getters.token;
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.common["Content-Security-Policy"] =
        "default-src 'none'; img-src 'self'; script-src 'self'; style-src 'self'";
    return axios;
};
