import api from "@/services/api";

export default {
    list(data) {
        const form = new FormData();
        for (let key in data) form.append(key, data[key]);
        return api().post("/log", form);
    },
    export(data) {
        const form = new FormData();

        form.append("exportar", "");
        form.append(
            "dt_referencia_inicial",
            data.dt_referencia_inicial ? data.dt_referencia_inicial : ""
        );
        form.append(
            "dt_referencia_final",
            data.dt_referencia_final ? data.dt_referencia_final : ""
        );
        form.append("evento", data.evento ? data.evento : "");

        return api().post("/log", form);
    }
};
