<template>
    <div id="app">
        <SplashScreen v-if="showIfItsntLoginScreen" :isLoading="splash_screen"></SplashScreen>
        <loading-screen v-if="status == 'loading'"></loading-screen>
        <loading-screen-processing v-if="status_processing"></loading-screen-processing>
        <app-alerts-wrapper></app-alerts-wrapper>
        <router-view />
    </div>
</template>

<script>
import axios from "axios";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import AppAlertsWrapper from "@/components/alerts/Alerts";
import LoadingScreen from "./components/status/LoadingScreen.vue";
import LoadingScreenProcessing from "./components/status/LoadingScreenProcessing.vue";
import SplashScreen from "./components/loading/SplashScreen.vue";

export default {
    name: "App",
    components: {
        AppAlertsWrapper,
        LoadingScreen,
        LoadingScreenProcessing,
        SplashScreen,
    },
    computed: {
        ...mapGetters(["status", "splash_screen"]),
        ...mapState(["status_processing"]),
        showIfItsntLoginScreen() {
            if (this.$route.name == "Login") return false;
            return true;
        },
    },
    methods: {
        ...mapMutations("modal", ["SET_MODAL_REF"]),
        ...mapActions("configs", ["initiateConfigs"]),
        ...mapActions(["logout"]),
    },
    beforeMount() {
        this.initiateConfigs(this.$router);
    },
    mounted() {
        this.SET_MODAL_REF(this.$swal);

        document.cookie = "";
        let htmlElement = document.documentElement;
        htmlElement.setAttribute("theme", "light");
        const _this = this;

        axios.interceptors.response.use(
            function (success) {
                return Promise.resolve(success);
            },

            async function (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        await _this.logout(true);
                        _this.$router.replace("/login");
                    }
                }
                return Promise.reject(err);
            }
        );
    },
};
</script>

<style lang="scss" scoped>
#app {
    height: 100%;
}
</style>
