import api from "@/services/api";
import { stringReaisToNumber } from "@/helpers/money.helper.js";

export default {
    duplicateOrder() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ data: { order_id: "@DRAFT0e8ba75c-dd41-4489-81ec-22a46cb1eff7" } });
            }, 1000);
        });
    },
    newOrder(cod_client, payment_method) {
        payment_method = payment_method ? `&payment_method=${payment_method}` : "";
        return api().post("/new_order/create_order?account_id=" + cod_client + payment_method, {});
    },
    getParameters(id_order) {
        return api().get(`/new_order/blueprint?order_id=${id_order}`, {});
    },
    updatePaymentCondition(id_order, cond_pagto) {
        return api().patch(`/new_order/blueprint?order_id=${id_order}`, {
            cond_pagto,
        });
    },
    //-- MERGE - 15-05 --
    updatePaymentMethod(id_order, payment_method) {
        return api().patch(`/new_order/blueprint?order_id=${id_order}`, {
            payment_method,
        });
    },
    saveOBS(id_order, obs) {
        return api().patch(`/new_order/blueprint?order_id=${id_order}`, {
            obs,
        });
    },
    saveClientEmail(id_order, email) {
        return api().patch(`/new_order/blueprint?order_id=${id_order}`, {
            email,
        });
    },
    getItems(id_order) {
        return api().get(`/new_order/items?order_id=${id_order}`);
    },
    insertNewItem(id_order, body_item, cond_pagto = null) {
        var obj_body = {
            cd_sku: body_item["cd_sku"],
            ds_sku: body_item["ds_sku"],
            qty: body_item["qtd"],
            reason_code: body_item["reason_code"],
        };

        if (body_item.price_selected) {
            if (
                body_item.price_selected.toLowerCase().includes("alcada gnv") ||
                body_item.price_selected.toLowerCase().includes("alcada gm") ||
                body_item.price_selected.toLowerCase().includes("preco campanha")
            ) {
                const number = stringReaisToNumber(body_item.price_typed);
                const price_selected = body_item.price_selected.substring(2);

                obj_body["price"] = `${number} ${price_selected}`;
            } else obj_body["price"] = body_item.price_selected;
        }

        const paymentCondition = cond_pagto ? `&cond_pagto=${cond_pagto}` : "";

        return api().post(`/new_order/items?order_id=${id_order}${paymentCondition}`, obj_body);
    },
    deleteItem(id_order, cd_sku, cond_pagto = null) {
        const paymentCondition = cond_pagto ? `&cond_pagto=${cond_pagto}` : "";

        return api().post(`/new_order/items/delete?order_id=${id_order}${paymentCondition}`, [
            ...cd_sku,
        ]);
    },
    updateQtd(id_order, cd_sku, qtd, cond_pagto = null) {
        const paymentCondition = cond_pagto ? `&cond_pagto=${cond_pagto}` : "";

        return api().patch(`/new_order/items?order_id=${id_order}${paymentCondition}`, {
            cd_sku_input: cd_sku,
            qty: qtd,
        });
    },
    createOrder(id_order) {
        return api().post(`/new_order/close?order_id=${id_order}`);
    },
    changeEndEnvio(id_order, cod) {
        return api().patch(`/new_order/blueprint?order_id=${id_order}`, {
            cod_end_envio: cod,
        });
    },
    workflowApproval(filter) {
        let query = [];
        Object.keys(filter).forEach((key) => {
            if (filter[key] !== "") query.push(`${key}=${filter[key]}`);
        });
        return api().get(`/new_order/workflow_approval?${query.join("&")}`);
    },
    approveOrder(order_id, obs = "") {
        return api().post(`/new_order/approve?order_id=${order_id}`, {
            obs,
        });
    },
    rejectOrder(order_id, obs = "") {
        return api().post(`/new_order/reject?order_id=${order_id}`, {
            obs,
        });
    },
    downloadFile(filters) {
        let query = [];
        Object.keys(filters).forEach((key) => {
            if (filters[key] !== "") query.push(`${key}=${filters[key]}`);
        });
        return api().get(`/new_order/workflow_approval/download?${query.join("&")}`, {
            responseType: "blob",
        });
    },
    searchClientsByCode(code) {
        return api().get(`/new_order/clients?search=${code}`);
    },
    getPaymentMethods() {
        try {
            return api().get(`/new_order/payment`);
        } catch {
            return {};
        }
    },
};
