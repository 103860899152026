<template>
    <router-link :to="route" tag="div">
        <div class="content-snippet" :style="{ 'border-left': '3px solid ' + color }">
            <div class="content-snippet-body">
                <div class="content-snippet-info">
                    <div class="content-snippet-title">
                        {{ title }}
                    </div>
                    <h5 class="content-snippet-number">
                        {{ value | formatNumber }}
                    </h5>
                </div>
                <div class="content-snippet-icon">
                    <b-icon font-scale="2" :style="{ color: color }" :icon="icon"></b-icon>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "ContentSnippet",
    props: {
        route: {
            type: Object,
            required: true
        },
        color: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        value: {
            type: Number,
            required: true
        }
    }
};
</script>
