import store from "@/store";

import { userHasAccessToCertainApp } from "@/helpers/router.helper";

export default (to, from, next) => {
    if (store.getters.isAuthenticated) {
        const modules = store.getters.modules;
        if (userHasAccessToCertainApp(modules, "sugestoes")) next();
        else next("/");
    } else {
        var query_obj = { redirect: "/" };
        if (to.fullPath != "/") query_obj = { redirect: to.fullPath };
        next({ name: "Login", query: query_obj });
    }
};
