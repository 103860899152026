var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-link", { attrs: { to: _vm.route, tag: "div" } }, [
    _c(
      "div",
      {
        staticClass: "content-snippet",
        style: { "border-left": "3px solid " + _vm.color },
      },
      [
        _c("div", { staticClass: "content-snippet-body" }, [
          _c("div", { staticClass: "content-snippet-info" }, [
            _c("div", { staticClass: "content-snippet-title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.title) +
                  "\n                "
              ),
            ]),
            _c("h5", { staticClass: "content-snippet-number" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm._f("formatNumber")(_vm.value)) +
                  "\n                "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "content-snippet-icon" },
            [
              _c("b-icon", {
                style: { color: _vm.color },
                attrs: { "font-scale": "2", icon: _vm.icon },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }