import store from "@/store";

export const returnClassLinhaDeletadaIfItemIsDeleted = function (item, type) {
    if (!item || type !== "row") return;
    if (item.status_checkout == "DELETE") return "linha-deletada";
};

export const returnClassLinhaDeletadaIfItemIsDeletedAndIsNotRow = function (key, item) {
    const alwaysShowLogs = store.getters["checkout/always_show_logs"];

    if (!item) return;

    if (key === "item_log" && alwaysShowLogs) return;

    if (item.status_checkout == "DELETE") return "coluna-deletada";
};
