import api from '@/services/api'
// import { reject, resolve } from 'core-js/fn/promise';

export default {
    list(data) {
        const form = new FormData(); 
        for(let key in data) form.append(key, data[key]); 
        return api().post('/list-profile', form);
    }, 
    checkIfUsernameExists() {
        return new Promise((resolve) => {
            // se existir retorna true s
            setTimeout(() => {
                resolve(false); 
            }, 2000);
        });
    }, 
    newUser() {
        return new Promise((resolve) => {

            // reject('erro'); 
            setTimeout(() => {
                resolve({status: 'ok', message: 'Usuario adicionado com sucesso!'})
            }, 4000);
        });
    }, 
    deleteUser() {
        return 'sucesso';
    }

}