import i18n from "@/plugins/i18n";
import bonus_service from "@/services/api/bonificacao";

const initial_state = () => ({
    loading: false,
    // Filtro
    cnpj: "",
    data: "",
    status: "",

    bonus: [],
    selected_items: [],

    pagination: {
        page: 1,
        perPage: 10,
        total_items: 0,
    },

    table_fields: [
        {
            key: "selected",
            label: "pages.bonus.table_labels.select",
            tdClass: "cell-bonificacao",
        },
        {
            key: "dt_ref",
            label: "pages.bonus.table_labels.dt_ref",
            tdClass: "cell-bonificacao",
        },
        {
            key: "vendedor",
            label: "pages.bonus.table_labels.salesman",
            tdClass: "cell-bonificacao",
        },
        {
            key: "cliente",
            label: "pages.bonus.table_labels.client",
            tdClass: "cell-bonificacao",
        },
        {
            key: "cnpj",
            label: "pages.bonus.table_labels.cnpj",
            tdClass: "cell-bonificacao",
        },
        {
            key: "documento",
            label: "pages.bonus.table_labels.document",
            tdClass: "cell-bonificacao",
        },
        {
            key: "total_compra",
            label: "pages.bonus.table_labels.total_sales",
            tdClass: "cell-bonificacao",
        },
        {
            key: "total_bonificacao",
            label: "pages.bonus.table_labels.total_bonus",
            tdClass: "cell-bonificacao",
        },
        {
            key: "porcentagem",
            label: "pages.bonus.table_labels.percentage",
            tdClass: "cell-bonificacao",
        },
        {
            key: "aprovacao_com",
            label: "pages.bonus.table_labels.comercial_approval",
            // tdClass: 'colunas-status cell-bonificacao',
            // thClass: 'colunas-status'
        },
        {
            key: "aprovacao_planejamento",
            label: "pages.bonus.table_labels.planning_approval",
            // tdClass: 'colunas-status cell-bonificacao',
            // thClass: 'colunas-status'
        },
        {
            key: "acoes",
            label: "pages.bonus.table_labels.actions",
            tdClass: "cell-bonificacao",
        },
    ],

    date: null,
});
const state = initial_state();

const doubleDigitsAlways = function (val) {
    return `${("0" + val).slice(-2)}`;
};

const getters = {
    loading: (state) => state.loading,
    date: (state) => {
        var aux = state.date;
        if (!aux) return "";
        return `${doubleDigitsAlways(aux.getDate())}/${doubleDigitsAlways(
            aux.getMonth() + 1
        )}/${doubleDigitsAlways(aux.getFullYear())} ${doubleDigitsAlways(
            aux.getHours()
        )}:${doubleDigitsAlways(aux.getMinutes())}:${doubleDigitsAlways(aux.getSeconds())}`;
    },
    // Filtro
    cnpj: (state) => state.cnpj,
    data: (state) => state.data,
    status: (state) => state.status,
    // Dados da bonificação
    table_fields: (state) =>
        state.table_fields.map((item) => ({ ...item, label: i18n.t(item.label) })),
    selected_items: (state) => state.selected_items,
    bonus: (state) => state.bonus,
    total_items: (state) => state.pagination.total_items,
    current_page: (state) => state.pagination.page,
    per_page: (state) => state.pagination.perPage,
    filter_to_api: (state) => ({
        offset: state.pagination.page - 1,
        per_page: state.pagination.perPage,
        cnpj: state.cnpj,
        status_filter: state.status,
        data: state.data,
    }),
};

const mutations = {
    SET_LOADING(state, value) {
        state.loading = value;
    },
    UPDATE_DATE(state) {
        state.date = new Date();
    },
    // Filtro
    SET_CNPJ(state, cnpj) {
        state.cnpj = cnpj;
    },
    SET_DATA(state, data) {
        state.data = data;
    },
    SET_STATUS(state, status) {
        state.status = status;
    },

    SET_SELECTED_ITEMS(state, selected_items) {
        state.selected_items = selected_items;
    },
    SET_BONUS(state, bonus) {
        state.bonus = bonus;
    },
    SET_TOTAL_ITENS(state, total) {
        state.pagination.total_items = total;
    },
    SET_PAGE(state, page) {
        state.pagination.page = page;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },
    RESET_FILTER(state) {
        state.cnpj = "";
        state.data = "";
        state.status = "";
        state.pagination.page = 1;
    },
    RESET_DATA: (state) => {
        Object.assign(state, initial_state());
    },
};

const actions = {
    // Actions dos botões do filtro
    async updateData({ commit, dispatch }) {
        commit("saveStatus", "loading", { root: true });
        await dispatch("getBonus");
        commit("saveStatus", "success", { root: true });
    },
    async search({ commit, dispatch }) {
        commit("SET_PAGE", 1);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getBonus");
        commit("saveStatus", "success", { root: true });
    },
    async clearFilter({ commit, dispatch }) {
        commit("RESET_FILTER");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getBonus");
        commit("saveStatus", "success", { root: true });
    },
    // Actions orders
    async seeOrder(_, { item, router }) {
        const query = {
            loja: item.cnpj,
            nome_cliente_filter: item.cliente,
            file_filter: item.documento,
            dt_ref_filter: item.uploaded,
            order_id: item.order_id,
        };
        router.push({ name: "detalhamento", query });
    },
    async editOrder(_, { item, router }) {
        const query = {
            file_name: item.documento,
            file_id: item.order_id,
            uuid_list: item.uuid_list,
        };
        router.push({ name: "order", query });
    },
    async acceptOrders({ getters, dispatch, commit }) {
        if (getters.selected_items.length == 0) {
            commit("PUSH_WARNING_TO_ARRAY", "no item selected", { root: true });
            return;
        }

        var resultado = await dispatch(
            "modal/ModalGeneric",
            {
                title: i18n.t("modals.bonus.accept"),
                text: "",
                icon: "success",
                confirmButtonColor: "#33cc33",
            },
            { root: true }
        );

        if (resultado) {
            var order_id_array = [];
            var filepath_array = [];
            var uploaded_array = [];
            var cnpj_array = [];
            const selected_items = getters.selected_items;
            const todos_os_itens = selected_items;

            selected_items.forEach((element) => {
                order_id_array.push(element.order_id);
                filepath_array.push(element.documento);
                uploaded_array.push(element.uploaded);
                cnpj_array.push(element.cnpj);
            });

            var obj_update = {
                order_id: order_id_array.join("; "),
                filepath: filepath_array.join("; "),
                uploaded: uploaded_array.join("; "),
                cnpj: cnpj_array.join("; "),
            };

            var response;
            var status_response;
            try {
                response = await bonus_service.accept_request(obj_update);
                status_response = response.data;
            } catch (error) {
                commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Falha ao aceitar os itens", {
                    root: true,
                });
                return;
            }

            if (status_response.status == "ok") {
                var bloqueados = [];
                var aprovados = [];
                status_response.items.forEach((item) => {
                    if (item.status == "BLOQUEADO") {
                        bloqueados.push(item);
                    } else aprovados.push(item);
                });

                if (aprovados.length == todos_os_itens.length) {
                    commit("PUSH_SUCCESS_TO_ARRAY", "All item accepted", { root: true });
                    dispatch("search");
                } else if (bloqueados.length == todos_os_itens.length) {
                    commit("PUSH_ERROR_TO_ARRAY", "all item blocked", { root: true });
                    dispatch("search");
                } else {
                    if (aprovados.length > 0)
                        commit(
                            "PUSH_SUCCESS_TO_ARRAY_WITH_PARAMETERS",
                            { message: "x itens accepted", value: aprovados.length },
                            { root: true }
                        );
                    if (bloqueados.length > 0)
                        commit(
                            "PUSH_ERROR_TO_ARRAY_WITH_PARAMETERS",
                            { message: "x itens blocked", value: bloqueados.length },
                            { root: true }
                        );
                }
            } else {
                commit("PUSH_ERROR_TO_ARRAY", "action failed", { root: true });
            }
        }
    },
    async rejectOrders({ getters, dispatch, commit }) {
        if (getters.selected_items.length == 0) {
            commit("PUSH_WARNING_TO_ARRAY", "no item selected", { root: true });
            return;
        }

        var resultado = await dispatch(
            "modal/ModalGeneric",
            {
                title: i18n.t("modals.bonus.reject"),
                text: "",
                icon: "error",
            },
            { root: true }
        );

        if (resultado) {
            var order_id_array = [];
            var filepath_array = [];
            var uploaded_array = [];
            var cnpj_array = [];
            const selected_items = getters.selected_items;
            const todos_os_itens = selected_items;

            selected_items.forEach((element) => {
                order_id_array.push(element.order_id);
                filepath_array.push(element.documento);
                uploaded_array.push(element.uploaded);
                cnpj_array.push(element.cnpj);
            });

            var obj_update = {
                order_id: order_id_array.join("; "),
                filepath: filepath_array.join("; "),
                uploaded: uploaded_array.join("; "),
                cnpj: cnpj_array.join("; "),
            };

            var response;
            var status_response;
            try {
                response = await bonus_service.reject_request(obj_update);
                status_response = response.data;
            } catch (error) {
                commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Falha ao rejeitar os itens", {
                    root: true,
                });
                return;
            }

            if (status_response.status == "ok") {
                var bloqueados = [];
                var rejeitados = [];
                status_response.items.forEach((item) => {
                    if (item.status == "BLOQUEADO") {
                        bloqueados.push(item);
                    } else rejeitados.push(item);
                });

                if (rejeitados.length == todos_os_itens.length) {
                    commit("PUSH_SUCCESS_TO_ARRAY", "All item rejected", { root: true });
                    dispatch("search");
                } else if (bloqueados.length == todos_os_itens.length) {
                    commit("PUSH_ERROR_TO_ARRAY", "all item blocked", { root: true });
                } else {
                    if (rejeitados.length > 0)
                        commit(
                            "PUSH_SUCCESS_TO_ARRAY_WITH_PARAMETERS",
                            { message: "x itens rejected", value: rejeitados.length },
                            { root: true }
                        );
                    if (bloqueados.length > 0)
                        commit(
                            "PUSH_ERROR_TO_ARRAY_WITH_PARAMETERS",
                            { message: "x itens blocked", value: bloqueados.length },
                            { root: true }
                        );
                }
                // this.pedidos.forEach((item) => {
                //     this.$set(item, '_rowVariant', '')
                //     var esta_bloqueado = bloqueados.find(s =>
                //         s.order_id == item.order_id && s.cnpj == item.cnpj && s.uploaded == item.uploaded && s.filepath == item.filepath
                //     );
                //     if(esta_bloqueado) {
                //         this.$set(item, '_rowVariant', 'danger-row');
                //     }
                // });
            } else {
                commit("PUSH_ERROR_TO_ARRAY", "action failed", { root: true });
            }
        }
    },
    // Data manipulation
    async getBonus({ commit, getters }) {
        commit("SET_LOADING", true);
        let bonus = [];
        let total_itens = 0;
        try {
            var filter = getters.filter_to_api;
            var response = await bonus_service.list(filter);
            var data_response = response.data;
            commit("UPDATE_DATE");
            bonus = data_response.slice(0, data_response.length - 1);
            total_itens = _.find(data_response, "total_items")["total_items"];
        } catch {
            bonus = [];
            total_itens = 0;
        }
        commit("SET_BONUS", bonus);
        commit("SET_TOTAL_ITENS", total_itens);
        commit("SET_LOADING", false);
    },
    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getBonus");
        commit("saveStatus", "success", { root: true });
    },
    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PER_PAGE", per_page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getBonus");
        commit("saveStatus", "success", { root: true });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
