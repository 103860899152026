<template>
    <div class="login">
        <div class="d-flex flex-column login-header mb-2">
            <img src="../../assets/img/logo_pedmais_cut.png" height="150" width="150" />
        </div>
        <div class="title-page-mfa">
            {{ $t("auth.mfa.check_acces") }}
        </div>
        <div class="card mfa-card">
            <div class="card-body">
                <p class="text-black-pedmais inside-card-text">
                    {{ $t("auth.mfa.verification_code") }}
                </p>
                <template v-if="method == 'qrcode'">
                    <div v-if="uri && uri != ''" class="qrcode-uri">
                        <QrcodeVue class="qrcode-component" :value="uri" level="H"></QrcodeVue>
                    </div>
                    <div v-else>
                        {{ $t("auth.mfa.check_the_code") }}
                    </div>
                </template>
                <template v-else>
                    {{ $t("auth.mfa.check_email") }}
                </template>

                <div class="message" v-if="error != ''">
                    <b-alert show variant="danger"> {{ $t(error) }} </b-alert>
                </div>
                <div class="message" v-if="message != ''">
                    <b-alert show variant="success"> {{ $t(`auth.mfa.${message}`) }} </b-alert>
                </div>

                <div>
                    <OTPInput
                        v-if="!loading"
                        @enter_pressed="SendButtonClicked"
                        @final_code="updateCode"
                    ></OTPInput>
                </div>
                <div class="my-1 send-code d-flex flex-column justify-content-center">
                    <b-button
                        :disabled="!checkCodeValidation || loading"
                        @click="SendButtonClicked"
                    >
                        {{ $t("auth.mfa.button_send_code") }}
                        <b-spinner small v-if="loading"></b-spinner>
                    </b-button>
                </div>

                <p class="text-black-pedmais">
                    {{ $t("auth.mfa.message_days_validity", { days: tf_login_validity }) }}
                </p>

                <div class="back-to-login">
                    <router-link class="card-body-link" to="/login">
                        <span>{{ $t("auth.mfa.back_to_login") }}</span>
                    </router-link>
                </div>

                <div class="mt-1 row align-items-center justify-content-center no-gutters">
                    <img src="../../assets/img/logo_weduu.png" width="100px" />
                </div>
            </div>
        </div>
        <div class="text-bellow-card">
            <p class="mt-1">
                <span v-if="seconds_count > 0">{{
                    $t("auth.mfa.countdown", { seconds: seconds_count })
                }}</span>
                <span v-else> {{ $t("auth.mfa.link_bellow") }} </span>
            </p>
            <div class="mb-2">
                <b-link v-if="seconds_count == 0" @click="resendCode()">{{
                    $t("auth.mfa.generate_new_code")
                }}</b-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { decrypt } from "@/helpers/encrypt.helper";
import QrcodeVue from "qrcode.vue";
import OTPInput from "./OTPInput.vue";

import { localStorageSet } from "@/helpers/storage.helper";

export default {
    name: "AppTwoFactor",
    components: {
        QrcodeVue,
        OTPInput,
    },
    data() {
        return {
            loading: false,
            verification_code: "",
            email: "",
            uri: "",
            url: "",
            tf_login_validity: 7,
            sent_date: new Date(),
            seconds_count: 60,
            interval_time: 60,
            interval: null,
            token: "",
            error: "",
            message: "",
            method: "",
        };
    },
    filters: {
        ocultEmail(value) {
            var aux = value.split("@");
            return value[0] + "*".repeat(aux[0].substr(1).length) + "@" + aux[1];
        },
    },
    computed: {
        email_sent() {
            return this.email;
        },
        counting() {
            return this.seconds_count > 0;
        },
        disable_resend_button() {
            return this.seconds_count != 0;
        },
        checkCodeValidation() {
            return this.verification_code && this.verification_code.length == 6;
        },
    },
    methods: {
        ...mapActions([
            "sendTwoFactorCode",
            "resendCodeTwoFactor",
            "consolidateUserLogin",
            "checkAccess",
        ]),
        ...mapMutations(["SET_TYPE_LOGGIN", "PUSH_ERROR_TO_ARRAY_PURE_TEXT", "DOWN_SPLASH_SCREEN"]),
        SendButtonClicked() {
            this.loading = true;
            this.sendTwoFactorCode({ code: this.verification_code, token: this.token })
                .then(async () => {
                    localStorageSet("type_loggin", "normal");
                    this.SET_TYPE_LOGGIN("normal");

                    await this.consolidateUserLogin(this.token);
                })
                .catch((err) => {
                    this.error = err.response.data.message;
                    this.loading = false;
                });
        },
        resendCode() {
            this.CountingTime();
            this.resendCodeTwoFactor(this.token)
                .then((result) => {
                    const res = result.data;
                    this.uri = res.uri;
                    this.interval = res.interval;
                    this.message = res.message.replace(".", "").trim();
                })
                .catch(() => {
                    this.PUSH_ERROR_TO_ARRAY_PURE_TEXT(this.$t("auth.mfa.error_resending_code"));
                });
        },
        async CountingTime() {
            this.seconds_count = this.interval_time;

            var timer = setInterval(() => {
                this.seconds_count -= 1;
                if (this.seconds_count == 0) clearInterval(timer);
            }, 1000);
        },
        checkIfEmailIsValid() {
            if (this.email_sent && this.email_sent != "") return true;
            return false;
        },
        updateCode(value) {
            this.verification_code = value;
            if (this.error != "") this.error = "";
            if (this.message != "") this.message = "";
        },
    },
    mounted() {
        const time_param = this.$route.query.interval;
        this.tf_login_validity = this.$route.query.tf_login_validity;
        this.interval_time = this.seconds_count = time_param >= 60 ? time_param : 10; // alterar para no minimo 60
        this.email = this.$route.query.email;
        this.method = this.$route.query.method;
        this.uri = decrypt(this.$route.query.uri);
        this.url = decrypt(this.$route.query.url);
        this.token = decrypt(this.$route.query.token);

        this.CountingTime();

        this.checkAccess();
        this.DOWN_SPLASH_SCREEN();
    },
};
</script>

<style lang="scss" scoped>
.qrcode-uri {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .qrcode-component {
        max-width: 200px;
    }
}
.title-page-mfa {
    font-weight: bolder;
    font-size: 18px;
    margin-bottom: 20px;
}

.inside-card-text {
    font-size: 14px;
}
.mfa-card {
    text-align: center;
    min-width: 350px;
    min-height: 400px;

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
.title-login-pedmais {
    font-size: 12px;
    font-weight: bolder;
}

.text-bellow-card {
    max-width: 350px;
}

.show-password {
    cursor: pointer;
}

.login {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    text-align: left;
    &-header {
        align-items: center;
        display: flex;
        flex-direction: row;
    }
}

.card {
    &-body {
        max-width: 400px;
        &-link {
            color: $black-color;
            text-decoration: none;
            transition: $transition;
            &:hover {
                color: $primary-color;
                cursor: pointer;
            }
        }
    }
}

.custom-switch {
    padding-right: 2.25rem;
}

.titulo-login {
    font-size: 14px;
}

a.disabled {
    pointer-events: none;
}
</style>
