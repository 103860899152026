import api from "@/services/api";
import { objectToURLQuery } from "@/helpers/http.helper";
// consultas

export const listTemplates = (params) => {
    return api()
        .get(`/list_templates?${objectToURLQuery(params)}`)
        .then((res) => res.data);
};

export const viewTemplate = (params) => {
    return api()
        .get(`/template_content?${objectToURLQuery(params)}`)
        .then((res) => res.data);
};

export const createTemplate = (params) => {
    return api()
        .post(`/create_json_template`, params)
        .then((res) => res.data);
};

export const deleteTemplate = (params) => {
    return api()
        .delete(`/delete_template?${objectToURLQuery(params)}`)
        .then((res) => res.data);
};

export const downloadTemplate = (params) => {
    return api()
        .get(`/download_template?${objectToURLQuery(params)}`)
        .then((res) => res.data);
};

export const uploadTemplate = (params) => {
    const form = new FormData();
    form.append("file", params.file);
    form.append("company_id", params.company_id);
    return api()
        .post(`/upload_template`, form)
        .then((res) => res.data);
};

export const loadTemplate = () => {
    return api()
        .get(`/load_templates`)
        .then((res) => res.data);
};
