import { clearStringAndUppercaseFirstLetter } from "./string.helper";
import { returnCopyOfObject } from "./object.helper";

export const sortArray = (array, sortFunction) => array.sort(sortFunction);

export const removeOneItemFromArray = (array, compareFunction) => {
    const index = array.findIndex(compareFunction);
    if (index === -1) return array;
    return array.splice(index, 1);
};

export const addItemToArray = (array, index, item) => {
    return array.splice(index, 0, item);
};

export const sortArraySelectOptionByText = (array) => {
    let aux = array;
    sortArray(aux, (a, b) => (a.text >= b.text ? 1 : -1));
    return aux;
};

export const filterTableColumnsByBackendResponse = (auxiliarArray, objectFilter) => {
    const fieldsToChange = Object.keys(objectFilter);
    if (!fieldsToChange.length) return auxiliarArray;

    const arrayTableColumns = returnCopyOfObject(auxiliarArray);

    fieldsToChange.forEach((field) => {
        if (objectFilter[field] != 0 && objectFilter[field] != 1) return;
        if (objectFilter[field] == 0) {
            removeOneItemFromArray(arrayTableColumns, (item) => item.key === field);
            return;
        }
        addItemToArray(arrayTableColumns, arrayTableColumns.length - 2, {
            key: field,
            label: clearStringAndUppercaseFirstLetter(field),
        });
        return;
    });
    return arrayTableColumns;
};

export const createIntegerArray = (numberOfElements) => [...Array(numberOfElements).keys()];
