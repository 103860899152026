import autocomplete_service from "@/services/api/autocomplete";

const initial_state = () => ({
    filter: {
        search: "",
    },

    loading: false,

    page: 0,

    autocomplete: [],
    total_items: [],
});
const state = initial_state();

const getters = {
    itemsDownloaded: (state) => state.autocomplete.length,
};

const mutations = {
    SET_LOADING: (state, payload) => (state.loading = payload),
    SET_AUTOCOMPLETE: (state, payload) => (state.autocomplete = payload),
    CLEAR_AUTOCOMPLETE: (state) => (state.autocomplete = []),
    SET_AUTOCOMPLETE_TOTAL_ITEMS: (state, payload) => (state.total_items = payload),
    SET_SEARCH_FILTER: (state, payload) => (state.filter.search = payload),
    SET_PAGE: (state, payload) => (state.page = payload),
};

const actions = {
    async getAutocomplete({ commit }) {
        commit("SET_LOADING", true);
        var autocomplete_itens = [];
        try {
            const autocomplete = await autocomplete_service.list();
            if (autocomplete.data != "") autocomplete_itens = autocomplete.data;
            else autocomplete_itens = [];
        } catch (error) {
            throw error;
        }
        commit("SET_AUTOCOMPLETE", autocomplete_itens);
        commit("SET_LOADING", false);
    },

    getAutocompleteAndReturn({ state }, { cnpj, order_id }) {
        return autocomplete_service
            .find({
                cnpj,
                order_id,
                offset: state.page,
                search: state.filter.search,
            })
            .then((res) => res.data);
    },

    async getAutocompleteWithCNPJ({ commit, dispatch }, params) {
        commit("saveStatus", "loading", { root: true });
        commit("SET_PAGE", 0);

        try {
            const response = await dispatch("getAutocompleteAndReturn", params);

            const autocompleteList = response.items;
            const totalItemsAutocomplete = response.total_items;

            commit("SET_AUTOCOMPLETE", autocompleteList);
            commit("SET_AUTOCOMPLETE_TOTAL_ITEMS", totalItemsAutocomplete || 0);
        } catch (error) {
            commit("SET_AUTOCOMPLETE", []);
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao buscar o autocomplete!", {
                root: true,
            });
        }

        commit("saveStatus", "success", { root: true });
    },

    async nextPageAutocompleteWithCNPJ({ state, commit, dispatch }, params) {
        if (state.loading || state.autocomplete.length >= state.total_items) return;
        commit("SET_LOADING", true);
        try {
            const nextPage = state.page + 1;
            commit("SET_PAGE", nextPage);

            const response = await dispatch("getAutocompleteAndReturn", params);

            const aux = state.autocomplete;
            const newArrayAutocomplete = aux.concat(response.items);
            commit("SET_AUTOCOMPLETE", newArrayAutocomplete);
        } catch (error) {
            console.error(error);
        }
        commit("SET_LOADING", false);
    },

    resetData({ commit }) {
        commit("CLEAR_AUTOCOMPLETE");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
