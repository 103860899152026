import dashboard_service from "@/services/api/dashboard";

const initial_state = () => ({
    loading: false,

    // Valores dos cards
    order: null,
    supply: null,
    curator: null,
    dictionary: null,
    sku: null,
    logs: null,

    pagamento_total: null,
    pagamento_pendente: null,
    pagamento_processado: null,

    cards_to_be_shown: [
        "upload",
        "order",
        "detalhamento",
        "curator",
        "sku",
        "logs",
        "pagamento_module",
    ],
});

const state = initial_state();

const getters = {
    loading: (state) => state.loading,
    order: (state) => state.order,
    supply: (state) => state.supply,
    curator: (state) => state.curator,
    dictionary: (state) => state.dictionary,
    sku: (state) => state.sku,
    logs: (state) => state.logs,

    pagamento_total: (state) => state.pagamento_total,
    pagamento_pendente: (state) => state.pagamento_pendente,
    pagamento_processado: (state) => state.pagamento_processado,

    cards_to_be_shown(state, getters, rootState, rootGetters) {
        var role = rootGetters.role;
        var aux = Array().concat(state.cards_to_be_shown);
        if (role >= 6) {
            aux.splice(0, 0, "dictionary");
        }
        return aux;
    },
};

const mutations = {
    SET_LOADING(state, value) {
        state.loading = value;
    },
    SET_FIELD(state, { field, value }) {
        state[field] = value;
    },
    RESET_DATA: (state) => {
        Object.assign(state, initial_state());
    },
    SET_PAGAMENTO_DATA: (state, params) => {
        state.pagamento_total = params.total;
        state.pagamento_pendente = params.pendentes;
        state.pagamento_processado = params.processados;
    },
};

const actions = {
    async getDashboardData({ commit }) {
        try {
            commit("SET_LOADING", true);
            var dashboard_cards_data = dashboard_service.cards_request();
            var pagamento_data = dashboard_service.pagamento_resume();

            dashboard_cards_data.then((data) => {
                var aux = data.data.cards;
                commit("SET_FIELD", { field: "order", value: aux.order });
                commit("SET_FIELD", { field: "supply", value: aux.supply });
                commit("SET_FIELD", { field: "curator", value: aux.curator });
                commit("SET_FIELD", { field: "dictionary", value: aux.dictionary });
                commit("SET_FIELD", { field: "sku", value: aux.eans });
                commit("SET_FIELD", { field: "logs", value: aux.logs });
                commit("SET_LOADING", false);
            });
            pagamento_data.then((data) => {
                commit("SET_PAGAMENTO_DATA", data.data);
            });
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Error", { root: true });
            commit("SET_LOADING", false);
        }
    },
    resetDashboardStore({ commit }) {
        commit("RESET_DATA");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
