import i18n from "@/plugins/i18n"; // plugin para internacionalização em vue.js|faz a tradução
import product_service from "@/services/api/products";
// import exportFile from "@/helpers/export-xlsx-file";
// import DatesHelper from "@/helpers/dates-helper";
import { GetTodayDateTimeString } from "@/helpers/date.helper";
import { exportFileXLSX } from "@/helpers/files.helper";
import { productsTableColumns } from "@/configs/table.columns";

const initial_state = () => ({
    loading: false,

    empresa_id: "",
    cd_sku: "",
    ds_sku: "",
    cd_ean: "",
    bu: "",
    bk_sku: "",
    status: "",
    single_filter: "",
    units: "",

    sku: [],

    pagination: {
        page: 1,
        perPage: 10,
        total_items: 0,
    },

    table_fields: productsTableColumns,

    // Variável para exportação
    modal_export_produto: false,

    // Variaveis da página de edição de sku
    operation_type: "new",
    loading_search_sku: false,
    cod_sku_already_exists: false,
    cod_pesquisado: false,
});
const state = initial_state();

const getters = {
    loading: (state) => state.loading,

    loading_search_sku: (state) => state.loading_search_sku,
    cod_sku_already_exists: (state) => state.cod_sku_already_exists,
    cod_pesquisado: (state) => state.cod_pesquisado,
    operation_type: (state) => state.operation_type,

    sku: (state) => state.sku,
    modal_export_produto: (state) => state.modal_export_produto,

    // Filtro
    empresa_id: (state) => state.empresa_id,
    status: (state) => state.status,

    //filtro único
    single_filter: (state) => state.single_filter,
    units: (state) => state.units,

    // Paginação
    total_items: (state) => state.pagination.total_items,
    current_page: (state) => state.pagination.page,
    per_page: (state) => state.pagination.perPage,
    // Table
    table_fields: (state, getters, rootState, rootGetters) => {
        var aux = [];
        if (rootGetters.role >= 6) {
            aux = [
                {
                    key: "empresa_id",
                    label: "pages.products.table_labels.company_name",
                    sortable: false,
                },
            ];
        }
        aux = aux.concat(state.table_fields);
        return aux.map((item) => ({ ...item, label: i18n.tc(item.label) }));
    },
    filter_to_api: (state) => ({
        offset: state.pagination.page - 1,
        per_page: state.pagination.perPage,
        single_filter: state.single_filter,
        empresa_id: state.empresa_id,
        status: state.status,
    }),
};

const mutations = {
    SET_LOADING_TRUE(state) {
        state.loading = true;
    },
    SET_LOADING_FALSE(state) {
        state.loading = false;
    },

    SET_LOADING_SEARCH_SKU: (state, payload) => (state.loading_search_sku = payload),
    SET_STATUS_SKU_CODE_EXISTS: (state, payload) => (state.cod_sku_already_exists = payload),
    SET_STATUS_CODE_SEARCHED: (state, payload) => (state.cod_pesquisado = payload),
    SET_OPERATION_TYPE_NEW: (state) => (state.operation_type = "new"),
    SET_OPERATION_TYPE_EDIT: (state) => (state.operation_type = "edit"),

    // FILTROS
    SET_EMPRESA_ID: (state, id) => (state.empresa_id = id),
    // SET_CATEGORIA: (state, categoria) => (state.categoria = categoria),

    SET_CD_SKU: (state, cod) => (state.cd_sku = cod),
    SET_DS_SKU: (state, ds) => (state.ds_sku = ds),
    SET_EAN: (state, ean) => (state.cd_ean = ean),
    SET_BU: (state, buu) => (state.bu = buu),
    SET_BK_SKU: (state, bksku) => (state.bk_sku = bksku),
    SET_STATUS: (state, status) => (state.status = status),

    // Mutantion para o filtro único
    SET_SINGLE_FILTER: (state, filter) => (state.single_filter = filter),
    SET_UNITS: (state, units) => (state.units = units),

    // mutantions para o modal de export
    OPEN_MODAL_EXPORT_PRODUTO: (state) => (state.modal_export_produto = true),
    CLOSE_MODAL_EXPORT_PRODUTO: (state) => (state.modal_export_produto = false),

    SET_SELECT_MULTIPLE: (state, status) => (state.all_selected = status),

    SET_SKUS(state, skus) {
        state.sku = skus;
    },
    SET_TOTAL_ITEMS(state, total_items) {
        state.pagination.total_items = total_items;
    },
    SET_PAGE(state, page) {
        state.pagination.page = page;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },
    RESET_FILTER(state) {
        state.empresa_id = "";
        state.status = "";
        state.unique_filter = "";
        state.units = "";

        state.pagination.page = 1;
    },
    RESET_DATA: (state) => Object.assign(state, initial_state()),

    CLEAR_SKU_EDIT_PAGE_DATA: (state) => {
        state.loading_search_sku = false;
        state.cod_sku_already_exists = false;
        state.cod_pesquisado = false;
    },
};

const actions = {
    async openModalExportProduto({ commit, dispatch }) {
        await dispatch("getSKUS");
        commit("OPEN_MODAL_EXPORT_PRODUTO");
    },
    async getSKUS({ commit, getters }) {
        commit("SET_LOADING_TRUE");
        let logs_items = [];
        let total_items = 0;
        try {
            const filter = getters.filter_to_api;
            const res = await product_service.find(filter);
            const response = res.data;
            logs_items = response.slice(0, response.length - 1);
            total_items = _.find(response, "total_items")["total_items"];
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", i18n.t("action failed"), {
                root: true,
            });
        }
        commit("SET_SKUS", logs_items);
        commit("SET_TOTAL_ITEMS", total_items);
        commit("SET_LOADING_FALSE");
    },
    async dowloadLines({ getters, commit }) {
        try {
            const response = await product_service.export_table(getters.filter_to_api);

            const todayStringAndFilename = `${GetTodayDateTimeString({ seconds: true })}
            ${i18n.tc("pages.products.detailing.filename_export")}`;

            exportFileXLSX(response.data, todayStringAndFilename);

            commit("CLOSE_MODAL_EXPORT_PRODUTO");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Falha ao baixar documento", { root: true });
        }
    },

    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getSKUS");
        commit("saveStatus", "success", { root: true });
    },

    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PAGE", 1);
        commit("SET_PER_PAGE", per_page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getSKUS");
        commit("saveStatus", "success", { root: true });
    },

    async search({ commit, dispatch }) {
        commit("SET_PAGE", 1);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getSKUS");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    async clearFilter({ commit, dispatch }) {
        commit("RESET_FILTER");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getSKUS");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    closeFilterSidebar({ commit }) {
        commit("SET_RIGHT_SIDEBAR", false, { root: true });
    },

    async checkIfSKUExists({ commit }, { sku_code, company_code }) {
        commit("SET_LOADING_SEARCH_SKU", true);
        commit("SET_STATUS_SKU_CODE_EXISTS", false);
        try {
            const response = await product_service.find_sku({
                cd_sku: sku_code,
                empresa_id: company_code,
            });
            const skus_found = response.data;
            if (skus_found.length > 1) commit("SET_STATUS_SKU_CODE_EXISTS", true);
            commit("SET_STATUS_CODE_SEARCHED", true);
        } catch {
            commit("SET_STATUS_SKU_CODE_EXISTS", true);
            commit("PUSH_ERROR_TO_ARRAY", "action failed", { root: true });
        }
        commit("SET_LOADING_SEARCH_SKU", false);
    },

    async getSKUObject({ commit }, { cd_sku, empresa_id }) {
        commit("saveStatus", "loading", { root: true });
        try {
            const response = await product_service.find_sku({ cd_sku, empresa_id });
            const skus = response.data;
            commit("saveStatus", "success", { root: true });
            if (skus.length <= 1) throw "none sku found";
            else return skus[0];
        } catch {
            commit("PUSH_ERROR_TO_ARRAY", "action failed", { root: true });
            commit("saveStatus", "success", { root: true });
            return "error";
        }
    },
    async clearDataFromStore({ commit }) {
        commit("RESET_DATA");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
