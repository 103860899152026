import api from "@/services/api";

export default {
    monitoramento(data) {
        var query = Object.keys(data).map(item => `${item}=${data[item]}`);
        return api().get("/docsubmission/list_files?" + query.join("&"));
    },
    delete_file(data) {
        const form = new FormData();
        form.append("file_id", data.id);
        return api().post("/docsubmission/list_files/delete", form);
    },
    analytics(data) {
        var filtered_obj = Object.keys(data).filter(item => data[item] !== "");
        var query = filtered_obj.map(item => `${item}=${data[item]}`);
        return api().get("/docsubmission/analytics?" + query.join("&"));
    },
    export_table(data) {
        var filtered_obj = Object.keys(data).filter(item => data[item] !== "");
        var query = filtered_obj.map(item => `${item}=${data[item]}`);
        return api().post("/docsubmission/export_file?" + query.join("&"), null, {
            responseType: "blob",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "*"
            }
        });
    },
    get_doc(data) {
        const form = new FormData();
        form.append("timestamp", data.timestamp);
        form.append("filepath", data.filepath);
        return api().post("/docsubmission/download", form, {
            responseType: "arraybuffer",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/pdf"
            }
        });
    }
};
