import i18n from "@/plugins/i18n";
import users_service from "@/services/api/users";
import { usersTableColumns } from "@/configs/table.columns";

const initial_state = () => ({
    loading: false,

    empresa_id: "",
    name: "",
    client_email: "",
    role_id: "",
    valid_users: "",

    users: [],

    pagination: {
        page: 1,
        perPage: 10,
        total_items: 0,
    },

    table_fields: usersTableColumns,
});
const state = initial_state();

const getters = {
    loading: (state) => state.loading,

    // Getters do filtro
    empresa_id: (state) => state.empresa_id,
    name: (state) => state.name,
    client_email: (state) => state.client_email,
    role_id: (state) => state.role_id,
    valid_user: (state) => state.valid_users,

    users: (state) => state.users,
    total_items: (state) => state.pagination.total_items,
    current_page: (state) => state.pagination.page,
    per_page: (state) => state.pagination.perPage,

    table_fields: (state) => {
        return state.table_fields.map((item) => ({ ...item, label: i18n.tc(item.label) }));
    },

    filter_to_api: (state) => ({
        offset: state.pagination.page - 1,
        per_page: state.pagination.perPage,
        name: state.name,
        client_email: state.client_email,
        role_id: state.role_id,
        empresa_id: state.empresa_id,
        valid_user: state.valid_users,
    }),
};

const mutations = {
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_EMPRESA_ID(state, id) {
        state.empresa_id = id;
    },
    SET_NAME(state, name) {
        state.name = name;
    },
    SET_CLIENT_EMAIL(state, client_email) {
        state.client_email = client_email;
    },
    SET_ROLE_ID(state, role_id) {
        state.role_id = role_id;
    },
    SET_VALID_USERS(state, valid_users) {
        state.valid_users = valid_users;
    },
    SET_USERS(state, users) {
        state.users = users;
    },
    SET_TOTAL_ITEMS(state, total_items) {
        state.pagination.total_items = total_items;
    },
    SET_PAGE(state, page) {
        state.pagination.page = page;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },
    RESET_FILTER(state) {
        state.empresa_id = "";
        state.name = "";
        state.client_email = "";
        state.role_id = "";
        state.valid_users = "";
        state.pagination.page = 1;
    },
    RESET_DATA: (state) => {
        Object.assign(state, initial_state());
    },
};

const actions = {
    async getUsers({ commit, getters }) {
        commit("SET_LOADING", true);
        try {
            const filter = getters.filter_to_api;
            const res = await users_service.list(filter);
            const response = res.data;

            const logs_items = response.slice(0, response.length - 1);
            commit("SET_USERS", logs_items);
            var total_items = 0;
            try {
                total_items = _.find(response, "total_items")["total_items"];
            } catch {
                total_items = 0;
            }
            commit("SET_TOTAL_ITEMS", total_items);
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", i18n.t("action failed"), {
                root: true,
            });
        }
        commit("SET_LOADING", false);
    },

    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getUsers");
        commit("saveStatus", "success", { root: true });
    },

    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PER_PAGE", per_page);
        commit("SET_PAGE", 1);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getUsers");
        commit("saveStatus", "success", { root: true });
    },

    async search({ commit, dispatch }) {
        commit("SET_PAGE", 1);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getUsers");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    async clearFilter({ commit, dispatch }) {
        commit("RESET_FILTER");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getUsers");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    async LoginWithSearchedUser({ dispatch }, payload) {
        const modal = {
            title: i18n.tc("modals.users.login_with_users.title"),
            text: i18n.t("modals.users.login_with_users.text", {
                email: payload.item.client_email,
            }),
            icon: "warning",
        };
        const confirm = await dispatch("modal/ModalGeneric", modal, { root: true });

        if (confirm) {
            await dispatch(
                "login_as_admin",
                { email: payload.item.client_email, router: payload.router },
                { root: true }
            );
        }
    },

    closeFilterSidebar({ commit }) {
        commit("SET_RIGHT_SIDEBAR", false, { root: true });
    },
    resetUsersStore({ commit }) {
        commit("RESET_DATA");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
