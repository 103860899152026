export const returnCopyOfObject = function (obj) {
    return JSON.parse(JSON.stringify(obj));
};

export const areFieldsOfTwoObjectsEqual = function (obj1, obj2, arrayOfFields) {
    const arrayOfBoolean = arrayOfFields.map((field) => obj1[field] === obj2[field]);
    const checkIfThereIsAnyFalse = arrayOfBoolean.some((item) => !item);
    return !checkIfThereIsAnyFalse;
};

export const countItensInLeafOfNestedObject = function (nestedObject) {
    if (nestedObject instanceof Object && nestedObject instanceof Array) {
        return nestedObject.length;
    }

    const listOfKeys = Object.keys(nestedObject);
    return listOfKeys.reduce(
        (pv, cur) => pv + countItensInLeafOfNestedObject(nestedObject[cur]),
        0
    );
};

export const removeEmptyFields = (obj) => {
    let newObj = {};

    Object.keys(obj).forEach((key) => {
        if (obj[key] === "" || obj[key] === null || obj[key] === undefined) return;
        if (obj[key] instanceof Object && obj[key] instanceof Array && !obj[key].length) return;

        newObj[key] = obj[key];
    });

    return newObj;
};
