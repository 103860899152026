import api from '@/services/api'

export default {
    list (data) {
        const form = new FormData()

        form.append('limpar','')
        form.append('offset', data.offset)
        form.append('per_page', data.limit ? data.limit : 10)

        return api().post('/dicionario-show', form)
    },
    find (data) {        
        const form = new FormData()

        form.append('buscar', '')
        form.append('search_de', data.from)
        form.append('search_para', data.to)
        form.append('empresa_id', data.empresa_id)
        form.append('offset', data.offset)
        form.append('per_page', data.limit ? data.limit : 10)

        return api().post('/dicionario-show', form)
    }
}