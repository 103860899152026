/* eslint-disable no-unused-vars */
// import i18n from "@/plugins/i18n";
import app from "@/main.js";

import {
    getSoldSkus,
    getProducts,
    getIndicators,
    getOrderDetail,
    getOpenCarts,
    getReceiveds,
    getOrders,
    getSuggestion,
    notifyClients,
    changeSuggestionStatus,
    deleteProductInSuggestion,
} from "@/services/api/central-de-comando.js";

import router from "@/router/index.js";

import { GetTodayDate } from "@/helpers/date.helper";

const initial_state = () => ({
    loadingOrders: false,
    loadingSuggestions: false,
    loadingReceiveds: false,
    loadingOpenCarts: false,
    indicators: {},
    openCarts: [],
    suggestionsOfClientOfRoute: [],
    receiveds: [],
    skusSold: [],
    orders: [],
    suggestions: [],
    orderDetails: {},
    products: [],
    filters: {
        search: "",
        date: "", //GetTodayDate(),
        channel: [],
        status: [],
    },
    productDetails: {},
});

const state = initial_state();

const getters = {
    indicators: (state) => state.indicators,
    openCarts: (state) => state.openCarts,
    receiveds: (state) => state.receiveds,
    skusSold: (state) => state.skusSold,
    loadingOrders: (state) => state.loadingOrders,
    loadingSuggestions: (state) => state.loadingSuggestions,
    loadingReceiveds: (state) => state.loadingReceiveds,
    loadingOpenCarts: (state) => state.loadingOpenCarts,
    orderDetails: (state) => state.orderDetails,
    searchFilter: (state) => state.filters.search,
    dateFilter: (state) => state.filters.date,
    channelFilter: (state) => state.filters.channel,
    statusFilter: (state) => state.filters.status,
    products: (state) => state.products,
    productDetails: (state) => state.productDetails,
    orders: (state) => state.orders,
    suggestions: (state) => state.suggestions,
};

const mutations = {
    SET_INDICATORS(state, value) {
        state.indicators = value;
    },
    SET_OPEN_CARTS(state, value) {
        state.openCarts = value;
    },
    SET_RECEIVEDS(state, value) {
        state.receiveds = value;
    },
    SET_SKUS_SOLD(state, value) {
        state.skusSold = value;
    },
    SET_LOADING_ORDERS(state, value) {
        state.loadingOrders = value;
    },
    SET_LOADING_SUGGESTIONS(state, value) {
        state.loadingSuggestions = value;
    },
    SET_LOADING_RECEIVEDS(state, value) {
        state.loadingReceiveds = value;
    },
    SET_LOADING_OPEN_CARTS(state, value) {
        state.loadingOpenCarts = value;
    },
    SET_ORDER_DETAILS(state, value) {
        state.orderDetails = value;
    },
    SET_SEARCH_FILTER(state, value) {
        state.filters.search = value;
    },
    SET_DATE_FILTER(state, value) {
        state.filters.date = value;
    },
    SET_CHANNEL_FILTER(state, value) {
        state.filters.channel = value;
    },
    SET_STATUS_FILTER(state, value) {
        state.filters.status = value;
    },
    SET_PRODUCTS(state, value) {
        state.products = value;
    },
    SET_PRODUCT_DETAILS(state, value) {
        state.productDetails = value;
    },
    SET_ORDERS(state, value) {
        state.orders = value;
    },
    SET_SUGGESTIONS(state, value) {
        state.suggestions = value;
    },
};

const actions = {
    openCalendar({ commit }) {
        //
    },
    async getData({ dispatch }) {
        switch (app.$route.name) {
            case "CalendarCentral":
                break;
            case "Indicadores":
                dispatch("getIndicators");
                break;
            case "RotaDoDia":
                dispatch("getOrders");
                dispatch("getSuggestion");
                break;
            case "Recebidos":
                dispatch("getReceiveds");
                break;
            case "Carteira":
                dispatch("getOpenCarts");
                break;
            case "SKUsVendidos":
                dispatch("getSKUsSold");
                break;
        }
    },
    async getIndicators({ state, commit }) {
        const filter = state.filters;
        const indicators = await getIndicators(filter);
        commit("SET_INDICATORS", indicators);
    },
    async notifyClients({ commit, dispatch }) {
        const params = {
            title: "Confirma a notificação de todos os clientes com o carrinho aprovado?",
            text: "", // subtitulo se necessário,
            icon: "warning",
        };

        const result = await dispatch("modal/ModalGeneric", params, { root: true });

        if (result) {
            notifyClients().then(() => {
                dispatch("getOpenCarts");
            });
        }
    },
    reload({ commit }) {
        commit("SET_LOADING", true);
    },
    addOrderToRoute({ commit }) {
        //
    },
    async research({ commit }, payload) {},
    async getOpenCarts({ state, commit }) {
        commit("SET_LOADING_OPEN_CARTS", true);
        const filter = state.filters;
        const { items } = await getOpenCarts(filter);
        commit("SET_OPEN_CARTS", items);
        commit("SET_LOADING_OPEN_CARTS", false);
    },
    async getReceiveds({ commit }) {
        commit("SET_LOADING_RECEIVEDS", true);
        const filter = state.filters;

        const { items } = await getReceiveds(filter);
        commit("SET_RECEIVEDS", items);
        commit("SET_LOADING_RECEIVEDS", false);
    },
    async getSKUsSold({ commit }) {
        const filter = state.filters;

        const { items } = await getSoldSkus(filter);
        commit("SET_SKUS_SOLD", items);
    },
    async getOrderDetails({ commit }, payload) {
        const order = await getOrderDetail(payload);
        commit("SET_ORDER_DETAILS", order);
    },
    async getProducts({ commit }) {
        const { items } = await getProducts();
        commit("SET_PRODUCTS", items);
    },
    async getOrders({ commit }) {
        commit("SET_LOADING_ORDERS", true);
        const filter = state.filters;

        const { items } = await getOrders(filter);
        commit("SET_ORDERS", items);
        commit("SET_LOADING_ORDERS", false);
    },
    async getSuggestion({ state, commit }) {
        commit("SET_LOADING_SUGGESTIONS", true);
        const filter = state.filters;

        const { items } = await getSuggestion(filter);
        commit("SET_SUGGESTIONS", items);
        commit("SET_LOADING_SUGGESTIONS", false);
    },
    async changeSuggestionStatus({ commit, dispatch }, payload) {
        const { id, status } = payload;

        const statusValues = {
            approve: "aprovar",
            reject: "rejeitar",
        };

        const params = {
            title: `Tem certeza que deseja ${statusValues[status]} a sugestão?`,
            text: "", // subtitulo se necessário,
            icon: "warning",
        };

        const result = await dispatch("modal/ModalGeneric", params, { root: true });

        if (result) {
            await changeSuggestionStatus(id, status);
            dispatch("getSuggestion");
        }
    },
    async deleteProductInSuggestions({ commit, dispatch }, payload) {
        const { suggestionId, productId } = payload;

        const params = {
            title: "Tem certeza que deseja excluir o produto da sugestão?",
            text: "", // subtitulo se necessário,
            icon: "warning",
        };

        const result = await dispatch("modal/ModalGeneric", params, { root: true });

        if (result) {
            await deleteProductInSuggestion(suggestionId, productId);
            dispatch("getSuggestion");
        }
    },
    async pullToRefreshAction({ commit }) {
        let pull = false;
        const pullToRefresh = document.querySelector(".pull-to-refresh");
        const navbar = document.querySelector(".navbar-custom");
        let touchstartY = 0;
        document.addEventListener("touchstart", (e) => {
            touchstartY = e.touches[0].clientY;
        });
        document.addEventListener("touchmove", (e) => {
            const touchY = e.touches[0].clientY;
            const touchDiff = touchY - touchstartY;
            if (touchDiff > 0 && window.scrollY === 0) {
                pull = true;
                pullToRefresh.style = `top: 0;`;
                navbar.style = `top: 50px;`;
                e.preventDefault();
            }
        });
        document.addEventListener("touchend", (e) => {
            if (pull) {
                pull = false;
                navbar.style = `top: 0;`;
                pullToRefresh.style = `top: -50px`;
                location.reload();
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
