import pedidos_service, {
    addItemCheckout,
    editItemCheckout,
    deleteItemFromOrder,
} from "@/services/api/pedidos";
import i18n from "@/plugins/i18n";
import router from "@/router";
import { exportFileXLSX } from "@/helpers/files.helper";

import { cardCheckoutTableColumns } from "@/configs/table.columns";
// Helpers
import {
    addItemToArrayOfCardHeader,
    updateDates,
    updatePrices,
    updateReserva,
    apiCallWithGlobalLoading,
    multipleRequestApiCall,
    returnFirstItemOfCardOrder,
    updateMultipleWithReplyDays,
} from "@/helpers/pedidos.helper";
import { secondsToString } from "@/helpers/time.helper";
import { delayedPromise } from "@/helpers/promises.helper";
import { doubleDigitsAlways } from "@/helpers/string.helper";
import { sortArraySelectOptionByText } from "@/helpers/array.helper";

const initial_state = () => ({
    loading: false,
    loading_export: false,
    process_loading: false,

    loading_approve_reject: [],

    status_process_loading: null,
    toggle_cards: false,

    draft_mode: false,

    list_of_filter_status: ["Checked", "Pendente", "Processando", "Processado"],

    restrictive_status: ["Processado", "Processando"],

    advanced_order_company_list: [],
    payment_list: [], // params

    date: new Date(),
    last_update_date: new Date(),
    update_keys_with_timestamp: new Date(),

    close_all_cards: false,

    order_processing_data: {
        in_processing: false,
        id: null,
        filename: null,
    },

    order_processing_progress_status: {
        id: null,
        total: 0,
        nok: 0,
        message: null,
    },

    id: "",
    filename: "",
    uuid: "",

    today: "",
    clientes: "",
    moeda: "",
    always_show_logs: null,
    vl_total: "",
    qty_total: 0,

    // Filtros
    cnpj: "",
    item: "",
    num_order: "",
    log: "",
    status: "",
    show_accept_reject_buttons: false,

    cond_pagto_global: "",

    filter_log_options: [{ value: "", text: "Log ..." }],
    filter_cnpj_options: [],

    pedido: [],
    grouped_items: [],

    flAlimentosPreparados: false,

    total_items: 0,

    table_fields: cardCheckoutTableColumns,
    input_fields_edition: [
        {
            key: "dt_entrega",
            mass_edition: true,
            mass_edition_by: "value", // value or sku
            placeholder: i18n.t("dt_entrega"),
            required: false,
            scope: ["item"],
            type: "date",
        },
    ],
    items_optional_fields: [],
});
const state = initial_state();

const getters = {
    loading(state) {
        return state.loading || state.loading_approve_reject.length > 0;
    },

    loading_export(state) {
        return state.loading_export;
    },

    isFileProcessing(state) {
        // return state.grouped_items.some((item) => item.status_card == "Processando");
        return false;
    },

    isPaymentListNotEmpty: (state) => state.payment_list.length > 0,

    last_update_date_raw: (state) => state.last_update_date.getTime(),
    last_update_date: (state) => {
        var aux = state.last_update_date;
        return `${doubleDigitsAlways(aux.getDate())}/${doubleDigitsAlways(
            aux.getMonth() + 1
        )}/${doubleDigitsAlways(aux.getFullYear())} ${doubleDigitsAlways(
            aux.getHours()
        )}:${doubleDigitsAlways(aux.getMinutes())}:${doubleDigitsAlways(aux.getSeconds())}`;
    },
    update_keys_with_timestamp: (state) => state.update_keys_with_timestamp.getTime(),

    advanced_order_root: (state, getters, rootState, rootGetters) => {
        return rootGetters["advanced_order"];
    },
    flAlimentosPreparados: (state) => state.flAlimentosPreparados,
    advanced_order_company_list: (state) => state.advanced_order_company_list,
    input_fields_edition: (state) => {
        return state.input_fields_edition;
    },
    items_optional_fields: (state) => state.items_optional_fields,

    thereIsGlobalScope: (state) => {
        return state.input_fields_edition.some(
            (item) => item.scope && item.scope.includes("global")
        );
    },
    thereIsCardScope: (state) =>
        state.input_fields_edition.some((item) => item.scope && item.scope.includes("card")),
    thereIsItemScope: (state) =>
        state.input_fields_edition.some((item) => item.scope && item.scope.includes("item")),

    loading_approve_reject: (state) => state.loading_approve_reject,

    process_loading: (state) => state.process_loading,
    status_process_loading: (state) => state.status_process_loading,
    show_accept_reject_buttons: (state) => state.show_accept_reject_buttons,
    restrictive_status: (state) => state.restrictive_status,
    list_of_filter_status: (state) => state.list_of_filter_status,

    draft_mode: (state) => state.draft_mode,

    order_processing_data: (state) => state.order_processing_data,
    order_processing_progress_status: (state) => state.order_processing_progress_status,

    timestamp: (state) => state.date.getTime(),

    cards_state: (state) => state.toggle_cards,
    close_all_cards: (state) => state.close_all_cards,

    vl_total: (state) => state.vl_total,
    always_show_logs: (state) => state.always_show_logs,
    qty_total: (state) => state.qty_total,
    total_order: (state) => {
        var aux = state.pedido.reduce((p, a) => {
            return p + a.total;
        }, 0);
        return aux;
    },

    grouped_items: (state) => state.grouped_items,
    all_cards: (state) =>
        state.grouped_items.map((item) => {
            return {
                cnpj: item.cnpj,
                num_pedido: item.num_pedido,
            };
        }),
    all_items: (state) => state.pedido,
    total_items: (state) => state.total_items,

    id: (state) => state.id,
    filename: (state) => state.filename,

    today: (state) => state.today,
    clientes: (state) => state.clientes,
    moeda: (state) => state.moeda,

    // Filtros
    cnpj: (state) => state.cnpj,
    item: (state) => state.item,
    log: (state) => state.log,
    num_order: (state) => state.num_order,
    status: (state) => state.status,

    filter_log_options: (state) => state.filter_log_options,
    filter_cnpj_options: (state) => state.filter_cnpj_options,

    table_fields: (state) =>
        state.table_fields.map((item) => ({ ...item, label: i18n.t(item.label) })),

    filter_to_api: (state) => ({
        id: state.id,
        filename: state.filename,
        uuid_list: state.uuid,
        cnpj: state.cnpj,
        item: state.item,
        log: state.log,
        status: state.status,
    }),
};

const mutations = {
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_LOADING_EXPORT(state, status) {
        state.loading_export = status;
    },
    SET_PROCESS_LOADING(state, status) {
        state.process_loading = status;
    },

    START_LOADING_APPROVE_REJECT: (state, params) => {
        state.loading_approve_reject.push({ num_pedido: params.num_pedido, cnpj: params.cnpj });
    },

    STOP_LOADING_APPROVE_REJECT: (state, params) => {
        const index = state.loading_approve_reject.indexOf(
            state.loading_approve_reject.filter(
                (item) => item.num_pedido == params.num_pedido && item.cnpj == params.cnpj
            )[0]
        );
        state.loading_approve_reject.splice(index, 1);
    },

    SET_DRAFT_MODE: (state, value) => (state.draft_mode = value),

    SET_ORDER_PROCESSING_DATA_IN_PROGRESS: (state, params) => {
        state.order_processing_data.in_processing = true;
        state.order_processing_data.id = params.id;
        state.order_processing_data.filename = params.filename;
    },

    SET_ORDER_PROCESSING_PROGRESS_STATUS: (state, payload) =>
        (state.order_processing_progress_status = payload),

    CLEAR_ORDER_PROCESSING_PROGRESS_STATUS: (state) =>
        (state.order_processing_progress_status = {
            id: null,
            total: 0,
            nok: 0,
        }),

    CLEAR_ORDER_PROCESSING_DATA: (state) => {
        state.order_processing_data.in_processing = false;
        state.order_processing_data.id = null;
        state.order_processing_data.filename = null;
    },

    SET_ADVANCED_ORDER_COMPANY_LIST: (state, payload) =>
        (state.advanced_order_company_list = payload),

    SET_INPUT_FIELDS_EDITION: (state, payload) => (state.input_fields_edition = payload),
    SET_ITEM_OPTIONAL_FIELDS: (state, payload) => (state.items_optional_fields = payload),

    SET_PAYMENT_LIST: (state, payload) =>
        (state.payment_list = sortArraySelectOptionByText(payload)),

    SET_COND_PAGTO: (state, payload) => (state.cond_pagto_global = payload),

    SET_ALIMENTOS_PREPARADOS: (state, payload) => (state.flAlimentosPreparados = payload),

    SET_LAST_UPDATE_DATE: (state) => (state.last_update_date = new Date()),
    SET_UPDATE_KEYS_WITH_TIMESTAMP: (state) => (state.update_keys_with_timestamp = new Date()),
    UPDATE_DATE: (state) => (state.date = new Date()),
    SET_APPROVE_REJECT: (state, payload) => (state.show_accept_reject_buttons = payload),
    SET_STATUS_PROCESS_SUCCESS: (state) => (state.status_process_loading = "success"),
    SET_STATUS_PROCESS_ERROR: (state) => (state.status_process_loading = "error"),
    SET_STATUS_PROCESS_NULL: (state) => (state.status_process_loading = null),

    SET_ID: (state, id) => (state.id = id),
    SET_FILENAME: (state, filename) => (state.filename = filename),
    SET_UUID: (state, uuid) => (state.uuid = uuid),

    SET_CLIENTES: (state, clientes) => (state.clientes = clientes),
    SET_MOEDA: (state, moeda) => (state.moeda = moeda),
    SET_TOTAL_ITEMS: (state, total_items) => (state.total_items = total_items),
    SET_ALWAYS_SHOW_LOGS: (state, always_show_logs) => (state.always_show_logs = always_show_logs),
    SET_VL_TOTAL: (state, total) => (state.vl_total = total),
    SET_QTY_TOTAL: (state, total) => (state.qty_total = total),
    // Filtros
    SET_CNPJ: (state, cnpj) => (state.cnpj = cnpj),
    SET_ITEM: (state, item) => (state.item = item),
    SET_LOG: (state, log) => (state.log = log),
    SET_NUM_ORDER: (state, num_order) => (state.num_order = num_order),
    SET_STATUS: (state, status) => (state.status = status),
    SET_LOG_OPTIONS: (state, log_options) =>
        (state.filter_log_options = [{ value: "", text: "Log ..." }].concat(
            log_options.map((item) => ({ value: item, text: item }))
        )),
    SET_CNPJ_OPTIONS: (state, cnpj_options) => (state.filter_cnpj_options = cnpj_options),
    RESET_FILTER: (state) => {
        state.cnpj = "";
        state.item = "";
        state.log = "";
        state.num_order = "";
        state.status = "";
    },

    TOGGLE_CARDS: (state) => {
        state.toggle_cards = !state.toggle_cards;
        state.grouped_items.forEach((item) => (item.show_card = state.toggle_cards));
    },
    OPEN_CARD_BY_INDEX: (state, index) => (state.grouped_items[index].show_card = true),
    CLOSE_CARD_BY_INDEX: (state, index) => (state.grouped_items[index].show_card = false),
    CLOSE_CARDS: (state) => {
        state.grouped_items.forEach((item) => (item.show_card = false));
    },

    CHANGE_STATE_CARD: (state, index) => {
        state.grouped_items[index].show_card = !state.grouped_items[index].show_card;
        const aux = state.grouped_items[index].show_card;
        const filtered = state.grouped_items.filter((item) => item.show_card == aux);
        if (filtered.length == state.grouped_items.length) state.toggle_cards = aux;
    },

    SET_ORDERS: (state, orders) => (state.pedido = orders),
    SET_GROUPED_ORDERS: (state, orders) => (state.grouped_items = orders),
    UPDATE_GROUPED_ORDERS: (state, orders) => {
        var aux = JSON.parse(JSON.stringify(state.grouped_items));
        aux = aux.map((item) => {
            var a = item;
            item.total = 0;
            item.items = [];
            return a;
        });

        orders.forEach((item) => {
            var index_in_array = aux.findIndex(
                (i) => i.cnpj === item.cnpj && i.num_pedido === item.num_pedido
            );
            addItemToArrayOfCardHeader(aux[index_in_array], item);
        });
        state.grouped_items = aux.slice();
    },
    RESET_STORE_DATA: (state) => Object.assign(state, initial_state()),
};

const actions = {
    async search({ commit, dispatch }) {
        commit("saveStatus", "loading", { root: true });
        await dispatch("getOrder");
        commit("saveStatus", "success", { root: true });
    },

    async openSpeficCards({ commit, getters }, text) {
        const filtered_cards = getters.grouped_items.filter(
            (item) => item.nome_cliente.trim() == text.trim()
        );
        var trues = filtered_cards.filter((item) => item.show_card == true).length;
        var falses = filtered_cards.filter((item) => item.show_card == false).length;

        if (trues > falses)
            filtered_cards.forEach((item) => commit("CLOSE_CARD_BY_INDEX", item.index_in_array));
        else filtered_cards.forEach((item) => commit("OPEN_CARD_BY_INDEX", item.index_in_array));
    },

    async clear({ commit, dispatch }) {
        commit("RESET_FILTER");
        dispatch("getOrder");
    },

    async backToOrder({ getters }) {
        let query = {
            file_name: getters.filename,
            file_id: getters.id,
            uuid_list: "",
        };
        router.push({ name: "order", query });
    },

    async toggleOneCard({ commit }, index) {
        commit("CHANGE_STATE_CARD", index);
    },

    async toogleAllCards({ commit }) {
        commit("TOGGLE_CARDS");
    },

    async getOrder({ getters, commit }) {
        commit("SET_LOADING", true);
        commit("SET_LAST_UPDATE_DATE");
        try {
            const filter = getters.filter_to_api;
            const res = await pedidos_service.list(filter);
            const response = res.data;

            const responseParams = await pedidos_service.list_orders_params();
            const paramsData = responseParams.data;

            const paymentList = paramsData["payment"]
                ? paramsData["payment"].map(({ code: value, text }) => ({
                      value,
                      text,
                  }))
                : [];
            const clientList = paramsData["client_list"] || [];
            let inputFiledsEdtion = paramsData["checkout"]
                ? paramsData["checkout"]["input_fields_edition"] || []
                : [];

            if (inputFiledsEdtion.length) {
                // inputFiledsEdtion.sort((a, b) => (a.type >= b.type ? 1 : -1));
                commit("SET_INPUT_FIELDS_EDITION", inputFiledsEdtion);
            }

            let itemInputFields = paramsData["checkout"]
                ? paramsData["checkout"]["items_optional_fields"] || []
                : [];

            if (itemInputFields.length) {
                // inputFiledsEdtion.sort((a, b) => (a.type >= b.type ? 1 : -1));
                commit("SET_ITEM_OPTIONAL_FIELDS", itemInputFields);
            }

            if (paramsData.checkout) {
                commit(
                    "SET_ALIMENTOS_PREPARADOS",
                    paramsData.checkout.alimentos_preparados || false
                );
            }

            commit("SET_ADVANCED_ORDER_COMPANY_LIST", clientList);
            commit("SET_PAYMENT_LIST", paymentList);

            const pedido = response.items;

            const clientes = response.cliente;
            const moeda = response.moeda;
            const total = response.total_items;
            const filter_log = response.filter_log;
            const filter_cnpj = response.filter_cnpj;
            const always_show_logs = response.always_show_logs;
            const vl_total_pedido = response.vl_total;
            const qty_total = response.qty_total;
            const app_rej = response.approve_reject;
            const draft_mode = response.draft ? true : false;
            var grouped_items = response.cards.map((item, index) => ({
                ...item,
                index_in_array: index,
                show_card: false,
            }));

            if (
                getters.grouped_items.length != 0 &&
                getters.grouped_items.length == grouped_items.length
            ) {
                getters.grouped_items.forEach((item, index) => {
                    grouped_items[index].show_card = item.show_card;
                });
            }

            commit("UPDATE_DATE");
            commit("SET_ALWAYS_SHOW_LOGS", always_show_logs);
            commit("SET_VL_TOTAL", vl_total_pedido);
            commit("SET_ORDERS", pedido);
            commit("SET_GROUPED_ORDERS", grouped_items);
            commit("SET_TOTAL_ITEMS", total);
            commit("SET_CLIENTES", clientes);
            commit("SET_MOEDA", moeda);
            commit("SET_LOG_OPTIONS", filter_log);
            commit("SET_CNPJ_OPTIONS", filter_cnpj);
            commit("SET_QTY_TOTAL", qty_total);
            commit("SET_APPROVE_REJECT", app_rej ? true : false);
            commit("SET_DRAFT_MODE", draft_mode);
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", error.response.data.error, { root: true });
        }
        commit("SET_LOADING", false);
        commit("SET_UPDATE_KEYS_WITH_TIMESTAMP");
    },

    async exportOrders({ getters, commit }) {
        commit("SET_LOADING_EXPORT", true);
        let filter = { ...getters.filter_to_api };
        filter["exportar"] = "";
        try {
            const response = await pedidos_service.export_list(filter);
            exportFileXLSX(response.data, "pedidos");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY", "Export failed", { root: true });
        }
        commit("SET_LOADING_EXPORT", false);
    },

    async confirmProcess({ dispatch }, router) {
        if (
            await dispatch(
                "modal/ModalGeneric",
                {
                    title: i18n.t("modals.checkout.confirm_process"),
                    text: "",
                    icon: "warning",
                },
                { root: true }
            )
        )
            dispatch("process", router);
    },

    async process({ getters, commit, dispatch }, router) {
        const { id, filename, uuid_list } = getters.filter_to_api;
        try {
            commit("SET_PROCESS_LOADING", true);
            commit("SET_STATUS_PROCESS_NULL");
            commit("SET_STATUS_PROCESSING", true, { root: true });

            var response = await pedidos_service.process({ id, filename, uuid_list });

            if (response.data.status == "error") {
                commit("PUSH_ERROR_TO_ARRAY", "new item failed", { root: true });
            } else {
                let id_process = id.replace(/@DRAFT/g, "");
                commit("SET_ID", id_process);
                commit("SET_ORDER_PROCESSING_DATA_IN_PROGRESS", { id: id_process, filename });

                // Mostra que o pedido está sendo processado
                let counter = 0;

                const timeStart = Date.now();

                while (counter < 600) {
                    const time = 8;
                    try {
                        const promiseResult = await delayedPromise(time, () =>
                            pedidos_service.status(id_process)
                        );

                        commit("SET_ORDER_PROCESSING_PROGRESS_STATUS", {
                            id: promiseResult.data.id,
                            total: promiseResult.data.total,
                            nok: promiseResult.data.nok,
                            message: promiseResult.data.processing_message,
                        });

                        if (promiseResult.data.nok == 0) break;
                    } catch (error) {
                        console.error(error);
                    }

                    counter++;
                }

                const timeFinal = Date.now();
                const new_id = getters.order_processing_progress_status.id;

                if (id_process != new_id) commit("SET_ID", new_id);

                if (router.currentRoute.path.replace("/", "") == "checkout") {
                    history.pushState(
                        {},
                        null,
                        "/checkout" + `?file_name=${filename}&file_id=${new_id}`
                    );
                    dispatch("getOrder");
                }

                commit("SET_PROCESS_LOADING", false);
                commit("SET_STATUS_PROCESS_SUCCESS");

                const TimeProcessing = secondsToString((timeFinal - timeStart) / 1000);
                const minutes = TimeProcessing[0];
                const seconds = TimeProcessing[1];

                let timeString = "";
                if (minutes != 0) {
                    timeString += `${i18n.tc("modals.common.time.timeMinutes", minutes, {
                        minutes,
                    })} `;
                }

                timeString += `${i18n.tc("modals.common.time.timeSeconds", seconds, {
                    seconds: seconds,
                })}`;

                const timeStringCompleted = `${i18n.tc(
                    "modals.common.time.startMessage"
                )} ${timeString} ${i18n.tc("modals.common.time.endMessage")}`;

                dispatch(
                    "modal/SimpleModal",
                    {
                        type: "success",
                        title: i18n.tc("modals.common.order_process"),
                        text: timeStringCompleted,
                    },
                    { root: true }
                );
            }
            commit("CLEAR_ORDER_PROCESSING_DATA");
        } catch (error) {
            commit("SET_PROCESS_LOADING", false);
            commit("SET_STATUS_PROCESS_ERROR");
        }
        commit("SET_STATUS_PROCESSING", false, { root: true });
        commit("CLEAR_ORDER_PROCESSING_PROGRESS_STATUS");
    },

    async modalAddNewItem({ dispatch, getters }, items) {
        dispatch(
            "modal/newItemModalCheckout",
            { ...items[0], order_id: getters.id },
            { root: true }
        );
    },

    async modalEditOneItem({ dispatch, getters }, payload) {
        dispatch(
            "modal/editItemModalCheckout",
            { ...payload, order_id: getters.id },
            { root: true }
        );
    },

    async addNewItem({ getters, commit, dispatch }, { payload, updatedValues }) {
        // const identifiers = {
        //     uuid: payload.identifiers.uuid,
        //     num_pedido: payload.identifiers.num_pedido,
        //     cnpj: payload.identifiers.cnpj,
        // };

        const auxiliarItem = returnFirstItemOfCardOrder(
            {
                uuid: payload.uuid,
                num_pedido: payload.num_pedido,
                cnpj: payload.cnpj,
            },
            getters.grouped_items
        );

        return apiCallWithGlobalLoading(addItemCheckout, [payload, updatedValues, auxiliarItem], {
            commit,
            dispatch,
        });
    },

    async editOneItem({ getters, commit, dispatch }, payload) {
        let arrayOfChanges = [payload];

        updateMultipleWithReplyDays(
            payload,
            getters.grouped_items,
            pedidos_service.update_multiple_items_in_order
        );

        const itemsDates = await updateDates(payload, getters.all_items, dispatch);
        const itemsPrices = await updatePrices(payload, getters.all_items, dispatch);
        const itemsReserva = await updateReserva(payload, getters.all_items, dispatch);

        function getRightField(field) {
            const aux = {
                new_dt: "data",
                new_vl: "vl",
                new_cd_reserva: "cd_reserva",
            };
            return aux[field];
        }

        function updates(source, destination, pload, field, advanced_order = false) {
            source.forEach((i) => {
                const item = destination.find((el) => el.item.item_id == i.item.item_id);

                const objectField = !advanced_order ? "formValues" : "advanced_order";

                if (!item) {
                    i[objectField][field] = pload[objectField][field];
                    i["updates"] = [field];
                    destination.push(i);
                    return;
                }

                item[objectField][field] = pload[objectField][field];
                if (item["updates"]) {
                    item["updates"].push(field);
                } else {
                    item["updates"] = [field];
                }
            });
        }

        updates(itemsDates, arrayOfChanges, payload, getRightField("new_dt"));
        updates(itemsPrices, arrayOfChanges, payload, getRightField("new_vl"));
        updates(itemsReserva, arrayOfChanges, payload, getRightField("new_cd_reserva"), true);

        const arrayOfChangesWithIdAndFilename = arrayOfChanges.map((item) => {
            item["identifiers"]["id"] = getters.id;
            item["identifiers"]["filename"] = getters.filename;
            return item;
        });

        multipleRequestApiCall(arrayOfChangesWithIdAndFilename, editItemCheckout, {
            commit,
            dispatch,
        });

        return Promise.resolve();
    },

    async deleteOneItem({ getters, commit, dispatch }, payload) {
        const result = await dispatch(
            "modal/ModalGeneric",
            {
                title: i18n.t("modals.checkout.delete_one_item"),
                text: `${payload.ds_sku_output}, CD SKU: ${payload.cd_sku_output}, ${
                    payload.cd_ean_output != "" ? "EAN: " : ""
                } ${payload.cd_ean_output}`,
                icon: "error",
            },
            { root: true }
        );
        if (!result) return;

        const { id, filename } = getters.filter_to_api;
        const { cnpj, num_pedido, uuid } = payload;

        const {
            item_ped,
            cd_sku_output: cd_sku,
            ds_sku_output: ds_sku,
            cd_ean_output: cd_ean,
            qty,
            bu,
            vendor_id,
        } = payload;

        var obj = {
            id,
            filename,
            cnpj,
            num_pedido,
            uuid,
            to_remove: {
                item_ped,
                cd_sku,
                ds_sku,
                cd_ean,
                qty,
                bu,
                vendor_id,
            },
        };
        try {
            commit("saveStatus", "loading", { root: true });
            var response = await deleteItemFromOrder(obj);
            commit("saveStatus", "success", { root: true });

            if (response.status && response.status == "error") throw "error";

            commit("PUSH_SUCCESS_TO_ARRAY", "Item deleted sucessfully", { root: true });
            await dispatch("search");
        } catch (error) {
            commit("saveStatus", "error", { root: true });
            commit("PUSH_ERROR_TO_ARRAY", "Exclusion failed", { root: true });
        }
    },

    async deleteMultipleItems({ commit, dispatch }, payload) {
        const { cnpj, pedido, items, selected_items } = payload;

        if (selected_items.length == 0) {
            commit("PUSH_WARNING_TO_ARRAY", "no item selected", { root: true });
            return;
        }

        let title = "";
        let text = "";

        if (items.length == selected_items.length) {
            title = i18n.t("pages.checkout.modal_delete.delete_all_items_title");
            text = i18n.t("pages.checkout.modal_delete.delete_all_items_text", { pedido, cnpj });
        } else {
            title = i18n.t("pages.checkout.modal_delete.delete_some_items_title");
            text = i18n.t("pages.checkout.modal_delete.delete_some_items_text", {
                count: selected_items.length,
                pedido,
                cnpj,
            });
        }

        const result = await dispatch(
            "modal/ModalGeneric",
            { title, text, icon: "error" },
            { root: true }
        );
        if (!result) return;

        const pulled = _.pullAt(selected_items, [0]);
        commit("saveStatus", "loading", { root: true });

        try {
            await dispatch("deleteOneItemFromMultipleExclusion", pulled[0]);

            const array_promisses = selected_items.map((item) => {
                return dispatch("deleteOneItemFromMultipleExclusion", item);
            });
            await Promise.all(array_promisses);
            commit("PUSH_SUCCESS_TO_ARRAY", "Item deleted sucessfully", { root: true });
            await dispatch("search");
        } catch (err) {
            console.error(err);
            commit("PUSH_ERROR_TO_ARRAY", "Exclusion failed", { root: true });
            commit("saveStatus", "error", { root: true });
        }
    },

    async deleteOneItemFromMultipleExclusion({ getters }, payload) {
        const { id, filename } = getters.filter_to_api;
        const { cnpj, num_pedido, uuid } = payload;

        const {
            item_ped,
            cd_sku_output: cd_sku,
            ds_sku_output: ds_sku,
            cd_ean_output: cd_ean,
            bu,
            qty,
            vendor_id,
        } = payload;

        var obj = {
            id,
            filename,
            cnpj,
            num_pedido,
            uuid,
            to_remove: {
                item_ped,
                cd_sku,
                ds_sku,
                cd_ean,
                qty,
                bu,
                vendor_id,
            },
        };
        return deleteItemFromOrder(obj);
    },
    async clearDataFromStore({ commit }) {
        commit("RESET_STORE_DATA");
    },

    async approveOrder({ dispatch, getters }, params) {
        const result = await dispatch(
            "modal/ModalThreeButtonGeneric",
            {
                title1: i18n.t("pages.checkout.modal_approve_reject.approve"),
                text1: i18n.t("pages.checkout.modal_approve_reject.approve_text"),
                title2: i18n.t("modals.update_reserva.title2"),
                confirmButtonText: i18n.t("pages.checkout.modal_approve_reject.confirmButtonText"),
                denyButtonText: i18n.t("pages.checkout.modal_approve_reject.denyButtonText"),
                cancelButtonText: i18n.t("pages.checkout.modal_approve_reject.cancelButtonText"),
            },
            { root: true }
        );
        switch (result) {
            case "total":
                dispatch("approveAll", getters.all_cards);
                break;
            case "card":
                dispatch("approve", params);
                break;
            case "esc":
                break;
        }
    },

    async approveAll({ getters, commit, dispatch }, cards) {
        commit("SET_LOADING", true);
        try {
            const obj_filter = {
                id: getters.id,
                filename: getters.filename,
                orders: cards,
            };
            cards.forEach(async (card) => {
                commit("START_LOADING_APPROVE_REJECT", {
                    num_pedido: card.num_pedido,
                    cnpj: card.cnpj,
                });
            });
            const response = await pedidos_service.approveOrderGlobal(obj_filter);
            commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", response.msg, { root: true });
            await dispatch("getOrder");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", error.response.data.error, { root: true });
        }
        cards.forEach(async (card) => {
            commit("STOP_LOADING_APPROVE_REJECT", {
                num_pedido: card.num_pedido,
                cnpj: card.cnpj,
            });
        });
    },

    async rejectOrder({ dispatch, getters }, params) {
        const result = await dispatch(
            "modal/ModalThreeButtonGeneric",
            {
                title1: i18n.t("pages.checkout.modal_approve_reject.reject"),
                text1: i18n.t("pages.checkout.modal_approve_reject.reject_text"),
                title2: i18n.t("modals.update_reserva.title2"),
                confirmButtonText: i18n.t("pages.checkout.modal_approve_reject.confirmButtonText"),
                denyButtonText: i18n.t("pages.checkout.modal_approve_reject.denyButtonText"),
                cancelButtonText: i18n.t("pages.checkout.modal_approve_reject.cancelButtonText"),
            },
            { root: true }
        );
        switch (result) {
            case "total":
                dispatch("rejectAll", getters.all_cards);
                break;
            case "card":
                dispatch("reject", params);
                break;
            case "esc":
                break;
        }
    },

    async rejectAll({ getters, commit, dispatch }, cards) {
        commit("SET_LOADING", true);
        try {
            const obj_filter = {
                id: getters.id,
                filename: getters.filename,
                orders: cards,
            };
            cards.forEach(async (card) => {
                commit("START_LOADING_APPROVE_REJECT", {
                    num_pedido: card.num_pedido,
                    cnpj: card.cnpj,
                });
            });
            const response = await pedidos_service.rejectOrderGlobal(obj_filter);
            commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", response.msg, { root: true });
            await dispatch("getOrder");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", error.response.data.error, { root: true });
        }
        cards.forEach(async (card) => {
            commit("STOP_LOADING_APPROVE_REJECT", {
                num_pedido: card.num_pedido,
                cnpj: card.cnpj,
            });
        });
    },

    async approve({ getters, commit, dispatch }, params) {
        commit("SET_LOADING", true);

        try {
            const obj_filter = {
                num_pedido: params.pedido,
                cnpj: params.cnpj,
                id: getters.id,
                filename: getters.filename,
            };
            commit("START_LOADING_APPROVE_REJECT", {
                num_pedido: params.pedido,
                cnpj: params.cnpj,
            });
            const response = await pedidos_service.approve_order(obj_filter);
            commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", response.data.status, { root: true });
            await dispatch("getOrder");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", error.response.data.error, { root: true });
        }
        commit("STOP_LOADING_APPROVE_REJECT", {
            num_pedido: params.pedido,
            cnpj: params.cnpj,
        });
    },

    async reject({ getters, commit, dispatch }, params) {
        commit("SET_LOADING", true);

        try {
            const obj_filter = {
                num_pedido: params.pedido,
                cnpj: params.cnpj,
                id: getters.id,
                filename: getters.filename,
            };
            commit("START_LOADING_APPROVE_REJECT", {
                num_pedido: params.pedido,
                cnpj: params.cnpj,
            });
            const response = await pedidos_service.reject_order(obj_filter);
            commit("PUSH_SUCCESS_TO_ARRAY_PURE_TEXT", response.data.status, { root: true });
            await dispatch("getOrder");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", error.response.data.error, { root: true });
        }
        commit("STOP_LOADING_APPROVE_REJECT", {
            num_pedido: params.pedido,
            cnpj: params.cnpj,
        });
    },

    async removeApproval({ dispatch }, params) {
        if (
            !(await dispatch(
                "modal/ModalGeneric",
                {
                    title: i18n.t("pages.checkout.modal_approve_reject.already_approve"), //"Aprovar Pedido",
                    text: i18n.t("pages.checkout.modal_approve_reject.already_approve_text"),
                },
                { root: true }
            ))
        )
            return;
        dispatch("approve", params);
    },

    async remoreRejection({ dispatch }, params) {
        if (
            !(await dispatch(
                "modal/ModalGeneric",
                {
                    title: i18n.t("pages.checkout.modal_approve_reject.already_reject"), //"Aprovar Pedido",
                    text: i18n.t("pages.checkout.modal_approve_reject.already_reject_text"),
                },
                { root: true }
            ))
        )
            return;

        dispatch("reject", params);
    },
    async deleteItemsFromCard({ commit, dispatch }, payload) {
        const { items, cnpj, pedido, uuidCard, id, filename } = payload;
        const obj = {
            cnpj,
            pedido,
            uuidCard,
            id,
            filename,
        };
        let quantidadeDelete = 0;
        items.forEach((item) => {
            if (!item.status_checkout.includes("DELETE")) {
                quantidadeDelete++;
            }
        });
        let title = "";
        let text = "";

        title = i18n.t("pages.checkout.modal_delete.delete_all_items_title");
        text = i18n.t("pages.checkout.modal_delete.delete_some_items_text", {
            count: quantidadeDelete,
            pedido,
            cnpj,
        });

        const result = await dispatch(
            "modal/ModalGeneric",
            { title, text, icon: "error" },
            { root: true }
        );
        if (!result) return;

        try {
            await pedidos_service.delete_from_card(obj);

            commit("PUSH_SUCCESS_TO_ARRAY", "Item deleted sucessfully", { root: true });
            await dispatch("search");
        } catch {
            commit("PUSH_ERROR_TO_ARRAY", "Exclusion failed", { root: true });
            commit("saveStatus", "error", { root: true });
        }
    },
    async restoreDeletedItem({ getters, commit, dispatch }, payload) {
        const result = await dispatch(
            "modal/ModalGeneric",
            {
                title: i18n.t("modals.checkout.restore_one_item"),
                text: `${payload.ds_sku_output}, CD SKU: ${payload.cd_sku_output}, ${
                    payload.cd_ean_output != "" ? "EAN: " : ""
                } ${payload.cd_ean_output}`,
                icon: "error",
            },
            { root: true }
        );
        if (result) {
            const { id, filename } = getters.filter_to_api;
            const {
                cnpj,
                index_item,
                num_pedido,
                cd_sku_output,
                qty,
                dt_entrega,
                vl_p_unitario,
                cd_ean_input,
                ds_sku_input,
            } = payload;
            var obj = {
                id,
                filename,
                cnpj,
                index_item,
                num_pedido,
                cd_sku_output,
                qty,
                dt_entrega,
                vl_p_unitario,
                cd_ean_input,
                ds_sku_input,
            };

            try {
                commit("saveStatus", "loading", { root: true });
                var response = await pedidos_service.retrieveDeletedItem(obj);
                commit("saveStatus", "success", { root: true });

                if (response.data.status == "error") throw "error";

                commit("PUSH_SUCCESS_TO_ARRAY", "Item restored sucessfully", { root: true });
                await dispatch("search");
            } catch (error) {
                commit("saveStatus", "error", { root: true });
                commit("PUSH_ERROR_TO_ARRAY", "Restoration failed", { root: true });
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
