var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("content-header", {
        attrs: {
          title: _vm.title_page,
          subtitle: _vm.subtitle_page,
          loading: _vm.loading,
        },
      }),
      _c("div", { staticClass: "conteudo-pagina" }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }