import api from "@/services/api";

export default {
    list_companies() {
        return api().get("/views/company/logo");
    },
    delete_company(id) {
        return api().delete(`/views/company/logo?empresa_id=${id}`);
    },
    add_logo(data) {
        const formData = new FormData();
        formData.append("file", data.logo);
        formData.append("empresa_id", data.empresa_id);
        return api().post("/views/company/create_logo", formData);
    },
    edit_logo(data) {
        const formData = new FormData();
        formData.append("file", data.logo);
        formData.append("empresa_id", data.empresa_id);
        return api().put("/views/company/logo", formData);
    },
    get_logo_base64(empresa_id) {
        return api().get(`/views/company/logo/base64?empresa_id=${empresa_id}`);
    },
    get_logo_signedURL(empresa_id) {
        return api().get(`/views/company/logo/signedUrl?empresa_id=${empresa_id}`);
    }
};
