import api from "@/services/api";
import { prepareAndClearFormDataToRequest } from "@/helpers/http.helper";

export default {
    list(data) {
        const form = new FormData();
        form.append("offset", data.offset);
        form.append("janela", data.janela);
        form.append("per_page", data.limit ? data.limit : 10);

        return api().post("/detalhamento", form);
    },
    view(data) {
        const form = new FormData();
        form.append("view", "");
        form.append("buscar", "");
        form.append("s.update", data.dt_ref ? data.dt_ref : "");
        form.append("e.name", data.nome_empresa ? data.nome_empresa : "");
        form.append("s.nome_cliente", data.nome_cliente ? data.nome_cliente : "");
        form.append("u.username", data.nome_usuario ? data.nome_usuario : "");
        form.append("s.canal", data.canal ? data.canal : "");
        form.append("s.cnpj", data.cnpj ? data.cnpj : "");
        form.append("s.num_pedido", data.num_pedido ? data.num_pedido : "");
        form.append("s.filepath", data.file ? data.file : "");
        form.append("s.nr_exec", data.nr_exec ? data.nr_exec : "");
        form.append("s.order_id", data.order_id ? data.order_id : "");
        form.append("offset", data.offset ? data.offset : 0);
        form.append("log", data.log ? data.log : "");
        form.append("janela", data.janela ? data.janela : "");
        form.append("s.uuid", data.uuid_list ? data.uuid_list : "");
        form.append("per_page", data.limit ? data.limit : 10);
        form.append("uploaded", data.uploaded ? data.uploaded : "");

        return api().post("/detalhamento", form);
    },
    find(data) {
        const form = prepareAndClearFormDataToRequest(data);
        return api().post("/detalhamento", form);
    },
    export(data) {
        const form = prepareAndClearFormDataToRequest(data);
        return api().post("/detalhamento", form);
    },
};
