/* eslint-disable no-unused-vars */
import i18n from "@/plugins/i18n";
import ClientInfo from "@/views/Sugestoes/components/ClientInfo.vue";
import Vue from "vue";

import {
    listAllFilters,
    getColumnsAndGroups,
    getSuggestions,
    importSuggestions,
    exportSuggestions,
    sendSuggestions,
    getStatistics,
    getSuggestionParams,
    saveSuggestionParams,
    editSuggestionParams,
    deleteSuggestionParams,
    getImportedFiles,
    createNewSuggestion,
    editQuantityItem,
} from "@/services/api/sugestoes";

import { exportFileXLSX } from "@/helpers/files.helper";
import { returnCopyOfObject } from "@/helpers/object.helper";
import { localStorageGet, localStorageSet } from "@/helpers/storage.helper";
import { GetListOfStringsOfLastThreeMonths, transformDateInString } from "@/helpers/date.helper";
import {
    returnListOfAllSuggestionUnGrouped,
    filterSuggestionsByCustomFunction,
} from "@/helpers/suggestions.helper";
import router from "@/router/index";
import axios from "axios";

const initiateLocalStoredValue = function (key, defaultValue) {
    const storedValue = localStorageGet(key);
    if (!storedValue) return defaultValue;
    return JSON.parse(storedValue);
};

const initial_state = () => ({
    // Users preferences
    stickyHeaderOnSuggestionList: true,

    loadingAllFilters: true,
    loadingGroupsAndColumns: true,
    loadingSuggestion: true,
    loadingListingFilters: true,

    // Selected checkbox
    columnsFilter: initiateLocalStoredValue("columnsFilter", []),
    groupsFilter: initiateLocalStoredValue("groupsFilter", []),
    exportColumnsFilter: initiateLocalStoredValue("exportColumnsFilter", []),

    loadingSidebar: false,

    // Options of selection
    columnsState: initiateLocalStoredValue("columnsState", []),
    groupsState: initiateLocalStoredValue("groupsState", []),
    exportColumns: initiateLocalStoredValue("exportColumns", []),

    columnsSidebar: false,

    allAvailableFilters: localStorageGet("allAvailableFilterSuggestion")
        ? JSON.parse(localStorageGet("allAvailableFilterSuggestion"))
        : [],
    clientsOutOfRoute: [],
    filter: {},

    dynamic_filter: {},
    pagination: {
        page: 1,
        per_page: 10,
        total_items: 0,
    },

    selectedSuggestions: [],
    suggestions: [],

    generalFormToApi: {},

    // Statistic variables
    loadingStatistics: true,
    last_three_months: GetListOfStringsOfLastThreeMonths(),

    sent_orders_series: [
        {
            name: "Pedidos enviados",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
    ],
    sent_order_title: "Pedidos enviados",

    banners_store_series: [
        {
            name: "Redes",
            data: [0, 0, 0],
        },
        {
            name: "Bandeiras",
            data: [0, 0, 0],
        },
        {
            name: "Lojas",
            data: [0, 0, 0],
        },
    ],

    current_stock_series: [
        {
            name: "R$",
            data: [0, 0, 0],
        },
        {
            name: "HL/Ton",
            data: [0, 0, 0],
        },
        {
            name: "Un.",
            data: [0, 0, 0],
        },
    ],
    current_stock_title: "Estoque Atual Cliente",

    suggestion_series: [
        {
            name: "R$",
            data: [0, 0, 0],
        },
        {
            name: "HL/Ton",
            data: [0, 0, 0],
        },
        {
            name: "Un.",
            data: [0, 0, 0],
        },
    ],
    suggestion_title: "Sugestão",

    categories_series: [
        {
            name: "Categorias",
            data: [0, 0, 0],
        },
        {
            name: "Marcas",
            data: [0, 0, 0],
        },
        {
            name: "SKUs",
            data: [0, 0, 0],
        },
    ],

    doh_series: [
        {
            name: "DoH Real",
            data: [0, 0, 0],
        },
        {
            name: "DoH Meta",
            data: [0, 0, 0],
        },
    ],
    doh_title: "DoH Real x Meta",

    sku_rupture_series: [0, 0, 0, 0, 0],
    sku_rupture_title: "SKUs em Ruptura (Lista sugerida)",
    sku_rupture_categories: [
        "Categoria A",
        "Categoria B",
        "Categoria C",
        "Categoria D",
        "Categoria E",
    ],

    suggestion_params: {},

    readyToCallApi: false,

    // Abort Controller
    controller: null,
    importedFiles: [],
});

const state = initial_state();

const getters = {
    stickyHeaderOnSuggestionList: (state) => state.stickyHeaderOnSuggestionList,

    loading: (state) => {
        if (router.app.$route.name == "Statistics")
            return state.loadingListingFilters || state.loadingStatistics;
        else
            return (
                state.loadingSuggestion ||
                state.loadingListingFilters ||
                state.loadingAllFilters ||
                state.loadingGroupsAndColumns
            );
    },

    loadingSuggestion: (state) => state.loadingSuggestion,
    loadingStatistics: (state) => state.loadingStatistics,
    loadingAllFilters: (state) => state.loadingAllFilters,
    loadingGroupsAndColumns: (state) => state.loadingGroupsAndColumns,
    columnsFilter: (state) => state.columnsFilter,
    columnsState: (state) => state.columnsState,
    groupsFilter: (state) => state.groupsFilter,
    groupsState: (state) => state.groupsState,
    dynamic_filter: (state) => state.dynamic_filter,
    clientsOutOfRoute: (state) => state.clientsOutOfRoute,
    tableFilteredColumns: (state) => {
        return state.columnsState.filter((column) => state.columnsFilter.includes(column.value));
    },

    exportColumns: (state) => state.exportColumns,
    exportColumnsFilter: (state) => state.exportColumnsFilter,

    defaultFilters: (state) => {
        let aux = state.allAvailableFilters.filter((item) => item.default);
        return returnCopyOfObject(aux).sort((a, b) => (a.order >= b.order ? 1 : -1));
    },

    // Pagination
    currentPage: (state) => state.pagination.page,
    perPage: (state) => state.pagination.per_page,
    totalItems: (state) => state.pagination.total_items,

    // Conjunto de informações para mandar para a api
    filterToApi: (state) => {
        let otherParams = {};
        let auxDynamicFilter = { ...state.dynamic_filter };

        if (state.groupsFilter.length > 0) {
            otherParams["group_by"] = state.groupsFilter;
        }

        const { status = null } = auxDynamicFilter;

        if (status) {
            if (status == "Pendentes") otherParams["status"] = false;
            if (status == "Aprovados") otherParams["status"] = true;
            if (status == "Enviados") otherParams["sent"] = true;

            delete auxDynamicFilter.status;
        }

        return {
            ...auxDynamicFilter,
            ...otherParams,
            per_page: state.pagination.per_page,
            page: state.pagination.page,
        };
    },

    suggestions: (state) => state.suggestions,
    agroupedSuggestions: (state) => {
        return state.suggestions;
        // const groupment = {};

        // if (state.groupsFilter.length === 0) return state.suggestions;

        // state.suggestions.forEach((suggestion) => {
        //     let currentGroupment = groupment;

        //     state.groupsFilter.forEach((group, index) => {
        //         const groupKey = suggestion[group];

        //         if (index === state.groupsFilter.length - 1) {
        //             if (!currentGroupment[groupKey]) {
        //                 currentGroupment[groupKey] = [suggestion];
        //                 return;
        //             } else {
        //                 currentGroupment[groupKey].push(suggestion);
        //             }
        //         }

        //         if (!currentGroupment[groupKey]) {
        //             currentGroupment[groupKey] = {};
        //         }

        //         currentGroupment = currentGroupment[groupKey];
        //     });
        // });

        // return groupment;
    },

    loadingSidebar: (state) => state.loadingSidebar,
    selectedSuggestions: (state) => state.selectedSuggestions,
    selectedSuggestionsComplete: (state) => {
        const array = returnListOfAllSuggestionUnGrouped(state.suggestions);
        return array.filter((item) => state.selectedSuggestions.includes(item.order_id));
    },

    // Statistic Page - Chats and Graphs
    charts: (state) => ({
        sent_orders: {
            series: state.sent_orders_series,
            options: {
                chart: {
                    type: "line",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                title: {
                    text: state.sent_order_title,
                    align: "left",
                },
            },
        },
        banners_store: {
            series: state.banners_store_series,
            options: {
                chart: {
                    type: "bar",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: state.last_three_months,
                },
            },
        },
        current_stock: {
            series: state.current_stock_series,
            options: {
                chart: {
                    type: "line",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    staked: true,
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: false,
                    },
                },
                stroke: {
                    curve: "smooth",
                },
                title: {
                    text: state.current_stock_title,
                    align: "left",
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: state.last_three_months,
                },
            },
        },
        suggestion: {
            series: state.suggestion_series,
            options: {
                chart: {
                    type: "line",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    staked: true,
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: false,
                    },
                },
                stroke: {
                    curve: "smooth",
                },
                title: {
                    text: state.suggestion_title,
                    align: "left",
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: state.last_three_months,
                },
            },
        },
        categories: {
            series: state.categories_series,
            options: {
                chart: {
                    type: "bar",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: state.last_three_months,
                },
            },
        },
        doh: {
            series: state.doh_series,
            options: {
                chart: {
                    type: "bar",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    },
                },
                title: {
                    text: state.doh_title,
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: state.last_three_months,
                    labels: {
                        show: false,
                    },
                },
            },
        },
        sku_rupture: {
            series: state.sku_rupture_series,
            options: {
                chart: {
                    type: "donut",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                labels: state.sku_rupture_categories,
                title: {
                    text: state.sku_rupture_title,
                    align: "left",
                },
            },
        },
    }),
    suggestionParams: (state) => state.suggestion_params,
    readyToCallApi: (state) => state.readyToCallApi,
    controller: (state) => state.controller,
    importedFiles: (state) => state.importedFiles,
};

const mutations = {
    SET_COLUMNS_SIDEBAR: (state, payload) => (state.columnsSidebar = payload),
    SET_ALL_AVAILABLE_FILTERS: (state, payload) => {
        localStorageSet("allAvailableFilterSuggestion", JSON.stringify(payload));
        state.allAvailableFilters = payload;
    },
    SET_GENERAL_FORM: (state, payload) => {
        state.generalFormToApi = { ...payload };
        state.pagination.page = 1;
    },
    SET_LOADING_SUGGESTION: (state, payload) => (state.loadingSuggestion = payload),
    SET_LOADING_LISTING_FILTERS: (state, payload) => (state.loadingListingFilters = payload),
    SET_LOADING_ALL_FILTERS: (state, payload) => (state.loadingAllFilters = payload),
    SET_LOADING_GROUPS_AND_COLUMNS: (state, payload) => (state.loadingGroupsAndColumns = payload),
    SET_SUGGESTIONS: (state, payload) => {
        if (payload instanceof Array) state.suggestions = [...payload];
        else state.suggestions = { ...payload };
    },
    ADD_ID_TO_SELECT_SUGGESTIONS: (state, payload) => {
        let aux = state.selectedSuggestions;
        let payloadFilter = payload.filter((item) => !aux.includes(item));
        aux = aux.concat(payloadFilter);
        state.selectedSuggestions = [...aux];
    },
    REMOVE_IDS_FROM_SELECTED_SUGGESTIONS: (state, orderIds) => {
        let aux = state.selectedSuggestions.filter((item) => !orderIds.includes(item));
        state.selectedSuggestions = [...aux];
    },
    CLEAR_SELECTED_SUGGESTIONS: (state) => (state.selectedSuggestions = []),

    SET_DYNAMIC_FILTER: (state, payload) => (state.dynamic_filter = payload),
    ADD_CD_STORE_TO_DYNAMIC_FILTER: (state, payload) => {},
    SET_CLIENT_OUT_OF_ROUTE: (state, payload) => (state.clientsOutOfRoute = payload),
    SET_LOADING_SIDEBAR: (state, payload) => (state.loadingSidebar = payload),
    // Update the selected checkboxes
    SET_TABLE_COLUMN_FILTER: (state, payload) => {
        state.columnsFilter = payload;
        localStorageSet("columnsFilter", JSON.stringify(payload));
    },
    SET_EXPORT_COLUMN_FILTER: (state, payload) => {
        state.exportColumnsFilter = payload;
        localStorageSet("exportColumnsFilter", JSON.stringify(payload));
    },
    SET_TABLE_GROUP_FILTER: (state, payload) => {
        state.groupsFilter = payload;
        localStorageSet("groupsFilter", JSON.stringify(payload));
    },
    ADD_STRING_TO_TABLE_COLUMNS_FILTER: (state, string) => {
        const aux = state.columnsFilter;
        const isStringAlreadyInArray = aux.includes(string);
        if (!isStringAlreadyInArray) aux.push(string);
        state.columnsFilter = [...aux];
        localStorageSet("columnsFilter", JSON.stringify([...aux]));
    },
    ADD_STRING_TO_SELECTED_EXPORT_COLUMNS: (state, string) => {
        const aux = state.exportColumnsFilter;
        const isStringAlreadyInArray = aux.includes(string);
        if (!isStringAlreadyInArray) aux.push(string);
        state.exportColumnsFilter = [...aux];
        localStorageSet("exportColumnsFilter", JSON.stringify([...aux]));
    },

    // Pagination
    SET_PAGE: (state, payload) => (state.pagination.page = payload),
    SET_PER_PAGE: (state, payload) => (state.pagination.per_page = payload),
    SET_TOTAL_ITEMS: (state, payload) => (state.pagination.total_items = payload),

    // List of options
    SET_COLUMNS: (state, payload) => {
        state.columnsState = payload;
        localStorageSet("columnsState", JSON.stringify(payload));
    },
    REMOVE_COLUMN_FILTER_BY_ID: (state, value) => {
        let aux = returnCopyOfObject(state.columnsFilter);
        const indexOf = aux.indexOf(value);
        if (indexOf == -1) return;

        aux.splice(indexOf, 1);
        state.columnsFilter = [...aux];
        localStorageSet("columnsFilter", JSON.stringify(state.columnsFilter));
    },
    SET_GROUPS: (state, payload) => {
        state.groupsState = payload;
        localStorageSet("groupsState", JSON.stringify(payload));
    },
    SET_EXPORT_COLUMNS: (state, payload) => {
        state.exportColumns = payload;
        localStorageSet("exportColumns", JSON.stringify(payload));
    },

    SET_LOADING_STATISTICS: (state, payload) => (state.loadingStatistics = payload),
    SET_STATISTICS_VALUES: (state, payload) => {
        // state.sent_orders_series = payload["sent_orders"].series;
        // state.sent_order_title = payload["sent_orders"].title;
        // state.banners_store_series = payload["banners_store"].series;
        // state.current_stock_series = payload["current_stock"].series;
        // state.current_stock_title = payload["current_stock"].title;
        // state.suggestion_series = payload["suggestion"].series;
        // state.suggestion_title = payload["suggestion"].title;
        // state.categories_series = payload["categories"].series;
        // state.doh_series = payload["doh"].series;
        // state.doh_title = payload["doh"].title;
        // state.sku_rupture_series = payload["sku_rupture"].series;
        // state.sku_rupture_title = payload["sku_rupture"].title;
        // state.sku_rupture_categories = payload["sku_rupture"].labels;
    },
    SET_STATISTICS_FIRST: (state, payload) => {
        state.sent_orders_series = payload.series;
        state.sent_order_title = payload.title;
    },
    SET_STATISTICS_SECOND: (state, payload) => {
        state.banners_store_series = payload["banners_store"].series;
    },
    SET_STATISTICS_THIRD: (state, payload) => {
        state.current_stock_series = payload["current_stock"].series;
        state.current_stock_title = payload["current_stock"].title;
    },
    SET_STATISTICS_FOURTH: (state, payload) => {
        state.suggestion_series = payload["suggestion"].series;
        state.suggestion_title = payload["suggestion"].title;
    },
    SET_STATISTICS_FIFTH: (state, payload) => {
        state.categories_series = payload["categories"].series;
    },
    SET_STATISTICS_SIXTH: (state, payload) => {
        state.doh_series = payload["doh"].series;
        state.doh_title = payload["doh"].title;
    },
    SET_STATISTICS_SEVENTH: (state, payload) => {
        state.sku_rupture_series = payload["sku_rupture"].series;
        state.sku_rupture_title = payload["sku_rupture"].title;
        state.sku_rupture_categories = payload["sku_rupture"].labels;
    },
    SET_SUGGESTION_PARAMS: (state, payload) => (state.suggestion_params = payload),
    SET_FLAG_TO_CALL_API: (state, payload) => (state.readyToCallApi = payload),
    RESET_STORE: (state) => Object.assign(state, initial_state()),
    SET_CONTROLLER: (state, payload) => (state.controller = payload),
    SET_IMPORTED_FILES: (state, payload) => (state.importedFiles = payload),
};

const columnsSidebarFunctions = {
    openColumnsSidebar({ commit }) {
        commit("SET_COLUMNS_SIDEBAR", true);
    },
    closeColumnsSidebar({ commit }) {
        commit("SET_LOADING_SIDEBAR", false);
        commit("SET_COLUMNS_SIDEBAR", false);
    },
    toggleColumnsSidebar({ commit, state }) {
        commit("SET_COLUMNS_SIDEBAR", !state.columnsSidebar);
    },
};

const actions = {
    setDynamicFilterValues({ commit }, payload) {
        commit("SET_DYNAMIC_FILTER", returnCopyOfObject(payload));
    },
    changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        dispatch("getSuggestionsData");
    },
    changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PAGE", 1);
        commit("SET_PER_PAGE", per_page);
        dispatch("getSuggestionsData");
    },
    getData({ commit }) {
        commit("SET_FLAG_TO_CALL_API", false);

        commit("SET_LOADING_ALL_FILTERS", true);
        const filtersPromise = listAllFilters()
            .then((result) => {
                commit("SET_ALL_AVAILABLE_FILTERS", result.filters);
                commit(
                    "SET_CLIENT_OUT_OF_ROUTE",
                    result.outOfRouteClients ? result.outOfRouteClients : []
                );
            })
            .finally(() => commit("SET_LOADING_ALL_FILTERS", false));

        commit("SET_LOADING_GROUPS_AND_COLUMNS", true);
        const columnsPromise = getColumnsAndGroups()
            .then((result) => {
                const { columns, groups, exportColumns, defaultColumns } = result;

                const transformListFromBackend = (list) =>
                    list.map(({ key, text, ...rest }) => {
                        return { value: key, text, ...rest };
                    });

                const checkDefaultValues = (list, mutation_name) =>
                    list.forEach((element) => {
                        if (element.default) commit(mutation_name, element.key);
                    });

                checkDefaultValues(columns, "ADD_STRING_TO_TABLE_COLUMNS_FILTER");
                checkDefaultValues(exportColumns, "ADD_STRING_TO_SELECTED_EXPORT_COLUMNS");

                const columnsState = transformListFromBackend(columns);
                const groupsState = transformListFromBackend(groups);
                const exportColumnsState = transformListFromBackend(exportColumns);
                const defaultColumnsState = defaultColumns.map(({ key, text, value, ...rest }) => {
                    return {
                        value: key,
                        text,
                        disabled: rest["default"] ? true : false,
                        ...rest,
                    };
                });

                let allExportColumns = [...exportColumnsState, ...defaultColumnsState];
                allExportColumns.sort((a, b) => (a.order < b.order ? -1 : 1));

                commit("SET_COLUMNS", columnsState);
                commit("SET_GROUPS", groupsState);
                commit("SET_EXPORT_COLUMNS", allExportColumns);
            })
            .finally(() => commit("SET_LOADING_GROUPS_AND_COLUMNS", false));
    },
    getStatisticData({ getters, commit }) {
        const filter = getters.filterToApi;

        // commit("SET_LOADING_STATISTICS", true);
        // const first = getStatistics(filter, "first").then((res) => {
        //     commit("SET_STATISTICS_FIRST", res);
        //     return res;
        // });
        // const second = getStatistics(filter, "second").then((res) => {
        //     commit("SET_STATISTICS_SECOND", res);
        //     return res;
        // });
        // const third = getStatistics(filter, "third").then((res) => {
        //     commit("SET_STATISTICS_THIRD", res);
        //     return res;
        // });
        // const fourth = getStatistics(filter, "fourth").then((res) => {
        //     commit("SET_STATISTICS_FOURTH", res);
        //     return res;
        // });
        // const fifth = getStatistics(filter, "fifth").then((res) => {
        //     commit("SET_STATISTICS_FIFTH", res);
        //     return res;
        // });
        // const sixth = getStatistics(filter, "sixth").then((res) => {
        //     commit("SET_STATISTICS_SIXTH", res);
        //     return res;
        // });
        // const seventh = getStatistics(filter, "seventh").then((res) => {
        //     commit("SET_STATISTICS_SEVENTH", res);
        //     return res;
        // });

        // Promise.all([first, second, third, fourth, fifth, sixth, seventh]).finally(() =>
        //     commit("SET_LOADING_STATISTICS", false)
        // );
    },
    async getSuggestionsData({ commit, getters }) {
        const filter = getters.filterToApi;
        if (!filter.created) return;

        if (getters.controller) {
            getters.controller.abort();
            commit("SET_CONTROLLER", null);
        }

        commit("SET_LOADING_SUGGESTION", true);
        const [apiCall, controller] = getSuggestions(filter);

        commit("SET_CONTROLLER", controller);

        apiCall
            .then((result) => {
                commit("SET_SUGGESTIONS", result.suggestions);
                commit("SET_TOTAL_ITEMS", result.total_items);
                commit("SET_LOADING_SUGGESTION", false);
                commit("SET_CONTROLLER", null);
            })
            .catch((err) => {
                if (!axios.isCancel(err)) {
                    commit("SET_CONTROLLER", null);
                    commit("SET_LOADING_SUGGESTION", false);
                }
            });
    },
    createNewSuggestion({ commit }, listOfCdStore) {
        commit("saveStatus", "loading", { root: true });
        return createNewSuggestion(listOfCdStore)
            .then((result) => {
                return result;
            })
            .catch((err) => err)
            .finally(() => commit("saveStatus", "success", { root: true }));
    },
    importExcelFile({ commit, dispatch }, file) {
        commit("saveStatus", "loading", { root: true });
        importSuggestions(file)
            .then((result) => {
                commit(
                    "PUSH_SUCCESS_TO_ARRAY_PURE_TEXT",
                    "Importação das sugestões feita com sucesso!",
                    { root: true }
                );
                commit("saveStatus", "success", { root: true });
                dispatch("getSuggestionsData");
                dispatch("getImportedFiles");
            })
            .catch((err) => {
                console.error(err);
                commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao tentar importar.", { root: true });
                commit("saveStatus", "error", { root: true });
            });
    },
    exportExcelFile({ getters, commit }) {
        commit("saveStatus", "loading", { root: true });
        let filter = getters.filterToApi;
        delete filter.page;
        delete filter.per_page;

        return exportSuggestions({
            ...filter,
            columns: [...getters.exportColumnsFilter, ...getters.groupsFilter],
        })
            .then((res) => {
                const today = new Date();
                const filename = `sugestoes-export-${transformDateInString(today, true, true)}`;
                exportFileXLSX(res, filename);
                return res;
            })
            .catch((err) => {
                commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao tentar exportar.", { root: true });
                commit("saveStatus", "error", { root: true });
            })
            .finally(() => commit("saveStatus", "success", { root: true }));
    },
    selectSuggestion({ commit }, listOfIds) {
        commit("ADD_ID_TO_SELECT_SUGGESTIONS", listOfIds);
    },
    removeIdsOfSelectedSuggestion({ commit }, listOfIds) {
        commit("REMOVE_IDS_FROM_SELECTED_SUGGESTIONS", listOfIds);
    },
    async sendSuggestions({ state, commit, dispatch, getters }) {
        // const totalOfSelectedSuggestion = getters.selectedSuggestionsComplete.length;
        // const vueInstance = new Vue({
        //     render: (h) =>
        //         h(ClientInfo, {
        //             props: {
        //                 selectedSuggestions: getters.selectedSuggestionsComplete,
        //                 dialogSendEmail: true,
        //             },
        //         }),
        // });

        // const clientInfoHtml = await vueInstance.$mount().$el.outerHTML;

        // const firstConfirmation = await dispatch(
        //     "modal/ModalGeneric",
        //     {
        //         icon: "warning",
        //         title: "Envio das sugestões",
        //         text: `Deseja enviar ${totalOfSelectedSuggestion} sugestões? Essa ação irá encaminhar os produtos sugeridos para seus respectivos clientes. Deseja continuar?`,
        //         html: clientInfoHtml,
        //     },
        //     { root: true }
        // );

        // if (!firstConfirmation) return;

        const secondConfirmation = await dispatch(
            "modal/ModalGeneric",
            {
                icon: "error",
                title: "Confirmar envio",
                text: `Essa ação não pode ser revertida! Clique em 'Sim' para o envio das ${getters.totalItems} sugestões.`,
            },
            { root: true }
        );

        if (!secondConfirmation) return;

        const filter = getters.filterToApi;
        commit("saveStatus", "loading", { root: true });
        sendSuggestions(filter)
            .then(() => {
                commit("CLEAR_SELECTED_SUGGESTIONS");
                commit("saveStatus", "success", { root: true });
                dispatch("getSuggestionsData");
            })
            .catch((err) => {
                console.error(err);
                commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Erro ao enviar as sugestões.", {
                    root: true,
                });
                commit("saveStatus", "error", { root: true });
            });
    },
    getSuggestionParams({ commit }) {
        commit("saveStatus", "loading", { root: true });
        getSuggestionParams()
            .then((result) => {
                commit("SET_SUGGESTION_PARAMS", result);
            })
            .finally(() => {
                commit("saveStatus", "success", { root: true });
            });
    },
    saveSuggestionParams({ commit, dispatch }, payload) {
        commit("saveStatus", "loading", { root: true });
        saveSuggestionParams(payload)
            .then((result) => {
                dispatch("getSuggestionParams");
            })
            .catch(() => {
                commit("saveStatus", "error", { root: true });
            });
    },
    editSuggestionParams({ commit, dispatch }, payload) {
        commit("saveStatus", "loading", { root: true });
        editSuggestionParams(payload)
            .then((result) => {
                dispatch("getSuggestionParams");
            })
            .catch(() => {
                commit("saveStatus", "error", { root: true });
            });
    },
    deleteSuggestionParams({ commit, dispatch }, empresaId) {
        commit("saveStatus", "loading", { root: true });
        return deleteSuggestionParams(empresaId)
            .then(() => dispatch("getSuggestionParams"))
            .catch(() => commit("saveStatus", "error", { root: true }));
    },
    async editQuantityItem({ commit }, payload) {
        return await editQuantityItem(payload)
            .then(() => {
                commit("saveStatus", "success", { root: true });
            })
            .catch((error) => {
                commit("saveStatus", "error", { root: true });
                return Promise.reject(error);
            });
    },
    getImportedFiles({ commit }) {
        commit("saveStatus", "loading", { root: true });
        getImportedFiles()
            .then((result) => {
                commit("SET_IMPORTED_FILES", result);
            })
            .finally(() => {
                commit("saveStatus", "success", { root: true });
            });
    },
    ...columnsSidebarFunctions,
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
