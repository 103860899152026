import api from "@/services/api";

import { reverseStringDateWithSlash } from "@/helpers/date.helper";
import { prepareFormDataToRequest } from "@/helpers/http.helper";
import { prepareAndClearFormDataToRequest } from "@/helpers/http.helper";
import { removeEmptyFields } from "@/helpers/object.helper";

export const addItemCheckout = (payload, updatedValues, item) => {
    const { formValues, ...rest } = payload;
    const { sku = {}, price: vl_p_unitario, ...restFormValues } = formValues;

    const to_insertAux = {
        ...restFormValues,
        vl_p_unitario,
    };

    let data = {
        ...rest,
        item_referencia: item,

        to_insert: {},
    };

    Object.keys(updatedValues).forEach((key) => {
        if (key == "qty") data["to_insert"][key] = to_insertAux[key];

        if (!updatedValues[key]) return;

        const keyToBody = key != "sku__" ? (key == "price" ? "vl_p_unitario" : key) : "sku";

        if (keyToBody == "sku")
            data["to_insert"] = {
                ...data["to_insert"],
                ...sku,
            };
        else data["to_insert"][keyToBody] = to_insertAux[keyToBody];
    });
    const clearedObj = removeEmptyFields(data);
    return api().post("/checkout/insert_item_in_order", clearedObj);
};

export const editItemCheckout = (payload, updatedValues, reply) => {
    const { formValues, ...rest } = payload;
    const { price: vl_p_unitario, ...restReplay } = reply;

    const newReplay = { ...restReplay };
    if (vl_p_unitario) newReplay["vl_p_unitario"] = vl_p_unitario;

    let data = {
        ...rest,
        reply: newReplay,
        to_update: {},
        statement: "edit",
    };

    Object.keys(updatedValues).forEach((key) => {
        if (!updatedValues[key]) return;

        const keyToBody = key != "sku__" ? (key == "price" ? "vl_p_unitario" : key) : "sku";
        const keyToFormValues = key != "sku__" ? key : "sku";

        if (keyToBody == "sku")
            data["to_update"] = { ...data["to_update"], ...formValues[keyToFormValues] };
        else data["to_update"][keyToBody] = formValues[keyToFormValues];
    });
    return api().post("/checkout/update", data);
};

export const resendOrder = (data) => {
    const form = prepareFormDataToRequest(data);
    return api()
        .post(`/checkout/resend`, form)
        .then((res) => res.data);
};

export const duplicateOrder = (data) => {
    const form = prepareFormDataToRequest(data);
    return api()
        .post(`/checkout/duplicate_card`, form)
        .then((res) => res.data);
};

export const deleteDuplicatedOrder = () => {
    const form = prepareFormDataToRequest(data);
    return api()
        .post(`/checkout/delete_duplicate_card`, form)
        .then((res) => res.data);
};

export const deleteItemFromOrder = (data) => {
    return api()
        .post("/checkout/delete", data)
        .then((res) => res.data);
};

export default {
    list_orders(data) {
        const form = prepareAndClearFormDataToRequest(data);
        return api().post("/list-orders", form);
    },
    export_orders(data) {
        const form = prepareAndClearFormDataToRequest(data);
        return api().post("/list-orders", form);
    },
    list_orders_params() {
        return api().get(`/list-orders/params`);
    },
    upload(data) {
        data["canal"] = "WEB";
        const form = prepareAndClearFormDataToRequest(data, {
            divisao: "cd_divisao",
            operacao: "cd_operacao",
            payment_type: "payment_type_id",
        });
        return api().post("/compute", form);
    },
    status(orderId) {
        return api().post(`/status_processamento/${orderId}`);
    },
    find(data) {
        const form = new FormData();
        for (let key in data) form.append(key, data[key]);
        return api().post("/status_pedido", form);
    },
    export_find(data) {
        const form = new FormData();
        for (let key in data) form.append(key, data[key]);
        return api().post("/status_pedido", form);
    },
    curadoria(data) {
        return api().post("/curadoria_pedido", data);
    },
    list(data) {
        const form = new FormData();
        form.append("statement", "list");
        for (let key in data) form.append(key, data[key]);
        return api().post("/checkout/list", form);
    },
    export_list(data) {
        const form = new FormData();
        form.append("statement", "list");
        for (let key in data) form.append(key, data[key]);
        return api().post("/checkout/list", form, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    delete_grouped_items(data) {
        const form = new FormData();
        for (let key in data) form.append(key, data[key]);
        return api().post("/checkout/delete-grouped-items", form);
    },
    delete_order(data) {
        const form = new FormData();
        for (let key in data) form.append(key, data[key]);
        return api().post("/checkout/delete-order", form);
    },
    delete(data) {
        return api().post("/checkout/delete", data);
    },
    update(data) {
        const form = new FormData();
        for (let key in data) {
            if (key != "new_dt") form.append(key, data[key]);
            else form.append(key, reverseStringDateWithSlash(data[key]));
        }
        return api().post("/checkout/update", form);
    },
    update_order(values, cards, identifiers) {
        const obj = {
            ...identifiers,

            cards: [...cards],

            to_update: {},
        };

        Object.keys(values.updates).forEach((key) => {
            if (!values.updates[key]) return;

            const keyFormated = key != "sku__" ? key : "sku";
            obj["to_update"][keyFormated] = values["to_update"]["formValues"][keyFormated];
        });

        return api().patch(`/checkout/update_card`, obj);
    },
    update_multiple_items_in_order(data) {
        return api().patch("/checkout/update_item_in_all_orders", data);
    },
    process(data) {
        const form = new FormData();
        form.append("filename", data.filename);
        form.append("id", data.id);
        form.append("uuid_list", data.uuid_list);
        return api().post("/checkout/process", form);
    },
    approve_order(data) {
        const form = new FormData();
        Object.keys(data).forEach((element) => form.append(element, data[element]));
        return api().post("/approve_order", form);
    },
    approveOrderGlobal(data) {
        return api()
            .post(`/approve_order_global`, data)
            .then((res) => res.data);
    },
    reject_order(data) {
        const form = new FormData();
        Object.keys(data).forEach((element) => form.append(element, data[element]));
        return api().post("/reject_order", form);
    },
    rejectOrderGlobal(data) {
        return api()
            .post(`/reject_order_global`, data)
            .then((res) => res.data);
    },
    // Retorna os dados para rastreio do pedido, precisa informar o order_id
    track_order(id_order) {
        return api().post(`/tracking_order?order_id=${id_order}`);
    },
    delete_from_tables(data) {
        const form = new FormData();
        for (let key in data) form.append(key, data[key]);
        return api().post("/delete_order_id", form);
    },
    delete_from_card(data) {
        const form = prepareFormDataToRequest(data);
        return api().post("/checkout/delete-card-items", form);
    },
    retrieveDeletedItem(data) {
        const form = new FormData();
        for (let key in data) form.append(key, data[key]);
        return api().post("/checkout/return-deleted-item", form);
    },
    retrieveGroupedItem(data) {
        const form = new FormData();
        for (let key in data) form.append(key, data[key]);
        return api().post("/checkout/return-grouped-item", form);
    },
};
