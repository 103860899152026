import api from '@/services/api'

export default {
    list (data) {
        const form = new FormData()
        form.append('offset', data.offset)
        form.append('janela', data.janela)
        form.append('per_page', data.limit ? data.limit : 10)

        return api().post('/cockpit', form)
    },
    view (data) {
        const form = new FormData()
        form.append('view', '')
        form.append('buscar', '')
        form.append('s.update', data.dt_ref)
        form.append('e.name', data.nome_empresa)
        form.append('s.nome_cliente', data.nome_cliente)
        form.append('u.username', data.nome_usuario)
        form.append('s.canal', data.canal)
        form.append('offset', data.offset)
        form.append('janela', data.janela)
        form.append('per_page', data.limit ? data.limit : 10)

        return api().post('/cockpit', form)
    },
    find (data) {
        const form = new FormData()
        form.append('buscar', ''); 
        for(let key in data) form.append(key, data[key]); 
        // form.append('buscar', '')
        // form.append('s.update', data.dt_ref)
        // form.append('e.name', data.nome_empresa)
        // form.append('s.nome_cliente', data.nome_cliente )
        // form.append('u.username', data.nome_usuario)
        // form.append('s.canal', data.canal)
        // form.append('janela', data.janela)
        // form.append('offset', data.offset)
        // form.append('per_page', data.limit ? data.limit : 10)

        return api().post('/cockpit', form)
    },
    export (data) {
        const form = new FormData()
        form.append('exportar', '')
        form.append('buscar', '')
        for(let key in data) form.append(key, data[key]); 
        // form.append('s.update', data.dt_ref)
        // form.append('e.name', data.nome_empresa)
        // form.append('s.nome_cliente', data.nome_cliente)
        // form.append('u.username', data.nome_usuario)
        // form.append('s.canal', data.canal)
        // form.append('offset', data.offset)
        // form.append('janela', data.janela)
        // form.append('per_page', data.limit ? data.limit : 10)

        return api().post('/cockpit', form)
    }
}
