import Vue from "vue";
import VueI18n from "vue-i18n";

import pt from "./json/portuguese.json";
import en from "./json/english.json";
import es from "./json/spanish.json";

const messages = {
    pt,
    en,
    es,
};

Vue.use(VueI18n);

const languageChangedByUser = localStorage.getItem("language_changed_by_user");
const pedmaisLanguage = localStorage.getItem("pedmais_language");

const i18n = new VueI18n({
    locale: languageChangedByUser || pedmaisLanguage || "pt",
    fallbackLocale: "pt",
    formatFallbackMessages: true,
    silentFallbackWarn: true,
    messages, // set locale messages
});

export default i18n;
export const languagesMap = {
    pt: "pt-BR",
    en: "en",
};
