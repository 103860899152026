export default {
    en: {
        dateLabelFormat: 'dddd, MMMM D, YYYY',
        days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        daysShort: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
        ],
        colors: {
        selected: '#2E2C2C',
        inRange: '#696464',
        selectedText: '#fff',
        text: '#565a5c',
        inRangeBorder: '#33dacd',
        disabled: '#fff',
        hoveredInRange: '#9E9595'
        },
        texts: {
        apply: 'Select',
        cancel: 'Cancel',
        keyboardShortcuts: 'Keyboard Shortcuts',
        },
        // ariaLabels: {
        //   chooseDate: (date) => date,
        //   chooseStartDate: (date) => `Choose ${date} as your start date.`,
        //   chooseEndDate: (date) => `Choose ${date} as your end date.`,
        //   selectedDate: (date) => `Selected. ${date}`,
        //   unavailableDate: (date) => `Not available. ${date}`,
        //   previousMonth: 'Move backward to switch to the previous month.',
        //   nextMonth: 'Move forward to switch to the next month.',
        //   closeDatepicker: 'Close calendar',
        //   openKeyboardShortcutsMenu: 'Open keyboard shortcuts menu.',
        //   closeKeyboardShortcutsMenu: 'Close keyboard shortcuts menu'
        // },
        // keyboardShortcuts: [
        //   {symbol: '↵', label: 'Select the date in focus', symbolDescription: 'Enter key'},
        //   {symbol: '←/→', label: 'Move backward (left) and forward (right) by one day.', symbolDescription: 'Left or right arrow keys'},
        //   {symbol: '↑/↓', label: 'Move backward (up) and forward (down) by one week.', symbolDescription: 'Up or down arrow keys'},
        //   {symbol: 'PgUp/PgDn', label: 'Switch months.', symbolDescription: 'PageUp and PageDown keys'},
        //   {symbol: 'Home/End', label: 'Go to the first or last day of a week.', symbolDescription: 'Home or End keys'},
        //   {symbol: 'Esc', label: 'Close this panel', symbolDescription: 'Escape key'},
        //   {symbol: '?', label: 'Open this panel', symbolDescription: 'Question mark'}
        // ],
    },
    pt: {
        dateLabelFormat: 'dddd, MMMM D, YYYY',
        days: ['Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado', 'Domingo'],
        daysShort: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
        monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
        ],
        colors: {
        selected: '#2E2C2C',
        inRange: '#696464',
        selectedText: '#fff',
        text: '#565a5c',
        inRangeBorder: '#33dacd',
        disabled: '#fff',
        hoveredInRange: '#9E9595'
        },
        texts: {
        apply: 'Selecionar',
        cancel: 'Cancelar',
        keyboardShortcuts: 'Atalhos do teclado',
        },
        // ariaLabels: {
        //   chooseDate: (date) => date,
        //   chooseStartDate: (date) => `Choose ${date} as your start date.`,
        //   chooseEndDate: (date) => `Choose ${date} as your end date.`,
        //   selectedDate: (date) => `Selected. ${date}`,
        //   unavailableDate: (date) => `Not available. ${date}`,
        //   previousMonth: 'Move backward to switch to the previous month.',
        //   nextMonth: 'Move forward to switch to the next month.',
        //   closeDatepicker: 'Close calendar',
        //   openKeyboardShortcutsMenu: 'Open keyboard shortcuts menu.',
        //   closeKeyboardShortcutsMenu: 'Close keyboard shortcuts menu'
        // },
        keyboardShortcuts: [
          {symbol: '↵', label: 'Seleciona a data em foco', symbolDescription: 'Enter key'},
          {symbol: '←/→', label: 'Move para trás (esquerda) e pra frente (direita) em um dia.', symbolDescription: 'Left or right arrow keys'},
          {symbol: '↑/↓', label: 'Move para trás (pra cima) e pra frente (pra baixo) em uma semana', symbolDescription: 'Up or down arrow keys'},
          {symbol: 'PgUp/PgDn', label: 'Trocar meses.', symbolDescription: 'PageUp and PageDown keys'},
          {symbol: 'Home/End', label: 'Vá para o primeiro ou último dia da semana.', symbolDescription: 'Home or End keys'},
          {symbol: 'Esc', label: 'Fechar esse painel', symbolDescription: 'Escape key'},
          {symbol: '?', label: 'Abrir esse painel', symbolDescription: 'Question mark'}
        ],
    }
}