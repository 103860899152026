import start from "@/services/start";
import api from "@/services/api";

export default {
    login(data) {
        return start().post("/auth/login", {
            email: data.email,
            password: data.password
        });
    },

    logout() {
        return api().post("/auth/logout");
    },

    login_as_admin(user_email) {
        // const form = new FormData();
        const headers = {
            "Content-Type": "application/json"
        };

        const body = {
            new_user_email: user_email
        };

        return api().post("/get_user", body, headers);
        // return Promise.resolve({data: { token: 'token_Aqui', message: 'opa, deu bom!' }})
    },

    forgot_password(email) {
        const form = new FormData();

        form.append("email", email);

        return start().post("/auth/reset", form, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },

    set_new_password(new_password, token) {
        const form = new FormData();

        form.append("new_password", new_password);

        return start().post("/auth/reset/" + token, form, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },

    sendTwoFactorCode(code, token) {
        const form = new FormData();
        form.append("token", token);
        form.append("code", code);
        return start().post("/auth/login-2fa", form);
    },

    resendCode(token) {
        const form = new FormData();
        form.append("token", token);

        return start().post("/auth/login-2fa/newcode", form);
    }
};

// 123456
