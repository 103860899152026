<template>
    <div class="row content-header text-left ">
        <div class="col-12 col-sm-6 mb-2">
            <div class="d-flex content-header-title-page">
                <h4>{{ title }}</h4>
                <b-spinner class="ml-2" v-if="loading" label="Loading..."></b-spinner>
            </div>
            <small class="content-header-subtitle-page">
                {{ subtitle }}
            </small>
        </div>
        <div class="col-12 col-sm-6 align-self-end text-right mb-2 ">
            <small>
                <breadcrumb />
            </small>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "@/components/utils/Breadcrumb";

export default {
    name: "ContentHeader",
    components: {
        Breadcrumb
    },
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        }
    }
};
</script>
