<template>
    <SplashScreenVue />
</template>
<script>
import { mapActions } from "vuex";
import SplashScreenVue from "../loading/SplashScreen.vue";

export default {
    name: "NewLoginComponent",
    components: {
        SplashScreenVue,
    },
    methods: {
        ...mapActions(["set_token", "info"]),
    },
    async mounted() {
        const token = this.$route.params.token;

        this.set_token(token);

        await this.info();
        this.$router.replace("/");
    },
};
</script>
