<template>
    <b-skeleton-wrapper>
        <b-card>
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
        </b-card>
    </b-skeleton-wrapper>
</template>

<script>
export default {
    name: "SkeletonCard"
};
</script>
