var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _c("div", { staticClass: "d-flex flex-column login-header mb-4" }, [
      _c("img", {
        staticClass: "img-logo-pedmais",
        attrs: { src: require("../../assets/img/logo_pedmais_cut.png") },
      }),
      _c("div", { staticClass: "title-login-pedmais text-pedmais" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.$t("A PLATAFORMA INTELIGENTE DE PEDIDOS")) +
            "\n        "
        ),
      ]),
    ]),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("div", { staticClass: "mt-2 mb-3" }, [
            _c("h4", { staticClass: "titulo-login text-black-pedmais" }, [
              _vm._v(_vm._s(_vm.$t("auth.title"))),
            ]),
          ]),
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onLoginSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "div",
                  { staticClass: "input-group" },
                  [
                    _c("div", { staticClass: "input-group-prepend" }, [
                      _c(
                        "span",
                        {
                          staticClass: "input-group-text",
                          attrs: { id: "email" },
                        },
                        [
                          _c("b-icon", {
                            staticClass: "text-pedmais",
                            attrs: { icon: "envelope" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-form-input", {
                      staticClass: "input-pedmais input-login",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("auth.email"),
                        "aria-label": _vm.$t("auth.email"),
                        "aria-describedby": "email",
                        required: "",
                      },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-group mb-3" }, [
                _c(
                  "div",
                  { staticClass: "input-group" },
                  [
                    _c("div", { staticClass: "input-group-prepend" }, [
                      _c(
                        "span",
                        {
                          staticClass: "input-group-text",
                          attrs: { id: "password" },
                        },
                        [
                          _c("b-icon", {
                            staticClass: "text-pedmais",
                            attrs: { icon: "lock" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-form-input", {
                      staticClass: "input-pedmais input-login",
                      attrs: {
                        type: !_vm.show_password ? "password" : "text",
                        placeholder: _vm.$t("auth.password"),
                        "aria-label": _vm.$t("auth.password"),
                        "aria-describedby": "password",
                        required: "",
                      },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "input-group-append",
                        on: {
                          click: function ($event) {
                            _vm.show_password = !_vm.show_password
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "show-password input-group-text" },
                          [
                            _c("b-icon", {
                              staticClass: "text-pedmais",
                              attrs: {
                                icon: !_vm.show_password ? "eye-slash" : "eye",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm.error_show
                ? _c("div", { staticClass: "error-message-the-login" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t(_vm.error_message)) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "row justify-content-between no-gutters form-group mb-3",
                },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { switch: "" },
                      model: {
                        value: _vm.lembrar_me,
                        callback: function ($$v) {
                          _vm.lembrar_me = $$v
                        },
                        expression: "lembrar_me",
                      },
                    },
                    [_c("small", [_vm._v(_vm._s(_vm.$t("auth.remember_me")))])]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "card-body-link",
                      attrs: {
                        to: {
                          name: "esqueceu_senha",
                          params: { email: "email" },
                        },
                      },
                    },
                    [
                      _c("small", { staticClass: "text-pedmais" }, [
                        _vm._v(_vm._s(_vm.$t("auth.forgot_password")) + " "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              !_vm.loading_login
                ? _c(
                    "div",
                    { staticClass: "form-group mb-3" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            block: "",
                            disabled: _vm.loading_login,
                            variant: "secondary",
                            type: "submit",
                          },
                        },
                        [
                          !_vm.loading_login
                            ? _c("b-icon", {
                                attrs: { icon: "box-arrow-in-right" },
                              })
                            : _c("b-icon", {
                                attrs: {
                                  icon: "three-dots",
                                  animation: "cylon",
                                },
                              }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("buttons.enter")) +
                              "\n                    "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-center mb-4",
                      staticStyle: { "min-height": "37.19px" },
                    },
                    [_c("b-spinner")],
                    1
                  ),
            ]
          ),
          _c("hr"),
          _vm._m(0),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "row align-items-center justify-content-center no-gutters",
      },
      [
        _c("img", {
          attrs: {
            src: require("../../assets/img/logo_weduu.png"),
            width: "100px",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }