import { fibonacci } from "./math.helper";
import i18n from "@/plugins/i18n";

export const returnTimeFromFibonnaci = function (numberAttempt) {
    const aux = fibonacci(numberAttempt);
    return aux >= 60 ? 60 : aux;
};

export const secondsToString = function (seconds) {
    const secondsRounded = Math.round(seconds);

    const minutes = Math.floor(secondsRounded / 60);
    const secondsRest = Math.round(seconds % 60);

    return [minutes, secondsRest];
};

export const secondsToCompleteString = function (segundos) {
    if (segundos <= 0) {
        return i18n.tc("time.seconds", 0);
    }

    const anos = Math.floor(segundos / (365 * 24 * 60 * 60));
    const dias = Math.floor((segundos % (365 * 24 * 60 * 60)) / (24 * 60 * 60));
    const horas = Math.floor((segundos % (24 * 60 * 60)) / (60 * 60));
    const minutos = Math.floor((segundos % (60 * 60)) / 60);
    const segundosRestantes = segundos % 60;

    const partes = [];
    if (anos > 0) {
        //   partes.push(anos + (anos === 1 ? " ano" : " anos"));
        partes.push(i18n.tc("time.years", anos, { years: anos }));
    }
    if (dias > 0) {
        // partes.push(dias + (dias === 1 ? " dia" : " dias"));
        partes.push(i18n.tc("time.days", dias, { days: dias }));
    }
    if (horas > 0) {
        // partes.push(horas + (horas === 1 ? " hora" : " horas"));
        partes.push(i18n.tc("time.hours", horas, { hours: horas }));
    }
    if (minutos > 0) {
        // partes.push(minutos + (minutos === 1 ? " minuto" : " minutos"));
        partes.push(i18n.tc("time.minutes", minutos, { minutes: minutos }));
    }
    if (segundosRestantes > 0) {
        // partes.push(segundosRestantes + (segundosRestantes === 1 ? " segundo" : " segundos"));
        partes.push(i18n.tc("time.seconds", segundosRestantes, { seconds: segundosRestantes }));
    }

    if (partes.length > 1) {
        const ultimasDuasPartes = partes.slice(-2).join(i18n.tc("connectors.and"));
        partes.splice(partes.length - 2, 2, ultimasDuasPartes);
    }

    return partes.join(", ");
};
