<template>
    <div>
        <!-- HEADER -->
        <content-header :title="title_page" :subtitle="subtitle_page" :loading="loading" />
        <div class="conteudo-pagina">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import ContentHeader from "@/components/board/content/ContentHeader";

export default {
    name: "TemplatePageComponent",
    components: {
        ContentHeader
    },
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        // Titulos da página
        title_page: {
            type: String,
            required: true
        },
        subtitle_page: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            data: new Date()
        };
    },
    methods: {
        changePage(page) {
            this.$emit("change_page", page);
        },

        Pesquisar(filtro) {
            if (this.show_atualizando_em) this.data = new Date();
            this.$emit("pesquisar", filtro);
        },

        CustomEvent(event) {
            this.$emit(event.name, event.data);
        },

        // Eventos para serem acessados de fora
        ClicarFiltroGeralPesquisar() {
            this.$refs["filtro_geral_testes"].Pesquisar();
        },

        LerFiltroGeral() {
            return this.$refs["filtro_geral_testes"].ReadFilter();
        },

        InserirValorNoFiltro(campo, valor) {
            this.$refs["filtro_geral_testes"].InsertValue(campo, valor);
        },

        LimparFiltroGeral() {
            this.$refs["filtro_geral_testes"].LimparFiltro();
        },

        ResetarPaginasWeduuPaginator() {
            this.$refs["paginator_frame_geral"].ResetarPaginas();
        },

        PaginatorLimitChanged(itens) {
            this.InserirValorNoFiltro("limit", itens);
            this.ClicarFiltroGeralPesquisar();
        }
    }
};
</script>
