var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "breadcrumb-pedmais" },
    [
      _c(
        "router-link",
        { attrs: { to: "/" } },
        [
          _c("b-icon", {
            staticClass: "breadcrumb-pedmais-icon",
            attrs: { icon: "house-door" },
          }),
        ],
        1
      ),
      _c("b-breadcrumb", {
        staticClass: "breadcrumb-pedmais-text",
        attrs: { items: _vm.crumbArray },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }