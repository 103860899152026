import api from "@/services/api";
import { objectToURLQuery } from "@/helpers/http.helper";

// consultas
export const getSoldSkus = (filter) => {
    return api()
        .get(`/central/skus?${objectToURLQuery(filter)}`)
        .then((res) => res.data);
};

export const getProducts = () => {
    return api()
        .get("/central/products")
        .then((res) => res.data);
};

export const getIndicators = (filter) => {
    return api()
        .get(`/central/indicators?${objectToURLQuery(filter)}`)
        .then((res) => res.data);
};

export const getOrderDetail = (orderId) => {
    return api()
        .get(`/central/order-detail/${orderId}`)
        .then((res) => res.data);
};

export const getOpenCarts = (filter) => {
    return api()
        .get(`/central/open-cart?${objectToURLQuery(filter)}`)
        .then((res) => res.data);
};

export const getReceiveds = (filter) => {
    return api()
        .get(`/central/received?${objectToURLQuery(filter)}`)
        .then((res) => res.data);
};

export const getOrders = (filter) => {
    return api()
        .get(`/central/orders?${objectToURLQuery(filter)}`)
        .then((res) => res.data);
};

export const getSuggestion = (filter) => {
    return api()
        .get(`/central/suggestion?${objectToURLQuery(filter)}`)
        .then((res) => res.data);
};

// edições
export const addNewProduct = (
    suggestionId = "2043826c-224c-42de-814b-cb185f5dd9a6",
    productId = 7890123456,
    quantity = 10
) => {
    return api()
        .post(
            `/central/suggestion/${suggestionId}/product/${productId}`,
            JSON.stringify({
                quantity,
            })
        )
        .then((res) => res.data);
};

export const editProductQuantity = (
    suggestionId = "2043826c-224c-42de-814b-cb185f5dd9a6",
    productId = 7890123456,
    quantity = 10
) => {
    return api()
        .put(
            `/central/suggestion/${suggestionId}/product/${productId}`,
            JSON.stringify({
                quantity,
            })
        )
        .then((res) => res.data);
};

export const editSuggestion = (
    suggestionId = "2043826c-224c-42de-814b-cb185f5dd9a6",
    status = "approved"
) => {
    return api()
        .put(
            `/central/suggestion/${suggestionId}`,
            JSON.stringify({
                status,
            })
        )
        .then((res) => res.data);
};

export const deleteProduct = (
    suggestionId = "2043826c-224c-42de-814b-cb185f5dd9a6",
    productId = 7890123456
) => {
    return api()
        .delete(`/central/suggestion/${suggestionId}/product/${productId}`)
        .then((res) => res.data);
};

// notifications
export const notifyClients = () => {
    return api()
        .post("/central/notify-clients")
        .then((res) => res.data);
};

// suggestion
export const changeSuggestionStatus = (suggestionId, status) => {
    return api()
        .put(`/central/suggestion/${suggestionId}`, {
            statusProposals: status, // approve || reject || null
        })
        .then((res) => res.data);
};

export const deleteProductInSuggestion = (suggestionId, productId) => {
    return api()
        .delete(`/central/suggestion/${suggestionId}/product/${productId}`)
        .then((res) => res.data);
};

export const addProductQuantityInSuggestion = (suggestionId, productId, quantity) => {
    return api()
        .post(
            `/central/suggestion/${suggestionId}/product/${productId}`,
            JSON.stringify({
                quantity,
            })
        )
        .then((res) => res.data);
};

export const addProductInSuggestion = (suggestionId, productId, quantity) => {
    return api()
        .post(
            `/central/suggestion/${suggestionId}/product/${productId}`,
            JSON.stringify({
                quantity,
            })
        )
        .then((res) => res.data);
};

// orders
export const createNewOrder = (order = {}) => {
    return api()
        .post("/central/orders", JSON.stringify(order))
        .then((res) => res.data);
};

export const addProductInOrder = (orderId, productId, product) => {
    return api()
        .post(`/central/orders/${orderId}/product/${productId}`, JSON.stringify(product))
        .then((res) => res.data);
};

export const editQuantityOfProductInOrder = (orderId, productId, quantity) => {
    return api()
        .put(
            `/central/orders/${orderId}/product/${productId}`,
            JSON.stringify({
                quantity,
            })
        )
        .then((res) => res.data);
};

export const deleteProductInNewOrder = (orderId, productId) => {
    return api()
        .delete(`/central/orders/${orderId}/product/${productId}`)
        .then((res) => res.data);
};
