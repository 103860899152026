import api from "@/services/api";

export default {
    list(data) {
        const form = new FormData();

        form.append("limpar", "");
        form.append("offset", data.offset);
        form.append("per_page", data.limit ? data.limit : 10);

        return api().post("/views/sku", form); // rota vinda do backend
    },

    find(data) {
        const form = new FormData();

        form.append("buscar", "");
        for (let key in data) form.append(key, data[key]);
        return api().post("/views/sku", form);
    },

    find_sku(data) {
        const form = new FormData();
        form.append("empresa_id", data.empresa_id);
        return api().post(`/views/sku/${data.cd_sku}`, form);
    },
    export_table(data) {
        var filtered_obj = Object.keys(data).filter((item) => data[item] !== "");
        var query = filtered_obj.map((item) => `${item}=${data[item]}`);
        return api().post("/views/sku/export_file?" + query.join("&"), null, {
            responseType: "blob",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "*",
            },
        });
    },
};
