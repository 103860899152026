// Estilos
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import "@/assets/scss/_main.scss";
import "@/assets/global.scss";
import _ from "lodash";
import "dotenv/config";

import AppConfig from "@/configs/app.config";

import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueSweetalert2, AppConfig.components.config.vue_sweetalert2);

Object.keys(AppConfig.directives).forEach((key) => Vue.directive(key, AppConfig.directives[key]));
Object.keys(AppConfig.filters).forEach((key) => Vue.filter(key, AppConfig.filters[key]));

import "@/plugins/font-awesome";
import "@/plugins/airbnb-datepicker";

import VueCompositionAPI from "@vue/composition-api";

Vue.use(VueCompositionAPI);

import { install as VueMonacoEditorPlugin } from "@guolao/vue-monaco-editor";

Vue.use(VueMonacoEditorPlugin, {
    paths: {
        // The recommended CDN config
        vs: "https://cdn.jsdelivr.net/npm/monaco-editor@0.38.0/min/vs",
    },
});

import VueMask from "v-mask";
Vue.use(VueMask);

import money from "vuejs-money";
Vue.use(money, { precision: 2 });

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

// Vue Imports
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n/index";

// // Vue Config
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.config.productionTip = process.env.NODE_ENV == "production";
Object.defineProperty(Vue.prototype, "_", { value: _ });

export default new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
