var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "client-info-wrapper" }, [
      _c("div", { staticClass: "client-name" }, [
        _c("h5", [
          _c("span", [_vm._v("#")]),
          _c("span", { staticClass: "cd_store" }, [
            _vm._v(_vm._s(_vm.client.cd_store)),
          ]),
          _c("span", [_vm._v("-")]),
          _c("span", [_vm._v(_vm._s(_vm.client.nome_cliente))]),
        ]),
        _c(
          "span",
          [
            _c("SuggestionBadge", { attrs: { variant: "warning" } }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.client.nm_retail) +
                  "\n                "
              ),
            ]),
            _c("SuggestionBadge", { attrs: { variant: "warning" } }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.client.nm_banner) +
                  "\n                "
              ),
            ]),
            _vm.client.suggestion_out_of_route
              ? _c(
                  "SuggestionBadge",
                  { attrs: { variant: "danger" } },
                  [
                    _c("b-icon", { attrs: { icon: "stars" } }),
                    _vm._v(
                      "\n                    Fora de Rota\n                "
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "first-line" }, [
        _c("div", [
          _c(
            "span",
            [
              _c("b-icon", { attrs: { icon: "calendar3" } }),
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm._f("transformAndFormatOnlyDateGMT")(_vm.client.created)
                  ) +
                  "\n                "
              ),
            ],
            1
          ),
          _vm.dtDelivery
            ? _c(
                "span",
                [
                  _c("b-icon", { attrs: { icon: "truck" } }),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("transformAndFormatOnlyDateGMT")(_vm.dtDelivery)
                      ) +
                      "\n                "
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            [
              _c("b-icon", {
                staticClass: "icon",
                attrs: { icon: "geo-alt-fill" },
              }),
              _c("span", { staticClass: "address" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.clientAddress) +
                    "\n                    "
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("div", [
          _c(
            "span",
            { staticClass: "cnpj" },
            [
              _c("b-icon", { attrs: { icon: "shop" } }),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm._f("formatCNPJ")(_vm.client.num_cnpj)) +
                  "\n                "
              ),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "cnpj" },
            [
              _c("b-icon", { attrs: { icon: "cash-coin" } }),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.sumOfRecTransferPack) +
                  "\n                "
              ),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "cnpj" },
            [
              _c("b-icon", { attrs: { icon: "cart3" } }),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.sumOfSuggestions) +
                  "\n                "
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }