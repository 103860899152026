<template>
    <div class="wrapper">
        <div class="client-info-wrapper">
            <div class="client-name">
                <h5>
                    <span>#</span>
                    <span class="cd_store">{{ client.cd_store }}</span>
                    <span>-</span>
                    <span>{{ client.nome_cliente }}</span>
                </h5>
                <span>
                    <SuggestionBadge :variant="'warning'">
                        {{ client.nm_retail }}
                    </SuggestionBadge>
                    <SuggestionBadge :variant="'warning'">
                        {{ client.nm_banner }}
                    </SuggestionBadge>
                    <SuggestionBadge v-if="client.suggestion_out_of_route" :variant="'danger'">
                        <b-icon icon="stars"></b-icon>
                        Fora de Rota
                    </SuggestionBadge>
                </span>
            </div>
            <div class="first-line">
                <div>
                    <span>
                        <b-icon icon="calendar3"></b-icon>
                        {{ client.created | transformAndFormatOnlyDateGMT }}
                    </span>

                    <span v-if="dtDelivery">
                        <b-icon icon="truck"></b-icon>
                        {{ dtDelivery | transformAndFormatOnlyDateGMT }}
                    </span>

                    <span>
                        <b-icon class="icon" icon="geo-alt-fill"></b-icon>
                        <span class="address">
                            {{ clientAddress }}
                        </span>
                    </span>
                </div>

                <div>
                    <span class="cnpj">
                        <b-icon icon="shop"></b-icon>
                        {{ client.num_cnpj | formatCNPJ }}
                    </span>

                    <span class="cnpj">
                        <b-icon icon="cash-coin"></b-icon>
                        {{ sumOfRecTransferPack }}
                    </span>

                    <span class="cnpj">
                        <b-icon icon="cart3"></b-icon>
                        {{ sumOfSuggestions }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { findStateNameByAbbreviation } from "@/helpers/geolocation.helper.js";
import SuggestionBadge from "./SuggestionBadge.vue";

export default {
    components: {
        SuggestionBadge,
    },
    props: {
        client: {
            required: false,
        },
        dialogSendEmail: {
            required: false,
        },
    },
    filters: {
        formatCNPJ(value) {
            let newValue = `${value}`.padStart(14, "0");
            newValue = newValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            return newValue;
        },
    },
    data() {
        return {
            showDetails: false, // Estado para controlar a visibilidade
        };
    },
    computed: {
        clientAddress() {
            return `${this.client.cd_country}, ${findStateNameByAbbreviation(this.client.cd_uf)}, ${
                this.client.nm_city
            } - ${this.client.nm_district}`;
        },
        sendBadge() {
            const sendType = {
                0: {
                    text: "Email",
                    variant: "secondary",
                },
                4: {
                    text: "EDI Reverso",
                    variant: "primary",
                },
                2: {
                    text: "SAP",
                    variant: "warning",
                },
            };

            const {
                client: { tipo_envio },
            } = this;
            const aux = sendType[tipo_envio];
            if (!aux) return null;
            return aux;
        },
        dtDelivery() {
            try {
                const dt_delivery = this.client.dt_delivery;
                return dt_delivery;
            } catch {
                return false;
            }
        },
        sumOfRecTransferPack() {
            const totalVlRecTransferPack = this.client.suggestion.reduce((acc, item) => {
                const value = parseFloat(item.data.vl_rec_transfer_pack);
                if (!isNaN(value)) {
                    return acc + value;
                }
                return acc;
            }, 0);

            const formattedTotal = totalVlRecTransferPack.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });

            return formattedTotal;
        },
        sumOfSuggestions() {
            return this.client.suggestion.length;
        },
    },
};
</script>

<style lang="scss" scoped>
.client-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.client-name {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    gap: 8px;

    h5,
    span {
        display: flex;
    }
}

.first-line {
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;

    div {
        display: flex;
        align-items: center;
        gap: 12px;

        span {
            white-space: nowrap;
        }
    }
}

.cnpj {
    font-weight: bold;
}

h5 {
    margin-left: -5px;
    display: flex;
    gap: 5px;
    margin: 0;
    padding: 0;
    font-weight: 600;
}
.list-client-info {
    display: flex;
    align-items: flex-end;
}
.client-info {
    display: flex;
    flex-direction: column;
    padding-right: 10px;

    .title {
        font-size: 10px;
        font-weight: bolder;
    }

    .value {
        font-size: 14px;
    }
}

.client-info:not(:first-child) {
    border-left: 0.5px solid rgba($color: #000000, $alpha: 0.24);
    padding: 0px 10px;
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s ease;
}
.slide-enter, .slide-leave-to /* .slide-leave-active in <2.1.8 */ {
    transform: translateY(-100%);
    opacity: 0;
}
.toggle-details {
    cursor: pointer; /* Melhora a indicação visual de que é clicável */
}
.suggestions-list {
    max-height: 300px !important;
    overflow-y: auto;
}
.suggestions-text {
    font-size: 0.9rem;
}
.wrapper {
    ::-webkit-scrollbar {
        width: 8px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888 !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
    }
}
</style>
