import companyService from "@/services/api/companies";

const state = {
    companies: [],
    logo: null,
    loading_companies: false,
};

const getters = {
    companies: (state) => state.companies,
    company_logo: (state) => state.logo,
    loading_companies: (state) => state.loading_companies,
};

const mutations = {
    setCompanies(state, companies) {
        state.companies = companies;
    },
    setLogo(state, logo) {
        state.logo = logo;
    },
    setLoading(state, loading_companies) {
        state.loading_companies = loading_companies;
    }
};

const actions = {
    async getCompanies({ commit }) {
        try {
            commit("setLoading", true);
            const res = await companyService.list_companies();
            commit("setCompanies", res.data.logos);
        } catch (error) {
            console.error(error);
        }
        commit("setLoading", false);
    },
    async deleteCompany({ dispatch }, id) {
        try {
            await companyService.delete_company(id);
            dispatch("getCompanies");
        } catch (error) {
            console.error(error);
        }
    },
    async addLogo({ commit, dispatch }, data) {
        try {
            await companyService.add_logo(data);
            dispatch("getCompanies");
            commit("PUSH_SUCCESS_TO_ARRAY", "Logo adicionado com sucesso!", { root: true });
        } catch (error) {
            console.error(error);
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", error.response.data.message, { root: true });
        }
    },

    async editLogo({ commit, dispatch }, data) {
        try {
            await companyService.edit_logo(data);
            dispatch("getCompanies");
            commit("PUSH_SUCCESS_TO_ARRAY", "Logo editado com sucesso!", { root: true });
        } catch (error) {
            console.error(error);
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", error.response.data.message, { root: true });
        }
    },

    async getLogoBase64({ commit, dispatch }, empresa_id) {
        dispatch("clearLogo");
        try {
            const res = await companyService.get_logo_base64(empresa_id);
            commit("setLogo", res.data.logo);
        } catch (error) {
            console.error(error);
        }
    },
    clearLogo({ commit }) {
        commit("setLogo", null);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
