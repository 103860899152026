import api from "@/services/api";
import { objectToURLQuery } from "@/helpers/http.helper";
import { removeEmptyFields } from "@/helpers/object.helper";

// import { AbortController } from "axios";

const clearObjectAndTransformToURLQuery = (obj) => objectToURLQuery(removeEmptyFields(obj));

export const listAllFilters = () => {
    return api()
        .get(`/suggestion_center/all_filters`)
        .then((res) => res.data);
};

export const savedFilters = () => {
    return api()
        .get(`/suggestion_center/filter`)
        .then((res) => res.data);
};

export const saveNewFilter = (jsonBody) => {
    return api()
        .post(`/suggestion_center/filter`, jsonBody)
        .then((res) => res.data);
};

export const updateFilter = (filterId, jsonBody) => {
    return api()
        .put(`/suggestion_center/filter/${filterId}`, jsonBody)
        .then((res) => res.data);
};

export const deleteExistingFilter = (filterId) => {
    return api()
        .delete(`/suggestion_center/filter/${filterId}`)
        .then((res) => res.data);
};

export const getColumnsAndGroups = () => {
    return api()
        .get(`/suggestion_center/columns`)
        .then((res) => res.data);
};

export const getSuggestions = (filter) => {
    const controller = new AbortController();
    const apiCall = api()
        .get(`/suggestion_center/suggestions?${clearObjectAndTransformToURLQuery(filter)}`, {
            signal: controller.signal,
        })
        .then((res) => res.data);
    return [apiCall, controller];
};

export const createNewSuggestion = (listOfCdStore) => {
    return api()
        .post(`/suggestion_center/suggestions`, {
            cd_store: listOfCdStore,
        })
        .then((res) => res.data);
};

export const editSuggestion = (suggestionId, jsonData, filter) => {
    return api()
        .put(
            `/suggestion_center/suggestions/${suggestionId}?${clearObjectAndTransformToURLQuery(
                filter
            )}`,
            jsonData
        )
        .then((res) => res.data);
};

export const editMultipleSuggestions = (listOfOrderIds, changes) => {
    return api()
        .put(`/suggestion_center/suggestions/multiple_updates`, {
            ids: listOfOrderIds,
            to_update: changes,
        })
        .then((res) => res.data);
};

export const deleteSuggestion = (suggestionId) => {
    return api()
        .delete(`/suggestion_center/suggestions/${suggestionId}`)
        .then((res) => res.data);
};

export const exportSuggestions = (filter) => {
    return api()
        .post(
            `/suggestion_center/suggestions/export?${clearObjectAndTransformToURLQuery(filter)}`,
            null,
            {
                responseType: "blob",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((res) => res.data);
};

export const importSuggestions = (file) => {
    const form = new FormData();
    form.append(`file`, file);

    return api()
        .post(`/suggestion_center/suggestions/import`, form)
        .then((res) => res.data);
};

export const getOrders = (filter) => {
    return api()
        .get(`/suggestion_center/orders?${clearObjectAndTransformToURLQuery(filter)}`)
        .then((res) => res.data);
};

export const sendSuggestions = (filter) => {
    return api()
        .post(`/suggestion_center/send?${clearObjectAndTransformToURLQuery(filter)}`)
        .then((res) => res.data);
};

export const getStatistics = (filter, endpoint) => {
    return api()
        .get(`/suggestion_center/statistics/${endpoint}`, filter)
        .then((res) => res.data);
};

export const getSuggestionParams = () => {
    return api()
        .get(`/suggestion_center/params`)
        .then((res) => res.data);
};

export const saveSuggestionParams = (params) => {
    return api()
        .post(`/suggestion_center/params`, params)
        .then((res) => res.data);
};

export const editSuggestionParams = (params) => {
    return api()
        .put("/suggestion_center/params", params)
        .then((res) => res.data);
};

export const deleteSuggestionParams = (empresaId) => {
    return api().delete(`/suggestion_center/params/${empresaId}`);
};

export const getImportedFiles = () => {
    return api()
        .get(`/suggestion_center/suggestions/import_log`)
        .then((res) => res.data);
};

export const editQuantityItem = (params) => {
    const { order_id, cd_product, ...rest } = params;
    return api()
        .put(`/suggestion_center/suggestions/${order_id}/product/${cd_product}`, {
            ...rest,
        })
        .then((res) => res.data)
};
