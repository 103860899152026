import store from "@/store";

export default (to, from, next) => {
    if (!store.getters.isAuthenticated) next("/login");
    var permissions = store.getters.page_permission[to.name];
    var role = store.getters.role;
    var aux = permissions.indexOf(role);
    next();
    if (store.getters.pages_limited.length == 0) {
        if (aux >= 0) {
            next();
        } else {
            next("/no_permission");
        }
    } else {
        let indexOf = store.getters.pages_limited.indexOf(to.name);
        if (indexOf != -1) next();
        else next();
    }
};
