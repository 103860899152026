<template>
    <div class="wrapper">
        <input
            class="hidden-input"
            ref="input-money"
            @input="updateMoney($event)"
            @keydown="handleKeydown($event)"
            :value="formatMoney(money)"
            type="number"
            min="0"
            max="1000000000"
            pattern="\d*"
        />
        <div @click="$refs['input-money'].focus()" class="inputed-value">
            <span>
                <span class="format-money-css">{{ moneyFormated }}</span>
            </span>

            <span class="mr-1 p-1" @click.stop="clearInputInfo()">
                <b-icon icon="x-lg"></b-icon>
            </span>
        </div>
    </div>
</template>

<script>
const convertStringToNumberAndCheckNaN = function (value) {
    const convertStringToNumber = parseFloat(value);
    if (isNaN(convertStringToNumber)) return 0;
    return convertStringToNumber * 0.01;
};

const numberToLocaleString = function (value) {
    const multiplyByTenDecimal = convertStringToNumberAndCheckNaN(value);
    const convertNumberToLocaleString = multiplyByTenDecimal.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
    });
    return convertNumberToLocaleString;
};

export default {
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            money: this.value ? this.value : 0,
        };
    },
    computed: {
        moneyFormated() {
            return numberToLocaleString(this.money);
        },
    },
    methods: {
        updateMoney(e) {
            const getvalue = e.target.value;
            this.money = getvalue;
            this.$emit("input", convertStringToNumberAndCheckNaN(getvalue));
        },
        formatMoney(money) {
            return money;
        },
        handleKeydown(event) {
            switch (event.key) {
                case "ArrowLeft":
                case "ArrowRight":
                case "ArrowUp":
                case "ArrowDown":
                    event.preventDefault();
                    break;
                default:
                    return;
            }
        },
        clearInputInfo() {
            this.money = 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    position: relative;
    height: 35px;
    width: 100%;

    .hidden-input {
        width: 10px;
        position: absolute;
        border: 0px;
        top: 5px;
        left: 5px;
    }

    .hidden-input:focus {
        border: 0px;
    }

    .inputed-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgba($color: #000000, $alpha: 0.18);
        border-radius: 5px;
        padding-left: 5px;
        font-size: 13px;
        color: rgb(53, 52, 52);
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        height: 35px;
        width: 100%;
    }
}

.format-money-css {
    font-size: 13px !important;
}
</style>
