var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper-input-code" },
    [
      _c("b-input", {
        ref: "input_1",
        staticClass: "input-validation-code",
        attrs: { autofocus: "" },
        on: {
          keydown: function ($event) {
            return _vm.methodKeyDown($event, 1)
          },
        },
        model: {
          value: _vm.c1,
          callback: function ($$v) {
            _vm.c1 = $$v
          },
          expression: "c1",
        },
      }),
      _c("b-input", {
        ref: "input_2",
        staticClass: "input-validation-code",
        on: {
          keydown: function ($event) {
            return _vm.methodKeyDown($event, 2)
          },
          click: function ($event) {
            $event.preventDefault()
          },
        },
        model: {
          value: _vm.c2,
          callback: function ($$v) {
            _vm.c2 = $$v
          },
          expression: "c2",
        },
      }),
      _c("b-input", {
        ref: "input_3",
        staticClass: "input-validation-code",
        on: {
          keydown: function ($event) {
            return _vm.methodKeyDown($event, 3)
          },
          click: function ($event) {
            $event.preventDefault()
          },
        },
        model: {
          value: _vm.c3,
          callback: function ($$v) {
            _vm.c3 = $$v
          },
          expression: "c3",
        },
      }),
      _c("b-input", {
        ref: "input_4",
        staticClass: "input-validation-code",
        on: {
          keydown: function ($event) {
            return _vm.methodKeyDown($event, 4)
          },
          click: function ($event) {
            $event.preventDefault()
          },
        },
        model: {
          value: _vm.c4,
          callback: function ($$v) {
            _vm.c4 = $$v
          },
          expression: "c4",
        },
      }),
      _c("b-input", {
        ref: "input_5",
        staticClass: "input-validation-code",
        on: {
          keydown: function ($event) {
            return _vm.methodKeyDown($event, 5)
          },
          click: function ($event) {
            $event.preventDefault()
          },
        },
        model: {
          value: _vm.c5,
          callback: function ($$v) {
            _vm.c5 = $$v
          },
          expression: "c5",
        },
      }),
      _c("b-input", {
        ref: "input_6",
        staticClass: "input-validation-code",
        on: {
          keydown: function ($event) {
            return _vm.methodKeyDown($event, 6)
          },
          click: function ($event) {
            $event.preventDefault()
          },
        },
        model: {
          value: _vm.c6,
          callback: function ($$v) {
            _vm.c6 = $$v
          },
          expression: "c6",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }