import { GetTomorrowString } from "./date.helper";
import { returnCopyOfObject } from "./object.helper";
import { v4 as uuidv4 } from "uuid";
import i18n from "@/plugins/i18n";

export const groupNewOrderProductsByModel = function (products) {
    const groups = products.reduce((acc, value) => {
        const keyString = `${value.type}/${value.familia}/${value.categoria}/${value.modelo}`;
        let auxiliarPrice = value.selling_price;
        if (!auxiliarPrice) auxiliarPrice = value.price_selected;
        if (!auxiliarPrice) auxiliarPrice = value.price;

        if (!acc[keyString]) {
            return {
                ...acc,
                [keyString]: { qtd: value.qtd, price: auxiliarPrice * value.qtd },
            };
        } else
            return {
                ...acc,
                [keyString]: {
                    qtd: acc[keyString].qtd + value.qtd,
                    price: acc[keyString].price + auxiliarPrice * value.qtd,
                },
            };
    }, {});

    return Object.keys(groups).map((key) => {
        const second_group_key = key.split("/").pop();
        return { model: second_group_key, ...groups[key] };
    });
};

export const findItemInArrayOfCardHeader = function (array, item) {
    const find_item = array.findIndex(
        (element) => element.cnpj === item.cnpj && element.num_pedido === item.num_pedido
    );
    return find_item;
};

export const addItemToArrayOfCardHeader = function (obj, item) {
    item["item_id"] = uuidv4();
    if (item.status_checkout != "DELETE") obj.total += item.total;
    // if (obj.cd_reserva === "" && item.cd_reserva !== "") obj.cd_reserva = item.cd_reserva;
    if (item.cd_reserva && !obj.cd_reserva.includes(item.cd_reserva)) {
        obj.cd_reserva.push(item.cd_reserva);
    }
    obj.items.push(item);
};

export const groupItemsAsCardHeaders = function (items) {
    var array = [];
    items.forEach((item) => {
        const index = findItemInArrayOfCardHeader(array, item);
        if (index === -1) {
            var new_item_in_array = {
                index_in_array: array.length,
                uuid: item.uuid,
                cnpj: item.cnpj,
                nome_cliente: item.nome_cliente,
                num_pedido: item.num_pedido,
                show_card: false,
                status_card: item.status_card,
                log_card: item.log_card,
                num_ifc: item.num_ifc ? item.num_ifc : "",
                tipo_pedido: item.tipo_pedido,
                // Advanced order
                cd_divisao: item.cd_divisao ? item.cd_divisao : "",
                cd_operacao: item.cd_operacao ? item.cd_operacao : "",
                dt_entrega: item.dt_entrega ? item.dt_entrega : "",
                dt_embarque: item.dt_embarque ? item.dt_embarque : "",
                sigla_empresa_embarque: item.sigla_empresa_embarque
                    ? item.sigla_empresa_embarque
                    : "",
                local_estoque: item.local_estoque ? item.local_estoque : "",
                max_dias: item.max_dias ? item.max_dias : "",
                min_dias: item.min_dias ? item.min_dias : "",

                cd_reserva: item.cd_reserva ? [item.cd_reserva] : [],

                duplicated: item.duplicated,
                permite_reenvio: item.permite_reenvio,
                cond_pagto: item.cond_pagto || "",

                num_pedido_base: item.num_pedido_base || "",

                // Variaveis que mudam com o percorrer do array items original
                total: 0,
                items: [],
            };

            const new_length = array.push(new_item_in_array);
            addItemToArrayOfCardHeader(array[new_length - 1], item);
        } else {
            addItemToArrayOfCardHeader(array[index], item);
        }
    });
    return array;
};

export const filterItemsByCnpjAndOrder = function (items, cnpj, pedido) {
    const aux = items.filter((item) => item.cnpj == cnpj && item.num_pedido == pedido);
    return aux[0].items;
};

export const filterItemsByUuid = function (items, uuid) {
    const aux = items.filter((item) => item.uuid == uuid);
    return aux;
};

export const payloadToEditionCheckout = function (payload) {
    const { id, filename, cnpj, num_pedido, uuid, statement, advanced_order = {} } = payload;
    const { cd_sku_output, qty, dt_entrega, vl_p_unitario, min_dias, max_dias, item_id } =
        payload.item;
    const {
        new_dt_embarque = "",
        new_sigla_empresa_embarque = "",
        new_local_estoque = "",
        new_min_dias = "",
        new_max_dias = "",
        reserva = "",
        reply_days = false,
    } = advanced_order;

    const [new_cd = "", new_ds = ""] = payload.sku.split(" -- ");
    const { data: new_dt = "", qtd: new_qty = "", vl: new_vl = "" } = payload;

    return [
        {
            id,
            filename,
            item_id,

            cnpj,
            num_pedido,
            uuid,

            cd_sku_output,
            qty,
            dt_entrega,
            vl_p_unitario,
            min_dias,
            max_dias,

            new_dt_embarque,
            new_sigla_empresa_embarque,
            new_local_estoque,
            new_min_dias,
            new_max_dias,
            reserva,
            reply_days,

            new_cd,
            new_ds,
            new_qty,
            new_dt: new_dt || GetTomorrowString(),
            new_vl,
            statement,
        },
        payload.item,
    ];
};

export const preparePayloadToAddOrEditItemCheckout = function (payload, filterToApi, groupedItems) {
    const { id, filename } = filterToApi;
    let payloadWithIdAndFilename = { id, filename, ...returnCopyOfObject(payload) };
    const { cnpj, num_pedido } = payloadWithIdAndFilename;

    if (!payloadWithIdAndFilename["item"]) {
        const group = groupedItems.find((el) => el.cnpj == cnpj && el.num_pedido == num_pedido);
        payloadWithIdAndFilename["item"] = group["items"][0];
        payloadWithIdAndFilename["statement"] = "new";
        return payloadToEditionCheckout(payloadWithIdAndFilename);
    }

    payloadWithIdAndFilename["statement"] = "edit";
    return payloadToEditionCheckout(payloadWithIdAndFilename);
};

export const returnFirstItemOfCardOrder = function (
    { uuid, num_pedido: numPedido, cnpj },
    groupedItems
) {
    const group = groupedItems.find(
        (el) => el.uuid == uuid && el.cnpj == cnpj && el.num_pedido == numPedido
    );
    return group["items"][0];
};

export const replyDaysToMultiple = function (objectToApi, groupedItems) {
    const { reply_days, new_min_dias, new_max_dias } = objectToApi;

    if (reply_days && (new_min_dias !== "" || new_max_dias !== "")) {
        const uuid_list = groupedItems.map((item) => item["uuid"]);
        const { id, filename, cnpj, num_pedido, uuid, min_dias, max_dias } = objectToApi;
        const { new_cd: cd_sku, new_ds: ds_sku } = objectToApi;

        return [
            true,
            {
                id,
                filename,
                cnpj,
                num_pedido,
                uuid,
                uuid_list,
                cd_sku,
                ds_sku,
                min_dias,
                max_dias,
                new_min_dias,
                new_max_dias,
            },
        ];
    }

    return [false, {}];
};

export const groupTotalItemsAndPerCardByOldDate = function (objectToApi, groupedItems, allItems) {
    const { new_dt, dt_entrega: old_dt } = objectToApi;

    if (new_dt == old_dt) return null;

    const ordersOfCard = filterItemsByCnpjAndOrder(
        groupedItems,
        objectToApi.cnpj,
        objectToApi.num_pedido
    );

    const equalDates = {
        total: allItems.filter(
            (item) => item.dt_entrega == old_dt && item.status_checkout != "DELETE"
        ),
        card: ordersOfCard.filter(
            (item) => item.dt_entrega == old_dt && item.status_checkout != "DELETE"
        ),
    };

    return equalDates;
};

export const formatItemFields = function (item) {
    return {
        identifiers: {
            item_id: item.item_id,
            id: item.id,
            filename: item.filename,

            cnpj: item.cnpj,
            num_pedido: item.num_pedido,
            uuid: item.uuid,
        },

        formValues: {
            sku: item.cd_sku_output,
            data: item.dt_entrega,
            vl: item.vl_p_unitario,
            qtd: item.qty,
            cd_reserva: item.cd_reserva,
        },

        advanced_order: {},

        item,
    };
};

export const groupTotalItemsAndCard = function (uuid, allItems, booleanCompareFunction) {
    const itemsOfCard = filterItemsByUuid(allItems, uuid);

    const totalFiltered = allItems.filter(
        (item) => booleanCompareFunction(item) && item.status_checkout != "DELETE"
    );

    const cardFiltered = itemsOfCard.filter(
        (item) => booleanCompareFunction(item) && item.status_checkout != "DELETE"
    );

    const equivalent = {
        total: totalFiltered.map(formatItemFields),
        card: cardFiltered.map(formatItemFields),
    };
    return equivalent;
};

export const groupTotalItemsAndPerCardByOldPrice = function (objectToApi, groupedItems, allItems) {
    const { cd_sku_output, vl_p_unitario: old_price, new_vl: new_price } = objectToApi;

    if (new_price == old_price) return null;

    const ordersOfCard = filterItemsByCnpjAndOrder(
        groupedItems,
        objectToApi.cnpj,
        objectToApi.num_pedido
    );

    const equalPrices = {
        cd_sku: "",
        ds_sku: "",
        total: [],
        card: [],
    };

    function compareItemWithValues(item) {
        if (
            item.vl_p_unitario == old_price &&
            item.cd_sku_output == cd_sku_output &&
            item.status_checkout != "DELETE"
        ) {
            if (equalPrices.cd_sku == "") {
                equalPrices.cd_sku = item.cd_sku_output;
                equalPrices.ds_sku = item.ds_sku_input;
            }

            return true;
        }
        return false;
    }

    equalPrices.total = allItems.filter(compareItemWithValues);
    equalPrices.card = ordersOfCard.filter(compareItemWithValues);

    return equalPrices;
};

export const waitResponseInUpdateDateCardOrTotal = async function (equalDates, dispatch) {
    if (equalDates && (equalDates.total.length > 1 || equalDates.card.length > 1)) {
        const result = await dispatch("modal/ModalThreeButtonDateChanged", {}, { root: true });
        return result == "esc" ? null : result;
    }
    return null;
};

export const waitResponseInUpdatePriceCardOrTotal = async function (equalPrices, dispatch) {
    if (equalPrices && (equalPrices.total.length > 1 || equalPrices.card.length > 1)) {
        const cd_sku = equalPrices.total[0]
            ? equalPrices.total[0]["item"]["cd_sku_output"]
            : equalPrices.card[0]["item"]["cd_sku_output"];
        const ds_sku = equalPrices.total[0]
            ? equalPrices.total[0]["item"]["ds_sku_output"]
            : equalPrices.card[0]["item"]["ds_sku_output"];
        const result = await dispatch(
            "modal/ModalThreeButtonPriceChanged",
            { sku: cd_sku, sku_description: ds_sku },
            { root: true }
        );
        return result == "esc" ? null : result;
    }
    return null;
};

export const waitResponseInUpdateReserva = async function (equalReserva, dispatch) {
    if (equalReserva && (equalReserva.total.length > 1 || equalReserva.card.length > 1)) {
        const result = await dispatch(
            "modal/ModalThreeButtonGeneric",
            {
                title1: i18n.t("modals.update_reserva.title"),
                text1: i18n.t("modals.update_reserva.text"),
                title2: i18n.t("modals.update_reserva.title2"),
                text2: i18n.t("modals.update_reserva.title2"),
            },
            { root: true }
        );
        return result == "esc" ? null : result;
    }
    return null;
};

export const apiCallFromVuexStore = async function (request, params, { dispatch, commit }) {
    commit("saveStatus", "loading", { root: true });
    try {
        var res = await request(params);
        if (res.data.status == "error") throw "error";
        commit("PUSH_SUCCESS_TO_ARRAY", "edit item success", { root: true });
        await dispatch("search");
    } catch (error) {
        console.error(error);
        commit("PUSH_ERROR_TO_ARRAY", "edit item failed", { root: true });
    } finally {
        commit("saveStatus", "success", { root: true });
    }
};

export const prepareMultipleRequestToBeMade = function (request, items, mapCallback) {
    var aux = items.map(mapCallback);
    return aux.map((item) => [request, item]);
};

export const updateDates = async function (product, allItems, dispatch) {
    const compareDates = (item) =>
        product.item.dt_entrega != product.formValues.data &&
        item.dt_entrega == product.item.dt_entrega;

    const equalDates = groupTotalItemsAndCard(product.identifiers.uuid, allItems, compareDates);
    const response = await waitResponseInUpdateDateCardOrTotal(equalDates, dispatch);

    if (!response) return [];

    return equalDates[response];
};

export const updatePrices = async function (product, allItems, dispatch) {
    const comparePrices = (item) =>
        product.item.vl_p_unitario != product.formValues.vl &&
        item.vl_p_unitario == product.item.vl_p_unitario &&
        item.cd_sku_output == product.item.cd_sku_output;

    const equalPrices = groupTotalItemsAndCard(product.identifiers.uuid, allItems, comparePrices);

    const response = await waitResponseInUpdatePriceCardOrTotal(equalPrices, dispatch);

    if (!response) return [];

    return equalPrices[response];
};

export const updateReserva = async function (product, allItems, dispatch) {
    const compareReserva = (item) =>
        product.advanced_order.cd_reserva !== product.item.cd_reserva &&
        item.cd_sku_output == product.item.cd_sku_output;

    const equalReserv = groupTotalItemsAndCard(product.identifiers.uuid, allItems, compareReserva);
    const response = await waitResponseInUpdateReserva(equalReserv, dispatch);
    if (!response) return [];
    return equalReserv[response];
};

export const updateMultipleWithReplyDays = function (payload, groupedItems, request) {
    const { advanced_order } = payload;
    if (!advanced_order.reply_days) return;

    const { id, filename, cnpj, num_pedido, uuid } = payload.identifiers;
    const uuid_list = groupedItems.map((item) => item["uuid"]);
    const [new_cd, new_ds] = payload.formValues.sku.split(" -- ");
    const { min_dias, max_dias } = payload.item;
    const { new_min_dias, new_max_dias } = payload.advanced_order;

    const objAux = {
        id,
        filename,
        cnpj,
        num_pedido,
        uuid,

        uuid_list,
        cd_sku: new_cd,
        ds_sku: new_ds,

        min_dias,
        max_dias,
        new_min_dias,
        new_max_dias,
    };

    request(objAux);
};

export const apiCallWithGlobalLoading = async function (request, params, { commit, dispatch }) {
    var success = false;
    try {
        commit("saveStatus", "loading", { root: true });
        var response = await request(...params);
        if (response.data && response.data.status == "error") throw "error";

        commit("PUSH_SUCCESS_TO_ARRAY", "new item success", { root: true });
        await dispatch("search");
        success = true;
    } catch (error) {
        commit("PUSH_ERROR_TO_ARRAY", "new item failed", { root: true });
    } finally {
        commit("saveStatus", "success", { root: true });
    }
    if (success) return Promise.resolve();
    return Promise.reject();
};

export const multipleRequestApiCall = async function (arrayItems, request, { commit, dispatch }) {
    let arrayOfPromises;
    try {
        commit("SET_LOADING", true);
        commit("saveStatus", "loading", { root: true });
        arrayOfPromises = arrayItems.map((item) => request(item, item["item"]));
        await Promise.all(arrayOfPromises);
        commit(
            "PUSH_SUCCESS_TO_ARRAY",
            { text: "successCheckout", count: arrayOfPromises.length },
            { root: true }
        );
    } catch (error) {
        commit(
            "PUSH_ERROR_TO_ARRAY",
            { text: "errorCheckout", count: arrayOfPromises.length },
            { root: true }
        );
    } finally {
        await dispatch("search");
        commit("SET_LOADING", false);
        commit("saveStatus", "success", { root: true });
    }
};
