import {
    listTemplates,
    downloadTemplate,
    deleteTemplate,
    viewTemplate,
    createTemplate,
    uploadTemplate,
    loadTemplate,
} from "../../services/api/managementTemplates";
// import i18n from "@/plugins/i18n";

const initial_state = () => ({
    loading: false,
    loading_save: false,
    loading_view: false,
    templates: [],
    company_id: "",
    file_name: "",
    file_content: "",
    file_filter: "",
});
const state = initial_state();

const getters = {
    filter_to_api: (state) => ({
        company_id: state.company_id,
    }),
    templates: (state) => state.templates,
    loading: (state) => state.loading,
    loading_save: (state) => state.loading_save,
    loading_view: (state) => state.loading_view,
    company_id: (state) => state.company_id,
    file_name: (state) => state.file_name,
    file_content: (state) => state.file_content,
    file_filter: (state) => state.file_filter,
};

const mutations = {
    SET_LOADING: (state, loading) => (state.loading = loading),
    SET_LOADING_SAVE: (state, loading_save) => (state.loading_save = loading_save),
    SET_LOADING_VIEW: (state, loading_view) => (state.loading_view = loading_view),
    SET_TEMPLATE: (state, templates) => (state.templates = templates),
    SET_COMPANY_ID: (state, company_id) => (state.company_id = company_id),
    SET_FILE_NAME: (state, file_name) => (state.file_name = file_name),
    SET_FILE_CONTENT: (state, file_content) => (state.file_content = file_content),
    SET_FILE_FILTER: (state, file_filter) => (state.file_filter = file_filter),
    RESET_FILTER: (state) => {
        state.company_id = "";
        state.file_filter = "";
        state.templates = [];
    },
};

const actions = {
    search({ dispatch }) {
        dispatch("getTemplates");
    },
    clear({ commit, dispatch }) {
        commit("RESET_FILTER");
        dispatch("getTemplates");
    },
    async getTemplates({ commit, getters }) {
        commit("SET_LOADING", true);
        try {
            const filter = getters.filter_to_api;

            const response = await listTemplates(filter);

            const formattedFiles = response.files.map((template) => {
                return template.split("/").pop();
            });

            commit("SET_TEMPLATE", formattedFiles);
        } catch {
            commit("SET_TEMPLATE", []);
        }
        commit("SET_LOADING", false);
    },
    async downloadTemplate({ commit, getters }, file_name) {
        try {
            const response = await downloadTemplate({
                company_id: getters.company_id,
                filename: file_name,
            });
            const url = response.url;

            window.open(url, "_blank");
        } catch {
            commit("PUSH_ERROR_TO_ARRAY", "Erro ao baixar arquivo", { root: true });
        }
    },
    async deleteTemplate({ commit, dispatch, getters }, file_name) {
        const result = await dispatch(
            "modal/ModalGeneric",
            {
                title: "Deseja excluir o arquivo?",
                text: `O arquivo ${file_name} será excluído permanentemente.`,
                icon: "warning",
            },
            { root: true }
        );

        if (!result) return;

        try {
            const response = await deleteTemplate({
                company_id: getters.company_id,
                filename: file_name,
            });
            commit("PUSH_SUCCESS_TO_ARRAY", response.message, { root: true });
            dispatch("getTemplates");
        } catch {
            commit("PUSH_ERROR_TO_ARRAY", "Erro ao excluir arquivo", { root: true });
        }
    },
    async viewTemplate({ commit, getters }, file_name) {
        commit("SET_LOADING_VIEW", true);
        try {
            const response = await viewTemplate({
                company_id: getters.company_id,
                file_name: file_name,
            });
            commit("SET_FILE_CONTENT", response);
            commit("SET_FILE_NAME", file_name);
        } catch {
            commit("PUSH_ERROR_TO_ARRAY", "Erro ao visualizar arquivo", { root: true });
        }
        commit("SET_LOADING_VIEW", false);
    },
    async saveTemplate({ commit, dispatch, getters }, payload) {
        commit("SET_LOADING_SAVE", true);
        const { file_name, content } = payload;
        try {
            const response = await createTemplate({
                company_id: getters.company_id,
                file_name,
                content: JSON.parse(content),
            });
            commit("PUSH_SUCCESS_TO_ARRAY", response.message, { root: true });
            dispatch("getTemplates");
        } catch {
            commit("PUSH_ERROR_TO_ARRAY", "Erro ao salvar arquivo", { root: true });
        }
        commit("SET_LOADING_SAVE", false);
        await loadTemplate();
    },
    async uploadTemplate({ commit, dispatch, getters }, file) {
        commit("SET_LOADING_SAVE", true);
        try {
            await uploadTemplate({
                company_id: getters.company_id,
                file,
            });
            dispatch("getTemplates");
        } catch {
            commit("PUSH_ERROR_TO_ARRAY", "Erro ao fazer upload do arquivo", { root: true });
        }
        commit("SET_LOADING_SAVE", false);
        await loadTemplate();
    },
    clearFilter({ commit }) {
        commit("RESET_FILTER");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
