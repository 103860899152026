// Force redirect é diferente de poder ter acesso

export const userHasModulesOptions = (modules) => (!modules ? false : true);

export const userHasAccessToCertainApp = (modules, moduleName) => {
    if (!userHasModulesOptions(modules)) return false;
    if (!modules[moduleName]) return false;
    if (!modules[moduleName]["available"]) return false;
    if (modules[moduleName]["available"]) return true;
    else return false;
};

export const userHasExclusiveAccessToCertainApp = (modules, moduleName) => {
    if (!userHasAccessToCertainApp(modules, moduleName)) return false;
    if (!modules[moduleName]["exclusive"]) return false;
    if (modules[moduleName]["exclusive"]) return true;
    return false;
};

// Renomear depois talvez
export const shouldForceRedirectToSuggestion = (modules) => {
    const isExclusive = userHasExclusiveAccessToCertainApp(modules, "sugestoes");
    if (isExclusive) return true;
    return false;
};
