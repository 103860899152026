import store from "@/store";
import { shouldForceRedirectToSuggestion } from "@/helpers/router.helper";

export default (to, from, next) => {
    // Check if user is authenticated based on Vuex login's module actions

    if (!store.getters.isAuthenticated) {
        // If not authenticated, proceed to login route
        // store.dispatch('save_denied_url', to.fullPath);

        var query_obj = { redirect: "/" };
        if (to.fullPath != "/") {
            query_obj = { redirect: to.fullPath };
        }
        next({ name: "Login", query: query_obj });
    } else {
        // If authenticated, may pass to a guarded component
        var modules = store.getters.modules;

        if (shouldForceRedirectToSuggestion(modules)) {
            next({ name: "SugestoesPrincipal" });
        } else next();
    }
};
