import axios from "axios";
import store from "@/store";
import { encrypt } from "@/helpers/encrypt.helper";
import pedidosService from "@/services/api/pedidos";
import login from "@/services/api/login";
import info from "@/services/api/info";
import router from "@/router";
import { shouldForceRedirectToSuggestion } from "@/helpers/router.helper";

import { companyListAsObject } from "@/helpers/companies.helper";
import {
    localStorageGet,
    localStorageSet,
    localStorageSetMultiple,
    localStorageRemove,
    localStorageRemoveMany,
} from "@/helpers/storage.helper";
import { sessionStorageSet, sessionStorageRemove } from "@/helpers/storage.helper";

const actions = {
    set_token({ commit }, token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        localStorageSet("access_token", token);
        commit("saveToken", token);
    },
    save_denied_url({ commit }, url) {
        commit("save_denied_url", url);
    },
    // Auth
    async login({ commit, dispatch }, params) {
        commit("SET_LOADING_STATUS", true);
        try {
            const res = await login.login({ email: params.email, password: params.password });
            const response = res.data;
            const token = res.data.token;

            if (response.redirect) {
                router.push({
                    name: "TwoFactor",
                    query: {
                        method: response.method,
                        interval: response.interval,
                        email: response.email,
                        token: encrypt(token),
                        uri: encrypt(response.uri),
                        url: encrypt(response.url),
                        tf_login_validity: response.tf_login_validity,
                    },
                });
                commit("SET_LOADING_STATUS", false);
                return;
            }

            localStorageSet("type_loggin", "normal");
            commit("SET_TYPE_LOGGIN", "normal");

            await dispatch("consolidateUserLogin", token);
        } catch (error) {
            console.error(error);
            commit("SET_LOADING_STATUS", false);
            return Promise.reject("Login failed");
        }
        commit("SET_LOADING_STATUS", false);
    },
    async consolidateUserLogin({ dispatch, getters }, token) {
        await dispatch("set_token", token);
        await dispatch("info");

        if (shouldForceRedirectToSuggestion(getters.modules)) {
            router.push({ name: "SugestoesPrincipal" });
        } else {
            router.push("/");
        }
        return;
    },
    async info({ commit }) {
        const res = await info.get();

        const language_changed_by_user = localStorage.getItem("language_changed_by_user");
        const username_language_changed = localStorageGet("username_language");
        const language_of_user = res.data.language;

        if (res.data.advanced_order) {
            commit("SET_ADVANCED_ORDER", true);
        } else commit("SET_ADVANCED_ORDER", false); // Mudar

        if (res.data.edit_order_number) {
            commit("SET_EDIT_ORDER_NUMBER", true);
        } else commit("SET_EDIT_ORDER_NUMBER", false); // Mudar

        if (language_changed_by_user) {
            if (username_language_changed == res.data.username) {
                commit("configs/CHANGE_LANGUAGE", language_changed_by_user);
            } else {
                localStorageRemove("language_changed_by_user");
            }
        } else if (!language_of_user || language_of_user.toUpperCase() == "PT") {
            commit("configs/CHANGE_LANGUAGE", "pt");
        } else {
            commit("configs/CHANGE_LANGUAGE", "en");
        }

        const {
            username,
            name,
            empresa,
            role,
            local,
            client_email,
            lista_empresas,
            show_opt_in,
            new_features,
            owner_id,
            empresa_id,
        } = res.data;

        if (show_opt_in) {
            commit("SET_WHATSAPP_BANNER", true);
        } else {
            commit("SET_WHATSAPP_BANNER", false);
        }

        const customFilters = res.data["filters"];
        if (customFilters) {
            commit("SET_CUSTOM_FILTERS", customFilters);
        }

        let pages = res.data.pages;

        var formated_lista_empresas = [];
        if (lista_empresas) {
            formated_lista_empresas = companyListAsObject(lista_empresas);
        }

        if (!pages) {
            pages = [];
        }

        const modules = res.data.modules || {};

        const foto_url = "https://imagens.ndig.com.br/internet/perfil_sem_foto_facebook.jpg";

        var obj = {
            username,
            name,
            empresa,
            role,
            client_email,
            foto_url,
            pages,
            new_features,
            modules,
            location: local,
            lista_empresas: formated_lista_empresas,
            owner_id,
            company_id: empresa_id,
        };

        localStorageSetMultiple(obj);

        await commit("SET_USERNAME", username);
        await commit("SET_COMPANY", empresa);
        await commit("SET_NAME", name);
        await commit("SET_ROLE", role);
        await commit("SET_LOCATION", local);
        await commit("SET_MODULES", modules);
        await commit("SET_CLIENT_EMAIL", client_email);
        await commit("SET_FOTO_URL", foto_url);
        await commit("SET_PAGES_LIMITED", pages);
        await commit("SET_LISTA_EMPRESAS", formated_lista_empresas);
        await commit("SET_NEW_FEATURES", new_features);
        await commit("SET_OWNER_ID", owner_id);
        await commit("SET_COMPANY_ID", empresa_id);

        commit("DOWN_SPLASH_SCREEN");
    },
    async sendTwoFactorCode(_, params) {
        const { code, token } = params;
        return login.sendTwoFactorCode(code, token);
    },
    async resendCodeTwoFactor(_, token) {
        return login.resendCode(token);
    },
    async login_as_admin({ getters, commit, dispatch }, { email }) {
        let res = null;
        try {
            res = await login.login_as_admin(email);
            const token = res.data.token;

            var login_as_admin_info = {
                origin_username: getters.username,
                destiny_email: email,
                old_token: getters.token,
                new_token: token,
            };

            localStorageSet("type_loggin", "as_admin");
            commit("SET_TYPE_LOGGIN", "as_admin");

            sessionStorageSet("logged_from_admin_account", login_as_admin_info);
            commit("saveLoggedAsAdmin", login_as_admin_info);

            await dispatch("consolidateUserLogin", token);
        } catch {
            commit("PUSH_ERROR_TO_ARRAY", "Login failed", { root: true });
        }
        return res;
    },
    async backToLoginBefore({ getters, dispatch, commit }) {
        const login_before_info = getters.logged_from_admin_account;
        await dispatch("consolidateUserLogin", login_before_info.old_token);
        await commit("saveLoggedAsAdmin", null);
        localStorageRemove("logged_from_admin_account");
    },
    async logout({ dispatch, commit, getters }, called_by_error = false) {
        await commit("saveStatus", "loading");

        let clientEmail = getters.client_email;
        let itIsSSOLogin = false;
        try {
            const result = await axios.get(
                `${process.env.VUE_APP_WEDUU_LOGIN}/db/check_sso?email=${clientEmail}`
            );
            itIsSSOLogin = result.data.result;
        } catch (error) {
            console.error("erro ao chamar a requisição", error);
        }

        // Faz o logout do projeto de login primeiro
        if (itIsSSOLogin) {
            try {
                await dispatch("clearUserInfo");

                window.location.href = `${process.env.VUE_APP_WEDUU_LOGIN_FRONTEND}/logout`;
            } catch (err) {
                console.error(err);
            }
        }

        try {
            if (getters.isAuthenticated && !called_by_error) await login.logout();
            delete axios.defaults.headers.common["Authorization"];

            localStorageRemoveMany([
                "access_token",
                "username",
                "name",
                "company",
                "role",
                "location",
                "client_email",
                "foto_url",
                "pages",
                "logged_from_admin_account",
                "lista_empresas",
                "isAuthenticated_pedmais",
                "type_loggin",
                "owner_id",
            ]);
            sessionStorageRemove("logged_from_admin_account");

            await dispatch("autocomplete/resetData");

            if (store.hasModule("detalhamento")) await dispatch("detalhamento/ClearFilter");

            dispatch("clearUserInfo");
        } catch (err) {
            commit("PUSH_ERROR_TO_ARRAY", "Logout failed", { root: true });
        }

        await commit("saveStatus", null);
        const routeName = router.app.$route.name;

        if (routeName == "Login") return;
        router.replace("/login");
    },
    checkAccess({ commit }) {
        const langFull = navigator.language;
        const lang = langFull.includes("-") ? langFull.split("-")[0] : langFull;

        let language = "en";

        switch (lang) {
            case "pt":
            case "es":
            case "en":
                language = lang;
                break;
        }

        commit("configs/CHANGE_LANGUAGE", language);
    },
    saveLoginLembrarMe({ commit }, login_attempt) {
        commit("saveLoginStatus");
        var string = JSON.stringify(login_attempt);
        // const string_encrypted = CryptoJS.AES.encrypt(string, SECRET).toString();
        localStorageSet("login_pedmais", string);
    },
    async clearUserInfo({ commit }) {
        await commit("saveStatus", null);
        await commit("saveToken", null);
        await commit("SET_USERNAME", null);
        await commit("SET_COMPANY", null);
        await commit("SET_NAME", null);
        await commit("SET_ROLE", null);
        await commit("SET_LOCATION", null);
        await commit("SET_CLIENT_EMAIL", null);
        await commit("SET_FOTO_URL", null);
        await commit("SET_LISTA_EMPRESAS", []);
        await commit("SET_PAGES_LIMITED", []);
        await commit("saveLoggedAsAdmin", null);
        await commit("SET_TYPE_LOGGIN", null);
        await commit("SET_OWNER_ID", null);

        localStorageRemoveMany([
            "access_token",
            "username",
            "name",
            "company",
            "role",
            "location",
            "client_email",
            "foto_url",
            "pages",
            "logged_from_admin_account",
            "lista_empresas",
            "isAuthenticated_pedmais",
            "type_loggin",
            "owner_id",
        ]);
    },
    removeLoginLembrarMe() {
        localStorageRemove("login_pedmais");
    },
    setSidebarCollapse({ commit }, collapsed) {
        commit("setSidebarCollapse", collapsed);
    },
    setSideBarHidden({ commit }, hidden) {
        commit("setSideBarHidden", hidden);
    },
    setHiddenToTrue({ commit }, active) {
        commit("setHiddenToTrue", active);
    },
    setHiddenToFalse({ commit }, active) {
        commit("setHiddenToFalse", active);
    },
    setCollapsableToFalse({ commit }, active) {
        commit("setCollapsableToFalse", active);
    },
    setSidebarMenuActive({ commit }, active) {
        commit("setSidebarMenuActive", active);
    },
    getGlobalPedmaisParams({ commit }) {
        pedidosService
            .list_orders_params()
            .then((result) => {
                commit("SET_GLOBAL_PARAMETERS_PEDMAIS", result.data);
            })
            .catch((err) => {
                console.error(err);
            });
    },
};

export default actions;
